@import url(https://fonts.cdnfonts.com/css/circular-std);
html {
  scroll-behavior: smooth;
}
body,
html {
  font-family: "Circular Std", sans-serif !important;
}

.term-page {
  padding-top: 130px;
}

.comingsoon-img {
  width: 300px !important;
  margin: 50px auto;
}


.balance-check {
  background: none !important;
  border: none !important;
  color: #fe7702 !important;
}

.widget-container ._1CW8I {
  display: none !important;
}

.nil-providers {
  padding: 100px 0;
  position: relative;
}

.globe-image {
  position: absolute;
  max-width: 450px;
  left: -58%;
  bottom: 0;
}

hr {
  margin: 1rem 0;
  color: #ff8eff !important;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

button.submit-bt {
  background: #fe7702 !important;
  color: #fff !important;
  border-radius: 12px;
  margin: 0 7px 0 0;
  height: 52px;
}

.profile-bt-new img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.buy-now-bt {
  width: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
.user-timer-box .timer-box {
  background: #4f0c75;
  position: absolute;
  right: 3px;
  /* width: 185px; */
  top: 3px;
  height: 26px;
  border-radius: 49px;
  text-align: center;
  color: #fff;
  padding: 1px 10px;
}
.expired-nft {
  background: #4f0c75;
  padding: 2px 4px;
  border-radius: 50px;
  color: #ffffff;
  /* font-weight: 600; */
  /* margin-bottom: 10px; */
  display: block;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.coming-soon {
  background: #fe7702;
  max-width: fit-content;
  padding: 2px 7px;
  border-radius: 80px;
}

.signup-bt {
  text-align: center;
  margin: 20px 0 0;
}

.signup-bt a {
  color: #fe7702 !important;
}

.login-form-box button {
  width: 14vw;
  padding: 0 15px;
  margin: 40px auto 0 !important;
  display: block;
  background: #fe7702 !important;
}

.forgot {
  display: block;
  text-align: end;
  color: #fff !important;
  width: fit-content;
  margin-left: auto;
}

.login-form-box input {
  height: 55px;
  font-weight: 400;
}

.user-timer-box .timer-box ul {
  padding: 0;
  line-height: 26px;
}

.error-new-box {
  left: 24px;
  color: red;
  position: absolute;
  top: 52px;
  font-size: 14px;
}

.thenae-box {
  position: relative;
}

.air-desc {
  font-size: 19px;
  color: #ffd6ae;
  margin-bottom: 0;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 600;
}
.detail-owner-heading {
  margin-bottom: -13px;
}

.balance-nw {
  color: black !important;
  font-size: 24px !important;
  font-weight: 700 !important;
}

.withdraw-bt {
  background: #fe7702;
  padding: 10px 21px;
  border-radius: 10px;
  color: #fff;
}

section.collection_section.view-balance-box .single-multiple-box {
  max-width: 500px;
  text-align: center;
  background: white;
  border-radius: 10px;
  padding: 20px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 7px #dbdbdb;
  width: 100%;
}
.air-price-new {
  color: #fff;
  font-size: 18px;
  margin-top: 17px;
  margin-bottom: 20px;
  line-height: 30px;
}
.spinner-content h6 {
  font-weight: 600;
  color: #4f0c75;
}

.create-items .activedata {
  border: 1px solid #f67728;

  background: #f7f3f3;
}

.bt-div {
  margin-top: 30px !important;
}

.air-price-new span {
  background: #4f0c75;
  padding: 5px 13px;
  border-radius: 30px;
  margin-left: 6px;
  white-space: nowrap;
  font-size: 15px;
}

.creater-collection-area {
  display: flex;
  justify-content: space-between;
}

.collection-team {
  /* margin-left:  100px; */

  color: #fff;
}

#details-collapse-text p {
  color: #fff;
  font-size: 14px;
  margin: 0;
  min-width: 180px;
}

.view-details-collapse {
  margin: 4px 0;
  border-radius: 10px;
  font-size: 16px !important;
  background: none !important;
  border: none !important;
  padding: 0 !important;
  text-decoration: underline !important;
}

/* .creater-rayality-box {
    width: 360px;
} */
.collapse-details-view {
  display: flex;
  /* justify-content: space-between; */
}

.featured-athlet-left {
  /* background-image: url('../images/athlet-leftt.png'); */

  background-position: right;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  height: 500px;

  border-radius: 20px;

  padding: 20px;

  display: flex;

  flex-direction: column;

  justify-content: center;
}

.featured-athlet-right {
  /* background-image: url('../images/athlet-right.png'); */

  background-position: right;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  height: 500px;

  border-radius: 20px;

  padding: 20px;

  display: flex;

  flex-direction: column;

  justify-content: center;
  /* background-color: #1f1f23; */
}

.featured-athlet-left h6,
.featured-athlet-right h6 {
  color: #fe7702;
  font-size: 20px;
  margin-bottom: 20px;
}

.featured-athlet-left h3,
.featured-athlet-right h3 {
  font-size: 50px;

  font-weight: 700;
}

.featured-athlet-left p,
.featured-athlet-right p {
  font-size: 18px;
  max-width: 500px;
  margin-bottom: 15px;
}

.feature-athlet {
  background: #000;

  padding: 40px 0;
}

.sign-explore-bt {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 83px;
  width: 169px;
}

.sign-explore-bt {
  /* background-image: url('../images/btn-new.png'); */
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 83px;
  width: 212px;
}

.feature-athlet .col-md-6 {
  padding: 15px !important;
}

.collection-team p {
  color: #fff;

  font-size: 14px;
}

.owner-details p {
  margin-bottom: 0;

  font-size: 15px;

  color: #fff;

  max-width: 240px;
}

.owner-list a {
  font-size: 14px;
  padding: 3px 7px 3px 0;
  /* margin-left: 100px; */
  min-width: 70px;
}

.owner-sale-box {
  color: #ffffffad !important;
}

.owner-list {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ffffff3b;
  padding: 10px 0px;
}
.owner-list:first-child {
  padding-top: 0;
}
.all-owner-box .owner-list:last-child {
  border: none !important;
}

.owner-list-nosale {
  padding-top: 13px;
}

.new-loader {
  border: 5px solid #d3d3d3;

  border-radius: 50%;

  border-top: 5px solid #fe7702;

  width: 40px;

  height: 40px;

  animation: spin 2s linear infinite;

  position: absolute;

  top: 35px;

  left: 35px;
}

.air-max.auction-page-air {
  margin: 24px 0;
}

.loader-list-items {
  position: relative;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.nft-collection-box li.react-tabs__tab--selected {
  background: #000 !important;

  border: none !important;

  color: #fff !important;

  border-radius: 5px !important;
}

.nft-collection-box li.react-tabs__tab--selected:focus::after {
  background: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.collection-nft-tab-market {
  margin-top: 40px;
}

.market_banner_section #left-tabs-example-tabpane-first {
  margin-top: 100px;
  min-height: 400px;
}

.no-data-div {
  position: relative;
  min-height: 300px;
}

.no-data-div h2 {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.owner-details {
  margin-right: 40px;

  width: 352px;
}

.owner-list .sale-auction-tab li.nav-item button {
  width: 105px;

  padding: 6px 0px;

  font-size: 14px;

  /* background: url('../images/buy.png'); */

  background-position: left;

  background-repeat: no-repeat;

  background-size: contain;

  height: 79px;

  display: block;

  border-radius: 5px;

  text-align: center;

  line-height: 68px;

  color: #fff !important;

  max-width: 190px;

  border: none;

  box-shadow: none;

  height: 83px;
}

.margin-safari .goog-te-combo {
  margin-left: 12px !important;
}

.owner-list button.buy-now-bt {
  width: 100%;
}

.buy-now-bt {
  font-size: 15px !important;
}

.collection-team img {
  width: 50px !important;
  height: 50px !important;
  border-radius: 100%;
  border: 3px solid #fff;
  object-fit: cover;
  margin-right: 5px;
}

.collection-team a {
  color: white !important;
}

.owner-list img {
  width: 55px;

  height: 55px;

  border-radius: 50%;

  border: 3px solid #fff;

  object-fit: cover;
}

.linking-modal .modal-dialog {
  max-width: 410px;
}

.linking-modal .modal-content {
  padding: 16px 0 !important;
}

.linking-modal a {
  background: #fe7702;
  display: inline-block;
  color: #fff !important;
  width: 250px;
  border-radius: 10px;
  padding: 17px 0;
  margin: 5px 0;
  font-size: 16px;
}

.buy-now-modal a.buy-now-bt {
  width: 120px;

  margin-top: 10px;
}

.owner-list .sale-auction-tab li.nav-item button.nav-link.active {
  background-color: rgba(255, 0, 0, 0) !important;
}

.all-owner-box .tab-content {
  margin-left: 20px !important;
}

.all-owner-box .tab-content input {
  font-size: 14px;

  padding: 7px !important;

  height: 31px;

  max-width: 170px;

  margin-bottom: 5px;
}

.buy-now-modal .modal-content {
  padding-bottom: 0;
}

.buy-now-modal input {
  margin-bottom: 16px;
}

.buy-now-modal label {
  margin-bottom: 0;
}

.buy-now-modal {
  text-align: left;
}

.buy-now-modal .modal-dialog {
  max-width: 480px;
}

.no-data-new {
  text-align: center;
}

.creater-collection-area a {
  color: #fff !important;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.collection-up-both-img .modal-content {
  padding: 0 !important;
}

.finda-display {
  margin-top: 30px !important;
}

.new-banner-area {
  position: relative;

  margin-top: 100px;
}

.discord {
  color: #bf74cb !important;

  font-size: 20px;

  background: white;

  width: 100px;

  position: absolute;

  top: 50%;

  left: 50%;

  width: 200px;

  border-radius: 50px;

  text-align: center;

  padding: 11px 0;

  transform: translate(-130%, -50%);
}

.term-privacy-box-new {
  display: flex;

  /* align-items: flex-end; */
}
.rightsdefoote {
  margin: 30px 0;
}
.buy-now-modal .buy-now-bt {
  height: 43px;
  font-size: 17px !important;
  border-radius: 5px !important;
  padding: 0 10px !important;
  margin-top: 0;
}
.buy-now-bt.nft-buy-now {
  height: 40px !important;
  width: 130px !important;
  border-radius: 5px !important;
  margin-top: 0;
}

.term-page.about-page {
  padding: 150px 0 50px;
}

.forgot-password-page {
  background-image: url("../images/about_bg.jpg");

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  padding: 120px 0 60px;
  height: 75.2vh;
}
.forgot-password-form {
  margin-top: 13vh !important;
}

.contact-us-page {
  background-image: url("../images/about_bg.jpg");

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  padding: 120px 0 60px;
  min-height: 750px;
  max-height: 1600px;
}

.about-page {
  background-image: url("../images/about_bg.jpg");

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  padding: 120px 0 60px;
  min-height: 900px;
}

.about-page h1 {
  font-size: 80px;

  text-transform: uppercase;

  font-weight: 700;
}

.about-page h2 {
  font-size: 50px;

  text-transform: uppercase;

  font-weight: 700;

  margin-bottom: 0;
}

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

.about-page h1 span {
  color: #fe7702;
}

.about-page p {
  max-width: 630px;

  font-size: 18px;

  line-height: 32px;
}

.about-page h3 {
  font-size: 40px;

  margin: 30px 0;
}

.filda-bounding {
  background-image: url("../images/about_bg_two.jpg");

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  padding: 60px 0;
}

.listingsection .nft-collection-box ul {
  margin: auto !important;
  padding: 7px 7px;
}

.my_listing-area.listingsection {
  background-color: white;
  padding-top: 10px;
}

.listingsection .nft-collection-box li {
  padding: 11px 12px;
}

.rightside_border {
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  background-color: #ffffff00;
  border-color: #ffffff7d;
  padding: 8px 10px 2px 10px;
  /* width: 280px; */
}
.buy-sell-created {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.install-metamask {
  text-align: center;
}

.install-metamask-modal .modal-content {
  width: 70% !important;
  margin: 0 auto;
  padding: 10px 0 15px 0;
}

.install-metamask img {
  width: 80px;
}
.rightside_border .created {
  font-size: 14px;
  margin-bottom: 5px;
}
/* .created_use_img {
  filter: invert(1);
} */
.not-available-btn {
  display: flex;
}

.rightside_border p {
  font-size: 17px;
}

.frame-number {
  color: #fbb03b !important;
  /* margin-top: 14px; */
  display: inline-block;
  font-size: 23px;
}
.frame-value {
  color: #fbb03b !important;
  display: block;
  font-size: 19px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.wallet_num {
  display: inline-block;
  align-items: center;
  font-size: 14px;
  border-radius: 100px;
  line-height: 22px;
  text-align: center;
  color: grey;
  background: rgb(255 255 255);
  transition: all 0.12s ease-in-out 0s;
  padding: 6px 18px;
  margin-left: 34px;
}
.expired-area {
  display: unset;
}
.rightside_border div {
  position: relative;
  padding-left: 25px;
}

.rightside_border div span {
  color: white;
  font-size: 17px;
}

.rightside_border div img {
  position: absolute;
  left: 0;
}
.created_use_img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  object-fit: cover;
  overflow: hidden;
  margin-top: 4px;
}

.listingsection .nft-collection-box li.react-tabs__tab--selected {
  background: #fe7702 !important;
  white-space: nowrap;
}

.founding-div {
  /* max-width: 650px; */

  text-align: center;

  margin: 100px auto 0;
}

.rightsdefoote a {
  padding-right: 18px;
}

.member-one {
  background: #1b1225;
  border-radius: 15px;
  padding: 15px;
}

.our-team h2 {
  font-size: 50px;
  color: #fff;
  margin-top: 60px;
}

.our-team h2 span {
  color: #fe7702 !important;
}

.member-one {
  background: #2a202d;
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 25px;
  position: relative;
  z-index: 1;
  height: 100%;
}

.member-info p {
  color: #ffffffad;
  margin-bottom: 0 !important;
}

a.linkedin-bt {
  position: absolute;
  bottom: -27px;
  left: 50%;
  transform: translate(-50%, 0);
}

.our-team .col-md-6 {
  padding: 0 15px !important;
}

.user-img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 20px;
}

.user-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.member-one {
  text-align: center;
}

.air-max {
  margin-bottom: 20px;
}

.founding-div h4 {
  line-height: 40px;

  font-size: 27px;
}

.founding-div p {
  font-size: 18px;
}

.greate-our-goal {
  background-image: url("../images/goal.jpg");

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  padding: 60px 0;
}

.greate-left h2 {
  font-size: 60px;

  font-weight: 700;
}

.greate-left h2 span {
  color: #000;
}

.greate-left p {
  color: #000;
}

.greate-left {
  padding: 0 50px;
}

.footer-link {
  color: #fff !important;
}

.home_banner_section {
  /* background-image: url('../images/home_banner.jpg'); */

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;
  padding: 180px 0 90px;
}

.view-balance-box {
  min-height: 650px;
}

:focus {
  box-shadow: none !important;
  outline: 0 !important;
}

fieldset,
img {
  border: 0 none;
}

input[type="submit"],
select {
  -webkit-appearance: none;
}

html {
  scroll-behavior: smooth;
}

.alice-carousel__dots {
  display: none !important;
}

.alice-carousel__next-btn-item {
  background: #fe7702;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 30px;
  color: #fff !important;
  position: absolute;
  right: 0;
  top: 130px;
}
span.put-on-auction-note {
  /* font-style: italic;
  font-weight: bolder;
  font-size: 15px;
  color: #fe7702; */

  font-style: italic;
  font-weight: bolder;
  font-size: 15px;
  color: #7d02fe;
}

.nft-prize-usd {
  text-align: end;
  font-size: 14px;
  padding-top: 2px;
}
.alice-carousel__prev-btn-item {
  background: #fe7702;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 30px;
  color: #fff !important;
  position: absolute;
  left: 0;
  top: 130px;
  text-align: center;
}

img {
  border: none;
  height: auto;
  max-width: 100%;
  outline: 0;
  vertical-align: middle;
}

iframe {
  vertical-align: middle;
}

a:hover {
  text-decoration: none;
  color: #6bcbfc;
}

a,
abbr,
acronym,
address,
article,
aside,
b,
bdo,
big,
blockquote,
body,
br,
button,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
img,
ins,
kbd,
legend,
li,
map,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
ul,
var {
  border: medium none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none !important;
}

input[type="submit"],
select {
  -webkit-appearance: none;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}

header {
  padding: 9px 0;
  position: fixed;
  top: 0;
  z-index: 1024;
  width: 100%;
  background: #000;
}

.rightsdefooter p a {
  color: #fff;
}

.searchbar_header input {
  background: #f6f7fa !important;
  border-radius: 16px;
  border: 0;
  height: 56px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #081946;
  min-width: 330px;
}

.searchbar_header {
  position: relative;
  margin-left: 20px;
}

.bg-white {
  box-shadow: 2px 2px 2px #e1e1e138;
}

.profile-pagination {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-pagination li {
  list-style: none;
}

.profile-pagination li a {
  border: 1px solid #ffffff38;
  margin: 0 5px;
  display: block;
  padding: 5px 12px;
  border-radius: 5px;
  color: #fff;
}

.profile-pagination li.selected a {
  background: #fe7702;
}

.searchbar_header button {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translate(0, -50%);
}

.more-games:hover {
  color: #fe7702;
}

header .startselling_btn {
  background: #fe7702;
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  height: 52px;
}

.btm_details.market-btm-details {
  position: relative;
  top: inherit !important;
  left: inherit !important;
}

.btm_details.market-btm-details h6.bid-text-two {
  padding-left: 0 !important;
}

button.bg-transparent.border-0.subscribe-bt.btn.btn-unset {
  background: #fe7702 !important;
  height: 50px;
  width: auto;
  color: #fff;
  font-size: 18px;
  border-radius: 10px 10px 0 0;
}

.navbar-nav .dropdown-item .startselling_btn {
  text-align: left;
  border-radius: 0 0 10px 10px;
  width: 100%;
  border-top: 1px solid #ffffff3d;
  margin: 0;
}

.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.navbar-nav .dropdown-menu.show {
  top: 30px;
  min-width: 100%;
}

#google_translate_element {
  position: relative;
}

#details-collapse-text {
  margin-bottom: 20px;
  max-width: 300px;
}

#details-collapse-text a {
  color: #fff;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

.hot-collection-comming-soon {
  min-height: 300px;
}

.hot-collection-comming-soon h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#google_translate_element i {
  position: absolute;
  right: 10px;
  top: 16px;
  color: #fff;
  font-size: 12px;
}

.skiptranslate.goog-te-gadget {
  height: 55px;
  overflow: hidden;
}

header .nav-link {
  font-style: normal;
  font-weight: 500;
  padding: 0 0 6px 0 !important;
  font-size: 14px;
  border-radius: 0;
  margin: 0 20px;
  line-height: 20px;
  color: rgb(255 255 255) !important;
  border-bottom: 4px solid #fff;
}

.modal-email span {
  color: #fe7702;
}

header .nav-link:hover {
  color: #fe7702 !important;
}

header .active.nav-link {
  color: #fe7702 !important;
  border-bottom: 4px solid #fe7702;
}

header .nav-link {
  border-bottom: none;
}

header .login_header .logintext {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #081946;
}

.down-scroll {
  margin-top: 130px;
}

.searchbar_header input::-webkit-input-placeholder {
  color: #4f0c75;
}

.searchbar_header input::-moz-placeholder {
  color: #4f0c75;
}

.searchbar_header input:-ms-input-placeholder {
  color: #4f0c75;
}

.searchbar_header input:-moz-placeholder {
  color: #4f0c75;
}

.banner_section {
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: center;
  position: relative;
  padding: 135px 0;
  overflow: hidden;
  margin-top: 93px;
}

.banner_section .gobackbtn {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 99px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fff;
}
.main-term-box h5 {
  margin-top: 20px;
}

.banner_section h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  color: #fff;
  margin: 35px 0;
}

.banner_section p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #fff;
}

.banner_section h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-decoration-line: underline;
  color: #fe7702;
  cursor: pointer;
}

.banner_section .connectbtn {
  background: #fe7702;
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: #f8f6f6;
  padding: 17px 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 270px;
  margin: 40px 0;
}

.banner_section .connectbtn img {
  transform: rotate(180deg);
}

.images_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner_section p.dulltext {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #ffffffa6;
  margin-top: 25px;
}

footer {
  padding: 70px 0;
  background-color: #4f0c75;
}

.footer_head {
  display: flex;
  justify-content: end;
  align-items: center;
}

.footer_head h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  letter-spacing: -0.3px;
  color: #fff;
}

.footerline {
  background: #fff;
  opacity: 0.14;
  height: 1px;
  width: 100%;
}

footer h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  letter-spacing: -0.3px;
  color: #fff;
  margin-bottom: 35px;
  width: 50%;
}

.searchbar_header.footer input {
  border-radius: 0;
  background-color: transparent !important;
  border-bottom: 1px solid #ffffff1f;
  padding: 0;
  font-weight: 300;
  color: #fff;
  height: 52px;
  padding: 0 126px 0 10px;
}

.featured-area {
  text-align: center;
  padding: 40px 0;
}

.searchbar_header button.btn-unset {
  margin: 0 !important;
}

.searchbar_header.footer button {
  right: 0;
}

.searchbar_header.footer input::-webkit-input-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer input::-moz-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer input:-ms-input-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer input:-moz-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer {
  margin-left: 0;
}

.common_bg {
  background-color: #120715;
  position: relative;
}

.rightsdefooter h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: #ffd2dd;
  margin-bottom: 15px;
}

.img_position_1 {
  position: absolute;
  top: 0%;
  z-index: 1;
}

.common_padding_1 {
  padding: 100px 0;
}

.main_h2.underline_text {
  position: relative;
  font-size: 40px;
  display: inline-block;
  z-index: 2;
}

.count_box h5 {
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
}

.circle_row {
  position: relative;
}

.circle_row .col-md-6 {
  position: relative;
  padding: 0 85px 0 11px !important;
  z-index: 2;
}

.circle_row .col-md-6:last-child {
  padding: 0 0px 0 85px !important;
}

.circle_img_postion {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 61% !important;
}

.touch_section {
  /* padding-top: 80px;
  padding-bottom: 100px; */
  overflow: hidden;
  position: relative;
  padding: 50px 0;
}

.touch_section .container {
  position: relative;
  z-index: 2;
}

.circle_img_postion1 {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100% !important;
  z-index: 0;
}

.touch_section .circle_img_postion1 {
}

.touch_section .underline_text:before {
  position: absolute;
  content: "";
  bottom: -12px;
  height: 1px;
  background-color: white;
  width: 200px;
  left: 50%;
  transform: translate(-50%, 0);
}

.touch_section .underline_text:after {
  position: absolute;
  content: "";
  bottom: -13px;
  width: 18%;
  height: 4px;
  background-color: #fe7803;
  left: 50%;
  transform: translate(-50%, 0);
}

.service_section1 .col-lg-3 {
  padding: 0 12px !important;
}

.touch_form {
  background-color: #2a202d8a;
  border-radius: 10px;
  padding: 37px 80px;
  margin-bottom: 32px;
  box-shadow: rgb(163 163 163 / 12%) 0px 5px 15px inset;
}
.modal-footer {
  border: none !important;
}
.no-data img {
  opacity: 0.5;
}
.no-data {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.main_footer p,
.main_footer a {
  color: #ffffff !important;
  font-size: 16px;
}

.main_footer span {
  height: 100%;
  width: 1px;
  background-color: white;
  display: inline-block;
}
.login-form-box input,
.login-form-box textarea {
  background-color: transparent !important;
  border: 1px solid #ffffff8c;
  border-radius: 5px;
  padding: 12px;
  color: white !important;
  /* margin-bottom: 15px; */
  font-size: 16px;
}
.submit-bt {
  width: 100px !important;
}

.touch_form input,
.touch_form textarea {
  background-color: transparent !important;
  border: 1px solid #ffffff8c;
  border-radius: 5px;
  padding: 12px;
  color: white !important;
  /* margin-bottom: 15px; */
}

.touch_form .col-md-6 {
  padding: 0 12px !important;
}

.count_box {
  margin-bottom: 50px;
}

.main_footer {
  background-color: #0d010d;
}

.count_box p {
  color: #c8ceec;
  font-size: 17px;
  font-weight: normal;
  margin-left: 57px;
  position: relative;
  padding-left: 28px;
}

.count_box p span {
  color: white;
  padding-right: 5px;
  position: absolute;
  top: 0;
  left: 0;
}

.count_box h5 span {
  width: 35px;
  height: 35px;
  background-color: white;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 19px;
  margin-right: 20px;
}

.circle_row {
  position: absolute;
  top: 0%;
  z-index: 1;
}

.service_section1 {
  padding-top: 80px;
}

.underline_text:before {
  position: absolute;
  content: "";
  bottom: -12px;
  width: 100%;
  height: 1px;
  background-color: white;
}

.underline_text:after {
  position: absolute;
  content: "";
  bottom: -13px;
  width: 67%;
  height: 4px;
  background-color: #fe7803;
  left: 50%;
  transform: translate(-50%, 0);
}

.common_box_content {
  background-color: #10122d;
  padding: 30px 20px 10px;
  border-radius: 10px;
  height: 100%;
  position: relative;
}

/* .box1_shade{
    content: '';
    position: absolute;
    top:0;
    left: 0;
    background-image: image();
} */
.box1_shade .img_position_1 {
  left: 0;
  height: 100%;
  width: 100%;
}

.relative-div {
  position: relative;
  z-index: 2;
}

.common_box_content h5 {
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
}

.common_box_content p {
  color: #c8ceec;
  font-size: 17px;
  font-weight: normal;
}

.service_section1 {
  position: relative;
}

.service_section1 .img_position_2 {
  position: absolute;
  top: 0%;
  z-index: 1;
  max-width: 350px;
  left: -7%;
}

.main_h2 {
  font-size: 50px;
  color: #ffffff;
  font-weight: 700;
}

.section_positon {
  position: relative;
  z-index: 1;
}

.p_text {
  color: #c8ceec;
  font-size: 18px;
  font-weight: normal;
  width: 75%;
  margin-right: auto;
}

.shades-box {
  position: absolute;
  top: -290px;
  z-index: 0;
}

.App .common_bg .container {
  max-width: 1140px;
}

.main_h2 span {
  color: #fe7803;
}

.rightsdefooter p {
  font-style: normal;
  font-weight: 200;
  font-size: 15px;
  cursor: pointer;
  color: #fff;
}

.leftfooter {
  padding-right: 180px;
}

.collection_section {
  padding: 90px 0;
}

.market-btm-details .echo-two {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.preview-image video.video-container.video-container-overlay {
  width: 100%;
  height: -webkit-fill-available;
}

.sale-auction-tab li.nav-item button {
  border-radius: 10px !important;
}
button.profile-area-submit-btn {
  width: 100px !important;
}
.collection_section h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  color: #000;
}
fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.collection_section h5 {
  font-style: normal;
  font-weight: 200;
  font-size: 21px;
  color: #6b6b6b;
}

.time_row {
  background-color: #4f0c75;
  border-radius: 100px;
  padding: 7px 10px;
  display: inline-block;
  margin-top: 10px;
}

.time_row span {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  padding: 0 4px;
}

.collection_box h3 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin-top: 12px;
}

.userpic_name {
  color: #000;
  font-size: 16px;
  font-weight: 200;
}

.userpic_name {
  position: relative;
  padding-left: 46px;
  padding-top: 2px;
  margin-top: 12px;
}

.userpic_name img {
  width: 33px;
  position: absolute;
  left: 0;
  top: 0;
  height: 33px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 100%;
}

.collection_box {
  border: 1px solid #7070704d;
  border-radius: 17px;
  padding: 17px;
  box-shadow: 0 0 13px -1px #00000024;
}

.collection_img {
  margin-bottom: 5px;
}

.collection_img img {
  max-width: 100%;
  height: 300px;
  width: 100%;
  border-radius: 14px;
  object-fit: cover;
  overflow: hidden;
}

.collectible-upload-image h6 {
  color: #4f0c75;
  font-size: 18px;
  margin: 0;
}

.collectible-upload-image {
  background: #fff;
  box-shadow: 0 0 15px rgb(17 47 73 / 15%);
  border-radius: 15px;
  cursor: pointer;
  padding: 1px 0;
}

.collectible-box {
  position: relative;
  margin: 0 20px;
}

.uplage-image-collect {
  position: absolute;
  height: 290px;
  opacity: 0;
}

.name-description-filed {
  background: #fcfaff;
  padding: 30px 0;
  margin-top: 30px;
}

.collectible-bg {
  /* background: #fcfaff; */
  padding: 100px 0;
  border: 2px dashed #d7d7d7;
  margin: 20px;
  border-radius: 15px;
}

.name-description-filed input,
.name-description-filed textarea {
  border-radius: 10px;
  height: 48px;
  font-size: 14px;
}

.start_end_row .MuiFormControl-root {
  width: 100% !important;
}

.create-bt {
  background: #fe7702;
  color: #fff;
  width: 130px;
  height: 37px;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 20px;
}

.preview-text {
  color: #00000057;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.preview-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.description-toggle {
  margin-left: auto;
}

.spinner-section {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 5px;
}
.right-icon {
  width: 70px;
  text-align: center;
}

.sl-spinner3 .sl-loader .sl-loader-parent .sl-circle {
  width: 60px !important;
  height: 60px !important;
}

.grey-check {
  color: #d3d3d3 !important;
  font-size: 40px;
}

.form-switch .form-check-input {
  width: 62px !important;
  height: 25px !important;
}

.form-check-input:checked {
  background-color: #4f0c75 !important;
  border-color: #4f0c75 !important;
}

.auction-toggle.area label {
  margin-bottom: 0;
}

.home-banner-left {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tyler-hero {
  color: #4f0c75;
  background: #4f0c7521;
  padding: 8px 20px;
  border-radius: 50px;
  font-size: 16px;
}

.home-banner-left h1 {
  font-size: 64px;
  color: #fff;
  font-weight: 700;
  margin-top: 15px;
}

.home-banner-left p {
  font-size: 20px;

  color: #ffffff;
}

.nft-market-checkbox li {
  display: inline-block;
  margin-right: 25px;
}

.nft-market-checkbox {
  padding: 0;
  margin-bottom: 41px;
}

.collecting-bt i {
  margin-left: 15px;
}

.customer-box li {
  margin-left: -22px;
  display: inline-block;
}

.customer-box {
  padding: 0;
}

.customer-box li:last-child {
  margin-left: 20px;
}

.customer-box li:last-child {
  margin-left: 23px;
}

.customer-box {
  margin: 71px 0 50px 20px;
  display: flex;
  align-items: center;
}

.connect-wallet-bt {
  border-radius: 10px;
  border: 2px solid #fe7702;
  padding: 17px 15px 17px;
  text-align: center !important;
  margin: 0 10px;
  color: #fe7702;
}

.dropdown-menu.show {
  border: none;
  padding-left: 0;
  background: 0 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background: 0 0 !important;
}

a.dropdown-item {
  padding: 0;
}

.explore-heading {
  text-align: center;
  color: #fff;
  font-size: 48px;
  padding: 60px 0;
}

.explore-heading span {
  color: #fe7702;
}

/* .no-data {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

#hot-collection {
  /* background-image: url(../images/explore.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  color: #fff;
  text-align: center;
  position: relative;
  padding: 10px 0 40px;
}

.list-tab-box.tab-content {
  width: 100%;
  padding: 20px 0;
}

.more-div {
  margin-bottom: 60px;
}

.name-dec {
  cursor: pointer;
}

.hot-heading {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 50px;
}

.collection-head-new {
  background: #fff;
  color: #4f0c75;
  border-radius: 30px;
  padding: 7px 14px;
  line-height: 97px;
}

#hot-collection .tab-pane {
  margin: 0 10px;
}

.bt-div {
  margin-top: 10px;
}

.tab_box_home h4 {
  font-style: normal;
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 14px;
  color: #12041e;
}

.explore-box .col-md-3 {
  padding-right: 0;
}

.tab_box_home.active {
  background: #fe7702;
  min-height: 59px;
}

.tab_box_home.active h4 {
  color: #fff;
}

.touch_form p.danger {
  font-size: 15px;
  line-height: 5px;
  color: red !important;
}

.upcoming-events {
  background: #342b38;
  padding: 50px;
}

.upcoming-events .soccor-text {
  margin-bottom: 50px;
}

.event-dates h4 {
  margin: 0;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Space Grotesk", sans-serif;
}

.event-dates h3 {
  font-size: 38px;
  text-transform: uppercase;
  margin: 0;
  font-weight: 500;
  color: #fd7803;
  font-family: "Space Grotesk", sans-serif;
}

.event-dates {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  border: 1px solid #c66311;
  padding: 8px 50px;
  margin-top: 20px;
}

.upcoming-events {
  background: #342b38;
  padding: 50px;
  margin: 90px 0 20px;
  position: relative;
}

.upcoming-events:after {
  content: "";
  background: #342b38b5;
  height: 20px;
  position: absolute;
  bottom: -17px;
  left: 50%;
  z-index: 0;
  width: 95%;
  transform: translate(-50%, 0);
}

.upcoming-events:before {
  content: "";
  background: #342b386b;
  height: 20px;
  position: absolute;
  bottom: -35px;
  left: 50%;
  z-index: 0;
  width: 88%;
  transform: translate(-50%, 0);
}

.upcoming-events .soccor-text span {
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fd7803;
}

.event-dates.event-dates-two {
  border: 1px solid #c663113d;
}

input.btn.btn-unset.submit-input {
  background-color: #fe7702 !important;
  background: #fe7702 !important;
  color: #fff !important;
  border-radius: 12px;
  margin: 0 7px 0 0;
  height: 52px;
  border: none !important;
  width: -moz-fit-content;
  width: fit-content;
}

.explore-box h1 {
  margin-bottom: 70px;
}

.tab_box_home.active h5 {
  color: #fff;
  opacity: 0.5;
}

.tab_box_home h5 {
  font-style: normal;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
  color: #c2bfe8;
}

.tab_box_home span {
  position: absolute;
  top: 50%;
  right: 20px;
  cursor: pointer;
  transform: translate(0, -50%);
}

img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-30px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-30px);
  }
}

.market_banner_section {
  background-color: #000;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  color: #fff;
  text-align: center;
  position: relative;
  padding-bottom: 60px;
}

.market-left h1 {
  font-size: 90px;
  background: -webkit-linear-gradient(45deg, #f8a8ff, #6155ed);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  font-weight: 800;
  margin-bottom: 30px;
}

#nft-feature {
  /* background-image: url(../images/feature.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  color: #fff;
  text-align: center;
  position: relative;
  padding-bottom: 60px;
}

.searchbar_item video {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 10px;
}

.explore-box {
  /* background-image: url(../images/explore_bg.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  color: #fff;
  position: relative;
  padding: 60px 0;
}

.isolate-inheritance ._1CW8I {
  opacity: 0 !important;
  cursor: pointer;
}

.header-dropdown.dropdown-menu.show {
  background: #fe7702;
  padding: 5px 10px 0 10px;
}

.header-dropdown.dropdown-menu.show a {
  color: #fff !important;
  border-top: 1px solid #ffffff59;
  padding: 3px 0;
  font-size: 14px;
}

.header-dropdown.dropdown-menu.show a:first-child {
  border-top: none;
}

.header-dropdown.dropdown-menu.show a:hover {
  background: #ff861e !important;
}

.header-dropdown.dropdown-menu.show button {
  background: none;
  border: none;
  padding: 0 0 8px;
}

.navbar-nav .logout-dropdown.dropdown-menu.show {
  left: -62px !important;
  padding: 2px 8px;
}

.touch-bt-two {
  background: #fe7702 !important;
  color: #fff !important;
  border-radius: 12px !important;
  height: 52px;
  line-height: 39px !important;
}

.discord-section-bg {
  background: url("../images/discord-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  position: relative;
  height: 370px;
  max-height: 500px;
  margin: 100px 0 30px 0;
}

.discord-section-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.discord-section-content h2 {
  color: #fff;
  font-size: 50px;
  font-weight: 700;
}

.discord-section-content h2 span {
  color: #fe7702;
}

.discord-section-content p {
  font-size: 28px;
  color: #fff;
  font-weight: 600;
  line-height: 55px;
  margin-bottom: 20px;
}

.discord-section-content a {
  background: #fe7702;
  padding: 15px 40px;
  color: #fff !important;
  border-radius: 10px;
  font-size: 18px;
  letter-spacing: 1px;
}

.more-games {
  color: #fff;
  font-size: 17px;
  line-height: 60px;
}

#nft-feature h3 {
  color: #fff;
  margin-top: 20px;
}

#nft-feature h3 {
  color: #fff;
}

.step-two {
  padding-top: 40px;
}

.step-three {
  padding-top: 80px;
}

#nft-feature p {
  color: #ffffff70;
  padding: 0 120px;
}

.contact-box-area {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  padding: 60px 0;
}

.contact-left-side h3 {
  color: #fff;
  font-size: 40px;
  line-height: 63px;
  padding: 40px 0 0 70px;
}

.contact-left-side {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

.contact-right-side input {
  background: 0 0 !important;
  border-bottom: 1px solid #ffffff8c !important;
  border: none;
  border-radius: 0;
  color: #fff !important;
  padding-left: 0;
  padding-right: 0;
  margin-top: 50px;
}

.contact-right-side input::placeholder {
  color: #fff !important;
}

.contact-form .btn {
  display: flex;
  margin: 14px auto;
}

.contact-right-side {
  display: flex;
  margin: 25px auto;
}

.contact-form {
  background: #ffffff12;
  border-radius: 20px;
  border: 2px solid #ffffff14;
  padding: 10px 80px 10px 100px;
  position: absolute;
  bottom: 0;
}

.contact-filled-box .row {
  position: relative;
}

.choose-heading {
  font-size: 20px;
}
.air-max.Royality-max {
  justify-content: left;
}
.single-multiple-box .react-tabs__tab-list {
  display: inline-block;
}

.addresstext {
  position: relative;
}

.addresstext i {
  position: absolute;
  right: 0;
  top: 0;
}
.name-input {
  position: absolute;
  z-index: 999;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 9px;
  left: 75px;
}
.single-multiple-box .react-tabs__tab--selected {
  border-radius: 5px;
  border: none !important;
  background: #fe7702 !important;
  width: 135px !important;
  text-align: center;
  color: #fff !important;
}

.create-items li p {
  margin: 0;
}

.single-multiple-box .react-tabs__tab {
  border: none !important;
  padding: 6px 12px;
  cursor: pointer;
  background: #f0ebf6;
  color: #0000008c;
  width: 160px;
  border-radius: 5px;
  font-size: 20px;
  text-align: center;
}

.create-items h6 {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 16px;
  max-width: 100px;
  margin: 0 auto !important;
}

.items-area-box-two img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 2px;
}

.create-items h6 {
  margin: 0;
}

.searchbar_header input {
  padding: 0 68px 0 15px;
}

.create-items ul li {
  display: flex;
  justify-content: center !important;
  border: 1px solid #0000003d;
  border-radius: 5px;
  width: 110px;
  height: 110px;
  margin-right: 15px;
  cursor: pointer;
  padding: 12px 0;
  align-items: center;
}

.items-area-box,
.items-area-box-two {
  text-align: center;
}

.create-items ul {
  display: flex;
}

.modal-profile-img {
  position: relative;
  height: 30px;
}

.cover-image-box img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

form.text-left {
  text-align: left;
  margin-top: 70px;
}

.profile-input-mod {
  width: 135px !important;
  height: 120px;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.img-input-mod {
  position: absolute;
  top: 0;
  height: 270px;
  width: 94%;
  opacity: 0;
}

.collection-create .modal-content {
  padding: 0;
}

.create-items ul {
  padding: 0;
}

.copies-input {
  max-width: 200px;
}

.single-multiple-box .react-tabs__tab-list {
  border-bottom: none !important;
  margin-bottom: 20px;
}

.modalclose_icon i {
  margin-top: -2px;
}
.collection-create .modal-footer {
  padding-top: 0;
}
/* .modalclose_icon {
  width: 30px;
  cursor: pointer;
  height: 30px;
  background: #fe7702;
  color: #fff;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
} */

.uploadimg {
  display: inline-block;
  position: relative;
}

.uploadimg input {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  opacity: 0;
}

.addresstext {
  padding-right: 38px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.slide-1 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.tenis-img-two {
  margin-left: -20px;
}

.like-view {
  text-align: center;
  color: #fff;
  margin: 0 15px;
}

.like-view i {
  color: #fc3a3a;
}

.in-stock {
  background: #00000085;
  padding: 10px 13px;
  color: #fff;
  margin-left: auto;
  border-radius: 30px;
}

.slide-percent {
  font-size: 70px;
  color: #fff;
  padding: 80px 30px 0;
  margin-bottom: 0;
}

.slide-progress {
  color: #fff;
  border: 2px solid #fe7702;
  display: inline-block;
  padding: 3px 11px;
  margin-left: 30px;
}

.echo-two {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 1.6em;
  color: #fff;
  font-size: 18px;
}

.echo {
  color: #fff;
  padding: 140px 30px 0;
}

.items-name h5 {
  color: #fff;
  font-size: 18px;
}

.bid-text {
  padding-left: 30px;
  color: #fff;
  font-size: 14px;
}

.bid-text span {
  color: #fe7702;
}

.bid-text-two span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.bid-text-two {
  padding: 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.our-team {
  background: #000;
  padding: 1px 0 70px 0;
  position: relative;
}

.shades-box-two {
  position: absolute;
  right: 0;
  bottom: -370px;
  pointer-events: none;
}

.bid-text-two.bid-usd-box {
  padding-left: 8px;
}

.member-info span {
  font-size: 14px;
  color: #fe7702;
}

.member-info h5 {
  font-size: 22px;
  color: #c8ceec;
  font-weight: 400;
}

.mainimg {
  height: 246px;
}

.tenis-box {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  position: absolute;
  top: 4px;
  left: 0;
}

nav.home_header {
  padding: 8px 0;
}

.morefaq {
  background: #fe7702;
  color: #fff !important;
  padding: 9px 20px;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: fit-content;
}

li.alice-carousel__stage-item {
  padding: 0 10px;
}

.social-icon li {
  display: inline-block;
  margin: 0 22px;
}

.social-icon {
  padding: 0;
  text-align: center;
  border-radius: 10px;
}

.slide-margin {
  margin: 7px 5px;
}

.react-multi-carousel-track:first-child
  .react-multi-carousel-item.react-multi-carousel-item--active {
  display: none;
}

.errors {
  color: red;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  display: block;
}

.end-reserve-bid-box input {
  margin-bottom: 7px;
  width: 97%;
}

.end-reserve-bid-box {
  margin: 28px 23px 0;
  width: 90%;
}

.meta-mask-modal a b {
  text-decoration: underline;
}

.meta-mask-modal h3 {
  font-size: 24px;
  color: #000;
}

.meta-mask-modal .modal-content {
  border-radius: 10px;
  /* padding: 40px 0; */
  /* background-image: url(../images/modal-bg.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.modal-header h3 {
  font-size: 20px;
}

.modal-header {
  padding-bottom: 0 !important;
}

.meta-mask-modal a {
  color: #fe7702;
}

.ok-bt.btn::after {
  content: "";
  /* background-image: url(../images/line.png); */
  background-size: contain;
  position: absolute;
  height: 50px;
  width: 50px;
  right: -70px;
  top: -25px;
}

.sale-auction-tab {
  border: none !important;
}

.sale-auction-tab input {
  max-width: 240px !important;
  height: 46px;
  border-radius: 10px !important;
  border: none !important;
}

.sale-auction-tab li.nav-item button {
  width: 160px;
  border-radius: 10px;
  color: #5a0075;
  border: 1px solid #fff;
  box-shadow: 2px 4px 1px #b04f19;
  background: #ffffff;
  margin: 0 5px;
}

.alice-carousel__stage-item * {
  max-width: 400px !important;
}

.date-box .MuiFormControl-root {
  width: 100%;
}

.tab-one {
  max-width: 280px;
}

.tab-one input {
  height: 46px;
}

.tab-bid-box label {
  background: #fff;
  padding: 0 9px !important;
  border-radius: 5px;
}

.tab-bid-box input {
  width: 97%;
  height: 46px;
  border-radius: 10px;
}

.profile-box {
  background: #f3f3f3;
  margin: 86px 0 0 0;
  padding: 70px 0;
}

.profile-left-side img {
  width: 220px;
  height: 220px;
  border-radius: 15px;
  object-fit: cover;
}

.edint-pencil {
  background: #fe7702;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  font-size: 13px;
  position: absolute;
  right: -15px;
  top: -16px;
  cursor: pointer;
}

.profile-Right-side {
  text-align: left;
  padding: 0 0 0 18px;
}

.profile-content h5 {
  color: #47036f;
}

.logout-flag button {
  background: none !important;
}

.logout-flag {
  font-size: 15px;
  color: #fe7702 !important;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.profile-content h6 {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-weight: 500;
}

.logout-flag i {
  color: #fe7702 !important;
}

.profile-content h6 a,
.profile-content h6 i {
  color: #fe7702 !important;
}

.profile-Right-side i {
  font-size: 18px;
  margin-right: 8px;
}

.profile-Right-side .fa-address-book-o {
  margin-right: 0;
}

.modal-img-new {
  width: 160px;
  height: 160px;
  object-fit: cover;
}

.profile-Right-side h2 {
  font-size: 22px;
  color: #47036f;
  display: inline-flex;
  align-items: center;
}

.edit-profile-modal .modal-dialog {
  max-width: 410px;
}

.modal-form-new button.ok-bt {
  background: #fe7702;
  padding: 7px 20px;
  border-radius: 5px;
  color: #fff;
  margin: 0 auto;
  display: block;
}

.modal-form-new {
  text-align: left;
  margin: 0 60px;
}
.frame-options:hover {
  transform: scale(1.1);
  transition: 0.3s all;
  cursor: pointer;
}
.profile-Right-side h4 {
  font-size: 15px;
  color: #fe7702;
}

.bid_auction .tab-content {
  margin: 0 13px 0 0 !important;
}

.tab-bid-box .row {
  --bs-gutter-x: 0;
}

.sale-auction-tab .nav-item.show .nav-link,
.sale-auction-tab .nav-link.active {
  color: #ffffff !important;
  background-color: #5a0075 !important;
  border-color: #5a0075 !important;
}

.auction-right-side {
  text-align: center;
}

.navbar-light .navbar-toggler {
  color: rgb(255 255 255 / 100%) !important;
  border-color: rgb(255 255 255 / 50%) !important;
}

.navbar-light .navbar-toggler-icon {
  filter: invert(1);
}

.save-bid-price {
  background: #5a0075;
  padding: 6px;
  margin-top: 20px !important;
  display: block;
  width: 90px !important;
  text-align: center;
  font-size: 20px;
  color: #fff !important;
  border-radius: 10px;
}

.white_box {
  width: 70px;
  height: 70px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: -62px;
  z-index: 2;
  position: relative;
}

.blockchain_row h4 {
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
}

.blockchain_row span {
  min-height: 210px;
  overflow: hidden;
  display: inline-block;
}

.blockchain_row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
}

header .bloglink.active.nav-link {
  color: #fff !important;
  border-bottom: 0 !important;
}

.create_store_row h4 {
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
}

.create_store_row p {
  color: #c8ceec;
  font-size: 17px;
  font-weight: normal;
}

.launch_section .common_box_content {
  text-align: center;
  margin-bottom: 30px;
}

.launch_section h5 {
  margin-bottom: 20px;
}

.main-loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #000000d1;
  z-index: 1056;
  top: 0;
  left: 0;
}
.svg-animation svg {
  position: absolute;
  top: 0;
  visibility: visible !important;
}
/* .svg-animation p {
  position: absolute;
  top: 56%;
  left: 49%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
} */
.loading {
  text-align: center;
  font-size: 18px;
  color: #fff;
  margin-top: 15px;
}

.name-dec {
  display: flex;
  align-items: center;
}

.name-dec p {
  margin-right: 5px;
  font-size: 18px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.shade-box {
  position: absolute;
  width: 500px;
  top: 0;
  left: 220px;
}

.market_categories .nav-item .nav-link {
  background: linear-gradient(180deg, #fafaff 0, #cfd4fe 100%);
  border-radius: 16px;
  min-width: 240px;
  padding: 22px;
  margin: 1px 10px 9px 0;
  position: relative;
  cursor: pointer;
  text-align: left;
  height: 83px;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
/* 
body {
  top: 0 !important;
} */

.MuiOutlinedInput-root.MuiInputBase-root {
  background: #fff;
  height: 47px;
  margin-bottom: 7px;
  width: 97%;
  border-radius: 10px;
}

.market_categories .nav-item .nav-link span {
  position: absolute;
  top: 50%;
  right: 20px;
  cursor: pointer;
  transform: translate(0, -50%);
}

.market_categories .nav-item .nav-link.active {
  background: #fe7702;
  border-radius: 16px;
}

.market_categories .nav-item .nav-link.active h4 {
  color: #fff;
}

.market_categories .nav-item .nav-link.active h5 {
  color: #fff;
  opacity: 0.5;
}

.market_categories .nav-item .nav-link h4 {
  font-style: normal;
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 14px;
  color: #12041e;
}

.market_categories .nav-item .nav-link h5 {
  font-style: normal;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
  color: #c2bfe8;
}

.search_marketplace .searchbar_header {
  margin-left: 0;
}

.my_listing-area {
  min-height: 600px;
  background: #f3f3f3;
  padding: 50px 0;
  position: relative;
}

.searchbarlist {
  background-color: #fff;
  border-radius: 10px;
  border-bottom: 1px solid #d3d3d32e;
  padding: 10px;
  width: 100%;
  position: absolute;
  top: 64px;
  min-height: 100%;
  max-height: 370px !important;
  overflow: auto;
  box-shadow: 0 2px 4px #00000024;
}

.search-market-banner {
  background-color: #fff;
  border-radius: 10px;
  border-bottom: 1px solid #d3d3d32e;
  padding: 10px;
  width: 100%;
  min-height: 100%;
  max-height: 180px;
  overflow: auto;
  z-index: 1;
  position: relative;
  text-align: left;
}

#asset-search-result {
  max-width: 370px;
  max-height: 180px;
}

.onsale-modal.form-select {
  position: absolute;
  max-width: 150px;
  top: 0;
}

.searchbar_item {
  position: relative;
  padding: 5px 2px;
  border-bottom: 1px solid #d3d3d32e;
  margin-bottom: 0;
}

.__react_component_tooltip {
  background: #fcfaff !important;
  width: 291px;
  border-radius: 10px !important;
  color: #000 !important;
  font-size: 13px !important;
  font-weight: 400;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.33);
  padding: 10px !important;
}

.choose-heading i {
  cursor: pointer;
}

.__react_component_tooltip .multi-line {
  text-align: left !important;
}

.__react_component_tooltip::before {
  color: #fcfaff !important;
}

.__react_component_tooltip::after {
  border-bottom-color: #fcfaff !important;
}

.price-category {
  padding-left: 8px;
}

.searchbar_item h5 {
  font-size: 17px;
  color: #292929;
}

.bid-text-two.bid-usd-box {
  color: #fff !important;
}

.bid-text-two.bid-usd-box span {
  color: #fff !important;
}

.header-serach-box {
  padding-left: 43px;
}

.header-serach-box h5,
.header-serach-box p {
  margin: 0;
  word-break: break-word;
}

.searchbar_item img {
  width: 38px;
  position: absolute;
  height: 38px;
  border-radius: 100%;
  object-fit: cover;
  overflow: hidden;
}

.featured-area {
  text-align: center;
}

.btm_details .attri-description {
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 3px;
}

.featured-area ul li {
  list-style: none;
  padding: 13px 30px;
}

.featured-area {
  text-align: center;
  padding: 40px 0;
}

.featured-area ul {
  display: flex;
  justify-content: center;
}

.btm_details p {
  color: #fff;
  margin-bottom: 3px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 1.6em;
  margin-top: 9px;
}

.items-name {
  margin-top: 10px;
}

.sl-spinner3 {
  width: 70px;
}

.right-icon i {
  font-size: 40px;
}
/* .detail-collection-area {
  margin-left: 270px;
} */
.detail-left-side img {
  height: 540px;
  max-width: 540px;
  object-fit: contain;
  border-radius: 10px;
  width: -webkit-fill-available !important;
}
.detail-left-side {
  text-align: center;
}
/* .detail-left-side {
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 25px;
  height: calc(100vh - 200px);
  text-align: center;
} */
.items-datails {
  text-align: left;
  padding: 15px 15px 5px 15px;
}

.works-area-box {
  /* background-image: url('../images/faq_bg.png'); */
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  color: #fff;
  padding: 150px 0 60px;
}

.featured-area h5 {
  font-size: 36px;
  font-weight: 700;
}

.sign-area h5 {
  margin: 20px 0;
}

.sign-area p {
  color: #ffffffb8;
  font-weight: 400;
}

.auction-right-side video {
  max-height: 450px;
}

.sign-area {
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 20px 96px 20px 20px;
  height: 317px;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  justify-content: center;
}

.works-area-box h2 {
  text-align: center;
  font-size: 60px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 50px;
}

.featured-area {
  text-align: center;
  padding: 40px 0;
}

.faq-box h2.accordion-header {
  margin: 0;
  background: 0 0;
}

.accordion-item {
  border: 1px solid #fff !important;
}

.faq-box h2.accordion-header button {
  background: 0 0;
  color: #fff !important;
}

.slide-2 video {
  height: 246px;
  object-fit: cover;
  display: block;
  position: absolute !important;
}
.items-image-box-two video, .items-image-box-two img {
  position: relative !important;
}
.items-image-box-two.user-timer-box img, .items-image-box-two.user-timer-box video {
  position: absolute !important;
}
.kennedy-brooks {
  background-image: url("../images/promote-bg.png");
  background-repeat: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.accordion-button::after {
  filter: invert(1);
}

.accordion {
  max-width: 750px;
  margin: 0 auto;
}

.accordion-item {
  background: 0 0;
  border: 2px solid #fff;
  color: #fff;
  margin-bottom: 20px;
  border-radius: 10px !important;
}

.list-market-new video {
  height: 246px;
  object-fit: contain;
}

.accordion-item {
  background: 0 0 !important;
}

.featured-area h5 {
  font-size: 31px;
  font-weight: 700;
  color: #565656;
}

.slide-2 img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.list-market-new:hover {
  background: #131414;
}

.mainimg {
  height: 246px;
  border-radius: 10px 10px 0 0 !important;
  object-fit: cover;
}

.connect-wallet-bt a {
  color: #fe7702;
}

.connect-wallet-bt:hover a {
  color: #fff;
}

.my_listing-area h2 {
  color: #47036f;
}

.btm_details .attri-price {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.slide-2 .btm_details {
  width: 100%;
  text-align: left;
  padding: 0 8px 0 8px !important;
  padding-bottom: 10px;
  height: 101px;
}

.slide-2 .mainimg {
  min-width: 100%;
  height: 246px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 15px 15px 0 0;
}

.slide-2 .bid-text-two {
  padding-top: 8px;
  padding-left: 0;
}

.slide-2 .echo-two {
  padding: 0;
}

.auction-right-side video {
  max-width: 560px;
  width: -webkit-fill-available;
}

.live-auction h2 {
  color: #fff;
  font-size: 34px;
  margin-left: 10px;
}

.fade {
  transition: opacity 3s linear !important;
}

.day-timer h4 {
  font-family: "Circular Std", sans-serif !important;
  margin-bottom: 9px;
}

.live-auction {
  display: flex;
  align-items: start;
}

.live-auction img {
  margin-top: 10px;
}

.day-timer h6 {
  font-size: 14px;
  margin-bottom: 0;
}

.share-bt p {
  margin-bottom: 0;
  background: #ffffff21;
  border-radius: 50px;
  padding: 3px 20px;
  margin-left: 10px;
  font-size: 14px !important;
  /* min-width: 100px; */
}

.fa-share-alt {
  background: #fff;
  width: 20px;
  text-align: center;
  color: #b34933;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
}

.auction-form .input-group-text {
  border-radius: 0;
  background-color: #fff;
  border: none;
  border-left: 3px solid #26b1a1;
  margin: 13px 0;
  padding: 4px;
}

.auction-form .input-group {
  background: #fff;
  border-radius: 50px;
  padding: 0 20px;
}

.auction-form .row {
  display: flex;
  align-items: center;
}

.auction-form input {
  border-radius: 0 30px 30px 0 !important;
  height: 58px;
  border: none;
}

.eth-box h5 {
  font-size: 16px;
  color: #fff;
  background: #ffffff21;
  border-radius: 50px;
  padding: 0 11px;
  margin: 6px 0 6px 10px;
}
input.minimum-quantity {
  margin-bottom: 2px !important;
}
.collection-team h6 {
  color: #ffd6ae;
}

.bid_auction h1 {
  font-size: 24px;
  color: #ffd6ae;
  font-weight: 700;
}

.air-max h2 {
  font-size: 30px;
  color: #ffd6ae;
  margin-bottom: 0;
  display: block;
  line-height: 30px;
  max-width: 270px;
  word-break: break-word;
}

.name-description h5 {
  color: #fff;
  margin-bottom: 23px;
}

.name-description p {
  font-size: 14px !important;
  color: #efefef;
  font-weight: 400;
  margin: 0;
}

.air-max h4 {
  color: #fff;
  font-size: 20px;
}
/* 
.buy-sell-box {
  margin-top: 50px;
} */

.buy-sell-box h4 {
  color: #fff;
}

.collections-details-box img {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  object-fit: cover;
}

.buy-now-png {
  /* background-image: url('../images/buy.png'); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 44px;
  color: #fff;
  line-height: 60px;
}

.air-max {
  display: flex;
  align-items: center;
}

.day-timer li {
  color: #fff;
  display: inline-block;
  margin: 0 20px;
  text-align: center;
}

.day-timer {
  margin: 0 0 0 auto;
  text-align: center;
}

.collec-heading {
  color: #fff;
  margin-bottom: 30px;
}

.collec-heading a {
  color: #c5c5c5 !important;
}

.details-cover img {
  width: 100%;
  height: 400px;
  object-fit: contain;
}

.seller-box h2 {
  font-size: 36px;
  text-align: center;
  margin-bottom: 30px;
}

.seller-box .tab-pane {
  margin: 0 10px;
}

.seller-box h2 span {
  color: #fe7702;
}

.login_header button {
  font-size: 14px;
}

.my-acc-bt {
  color: rgba(61, 66, 79, 0.65) !important;
}

.navbar-expand-lg .navbar-collapse {
  align-items: initial;
}

.nft-collection-box li {
  color: #ffffff94;
  list-style: none;
  display: inline-table;
  width: 170px !important;
  text-align: center;
}

.listingsection .nft-collection-box {
  background: white;
  padding: 11px 0;
  margin-bottom: 40px;
}

.load-absolute {
  text-align: center;
}

.items-price {
  color: #fff;
  margin-bottom: 10px;
}

.nft-collection-box ul {
  padding: 0;
  border: none;
}

.nft-collection-box li a {
  color: #fff;
}

.faq-link {
  color: #fe7702 !important;
  font-size: 16px;
  display: block;
  margin-top: 30px;
  text-decoration: underline !important;
}

.onsale-modal.form-select {
  position: absolute;
  max-width: 150px;
  top: 0px;
  left: 280px;
  height: 48px;
  border-radius: 10px;
  border: 2px solid #000;
}

.nft-collection-box li a.active {
  background: #000;
  padding: 8px 10px;
  border-radius: 10px;
  height: 40px;
  display: block;
  color: #fe7702;
}

.nft-collection-box ul {
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  background: #2f333c;
  padding: 5px;
  margin-bottom: 15px;
}

.nft-collection-box h5 {
  margin-bottom: 10px;
}

.searhmarket-content {
  padding-left: 45px;
}

.auto_break_next_line {
  /* max-width: 100px; */
  line-break: anywhere;
}

.image_fix_cover {
  object-fit: cover !important;
}

/* soccor page css statr*/

@import url("https://fonts.cdnfonts.com/css/space-grotesk");
@import url("https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap");

.soccor-banner h2 {
  font-family: "Space Grotesk", sans-serif;
  color: #fff;
  font-size: 100px;
  font-weight: 700;
  margin-bottom: 120px;
}

.soccor-banner h2 span {
  color: rgba(255, 255, 255, 0);
  font-size: 50px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(255, 255, 255);
  font-size: 130px;
  font-weight: 700;
}

.soccor-banner {
  padding: 235px 0;
  background-image: url("../images/soccor_banner.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 600px;
}

.beach-text {
  font-family: "Rock Salt", cursive;
  font-size: 50px;
  color: #fd7803;
  padding: 40px 0;
}

.input-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.soccor-text {
  font-family: "Space Grotesk", sans-serif;
  color: #fff;
  font-size: 70px;
  font-weight: 700;
  text-transform: uppercase;
}

.beach-box {
  background-color: #120817;
  padding: 60px 0;
}

.beach-soccor-one {
  position: relative;
}

.activities-box {
  background: #22102b;
  padding: 70px 0;
  position: relative;
}

.activities-heading h3 {
  font-size: 130px;
  font-weight: 700;
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
}

.activities-heading {
  position: absolute;
  top: 120px;
}

.activities-heading h3 span {
  font-size: 130px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(255, 255, 255);
}

.activities-heading h5 {
  font-size: 40px;
  color: #fff;
  line-height: 70px;
  font-family: "Space Grotesk", sans-serif;
  text-transform: uppercase;
}

.activities-heading h5 span {
  font-size: 40px;
  font-family: "Rock Salt", cursive;
  color: #fd7803;
}

.prizes-box {
  background-image: url("../images/prize_banner.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 250px 0;
}

.soccor-contact-box {
  background-image: linear-gradient(180deg, black, #23112d);
  padding: 120px 0;
}

.contact-form-two h3 {
  font-size: 40px;
  text-align: center;
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  line-height: 63px;
}

.contact-form-two input {
  background: no-repeat !important;
  border: none;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  padding: 10px 0;
  color: #fff !important;
}
.swal2-styled.swal2-confirm {
  background-color: #fe7702 !important;
}
.contact-form-two .danger {
  color: red !important;
  margin-top: 5px;
}

.forrm-submit-btn {
  background: #fd7803 !important;
  border: none !important;
  width: 390px;
  color: #fff !important;
  height: 48px;
  margin: 50px auto 0 !important;
  display: block !important;
}

.contact-form-two {
  max-width: 80%;
  margin: 0 auto;
  border: 2px solid rgb(255 255 255 / 18%);
  padding: 50px 150px;
}

.contact-form-two input::placeholder {
  color: #fff !important;
}

.contact-form-two h3 span {
  font-size: 40px;
  font-family: "Rock Salt", cursive;
  color: #fd7803;
  font-weight: 500;
}
.market-pagination .pagination {
  justify-content: center;
}
.market-pagination li a {
  background: black;
  color: #747474;
  border-color: #4e4e4e;
  width: 40px;
  margin: 0 5px;
}
.market-pagination .active.page-item a {
  border-color: #fe7803 !important;
  background: #fe7803 !important;
}
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
.signle-detail-data-area .MuiOutlinedInput-root.MuiInputBase-root {
  background: linear-gradient(211deg, #d65e1c, #e76911);
  border: 1px solid #fff !important;
}
.signle-detail-data-area
  .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root
  label {
  background: linear-gradient(211deg, #d65e1c, #e76911);
  padding: 0 6px;
  color: #fff;
}
.signle-detail-data-area.tab-bid-box input {
  width: 97%;
  height: 46px;
  border-radius: 10px;
  color: #fff;
}
.signle-detail-data-area.tab-bid-box input::placeholder {
  color: #fff;
}
.signle-detail-data-area .css-i4bv87-MuiSvgIcon-root {
  fill: #fff;
}
.signle-detail-data-area input.form-control {
  background: linear-gradient(211deg, #d65e1c, #e76911);
  color: #fff !important;
  border: 1px solid #fff;
}
.signle-detail-data-area input.form-control::placeholder {
  color: #fff;
}
.signle-detail-data-area .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: #fff;
}
.preview-mobile-view {
  display: none;
}
.single-nft-detail-area {
  background: #fff !important;
  margin-top: 10px !important;
}
p.logout-title {
  margin-bottom: 0;
  color: #000;
}
.swal2-popup {
  width: 25em !important;
}
.swal2-image {
  margin-bottom: 0 !important;
}
div#swal2-html-container {
  font-size: 17px;
}
.swal2-title {
  color: #000 !important;
}
.toggle-drop-right .dropdown-menu.show {
  min-width: 100px;
  padding-top: 0;
  padding-bottom: 0;
  left: 80px;
  top: 40px;
}
.toggle-drop-right .wallet-bt-connect.dropdown {
  display: none;
}
/* get-in-touch */
.athe-banner-area {
  background-color: #000;
  color: #fff;
  background-image: url(../images/sports-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  padding: 133px 0;
  margin-top: 78px;
}
.banner-ath-image {
  display: none;
}
.ath-content h2,
.get-in-area h2,
.unique-content h2 {
  font-size: 50px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 30px;
}
.text-sport {
  color: #fe7803;
}
.ath-content p,
.get-in-area p,
.unique-content p {
  font-size: 18px;
  color: #c8ceec;
}
.inner-bg {
  background-image: url(../images/main-bg.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 55px;
}
.inner-content {
  padding: 32px 0;
}
.unique-img {
  text-align: center;
}
.get-in-section {
  padding: 52px 0;
}
.get-in-bg {
  background: hsla(0, 0%, 100%, 0.13);
  border: 2px solid hsla(0, 0%, 100%, 0.16);
  -webkit-backdrop-filter: blur(85px);
  backdrop-filter: blur(85px);
  border-radius: 24px;
  padding: 51px;
}
.get-in-bg input,
.get-in-bg textarea {
  border: none !important;
  background: hsla(0, 0%, 100%, 0.12156862745098039) !important;
  color: #fff !important;
  resize: none;
}
.get-in-bg .text-danger {
  text-align: left;
  display: inherit;
}
.btn-get-in {
  border-radius: 6px;
  background: #fe7803 !important;
  border: #fe7803 !important;
  padding: 11px 39px !important;
  margin: 15px 0 0;
}
.submit-btn .btn-get-in {
  float: left;
}
.get-in-bg input::placeholder,
.get-in-bg textarea::placeholder {
  color: #fff;
  font-size: 14px;
}
.header-login-hide {
  display: none !important;
}
iframe#review-card-payment-iframe {
  position: fixed;
  top: 5%;
  z-index: 9999999;
  width: 62%;
  left: 19%;
  height: 89%;
  overflow: auto;
}
div#headlessui-portal-root {
  position: fixed;
  top: 5%;
  z-index: 9999999;
  width: 0%;
  left: 19%;
  height: 89%;
  overflow: auto;
}
.detail-area-buy-nft ul.react-tabs__tab-list {
  margin-bottom: 15px;
}
.detail-area-buy-nft .nft-modal-items {
  margin-bottom: 10px;
}
.detail-area-buy-nft label.m-0.mb-2.form-label {
  font-weight: 600;
}
.detail-area-buy-nft .meta-mask-modal h3 {
  font-size: 24px;
  color: #000;
  font-weight: 700;
}
input#formBasicEmail::placeholder {
  font-size: 13px;
  color: #adadad;
}
.detail-area-buy-nft label.m-0.form-label {
  font-weight: 600;
}
.detail-area-buy-nft .buy-nft-tab .nft-modal-items {
  min-height: 70px;
}
svg#loader {
  display: none;
}
.absolute.top-1\/2.left-1\/2.-translate-x-1\/2.-translate-y-1\/2 {
  display: none !important;
}

/* soccor page css end */

/* soccor page css end */
/* athlenfts page css starts */
.athe-banner-area {
  background-color: #000;
  color: #fff;
  background-image: url("../images/sports-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  padding: 150px 0;
  margin-top: 100px;
}
.banner-ath-image {
  display: none;
}

.new-header .navbar {
  background: rgb(7 10 41);
  padding: 30px;
  position: fixed;
  top: 0;
  width: 100%;
}

.new-header .nav-link {
  color: #fff !important;
  margin: 0px 14px;
}

.ath-content h2,
.unique-content h2,
.get-in-area h2 {
  font-size: 50px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 30px;
}
section.works-area-box.faq-page-box {
  background: black;
}
.works-area-box.faq-page-box .accordion-body {
  color: #fff;
}

.ath-content p,
.unique-content p,
.get-in-area p {
  font-size: 18px;
  color: #c8ceec;
}

.text-sport {
  color: #fe7803;
}

.btn-get-in {
  border-radius: 6px;
  background: #fe7803 !important;
  border: #fe7803 !important;
  padding: 11px 39px !important;
  margin: 15px 0 0 0;
}

.inner-bg {
  background-image: url(../images/main-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 55px;
}

.inner-content {
  padding: 32px 0;
}
.get-in-bg {
  background: rgb(255 255 255 / 13%);
  border: 2px solid rgb(255 255 255 / 16%);
  -webkit-backdrop-filter: blur(85px);
  backdrop-filter: blur(85px);
  border-radius: 24px;
  padding: 51px;
}

.get-in-bg input,
.get-in-bg textarea {
  border: none !important;
  background: #ffffff1f !important;
  color: #fff !important;
  resize: none;
}

.submit-btn .btn-get-in {
  float: left;
}

.footer-area {
  background: #0d113a;
  color: #fff;
  text-align: center;
  position: relative;
}

.social-icons img {
  margin: 0 24px;
}

.copyright-text {
  padding: 22px 0 0 0;
  border-top: 1px solid #ffffff1a;
}

.social-icons {
  margin-bottom: 24px;
}

.unique-img {
  text-align: center;
}

.get-in-section {
  padding: 52px 0;
}

.new-header .nav-link:hover {
  color: #fff;
}

.get-in-bg input::-webkit-input-placeholder {
  color: #fff;
}

.get-in-bg textarea::-webkit-input-placeholder {
  color: #fff;
}

.get-in-bg .text-danger {
  text-align: left;
  display: inherit;
}
select.sort-by-filter.form-select {
  height: 55px;
  border-radius: 15px;
  width: 200px;
}
.search_marketplace {
  display: flex;
  align-items: center;
}
.choose-ai-tab-area nav.mb-3.nav.nav-tabs {
  justify-content: center;
}
.choose-ai-tab-area {
  margin-top: 30px;
}
.choose-ai-tab-area ul li {
  color: #fff;
}
.choose-ai-tab-area ul {
  display: flex;
  justify-content: center;
  list-style: none;
  padding-left: 0;
}
.choose-ai-tab-area ul li img {
  width: 30px;
  height: auto;
  filter: invert(1);
  margin-right: 5px;
}

.choose-ai-tab-area ul li {
  background: linear-gradient(180deg, #ffffff 0%, #cfd4fe 100%);
  color: #000;
  padding: 18px;
  margin: 0 8px;
  font-weight: 500;
  font-size: 15px;
  border-radius: 15px;
  cursor: pointer;
}
.choose-ai-tab-area ul li.active {
  background: #fe7702 !important;
  color: #fff !important;
  position: relative;
}
.choose-ai-tab-area ul li.active img {
  filter: unset !important;
}
.choose-ai-tab-area ul li.active:before {
  content: "";
  position: absolute;
  background: #fe7702;
  width: 25px;
  height: 25px;
  right: 7px;
  bottom: -7px;
  -webkit-transform: rotate(127deg);
  transform: rotate(127deg);
}
.image-image-upload-area {
  justify-content: center;
}
.ai-content-area-right {
  background: #000000a6;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 2px solid #ffffff26;
  padding: 50px 10px;
  /* height: 100%; */
  border-radius: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
}
.ai-content-area-inner h6 {
  text-transform: uppercase;
  font-weight: 600;
}
.ai-content-area-inner p {
  font-size: 13px;
  text-transform: capitalize;
  margin-top: 14px;
}
.ai-content-area-inner {
  width: 100%;
  padding: 0 35px;
}
.ai-content-area-inner .input-group {
  height: 50px;
  background: #fff;
  border-radius: 6px;
}
.ai-content-area-inner .input-group input::placeholder {
  font-size: 13px;
}
.ai-content-area-inner .input-group input {
  border-right: none;
}
.ai-content-area-inner .input-group span#basic-addon2 {
  background: #fe7702;
  border: none;
  color: #fff;
  margin: 3px;
  border-radius: 5px;
  cursor: pointer;
}
.search-content-alignment {
  align-items: center;
}
h5.ai-to-heading span {
  color: #fe7702;
  font-weight: 600;
}

h5.ai-to-heading {
  color: #fff;
  text-align: center;
  margin-bottom: 45px;
  font-size: 20px;
}
.ai-tab-content-area {
  padding-top: 28px;
}
.ai-content-area-right:after {
  content: "";
  position: absolute;
  background-image: url("../images/blur-bg.png");
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  object-fit: contain;
  background-position: center;
  z-index: -1;
}
/* .generate-ai h2 {
  font-size: 50px !important;
} */
.generate-ai-area {
  padding: 50px 0 20px 0;
}
.generate-ai-content h6 {
  font-size: 16px;
  color: #fff;
  display: flex;
  margin-top: 15px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.generate-ai-content h6 span {
  background: linear-gradient(91.41deg, #f8a8ff 43.18%, #6155ed 154.56%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 25px;
  font-weight: 800;
  border: 1px solid #f8a8ff;
  padding: 8px 12px;
  border-radius: 6px;
  margin-right: 10px;
}
.process-image {
  min-height: 340px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.frame-area {
  background: url("../images/frame-area-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 14px 0 30px 0;
}
.frame-center-image-area {
  position: absolute;
  top: 6%;
  z-index: 0;
  width: 86%;
  left: 5%;
  height: 81%;
  overflow: hidden;
}
.center-frame-area {
  position: relative;
}
.center-frame-area img {
  z-index: 1;
  object-fit: inherit;
  position: relative;
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}
/* .name-input input {
  width: 248px;
  left: 145px;
  left: 145px;
  position: absolute;
  bottom: 11px;
  z-index: 2;
  height: 58px;
  background: transparent;
  border: transparent;
  color: #fff !important;
  font-size: 22px;
  font-weight: 700;
} */
.name-input input {
  width: 100%;
  /* left: 20%; */
  /* position: absolute; */
  /* bottom: 0; */
  z-index: 2;
  /* height: 58px; */
  background: transparent;
  border: transparent;
  font-size: 17px;
  font-weight: 700;
  background: -webkit-linear-gradient(#fcee21, #fbb03b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.name-input .form-control:focus {
  background-color: #0000 !important;
}
.name-input input::placeholder {
  color: #ffffffa3;
  font-size: 18px;
  font-weight: 900px;
  background: -webkit-linear-gradient(#fcee21, #fbb03b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.number-input input {
  padding: 0 13px !important;
  width: 49px;
  /* position: absolute; */
  /* height: 60px; */
  right: 12px;
  z-index: 2;
  top: 0;
  border-radius: 50%;
  background: transparent;
  border: transparent;
  font-size: 20px;
  font-weight: 700;
  color: #fff !important;
  background: -webkit-linear-gradient(#fcee21, #fbb03b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.number-input input::placeholder {
  color: #ffffffa3;
  font-size: 22px;
  font-weight: 900px;
  background: -webkit-linear-gradient(#fcee21, #fbb03b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.display-none-important {
  display: none !important;
}
.number-input .form-control:focus {
  background-color: #0000 !important;
}
.frame-options-area {
  padding: 60px 0 120px;
}
.image-image-area {
  text-align: center;
}
.image-image-area p span {
  color: red;
  font-size: 30px;
}

.image-image-area p {
  font-size: 15px;
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-image-area button {
  background: #fe7702;
  color: #fff;
  padding: 8px 33px;
  font-size: 16px;
  border-radius: 7px;
  margin-top: 10px;
}
.image-uploaad-area input {
  width: 130px;
  height: 130px;
  position: absolute;
  top: 0;
  opacity: 0;
}
.image-uploaad-area img {
  width: 130px;
  height: auto;
  min-width: 130px;
  object-fit: cover;
  border-radius: 5px;
}
.image-uploaad-area {
  width: 130px;
  margin: 0 auto;
  position: relative;
}
.bottom-area-btns {
  display: flex;
  justify-content: center;
}
.frame-center-image-area .card {
  height: 100% !important;
  background: #120715  !important;
  padding: 2px;
}

.shimmerBG.media {
  background: linear-gradient(
    to right,
    #492018 8%,
    #451f17ed 18%,
    #8364e221 33%
  );
  height: 100% !important;
}
.frame-center-image-area .card .shimmerBG {
  height: 100% !important;
  background: linear-gradient(to right, #fe770200  8%, #fe77020a  33%);
}
.card .media {
  height: 100% !important;
}
/* .frame-center-image-area .card .shimmerBG.media {
  height: 100% !important;
  background: linear-gradient(to right, #492018 8%, #451f17ed 18%, #8364e221 33%) !important;
}
.card .shimmerBG {
  height: 100% !important;
  -webkit-animation-duration: 2.2s;
  animation-duration: 2.2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #492018 8%, #451f17ed 18%, #8364e221 33%) !important;
  background-size: 1200px 100%;
} */
.regenerate-btn button {
  background: linear-gradient(
    91.74deg,
    #e853e2 -1.58%,
    #e74fe2 -1.57%,
    #34a6e3 103.26%
  ) !important;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  width: 200px;
  height: 50px;
  margin: 0 5px;
}
.ai-mint-nft button {
  border: 2px solid #d158e2;
  background: transparent;
  width: 200px;
  height: 52px;
  margin: 0 5px;
  border-radius: 10px;
  background: -webkit-linear-gradient(#40a0e3, #d158e2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  font-weight: 700;
}
.outer-box-frame {
  width: 100%;
  position: relative;
}
/* .frame-ai-image .name-input input {
  bottom: 4% !important;
  width: 165px !important;
} */
/* .frame-ai-image .number-input input {
  right: 7px !important;
  top: 0% !important;
  padding: 0 !important;
} */
.frame-ai-image .frame-center-image-area {
  /* height: 78%;
  left: 4%; */
  background-color: #000000a1;
}

/* .wave {
  float: left;
  animation: wave ease-in-out 0.7s infinite alternate;
  transform-origin: center -36px;
} */

/* .wave:hover {
  animation-play-state: paused;
  cursor: pointer;
}

.wave img {
  display: block;
  width: 100%;
  height: 100%;
}

.wave figcaption {
  text-align: center;
}

.wave:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1.5px solid #ffffff;
  top: -10px;
  left: 40%;
  border-bottom: none;
  border-right: none;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
}

.wave:before {
  content: "";
  position: absolute;
  top: -23px;
  left: 40%;
  display: block;
  height: 44px;
  width: 47px;
  background-image: url(https://cdn.hashnode.com/res/hashnode/image/upload/v1602040503586/FW6g79WBY.png);
  background-size: 20px 20px;
  background-repeat: no-repeat;
  z-index: 16;
}

@keyframes wave {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
} */

/* iframe{
  display: none;
} */
.button-disables {
  opacity: 1;
  pointer-events: none;
  background: #808080 !important;
  cursor: none !important;
}
main {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #090707;
}
.dank-ass-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dank-ass-loader .row {
  display: flex;
}
.arrow {
  width: 0;
  height: 0;
  margin: 0 -6px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 21.6px solid #fd7000;
  animation: blink 1s infinite;
  filter: drop-shadow(0 0 18px #fd7000);
}
.arrow.down {
  transform: rotate(180deg);
}
.arrow.outer-1 {
  animation-delay: -0.0555555556s;
}
.arrow.outer-2 {
  animation-delay: -0.1111111111s;
}
.arrow.outer-3 {
  animation-delay: -0.1666666667s;
}
.arrow.outer-4 {
  animation-delay: -0.2222222222s;
}
.arrow.outer-5 {
  animation-delay: -0.2777777778s;
}
.arrow.outer-6 {
  animation-delay: -0.3333333333s;
}
.arrow.outer-7 {
  animation-delay: -0.3888888889s;
}
.arrow.outer-8 {
  animation-delay: -0.4444444444s;
}
.arrow.outer-9 {
  animation-delay: -0.5s;
}
.arrow.outer-10 {
  animation-delay: -0.5555555556s;
}
.arrow.outer-11 {
  animation-delay: -0.6111111111s;
}
.arrow.outer-12 {
  animation-delay: -0.6666666667s;
}
.arrow.outer-13 {
  animation-delay: -0.7222222222s;
}
.arrow.outer-14 {
  animation-delay: -0.7777777778s;
}
.arrow.outer-15 {
  animation-delay: -0.8333333333s;
}
.arrow.outer-16 {
  animation-delay: -0.8888888889s;
}
.arrow.outer-17 {
  animation-delay: -0.9444444444s;
}
.arrow.outer-18 {
  animation-delay: -1s;
}
.arrow.inner-1 {
  animation-delay: -0.1666666667s;
}
.arrow.inner-2 {
  animation-delay: -0.3333333333s;
}
.arrow.inner-3 {
  animation-delay: -0.5s;
}
.arrow.inner-4 {
  animation-delay: -0.6666666667s;
}
.arrow.inner-5 {
  animation-delay: -0.8333333333s;
}
.arrow.inner-6 {
  animation-delay: -1s;
}
@keyframes blink {
  0% {
    opacity: 0.1;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
/* .preview-area .center-frame-area img {
  width: 72%;
} */
.preview-area .frame-center-image-area {
  left: 5%;
}
.preview-area .center-frame-area {
  text-align: center;
  width: 466px;
  height: auto;
}
.cancel-btn {
  background: #717171 !important;
}
.preview-area .number-input.two-digit-preview {
  right: 28px;
}
.preview-area .number-input.one-digit-preview {
  right: 35px;
}
.link-copied-box svg {
  visibility: visible;
}
.branding-whole-page {
  background: #120715;
}
.branding-banner-area {
  background-image: url("../../assets/images/branding-hero-bnr.png");
  background-repeat: no-repeat;
  margin-top: 25px;
  height: 90vh;
  display: flex;
  background-position: top center;
  align-items: center;
  background-size: 100% auto;
}
.touch_form input::placeholder, .touch_form textarea::placeholder {
  color: #ffffff52 !important;
}
.not-found-page-section {
  display: flex;
  height: calc(100vh - 243px);
  align-items: center;
  justify-content: center;
}
.not-found-box h1 {
  font-size: 29px;
}
.not-found-box img {
  margin-bottom: 22px;
}
.not-found-box {
  text-align: center;
}
.cursor-pointer{
  cursor: pointer;
}
.branding-banner-text-area h1 {
  font-size: 60px;
  color: #fff;
  font-weight: 500;
  line-height: 75px;
}
.branding-banner-text-area h1 span {
  color: #fe7803;
  font-weight: 600;
}
.branding-banner-text-area p {
  color: #fff;
  font-size: 22px;
  margin: 15px 0 25px;
}
button.partner-with-us {
  background: #fe7803;
  color: #fff;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 10px;
}
.branding-about-sportsfinda {
  padding: 70px 0;
}
.branding-about-sportsfinda-content {
  text-align: center;
  margin-bottom: 70px;
}
.branding-content-left img {
  width: 100%;
}
.branding-content-right p {
  color: #fff;
  font-size: 18px;
  line-height: 34px;
}
.bank-form div {
  margin: 13px 0;
}
.branding-content-right p span {
  color: #fe7702;
}
.branding-choose-area-content {
  border-radius: 10px;
  -webkit-backdrop-filter: blur(22px);
  backdrop-filter: blur(22px);
  background: #440a653d;
  text-align: center;
  color: #fff;
  padding: 30px;
  border: 1px solid #fe78036e;
  height: 100%;
  position: relative;
}
.brading-choose-icon-area {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.get-in-touch-area {
  background-image: url("../../assets/images/get-in-touch-bg.png");
  background-repeat: no-repeat;
  padding: 50px 0;
  background-position: center;
}
.get-in-touch-left-content p {
  color: #fff;
  font-size: 24px;
  margin-bottom: 35px;
}
.get-in-touch-right-picture {
  text-align: center;
}
.branding-why-choose-us-area {
  padding: 40px 0 50px;
}
.brading-choose-icon-area img {
  background: #120715;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #fe780399;
}
.branding-choose-area-content p {
  margin-bottom: 0;
}
.branding-choose-area-content h4 {
  font-size: 24px;
  font-weight: 500;
  margin: 30px 0 10px;
}
.branding-choose-area-content p {
  font-size: 18px;
  line-height: 29px;
}
.buy-nft-tab button.disabled {
  background: #646262;
  cursor: not-allowed;
}
.crossmintButton-0-2-1:hover:enabled {
  cursor: pointer;
  opacity: 1 !important;
}
.main-section-container {
  height: calc( 100vh - 243px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirmation-div h1 {
  font-size: 25px;
  margin-top: 11px;
}
.confirmation-div {
  text-align: center;
}
.profile-box.under-profile-box {
  padding-bottom: 20px;
}
.action-right-btn {
  text-align: right;
  padding: 0 23px;
  display: flex;
  justify-content: flex-end;
}
.css-hlgwow {
  height: 45px;
  margin: 0 !important;
}
.form-group.address-box div {
  margin: 0 !important;
}
.address-box .css-1jqq78o-placeholder, .address-box .css-1dimb5e-singleValue {
  color: #fff;
}
.form-group.address-box label {
  margin: 0 0 7px 0;
}
.add-bank-section .text-danger {
  margin-top: 10px;
  display: inline-block;
}
.follow-boxes {
  display: flex !important;
  align-items: center;
  flex-direction: unset !important;
}
span.sold-out-status {
  background: #4f0c75;
  padding: 7px 17px;
  font-size: 15px;
  border-radius: 22px;
  /* cursor: none; */
}
.follow-btn-text {
  background: #fe7702;
  display: inline-block;
  padding: 2px 10px;
  border-radius: 12px;
  font-size: 12px;
  border: 1px solid;
  cursor: pointer;
}
.follow-div p {
  color: #fff !important;
}
.checkmark {
  background: green;
  padding: 4px;
  margin: 0 0 0 7px;
  border-radius: 12px;
  font-size: 12px;
}
.about-main-content p {
    max-width: 100% !important;
}
.about-main-content {
    text-align: center;
    max-width: 1080px;
    margin: 0 auto;
    padding-top: 42px;
}
.about-page .about-main-content h1 {
    font-size: 42px;
    
}
.about-page .about-main-content h2 {
    font-size: 31px;
    
}
/* athlenfts page css end */
@media (min-width: 1700px) {
  .App .container {
    max-width: 1600px;
  }
}

@media (max-width: 1699px) {
  .athe-banner-area {
    padding: 95px 0;
    margin-top: 73px;
  }
  .searchbar_header input {
    min-width: 276px;
  }

  .input-image {
    width: 250px;
    height: 250px;
  }
  .market_banner_section #left-tabs-example-tabpane-first {
    margin-top: 40px;
  }
}

@media (max-width: 1399px) {
  .home_banner_right {
    background-size: contain;
    background-repeat: no-repeat;
  }
  .branding-banner-text-area {
    padding: 132px 0;
}
.branding-banner-area {
  display: block;
  align-items: unset;
}
  .searchbar_header input {
    min-width: 233px;
  }

  .slide-2 .mainimg {
    height: 310px;
  }

  header .nav-link {
    font-size: 15px;
    margin: 0 15px;
  }

  .navbar-brand img {
    max-width: 150px;
  }
}


@media (max-width: 1500px) {
  .preview-area .center-frame-area {
    width: 466px;
  }
  .preview-area .number-input.one-digit-preview {
    right: 32px;
}
}
@media (max-width: 1400px) {
  .branding-choose-area-content p {
    font-size: 16px;
    line-height: 25px;
}
.branding-choose-area-content {
  padding: 20px;
}
.branding-choose-area-content h4 {
  font-size: 21px;
  font-weight: 500;
  margin: 40px 0 8px;
}
  .ath-content h2,
  .get-in-area h2,
  .unique-content h2 {
    font-size: 40px;
  }
  span.navbar-brand img {
    cursor: pointer;
    max-width: 160px !important;
  }

  header .container {
    max-width: 100%;
  }

  .collection_img img {
    height: 245px;
  }

  .tab_box_home span {
    right: 7px;
  }

  .in-stock {
    padding: 6px 13px;
    font-size: 13px;
  }
}

@media (max-width: 1199px) {

  .branding-banner-text-area h1 {
    font-size: 41px;
    line-height: 70px;
}
.branding-banner-text-area h1 br{
  display: none;
}
.branding-banner-area{
  height: 59vh;
}
  .generate-ai-content h6 span {
    font-size: 22px;
    padding: 8px 12px;
  }
  .number-input.one-digit-preview {
    right: 31px;
  }
  .athe-banner-area {
    padding: 50px 0;
  }
  .home_header .navbar-toggler {
    padding: 2px 5px;
  }
  .wallet-bt-connect.dropdown {
    display: none;
  }
  .toggle-drop-right .wallet-bt-connect.dropdown {
    display: block;
  }
  .toggle-drop-right button#dropdown-basic {
    /* width: auto; */
    position: relative;
    left: 60px;
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
    top: -2px;
  }
  .toggle-drop-right {
    display: flex;
    align-items: center;
  }

  .our-team h2 {
    font-size: 42px;
  }

  .event-dates h4 {
    font-size: 24px;
  }
  .event-dates h3 {
    font-size: 30px;
  }

  .market_categories .nav-item .nav-link {
    min-width: 160px !important;
  }

  .home-banner-left h1 {
    font-size: 50px;
  }

  .navbar-nav.navbar-nav-scroll {
    margin-bottom: 16px !important;
  }

  #dropdown-basic {
    padding: 14px 7px;
    font-size: 13px;
  }

  .searchbar_header input {
    min-width: 192px;
  }

  header .startselling_btn {
    padding: 14px 7px;
    font-size: 13px;
  }

  .collecting-bt {
    font-size: 16px;
  }

  .market-left h1 {
    font-size: 50px;
  }

  .tab_box_home {
    padding: 10px;
  }

  .explore-box h1 {
    margin-bottom: 23px;
  }

  .home_banner_right {
    padding: 35px 0;
  }

  .hot-heading {
    font-size: 50px;
  }

  .home-banner-left p {
    font-size: 18px;
  }

  .collection_img img {
    height: 180px;
  }

  .App .container {
    max-width: 1100px;
    padding: 0 20px;
  }

  .slide-2 .mainimg {
    height: 290px;
  }

  .tenis-box img {
    max-width: 50px;
  }

  .tenis-box {
    padding: 9px 8px;
  }

  .like-view h6 {
    font-size: 12px;
  }

  .instant-sale-prize {
    width: 78%;
  }

  .name-description-filed {
    padding: 30px 12px;
  }

  .seller-box h2 {
    font-size: 50px;
  }

  #google_translate_element {
    text-align: center;
    margin-top: 11px;
  }

  .navbar-nav .dropdown,
  .navbar-nav .dropdown-menu.show {
    text-align: center;
  }

  header .startselling_btn {
    margin: 10px auto !important;
    width: fit-content;
    width: 180px;
  }

  #dropdown-basic {
    width: 180px;
    height: 50px;
  }

  #google_translate_element select {
    margin-left: 0;
  }

  header .nav-link {
    margin: 12px 15px 0;
  }
  .number-input input {
    right: 3% !important;
    top: 1% !important;
  }
  .number-input input {
    top: 0 !important;
  }
}

@media (max-width: 991px) {
  .get-in-touch-left-content p {
    color: #fff;
    font-size: 20px;
    margin-bottom: 25px;
}
.branding-banner-area {
  background-image: url("../../assets/images/branding-banner-area.png");
  background-position: center;
  height: 100vh;
  background-size: 100% auto;
}
.branding-banner-text-area {
  padding: 99px 0;
}
.branding-banner-text-area h1 {
  line-height: 52px;
}
  .branding-content-left {
    margin-bottom: 15px;
}
  .preview-area {
    margin-top: 18px  !important;
}
  .process-image {
    min-height: unset;
    display: unset;
  }
  /* .frame-ai-image .name-input input {
    width: 215px !important;
  } */
  /* .frame-ai-image .number-input input {
    right: 20px !important;
    top: 2% !important;
  } */
  .generate-ai-content {
    text-align: center;
    margin-top: 50px;
  }
  .frame-options-area {
    padding: 60px 0;
  }
  .ai-generator-area h2 {
    font-size: 55px;
  }
  .main_h2 {
    font-size: 30px;
  }
  /* .preview-desktop-view {
    display: none;
  } */
  .signle-detail-data-area {
    margin-bottom: 15px;
  }
  .touch_form {
    padding: 37px 30px;
  }
  .nil-providers {
    padding: 50px 0;
  }
  .circle_row .col-md-6:last-child {
    padding: 0 0px 0 50px !important;
  }
  .unique-content h2 br {
    display: none;
  }

  .member-one {
    padding: 10px;
  }

  .discord-section-content h2 {
    font-size: 39px !important;
  }

  .discord-section-content p {
    font-size: 18px !important;
  }

  .discord-section-bg {
    padding: 10px;
  }

  .circle_row .col-md-6 {
    position: relative;
    padding: 0 50px 0 11px !important;
    z-index: 2;
  }

  .preview-image img {
    width: 100%;
    height: 250px;
    object-fit: contain;
  }

  .home_banner_section {
    height: auto !important;
  }

  .about-page h1 {
    font-size: 60px;
  }

  .creater-collection-area {
    display: block;
  }

  .sign-area {
    height: 237px;
    margin-bottom: 20px;
  }

  #google_translate_element select {
    margin: 0;
  }

  .App .container {
    max-width: 720px;
  }

  .market_banner_section {
    margin-top: 74px;
  }

  .home_banner_right {
    /* height: 85vh; */
  }

  .attri-name {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 50px;
    line-height: 1.8em;
  }

  .more-div {
    margin-bottom: 12px;
  }

  .explore-box {
    padding: 30px 0 0;
  }

  .leftfooter {
    padding-right: 20px;
  }

  footer h3 {
    margin-bottom: 19px;
    width: 100%;
  }

  .category_row.row {
    margin-top: 0px;
  }

  .footer_head h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: -0.3px;
    color: #fff;
  }

  .auction-right-side {
    padding: 0 20px;
  }

  .connect-wallet-bt {
    margin: 0 auto;
  }

  .preview-image {
    width: 100%;
    height: auto;
  }

  .preview-area {
    height: auto;
  }

  .event-dates {
    padding: 8px 15px;
  }
  .event-dates h4 {
    font-size: 18px;
  }
  .event-dates h3 {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .get-in-touch-right-picture {
    margin-bottom: 20px;
}
button.partner-with-us {
  background: #fe7803;
  color: #fff;
  font-weight: 500;
  padding: 8px 20px;
  border-radius: 10px;
  font-size: 15px;
}
.get-in-touch-left-content p {
  color: #fff;
  font-size: 19px;
  margin-bottom: 12px;
}
  .get-in-touch-area .row {
    flex-direction: column-reverse;
}
  .branding-why-choose-us-area {
    padding: 0 0 50px;
}

  .choose-ai-tab-area ul li {
    padding: 18px 20px;
  }
  .choose-ai-tab-area ul li img {
    width: 35px;
  }
  .ai-generator-area h2 {
    font-size: 40px;
  }
  .ai-generator-area h2 {
    font-size: 55px;
  }
  .frame-options {
    text-align: center;
    margin-bottom: 40px;
  }
  .home-banner-left .collecting-bt {
    margin: 0 auto !important;
  }
  .event-dates {
    display: block;
  }

  .boder-box {
    margin-top: 20px;
  }

  .event-dates h4 {
    margin-bottom: 5px;
  }
  .MuiOutlinedInput-root.MuiInputBase-root {
    width: 100% !important;
  }

  .first_section_main img {
    max-width: 450px;
    margin-bottom: 10px;
    width: 100%;
  }

  .member-one {
    /* height: auto; */
    padding: 10px;
    /* max-width: 70%; */
    margin: 0 auto 50px;
  }

  .member-info p {
    color: #ffffffad;
    min-height: 60px;
  }

  .blockchain_row div {
    width: 100% !important;
  }

  .discord-section-content p {
    line-height: 23px !important;
    margin-bottom: 40px !important;
  }

  .about-page h3 {
    font-size: 30px;
    margin: 0 0 20px 0;
  }

  .about-page h2 {
    font-size: 36px;
  }

  .about-page h1 {
    font-size: 48px;
  }

  .founding-div p {
    font-size: 16px;
    margin: 23px 30px;
  }

  .new-banner-area {
    margin-top: 75px !important;
  }

  .navbar-brand img {
    max-width: 150px !important;
  }

  .air-max h2 {
    font-size: 20px;
  }

  .details-cover img {
    height: 280px;
  }

  .bid_auction p {
    font-size: 14px;
  }

  .home-banner-left h1 {
    font-size: 40px;
  }

  .bid_auction h1 {
    font-size: 20px;
  }

  .hot-heading {
    font-size: 40px;
  }

  .works-area-box h2 {
    font-size: 40px;
  }

  .shade-box {
    left: 0;
  }
  /* 
  .crete-collect-new {
    flex-direction: column-reverse;
  } */

  /* .preview-area {
    margin: 20px;
  } */

  .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
  }

  .home-banner-left h1 {
    text-align: center;
  }

  .home-banner-left {
    text-align: center;
  }

  .rightsdefooter {
    margin-top: 30px;
  }

  .explore-box .text-center.col-md-5 {
    max-width: 500px;
    margin: 0 auto;
  }

  .down-scroll {
    margin-top: 80px;
  }

  .leftfooter {
    margin-bottom: 20px;
  }

  .rightsdefoote {
    text-align: left;
  }

  .market_banner_section.h-100 {
    padding: 30px 0;
  }

  .ath-content h2,
  .unique-content h2,
  .get-in-area h2 {
    font-size: 35px;
  }
  .new-header .navbar-collapse {
    margin-top: 20px;
  }
  .new-header .navbar-collapse {
    margin-top: 20px;
  }
  .new-header .nav-link:first-child {
    border-top: 1px solid #ffffff26;
  }
  .ath-content h2,
  .unique-content h2,
  .get-in-area h2 {
    font-size: 30px !important;
  }
  .get-in-area p br {
    display: none;
  }
  .new-header .navbar {
    padding: 15px 5px;
  }
  .get-in-section {
    padding: 52px 0 0;
  }
  .new-header .nav-link {
    color: #fff;
    margin: 0px 8px;
    font-size: 14px;
    border-bottom: 1px solid #ffffff26;
    padding: 10px 0;
  }

  .btn-get-in {
    font-size: 14px !important;
  }
  .get-in-img img {
    width: 100%;
    max-width: 60%;
  }
}

@media (max-width: 576px) {
  .branding-content-right p {
    font-size: 17px;
    line-height: 30px;
}
.about-page .about-main-content h2 {
    font-size: 22px;
    margin-bottom: 12px;
}
.about-page .about-main-content h1 {
    font-size: 31px;
}
.about-main-content {
  
    padding-top: 0;
}
  .branding-banner-text-area h1 {
    font-size: 44px;
    line-height: 56px;
}

.branding-banner-text-area p {
  font-size: 19px;
}
  .generate-ai-area {
    padding: 70px 0 0;
  }
  /* .frame-ai-image .name-input input {
    width: 254px !important;
  } */
  .ai-generator-area h2 {
    font-size: 28px;
  }
  .ai-tab-content-area {
    padding-top: 30px;
  }

  h5.ai-to-heading {
    font-size: 15px;
    margin-bottom: 5px;
  }
  .choose-ai-tab-area ul li img {
    width: 22px;
  }
  .choose-ai-tab-area ul li {
    font-size: 14px;
  }
  .choose-ai-tab-area ul li img {
    width: 30px;
    margin-right: 4px;
  }
  .home_header span.navbar-brand {
    margin-right: 0;
  }
  .user-icon {
    font-size: 24px !important;
  }
  .navbar-light .navbar-toggler-icon {
    font-size: 16px;
  }
  .toggle-drop-right button#dropdown-basic {
    left: 70px;
  }
  h2#swal2-title {
    font-size: 22px;
  }
  .our-team h2 {
    font-size: 30px;
  }
  .discord-section-content h2 {
    font-size: 35px !important;
  }
  .discord-section-content a {
    padding: 10px 40px;
  }
  .owner-details p {
    max-width: inherit;
  }
  .ath-content h2,
  .unique-content h2,
  .get-in-area h2 {
    font-size: 28px !important;
  }

  .owner-details {
    margin-right: 0;
    width: auto;
  }

  .air-price-new {
    font-size: 16px;
  }

  .shades-box {
    z-index: -1;
  }

  .owner-list {
    display: block;
  }

  .owner-list a {
    float: left;
  }

  .margin-ios .goog-te-combo {
    margin-left: 10px !important;
  }

  .air-max h2 {
    font-size: 36px;
  }

  .social-icon li {
    display: inline-block;
    margin: 13px 22px;
  }

  .featured-area ul {
    display: block;
  }

  .home-banner-left h1 {
    font-size: 42px;
  }

  .hot-heading {
    font-size: 42px;
  }

  .eth-box h5 {
    font-size: 20px;
  }

  .seller-box h2 {
    font-size: 36px;
  }

  .air-max h4 {
    font-size: 20px;
  }

  .explore-box .col-sm-3 {
    width: 50%;
  }

  .home-banner-left {
    padding: 70px 10px 0;
  }

  .home_banner_right {
    /* height: 36vh; */
  }

  .home-banner-left h1 {
    font-size: 30px;
  }

  .hot-heading {
    font-size: 30px;
  }

  .market-left h1 {
    font-size: 36px;
  }

  .collection-head-new {
    padding: 7px 9px;
    font-size: 14px;
  }

  .works-area-box h2 {
    font-size: 30px;
  }

  .rightsdefooter {
    margin-top: 0;
  }

  footer {
    padding: 30px 0;
  }

  .end-reserve-bid-box {
    margin: 15px 23px 0 8px;
  }

  .category_row.row .nav-item {
    width: 50%;
  }

  .market_categories .nav-item .nav-link {
    min-width: 160px;
    margin: 5px;
  }

  .search_marketplace {
    width: 100%;
  }

  .category_row.row {
    margin-top: 30px;
  }
  .ok-bt-install-metamask-modal {
    margin-bottom: 12px;
  }
  .discord-section-content {
    width: 90% !important;
  }

  .member-one {
    max-width: 100%;
  }
  .air-price-new b {
    display: inline-block;
  }
  /* .frame-ai-image .name-input input {
    width: 204px !important;
  } */
}
@media (max-width: 480px) { 
  .branding-banner-text-area h1 {
    font-size: 35px;
    line-height: 46px;
}
}
@media (max-width: 420px) {
  .get-in-touch-area {
    margin: 0 0 60px;
}
  .branding-choose-area-content p {
    font-size: 15px;
}
.branding-choose-area-content {
  padding: 15px;
}
  .get-in-touch-left-content p {
    color: #fff;
    font-size: 15px;
    margin-bottom: 23px;
}
  .branding-content-right p {
    font-size: 15px;
}
  button.partner-with-us {
    padding: 7px 15px;
    font-size: 15px;
}
.branding-banner-text-area p {
  font-size: 17px;
}
  .branding-banner-text-area h1 {
    font-size: 28px;
    line-height: 38px;
}
  h6.air-price-new.not-available-btn {
    display: block;
}
  button.swal2-cancel.swal2-styled {
    padding: 5px 18px;
  }
  /* .frame-ai-image .number-input input {
    right: 11px !important;
    top: 0% !important;
  } */
  /* .frame-ai-image .name-input input {
    width: 164px !important;
  } */
  .swal2-popup {
    width: 17em !important;
    /* height: 280px; */
  }
  div#swal2-html-container {
    font-size: 14px;
    margin-top: 0;
  }
  .swal2-styled.swal2-confirm {
    background-color: #fe7702 !important;
    padding: 5px 18px;
  }
  h2#swal2-title {
    font-size: 18px;
  }
  /* button#uncontrolled-tab-example-tab-sale {
    margin-bottom: 15px;
  } */
  .touch_section {
    padding: 0;
  }
  .buy-sell-created .rightside_border {
    margin-top: 14px;
  }
  .buy-sell-created {
    flex-wrap: wrap;
  }
  .collec-heading {
    font-size: 18px;
  }

  .about-page p {
    font-size: 14px;
    line-height: 30px;
  }
  .service_section1 {
    padding: 80px 0 0 0 !important;
  }
  .nft-collection-box li {
    font-size: 14px;
    width: auto !important;
    padding: 8px 11px !important;
  }

  /* .share-bt p {
    padding: 3px 18px !important;
  } */

  .discord-section-content h2 {
    font-size: 25px !important;
  }

  .discord-section-content p {
    font-size: 16px !important;
    line-height: 28px !important;
  }

  .featured-area {
    padding: 40px 0 0;
  }

  .home-banner-left {
    padding: 30px 10px 0;
  }

  .works-area-box {
    padding: 44px 0 50px;
  }

  #hot-collection {
    padding: 0;
  }

  .market_categories .nav-item .nav-link {
    min-width: 100px;
  }

  .market_categories .nav-item .nav-link {
    padding: 12px;
  }

  .sign-ar {
    padding: 20px 20px 20px 20px;
  }
}
@import url(https://fonts.cdnfonts.com/css/circular-std);

body,
html {
  font-family: "Circular Std", sans-serif !important;
}

:focus {
  box-shadow: none !important;
  outline: 0 !important;
}

fieldset,
img {
  border: 0 none;
}

input[type="submit"],
select {
  -webkit-appearance: none;
}

html {
  scroll-behavior: smooth;
}

.alice-carousel__dots {
  display: none !important;
}

.alice-carousel__next-btn-item {
  background: #fe7702;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 30px;
  color: #fff !important;
  position: absolute;
  right: 0;
  top: 130px;
}

.alice-carousel__prev-btn-item {
  background: #fe7702;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 30px;
  color: #fff !important;
  position: absolute;
  left: 0;
  top: 130px;
  text-align: center;
}

img {
  border: none;
  height: auto;
  max-width: 100%;
  outline: 0;
  vertical-align: middle;
}

iframe {
  vertical-align: middle;
}

a:hover {
  text-decoration: none;
  color: #6bcbfc;
}

a,
abbr,
acronym,
address,
article,
aside,
b,
bdo,
big,
blockquote,
body,
br,
button,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
img,
ins,
kbd,
legend,
li,
map,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
ul,
var {
  border: medium none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none !important;
}

input[type="submit"],
select {
  -webkit-appearance: none;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}

header {
  padding: 9px 0;
  position: fixed;
  top: 0;
  z-index: 1024;
  width: 100%;
  background: rgb(0 0 0);
}

.img_position_blue {
  position: absolute;
  right: 0;
  z-index: 1;
}

.black-head {
  background: black;
}

.searchbar_header input {
  background: #f6f7fa !important;
  border-radius: 16px;
  border: 0;
  height: 56px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #081946;
  min-width: 550px;
}

.searchbar_header {
  position: relative;
  margin-left: 20px;
}

.bg-white {
  box-shadow: 2px 2px 2px #e1e1e138;
}

.searchbar_header button {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translate(0, -50%);
}
.bid-modal-bg .text-right {
  margin-bottom: 0;
}
.collection-correct-image {
  margin-top: -22px;
}
.more-games:hover {
  color: #fe7702;
}

.correct-image {
  text-align: center;
  /* margin-top: -39px; */
}
.modal-content {
  width: 90% !important;
  margin: 0 auto;
}
header .startselling_btn {
  background: #fe7702 !important;
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #fff !important;
  height: 52px;
}

.modal-cross-icon-new {
  font-size: 22px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.error {
  color: red;
}

button.btn.btn-unset {
  background: #fe7702 !important;
  color: #fff !important;
  border-radius: 14px;
  width: fit-content;
  padding: 14px;
}
.react-loading-skeleton {
  height: 100% !important;
}

.btm_details.market-btm-details {
  position: relative;
  top: inherit !important;
  left: inherit !important;
}
.home-get-in-touch {
  position: relative;
  z-index: 999;
}
.btm_details.market-btm-details h6.bid-text-two {
  padding-left: 0 !important;
}

button.bg-transparent.border-0.subscribe-bt.btn.btn-unset {
  background: #fe7702 !important;
  height: 50px;
  width: auto;
  color: #fff;
  font-size: 18px;
  border-radius: 10px 10px 0 0;
}

.navbar-nav .dropdown-item .startselling_btn {
  text-align: left;
  border-radius: 0 0 10px 10px;
  width: 100%;
  border-top: 1px solid #ffffff3d;
  margin: 0;
}

.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.navbar-nav .dropdown-menu.show {
  top: 54px;
  min-width: 100px;
  left: -36px;
  padding: 0;
}

.user-icon {
  font-size: 30px;
}

.user-profile-pic {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
}

.profile-bt-new {
  display: flex !important;
  align-items: center;
}

#google_translate_element {
  position: relative;
}

#google_translate_element select option {
  color: #000 !important;
}

.skiptranslate.goog-te-gadget {
  background: #fe7702;
  border-radius: 10px;
  width: 139px;
  height: 50px !important;
  margin-left: 10px;
}

#google_translate_element select {
  background: none;
  border: none !important;
  color: #fff;
  border-radius: 10px;
  height: 42px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

#google_translate_element i {
  position: absolute;
  right: 10px;
  top: 16px;
  color: #fff;
  font-size: 12px;
}

.skiptranslate.goog-te-gadget {
  height: 55px;
  overflow: hidden;
}

header .nav-link {
  font-style: normal;
  font-weight: 500;
  padding: 0 0 6px 0 !important;
  font-size: 14px;
  border-radius: 0;
  margin: 0 20px;
  line-height: 20px;
  color: rgb(255 255 255) !important;
  border-bottom: 4px solid #fff;
}

.modal-email span {
  color: #fe7702;
}
.modal-email {
  margin: 32px 20px;
}

header .nav-link:hover {
  color: #fe7702 !important;
}

header .active.nav-link {
  color: #fe7702 !important;
  border-bottom: 4px solid #fe7702;
}

header .nav-link {
  border-bottom: none;
}

header .login_header .logintext {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #081946;
}

.down-scroll {
  margin-top: 130px;
}

.searchbar_header input::-webkit-input-placeholder {
  color: #4f0c75;
}

.searchbar_header input::-moz-placeholder {
  color: #4f0c75;
}

.searchbar_header input:-ms-input-placeholder {
  color: #4f0c75;
}

.searchbar_header input:-moz-placeholder {
  color: #4f0c75;
}

.banner_section {
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: center;
  position: relative;
  padding: 135px 0;
  overflow: hidden;
  margin-top: 93px;
}

.banner_section .gobackbtn {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 99px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fff;
}

.banner_section h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  color: #fff;
  margin: 35px 0;
}

.banner_section p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #fff;
}

.banner_section h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-decoration-line: underline;
  color: #fe7702;
  cursor: pointer;
}

.banner_section .connectbtn {
  background: #fe7702;
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: #f8f6f6;
  padding: 17px 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 270px;
  margin: 40px 0;
}

.banner_section .connectbtn img {
  transform: rotate(180deg);
}

.images_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner_section p.dulltext {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #ffffffa6;
  margin-top: 25px;
}

footer {
  padding: 30px 0;
  background-color: #000;
}

.footer_head {
  display: flex;
  justify-content: end;
  align-items: center;
}

.footer_head h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  letter-spacing: -0.3px;
  color: #fff;
}

.footerline {
  background: #fff;
  opacity: 0.14;
  height: 1px;
  width: 100%;
}

footer h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  letter-spacing: -0.3px;
  color: #fff;
  margin-bottom: 35px;
  width: 50%;
}

button.buy-now-bt.buy-now-bt-copy {
  margin: 0;
}

.bid-amount h6 {
  color: #ffffffb8;
}

.auction-form input.bid-now-input {
  border-radius: 80px !important;
}

.searchbar_header.footer input {
  border-radius: 0;
  background-color: transparent !important;
  border-bottom: 1px solid #ffffff1f;
  padding: 0;
  font-weight: 300;
  color: #fff;
  height: 52px;
  padding: 0 126px 0 10px;
}

.featured-area {
  text-align: center;
  padding: 40px 0;
}

.searchbar_header button.btn-unset {
  margin: 0px 3px !important;
}

.searchbar_header.footer button {
  right: 0;
}

.searchbar_header.footer input::-webkit-input-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer input::-moz-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer input:-ms-input-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer input:-moz-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer {
  margin-left: 0;
}

.rightsdefooter h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: #ffd2dd;
  margin-bottom: 15px;
}

.rightsdefooter p {
  font-style: normal;
  font-weight: 200;
  font-size: 15px;
  cursor: pointer;
  color: #fff;
}

.leftfooter {
  padding-right: 180px;
}

.collection_section {
  padding: 90px 0;
}

.preview-image video.video-container.video-container-overlay {
  width: 100%;
  height: -webkit-fill-available;
}

.collection_section h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  color: #000;
}

.collection_section h5 {
  font-style: normal;
  font-weight: 200;
  font-size: 21px;
  color: #6b6b6b;
}

.time_row {
  background-color: #4f0c75;
  border-radius: 100px;
  padding: 7px 10px;
  display: inline-block;
  margin-top: 10px;
}

.time_row span {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  padding: 0 4px;
}

.collection_box h3 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin-top: 12px;
}

.userpic_name {
  color: #000;
  font-size: 16px;
  font-weight: 200;
}

.userpic_name {
  position: relative;
  padding-left: 46px;
  padding-top: 2px;
  margin-top: 12px;
}

.userpic_name img {
  width: 33px;
  position: absolute;
  left: 0;
  top: 0;
  height: 33px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 100%;
}

.collection_box {
  border: 1px solid #7070704d;
  border-radius: 17px;
  padding: 17px;
  box-shadow: 0 0 13px -1px #00000024;
}

.collection_img {
  margin-bottom: 5px;
}

.collection_img img {
  max-width: 100%;
  height: 300px;
  width: 100%;
  border-radius: 14px;
  object-fit: cover;
  overflow: hidden;
}

.collectible-upload-image h6 {
  color: #4f0c75;
  font-size: 18px;
  margin: 0;
}

.collectible-upload-image {
  background: #fff;
  box-shadow: 0 0 15px rgb(17 47 73 / 15%);
  border-radius: 15px;
  cursor: pointer;
  padding: 1px 0;
}

.collectible-box {
  position: relative;
  margin: 0 20px;
}

.uplage-image-collect {
  position: absolute;
  height: 290px;
  opacity: 0;
}

.name-description-filed {
  background: #fcfaff;
  padding: 30px 0;
  margin-top: 30px;
}

.collectible-bg {
  /* background: #fcfaff; */
  padding: 100px 0;
  border: 2px dashed #d7d7d7;
  margin: 20px;
  border-radius: 15px;
}

.name-description-filed input {
  border-radius: 10px;
  height: 48px;
}

.create-bt {
  background: #fe7702;
  color: #fff;
  width: 130px;
  height: 37px;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 20px;
}

.preview-area {
  background: #fff;
  box-shadow: 0 0 15px rgb(17 47 73 / 15%);
  border-radius: 15px;
  padding: 20px;
  position: relative;
  height: auto;
  min-height: 230px;
}

.preview-text {
  color: #00000057;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.date-box i {
  position: absolute;
  /* z-index: 1; */
  right: 15px;
  top: 9px;
  background: white;
  padding: 5px;
  color: #5e5e5e;
}

.date-box {
  position: relative;
}

.tab-bid-box {
  margin-top: 22px;
}

.width-box {
  width: 170px !important;
  margin: 0 !important;
}

.put-sale-box {
  margin: 15px 0 !important;
}

.preview-image {
  width: 100%;
  height: 350px;
  object-fit: contain;
  background: #f3f3f3;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.preview-area p {
  word-break: break-word;
  width: 100%;
  text-align: left;
}
.preview-area .description-text {
  word-break: break-word;
  width: 100%;
  text-align: left;
}

.description-toggle {
  margin-left: auto;
}

.form-switch .form-check-input {
  width: 62px !important;
  height: 25px !important;
}

.form-check-input:checked {
  background-color: #4f0c75 !important;
  border-color: #4f0c75 !important;
}

.auction-toggle.area label {
  margin-bottom: 0;
}

.auction-toggle.area {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
}
button.react-share__ShareButton.Demo__some-network__share-button {
  margin: 0 2px;
}
.instant-sale-prize {
  margin-bottom: 10px;
}
.home_banner_right {
  background-size: contain;
  width: 100%;
  /* height: 100vh; */
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  padding: 135px 0;
}

.tyler-hero {
  color: #4f0c75;
  background: #4f0c7521;
  padding: 8px 20px;
  border-radius: 50px;
  font-size: 16px;
}

.profile-image {
  text-align: center;
}

.home-banner-left h1 {
  font-size: 64px;
  color: #fff;
  font-weight: 700;
  margin-top: 15px;
}

.home-banner-left p {
  font-size: 20px;
  color: #bfbfbf;
  margin-bottom: 20px !important;
}

.nft-view-dtails-bt {
  background: #fe7702;
  display: block;
  width: 190px;
  border-radius: 10px;
  text-align: center;
  color: #fff !important;
  padding: 8px 0;
}

.nft-market-checkbox li {
  display: inline-block;
  margin-right: 25px;
}

.nft-market-checkbox {
  padding: 0;
  margin-bottom: 41px;
}

.collecting-bt {
  position: relative;
  background-color: #fe7702;
  padding: 20px 40px;
  border-radius: 10px;
  font-size: 24px;
  color: #fff !important;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  cursor: pointer;
  display: block;
  margin-bottom: 20px;
}

.collecting-bt i {
  margin-left: 15px;
}

.customer-box li {
  margin-left: -22px;
  display: inline-block;
}

.customer-box {
  padding: 0;
}

/* toggle css */
.switch {
  position: relative;
  display: inline-block;
  width: 62px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

.slider.round {
  border-radius: 34px;
}

input:checked + .slider {
  background-color: #4f0c75 !important;
}

.slider.round:before {
  border-radius: 50%;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(37px);
}

.customer-box li:last-child {
  margin-left: 20px;
}

.customer-box li:last-child {
  margin-left: 23px;
}

.customer-box {
  margin: 71px 0 50px 20px;
  display: flex;
  align-items: center;
}

#dropdown-basic {
  background: none;
  border: none;
}

.dropdown-menu.show {
  border: none;
  padding-left: 0;
  background: 0 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background: 0 0 !important;
}

a.dropdown-item {
  padding: 0;
}

.flex-row.flex-wrap.market_categories.nav.nav-pills {
  max-width: 50%;
}

.explore-heading {
  text-align: center;
  color: #fff;
  font-size: 48px;
  padding: 60px 0;
}

.explore-heading span {
  color: #fe7702;
}

/* .no-data {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

#hot-collection {
  /* background-image: url(../images/explore.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  color: #fff;
  text-align: center;
  position: relative;
  padding: 10px 0 40px;
}

.list-tab-box.tab-content {
  width: 100%;
  padding: 20px 0;
}

.more-div {
  margin-bottom: 60px;
}

.name-dec {
  cursor: pointer;
}

.hot-heading {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 50px;
}

.collection-head-new {
  background: #fff;
  color: #4f0c75;
  border-radius: 30px;
  padding: 7px 14px;
  line-height: 97px;
}

#hot-collection .tab-pane {
  margin: 0 10px;
}

.bt-div {
  margin-top: 10px;
}

.tab_box_home {
  background: linear-gradient(180deg, #fafaff 0, #cfd4fe 100%);
  border-radius: 16px;
  /* max-width: 222px; */
  padding: 22px;
  margin: 0 5px 10px;
  position: relative;
  cursor: pointer;
  text-align: left;
}

.tab_box_home h4 {
  font-style: normal;
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 14px;
  color: #12041e;
  white-space: nowrap;
}

.explore-box .col-md-3 {
  padding-right: 0;
}

.tab_box_home.active {
  background: #fe7702;
}

.tab_box_home.active h4 {
  color: #fff;
}

.explore-box h1 {
  margin-bottom: 70px;
}

.tab_box_home.active h5 {
  color: #fff;
  opacity: 0.5;
}

.tab_box_home h5 {
  font-style: normal;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
  color: #c2bfe8;
  height: 17px;
}

.tab_box_home span {
  position: absolute;
  top: 50%;
  right: 20px;
  cursor: pointer;
  transform: translate(0, -50%);
}

img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-30px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-30px);
  }
}

.market_banner_section {
  background-color: #000;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  color: #fff;
  text-align: center;
  position: relative;
  padding-bottom: 60px;
}

.market-left h1 {
  font-size: 90px;
  background: -webkit-linear-gradient(45deg, #f8a8ff, #6155ed);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  font-weight: 800;
}

#nft-feature {
  /* background-image: url(../images/feature.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  color: #fff;
  text-align: center;
  position: relative;
  padding-bottom: 60px;
}

.searchbar_item video {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 10px;
}

.explore-box {
  /* background-image: url(../images/explore_bg.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  color: #fff;
  position: relative;
  padding: 60px 0;
}

.more-games {
  color: #fff;
  font-size: 17px;
  line-height: 60px;
}

#nft-feature h3 {
  color: #fff;
  margin-top: 20px;
}

#nft-feature h3 {
  color: #fff;
}

.step-two {
  padding-top: 40px;
}

.step-three {
  padding-top: 80px;
}

#nft-feature p {
  color: #ffffff70;
  padding: 0 120px;
}

.contact-box-area {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  padding: 60px 0;
}

.contact-left-side h3 {
  color: #fff;
  font-size: 40px;
  line-height: 63px;
  padding: 40px 0 0 70px;
}

.contact-left-side {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

.contact-right-side input {
  background: 0 0 !important;
  border-bottom: 1px solid #ffffff8c !important;
  border: none;
  border-radius: 0;
  color: #fff !important;
  padding-left: 0;
  padding-right: 0;
  margin-top: 50px;
}

.contact-right-side input::placeholder {
  color: #fff !important;
}

.contact-form .btn {
  display: flex;
  margin: 14px auto;
}

.contact-right-side {
  display: flex;
  margin: 25px auto;
}

.contact-form {
  background: #ffffff12;
  border-radius: 20px;
  border: 2px solid #ffffff14;
  padding: 10px 80px 10px 100px;
  position: absolute;
  bottom: 0;
}

.contact-filled-box .row {
  position: relative;
}

.choose-heading {
  font-size: 20px;
}

.single-multiple-box .react-tabs__tab-list {
  display: inline-block;
}

.addresstext {
  position: relative;
}

.addresstext i {
  position: absolute;
  right: 0;
  top: 0;
}

.single-multiple-box .react-tabs__tab--selected {
  border-radius: 5px;
  border: none !important;
  background: #fe7702 !important;
  width: 120px;
  text-align: center;
  color: #fff !important;
}

.create-items li p {
  margin: 0;
}

.single-multiple-box .react-tabs__tab {
  border: none !important;
  padding: 6px 12px;
  cursor: pointer;
  background: #f0ebf6;
  color: #0000008c;
  width: 160px;
  border-radius: 5px;
  font-size: 20px;
  text-align: center;
}

.create-items h6 {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 16px;
  max-width: 100px;
  margin: 0 auto !important;
}

.items-area-box-two img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 2px;
}

.create-items h6 {
  margin: 0;
}

.searchbar_header input {
  padding: 0 68px 0 15px;
}

.create-items ul li {
  display: flex;
  justify-content: center !important;
  border: 1px solid #0000003d;
  border-radius: 5px;
  width: 110px;
  height: 110px;
  margin-right: 15px;
  cursor: pointer;
  padding: 12px 0;
  align-items: center;
}

.items-area-box,
.items-area-box-two {
  text-align: center;
}

.modal-profile-img img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid #fff;
  box-shadow: 1px 3px 7px #c7c7c7;
}

.create-items ul {
  display: flex;
}

.modal-profile-img {
  position: relative;
  height: 30px;
}

.cover-image-box img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

form.text-left {
  text-align: left;
  margin-top: 70px;
}

.profile-input-mod {
  width: 120px;
  height: 120px;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.img-input-mod {
  position: absolute;
  top: 0;
  height: 270px;
  width: 94%;
  opacity: 0;
}

.collection-create .modal-content {
  padding: 0;
}

.craete-bt {
  background: #fe7702;
  color: #fff;
  width: 164px;
  border-radius: 5px;
  height: 50px;
  margin-left: 16px;
}

.create-items ul {
  padding: 0;
  margin-bottom: 0px;
}
.craete-bt.submit-bt {
  width: fit-content !important;
  margin: 0 auto;
  padding: 0 20px;
}

.copies-input {
  max-width: 200px;
}

.single-multiple-box .react-tabs__tab-list {
  border-bottom: none !important;
  margin-bottom: 20px;
}

.buy-nft-tab .nft-modal-items {
  min-height: 85px;
}

.buy-nft-tab .react-tabs__tab--selected {
  border: none;
  color: #fff !important;
  background: #fe7702 !important;
}

.buy-nft-tab button.buy-now-bt.btn.btn-unset {
  width: 80px;
}

.share-modal-new input {
  height: 41px;
  width: 70%;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  padding: 0 8px;
  margin-right: 15px;
}

.buy-nft-tab .react-tabs__tab {
  border: none !important;
  background: #e7e7e7;
  border-radius: 5px;
  margin-right: 5px;
}

.buy-nft-tab ul {
  border: none;
}

.modalclose_icon i {
  margin-top: -2px;
  color: black;
  font-size: 22px;
}
.modalclose_icon {
  width: 30px;
  cursor: pointer;
  height: 30px;
  /* background: #fe7702; */
  color: #fff;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
}

.uploadimg {
  display: inline-block;
  position: relative;
}

.uploadimg input {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  opacity: 0;
}

.addresstext {
  padding-right: 38px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.slide-1 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.tenis-img-two {
  margin-left: -20px;
}

.like-view {
  text-align: center;
  color: #fff;
  margin: 0 15px;
}

.like-view i {
  color: #fc3a3a;
}

.in-stock {
  background: #00000085;
  padding: 10px 13px;
  color: #fff;
  margin-left: auto;
  border-radius: 30px;
}

.slide-percent {
  font-size: 70px;
  color: #fff;
  padding: 80px 30px 0;
  margin-bottom: 0;
}

.slide-progress {
  color: #fff;
  border: 2px solid #fe7702;
  display: inline-block;
  padding: 3px 11px;
  margin-left: 30px;
}

.echo {
  color: #fff;
  padding: 140px 30px 0;
}

.text-right {
  text-align: right;
}

p.text-right i {
  font-size: 22px;
  cursor: pointer;
}

.bid-text {
  padding-left: 30px;
  color: #fff;
  font-size: 14px;
}

.bid-text span {
  color: #fe7702;
}

.bid-text-two span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.btm_details .prices-nft {
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  color: #fff;
  margin-bottom: 10px !important;
}

.total-text {
  margin-bottom: 12px !important;
}

.bid-text-two {
  padding: 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.bid-text-two.bid-usd-box {
  padding-left: 8px;
}

.mainimg {
  height: 246px;
}

.tenis-box {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  position: absolute;
  top: 4px;
  left: 0;
}

.morefaq {
  background: #fe7702;
  color: #fff !important;
  padding: 9px 20px;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: fit-content;
}

li.alice-carousel__stage-item {
  padding: 0 10px;
}

.social-icon li {
  display: inline-block;
  margin: 0 22px;
}

.social-icon {
  padding: 0;
  text-align: center;
  border-radius: 10px;
}

.slide-margin {
  margin: 7px 5px;
}

.react-multi-carousel-track:first-child
  .react-multi-carousel-item.react-multi-carousel-item--active {
  display: none;
}

.errors {
  color: red;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  display: block;
}

.end-reserve-bid-box input {
  margin-bottom: 7px;
  width: 97%;
}

.end-reserve-bid-box {
  margin: 28px 23px 0;
  width: 90%;
}

.meta-mask-modal a b {
  text-decoration: underline;
}

.meta-mask-modal h3 {
  font-size: 24px;
  color: #000;
  font-weight: 600;
}

.meta-mask-modal .modal-content {
  border-radius: 10px;
  /* padding: 40px 0; */
  /* background-image: url(../images/modal-bg.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.ok-bt.btn {
  background: #fe7702 !important;
  border: none;
  width: 70px;
  height: 43px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 8px;
}

.meta-mask-modal a {
  color: #fe7702;
}

.ok-bt.btn::after {
  content: "";
  /* background-image: url(../images/line.png); */
  background-size: contain;
  position: absolute;
  height: 50px;
  width: 50px;
  right: -70px;
  top: -25px;
}

.sale-auction-tab {
  border: none !important;
}

.sale-auction-tab input {
  max-width: 240px !important;
  height: 46px;
  border-radius: 10px !important;
  border: none !important;
}

.sale-auction-tab li.nav-item button {
  width: 160px;
  border-radius: 0;
  color: #5a0075;
  border: 1px solid #fff;
  box-shadow: 2px 4px 1px #b04f19;
  background: #fff;
}
.tab-bid-box .date-box {
  margin-right: 10px;
}

.date-box .MuiFormControl-root {
  width: 100%;
}

.tab-one {
  max-width: 280px;
}

.tab-one input {
  height: 46px;
}

.tab-bid-box label {
  background: #fff;
  padding: 0 9px !important;
  border-radius: 5px;
}

.tab-bid-box input {
  width: 97%;
  height: 46px;
  border-radius: 10px;
}

.profile-box {
  background: #f3f3f3;
  margin: 86px 0 0 0;
  padding: 70px 0;
}

.profile-left-side img {
  width: 220px;
  height: 220px;
  border-radius: 15px;
  object-fit: cover;
}

.edint-pencil {
  background: #fe7702;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  font-size: 13px;
  position: absolute;
  right: -15px;
  top: -16px;
  cursor: pointer;
}

.profile-left-side {
  max-width: 400px;
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  margin: 0 auto;
  position: relative;
  width: 90%;
}

.profile-Right-side {
  text-align: left;
  padding: 0 0 0 18px;
}

.profile-Right-side i {
  font-size: 18px;
  margin-right: 8px;
}

.profile-Right-side .fa-address-book-o {
  margin-right: 0;
}

.modal-img-new {
  width: 160px;
  height: 160px;
  object-fit: cover;
}

.profile-Right-side h2 {
  font-size: 22px;
  color: #47036f;
  display: inline-flex;
  align-items: center;
}

.edit-profile-modal .modal-dialog {
  max-width: 410px;
  margin: 0 auto;
}

.modal-form-new button.ok-bt {
  background: #fe7702;
  padding: 7px 20px;
  border-radius: 5px;
  color: #fff;
  margin: 0 auto;
  display: block;
}

.modal-form-new {
  text-align: left;
  margin: 30px;
}

.profile-Right-side h4 {
  font-size: 15px;
  color: #fe7702;
}

.bid_auction .tab-content {
  margin: 0 13px 0 0 !important;
}

.tab-bid-box .row {
  --bs-gutter-x: 0;
}

.sale-auction-tab .nav-item.show .nav-link,
.sale-auction-tab .nav-link.active {
  color: #fff !important;
  background-color: #5a0075 !important;
  border-color: #5a0075 !important;
}

.auction-right-side {
  text-align: center;
}

.navbar-light .navbar-toggler {
  color: rgb(255 255 255 / 100%) !important;
  border-color: rgb(255 255 255 / 50%) !important;
}

.navbar-light .navbar-toggler-icon {
  filter: invert(1);
}

.save-bid-price {
  background: #5a0075;
  padding: 6px;
  margin-top: 20px !important;
  display: block;
  width: 90px !important;
  text-align: center;
  font-size: 20px;
  color: #fff !important;
}

.main-loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #000000d1;
  z-index: 1056;
  top: 0;
}

.loading {
  text-align: center;
  font-size: 18px;
  color: #fff;
  margin-top: 15px;
  width: 200px;
}

.name-dec {
  display: flex;
  align-items: center;
}

.name-dec p {
  margin-right: 5px;
  font-size: 18px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

/* .search_marketplace {
  width: 70%;
} */
form.searchbar_header {
  margin-right: 12px;
}
.shade-box {
  position: absolute;
  width: 500px;
  top: 0;
  left: 220px;
}

.market_categories .nav-item .nav-link {
  background: linear-gradient(180deg, #fafaff 0, #cfd4fe 100%);
  border-radius: 16px;
  min-width: 180px;
  padding: 22px;
  margin: 1px 10px 9px 0;
  position: relative;
  cursor: pointer;
  text-align: left;
  height: 83px;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

/* body {
  top: 0 !important;
} */

.MuiOutlinedInput-root.MuiInputBase-root {
  background: #fff;
  height: 47px;
  margin-bottom: 7px;
  width: 100%;
  border-radius: 10px;
  /* border: 1px solid #ced4da !important; */
}
.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root
  label {
  background: white;
  padding: 0 6px;
  color: #000;
}
.market_categories .nav-item .nav-link span {
  position: absolute;
  top: 50%;
  right: 20px;
  cursor: pointer;
  transform: translate(0, -50%);
}

.market_categories .nav-item .nav-link.active {
  background: #fe7702;
  border-radius: 16px;
}

.market_categories .nav-item .nav-link.active h4 {
  color: #fff;
}

.market_categories .nav-item .nav-link.active h5 {
  color: #fff;
  opacity: 0.5;
}

.market_categories .nav-item .nav-link h4 {
  font-style: normal;
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 14px;
  color: #12041e;
}

.market_categories .nav-item .nav-link h5 {
  font-style: normal;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
  color: #c2bfe8;
}

.market_banner_section.h-100 {
  padding: 100px 0 20px;
  min-height: 875px;
}

.search_marketplace .searchbar_header {
  margin-left: 0;
}

.slide-2 {
  background-image: unset;
  position: relative;
  margin-top: 0;
  padding: 0;
  border-radius: 15px;
}

.my_listing-area {
  min-height: 600px;
  background: #f3f3f3;
  padding: 50px 0;
  position: relative;
}

span.navbar-brand img {
  cursor: pointer;
  max-width: 229px;
}

.searchbarlist {
  background-color: #fff;
  border-radius: 10px;
  border-bottom: 1px solid #d3d3d32e;
  padding: 10px;
  width: 100%;
  position: absolute;
  top: 64px;
  min-height: 100%;
  max-height: 370px !important;
  overflow: auto;
  box-shadow: 0 2px 4px #00000024;
}

.search-market-banner {
  background-color: #fff;
  border-radius: 10px;
  border-bottom: 1px solid #d3d3d32e;
  padding: 10px;
  width: 100%;
  min-height: 100%;
  max-height: 180px;
  overflow: auto;
  z-index: 1;
  position: relative;
  text-align: left;
}

#asset-search-result {
  max-width: 370px;
  max-height: 180px;
}

.onsale-modal.form-select {
  position: absolute;
  max-width: 150px;
  top: 0;
}

.searchbar_item {
  position: relative;
  padding: 5px 2px;
  border-bottom: 1px solid #d3d3d32e;
  margin-bottom: 0;
}

.__react_component_tooltip {
  background: #fcfaff !important;
  width: 291px;
  border-radius: 10px !important;
  color: #000 !important;
  font-size: 13px !important;
  font-weight: 400;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.33);
  padding: 10px !important;
}

.choose-heading i {
  cursor: pointer;
}

.__react_component_tooltip .multi-line {
  text-align: left !important;
}

.__react_component_tooltip::before {
  color: #fcfaff !important;
}

.__react_component_tooltip::after {
  border-bottom-color: #fcfaff !important;
}

.price-category {
  padding-left: 8px;
}

.searchbar_item h5 {
  font-size: 17px;
  color: #292929;
}

.bid-text-two.bid-usd-box {
  color: #fff !important;
}

.bid-text-two.bid-usd-box span {
  color: #fff !important;
}

.header-serach-box {
  padding-left: 43px;
}

.header-serach-box h5,
.header-serach-box p {
  margin: 0;
  word-break: break-word;
}

.searchbar_item img {
  width: 38px;
  position: absolute;
  height: 38px;
  border-radius: 100%;
  object-fit: cover;
  overflow: hidden;
}

.featured-area {
  text-align: center;
}

.btm_details .attri-description {
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 3px;
}

.featured-area ul li {
  list-style: none;
  padding: 13px 30px;
}

.featured-area {
  text-align: center;
  padding: 40px 0;
}

.featured-area ul {
  display: flex;
  justify-content: center;
}

.works-area-box {
  /* background-image: url('../images/faq_bg.png'); */
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  color: #fff;
  padding: 150px 0 60px;
}

.featured-area h5 {
  font-size: 36px;
  font-weight: 700;
}

.sign-area h5 {
  margin: 20px 0;
}

.sign-area p {
  color: #ffffffb8;
  font-weight: 400;
}

.auction-right-side img {
  height: 500px;
  max-width: 540px;
  object-fit: cover;
  border-radius: 10px;
  width: -webkit-fill-available !important;
}

.sign-area {
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 20px 96px 20px 20px;
  height: 317px;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  justify-content: center;
}

.works-area-box h2 {
  text-align: center;
  font-size: 60px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 50px;
}

.featured-area {
  text-align: center;
  padding: 40px 0;
}

.faq-box h2.accordion-header {
  margin: 0;
  background: 0 0;
}

.accordion-item {
  border: 1px solid #fff !important;
}

.faq-box h2.accordion-header button {
  background: 0 0;
  color: #fff !important;
}

.slide-2 video {
  height: 246px;
  object-fit: cover;
  display: block;
  position: absolute;
  top: 0;
}

.accordion-button::after {
  filter: invert(1);
}

.accordion {
  max-width: 750px;
  margin: 0 auto;
}

.accordion-item {
  background: 0 0;
  border: 2px solid #fff;
  color: #fff;
  margin-bottom: 20px;
  border-radius: 10px !important;
}

.list-market-new video {
  height: 246px;
  object-fit: contain;
}

.accordion-item {
  background: 0 0 !important;
}

.featured-area h5 {
  font-size: 31px;
  font-weight: 700;
  color: #565656;
}

.slide-2 img {
  width: 100%;
  border-radius: 10px;
}

.slide-2 {
  background: #111722;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #6a6a6a66;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

.btm_details {
  border-radius: 0 0 15px 15px;
  padding: 8px 0 1px 8px !important;
  text-align: left;
  min-height: 45px;
}

.meta-mask-modal.edit-profile-modal.buy-now-modal.modal .modal-content {
  padding-bottom: 5px;
}

.list-market-new:hover {
  background: #131414;
}

.mainimg {
  height: 246px;
  border-radius: 10px 10px 0 0 !important;
  object-fit: cover;
}

.connect-wallet-bt a {
  color: #fe7702;
}

.connect-wallet-bt:hover a {
  color: #fff;
}

span.connect-wallet-bt:hover {
  background: #fe7702;
  border-color: #fe7702;
  color: #fff;
}

.wallet-bt-connect .dropdown-menu.show {
  background: #fe7702;
  /* padding: 5px 10px 5px 10px; */
}

.navbar-expand-xl .navbar-nav {
  align-items: center;
}

.wallet-bt-connect .dropdown-menu.show a:first-child {
  border-top: none;
}

.wallet-bt-connect .dropdown-menu.show a {
  color: #fff !important;
  border-top: 1px solid #ffffff59;
  padding: 10px;
  font-size: 14px;
}

.my_listing-area h2 {
  color: #47036f;
}

.btm_details .attri-price {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.list-market-new {
  border-radius: 15px;
  overflow: hidden;
  background-color: #111722;
}

.creater-rayality-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.slide-2 .bid-text-two {
  padding-top: 8px;
  padding-left: 0;
}

.slide-2 .echo-two {
  padding: 0;
}

.auction-right-side video {
  max-width: 560px;
  width: -webkit-fill-available;
}

.bid_auction {
  background-image: linear-gradient(211deg, #4f0c75, #fe7702);
  padding: 140px 0 50px;
  min-height: 80vh;
}

.live-auction h2 {
  color: #fff;
  font-size: 34px;
  margin-left: 10px;
}

.fade {
  transition: opacity 3s linear !important;
}

.day-timer h4 {
  font-family: "Circular Std", sans-serif !important;
  margin-bottom: 9px;
}

.live-auction {
  display: flex;
  align-items: start;
}

.day-timer {
  background: #00000099;
  border: 2px solid #053430;
  padding: 7px;
  border-radius: 20px;
  color: #fff;
}

.live-auction img {
  margin-top: 10px;
}

.day-timer h6 {
  font-size: 14px;
  margin-bottom: 0;
}

.share-bt p {
  margin-bottom: 0;
  background: #ffffff21;
  border-radius: 50px;
  padding: 5px 10px 5px 5px;
  margin-left: 10px;
  font-size: 14px !important;
  /* min-width: 100px; */
}

.fa-share-alt {
  background: #fff;
  width: 20px;
  text-align: center;
  color: #b34933;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
}
.footer-area-email.modal-body {
  font-size: 14px;
  /* padding-bottom: 11px; */
}
.auction-form .input-group-text {
  border-radius: 0;
  background-color: #fff;
  border: none;
  border-left: 3px solid #26b1a1;
  margin: 13px 0;
  padding: 4px;
}

.auction-form .input-group {
  background: #fff;
  border-radius: 50px;
  padding: 0 20px;
}

.auction-form .row {
  display: flex;
  align-items: center;
}

.auction-form input {
  border-radius: 0 30px 30px 0 !important;
  height: 58px;
  border: none;
}

.bid_auction hr {
  color: #fff;
}

.eth-box h5 {
  font-size: 16px;
  color: #fff;
  background: #ffffff21;
  border-radius: 50px;
  padding: 0 11px;
  margin: 6px 0 6px 10px;
}

.bid_auction h1 {
  font-size: 24px;
  color: #ffd6ae;
  font-weight: 700;
}

.buy-now-bt {
  background: url("../images/buy.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  height: 79px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  color: #fff !important;
  max-width: 190px;
  margin-top: 20px;
  justify-content: center;
}

.d-inline-block {
  display: inline-block !important;
}

.air-max h2 {
  font-size: 35px;
  color: #ffd6ae;
  margin-bottom: 0;
  display: block;
  line-height: 30px;
  max-width: 600px;
  word-break: break-word;
}

.name-description h5 {
  color: #fff;
  margin-bottom: 5px;
}

.name-description p {
  font-size: 14px !important;
  color: #efefef;
  font-weight: 400;
  margin: 0;
}


p.nft-auction-time {
  background: #4f0c75;
  color: #fff;
  padding: 2px 12px;
  border-radius: 15px;
  max-width: fit-content;
}
.air-max h4 {
  color: #fff;
  font-size: 20px;
  margin-bottom: 0;
}

.buy-sell-box {
  margin-top: 25px;
}

.buy-sell-box h4 {
  color: #fff;
}

.collections-details-box {
  max-width: 740px;
  display: flex;
  align-items: center;
}

.details-cover {
  margin-bottom: 50px;
}

.collections-details-box img {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  object-fit: cover;
}

.buy-now-png {
  /* background-image: url('../images/buy.png'); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 44px;
  color: #fff;
  line-height: 60px;
}

.bid_auction p {
  color: #ffffffb8;
  font-size: 16px;
  word-break: break-word;
  font-weight: 400;
  margin: 0;
}

.air-max {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.day-timer li {
  color: #fff;
  display: inline-block;
  margin: 0 20px;
  text-align: center;
}

.day-timer {
  margin: 0 0 0 auto;
  text-align: center;
}

.seller-box {
  background-color: #000;
  width: 100%;
  color: #fff;
  position: relative;
  padding: 40px 0;
}

.collec-heading {
  color: #fff;
  margin-bottom: 30px;
}
span.text-danger {
  color: red !important;
  font-size: 14px;
  font-weight: 400;
}
.collec-heading a {
  color: #c5c5c5 !important;
}

.details-cover img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 15px;
}
.collections-name-box {
  margin: -195px 0 0 0 !important;
  background: #00000087;
  position: relative;
  width: 100%;
  border-radius: 0 0 15px 15px;
  padding: 10px;
}
.wallet {
  width: 14% !important;
  height: 20% !important;
  object-fit: contain !important;
  border-radius: 0 !important;
  margin-right: 11px !important;
}
.seller-box h2 {
  font-size: 36px;
  text-align: center;
  margin-bottom: 30px;
}

.seller-box .tab-pane {
  margin: 0 10px;
}

.seller-box h2 span {
  color: #fe7702;
}

.login_header button {
  font-size: 14px;
}

.my-acc-bt {
  color: rgba(61, 66, 79, 0.65) !important;
}

.navbar-expand-lg .navbar-collapse {
  align-items: initial;
}

.nft-collection-box li {
  color: #ffffff94;
  list-style: none;
  display: inline-table;
  width: 120px;
  text-align: center;
}

.nft-collection-box {
  text-align: left;
  position: relative;
}

.nft-collection-box ul {
  padding: 0;
  border: none;
}

.nft-collection-box li a {
  color: #fff;
}

.faq-link {
  color: #fe7702 !important;
  font-size: 16px;
  display: block;
  margin-top: 30px;
  text-decoration: underline !important;
}

.onsale-modal.form-select {
  position: absolute;
  max-width: 150px;
  top: 0px;
  left: 280px;
  height: 48px;
  border-radius: 10px;
  border: 2px solid #000;
}

.nft-collection-box li a.active {
  background: #000;
  padding: 8px 10px;
  border-radius: 10px;
  height: 40px;
  display: block;
  color: #fe7702;
}

.nft-collection-box ul {
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  background: #2f333c;
  padding: 5px;
  margin-bottom: 15px;
}

.nft-collection-box h5 {
  margin-bottom: 10px;
}

.searhmarket-content {
  padding-left: 45px;
}
.service_section1 {
  padding: 80px 0;
}
.single-nft-preview {
  text-align: center !important;
}
.signle-area-collectibla-box {
  margin: 0;
}
.swal-button {
  background-color: #fe7702 !important;
}
.swal-footer {
  text-align: center;
}
.kennedy-brooks {
  background-image: url(../images/promote-bg.png);
  background-repeat: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}
.athelete-top.area {
  margin-top: 83px;
}
button.btn.btn-unset.generate-nft-btn {
  background: linear-gradient(
    91.74deg,
    #e853e2 -1.58%,
    #e74fe2 -1.57%,
    #34a6e3 103.26%
  ) !important;
  /* width: 221px; */
  margin-left: 12px;
  border: none;
}
.choose-ai-area {
  padding: 50px 0 30px;
}
.ai-generator-area h2 {
  margin-bottom: 0;
}
.ai-generator-area h2 {
  font-size: 50px;
  background: -webkit-linear-gradient(45deg, #f8a8ff, #6155ed);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-weight: 800;
}
.loading {
  word-break: unset !important;
}
.loader-div {
  min-width: 100px !important;
}
.preview-area .name-input input {
  left: 30%;
}
.preview-area .number-input input {
  /* right: 74px; */
  position: unset;
  color: #fbb03b !important;
  background: unset;
  ebkit-background-clip: unset;
  -webkit-text-fill-color: unset;
}
.preview-area .number-input {
  position: absolute;
  right: 23px;
  z-index: 99;
  top: 26px;
  width: unset;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ai-mint-nft {
  margin: 0 31px 0 12px;
}
.preview-area .name-input input {
  color: #fbb03b !important;
  background: unset;
  ebkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  position: unset;
}
.preview-area .name-input {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 24px;
  left: 103px;
}
.name-input input::placeholder{
    padding: 0 28px;
}
.preview-image {
  height: 538px !important;
}
.discord-section-bg.branding-partnership-mid-area {
  background-image: url("../images/branding-hero-bnr.png");
  background-repeat: no-repeat;
  background-position: center;
}
.discord-section-bg.branding-partnership-mid-area a {
  width: fit-content;
  padding: 12px 20px;
}
.discord-section-bg.branding-partnership-mid-area .discord-section-content {
  text-align: left;
  padding: 0 0 0 20px;
}
.discord-section-bg.branding-partnership-mid-area .discord-section-content h2 {
  color: #fff;
  font-size: 40px !important;
  font-weight: 700;
}
section.discord-section.brand-mid-spacing {
  padding-top: 100px;
}
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: #fe7702;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 
@media (max-width: 1700px) {
  .App .container {
    max-width: 1600px;
  }
  .number-input input {
    top: 4px !important;
  }
}

@media (max-width: 1699px) {
  .searchbar_header input {
    min-width: 276px;
  }
  .athe-banner-area {
    padding: 84px 0;
  }
}

@media (max-width: 1399px) {
  .home_banner_right {
    background-size: contain;
    background-repeat: no-repeat;
  }
  .preview-area .center-frame-area img {
    width:100%;
  }
  .preview-area .name-input input {
    left: 23%;
    bottom: -2px !important;
    width: 158px !important;
    position: 50%;
  }
  .preview-area .number-input input {
    right: 7% !important;
    top: 1% !important;
  }
  .preview-area .name-input input {
    left: 24% !important;
    bottom: 0% !important;
  }
  .market_categories .nav-item .nav-link {
    min-width: 170px;
  }

  .searchbar_header input {
    min-width: 233px;
  }

  header .nav-link {
    font-size: 15px;
    margin: 0 15px;
  }

  .navbar-brand img {
    max-width: 150px;
  }
}

@media (max-width: 1400px) {
  header .container {
    max-width: 100%;
  }
  .air-max h2 {
    font-size: 28px;
  }
  .input-image {
    width: 290px;
    height: 290px;
  }

  .activities-heading h3,
  .activities-heading h3 span {
    font-size: 100px;
  }

  .activities-heading h5 {
    font-size: 30px;
    line-height: 54px;
  }

  .activities-heading h5 span {
    font-size: 30px;
  }

  .soccor-text {
    font-size: 60px;
  }

  .collection_img img {
    height: 245px;
  }

  .tab_box_home span {
    right: 7px;
  }

  .in-stock {
    padding: 6px 13px;
    font-size: 13px;
  }
}

@media (max-width: 1199px) {
  .home-banner-section br, .kennedy-brooks br {
    display: none;
  }
  .main_h2 {
    font-size: 39px !important;
  }
  .home-banner-section button.btn.btn-unset.generate-nft-btn {
    margin-left: 0 !important;
  }
  .generate-ai h2 {
    font-size: 40px !important;
  }
  .number-input input {
    top: unset !important;
  }
  .one-digit.form-control, .two-digit.form-control {
    right: 2% !important;
  }
  .preview-area .number-input input {
    right: 5% !important;
    top: -1% !important;
  }
  .preview-area .name-input input {
    left: 22% !important;
    bottom: -1% !important;
  }
  /* .preview-area .center-frame-area img {
    width: 90%;
  } */
  .athelete-top.area {
    margin-top: 82px;
  }
  .collection-team a {
    display: flex;
    flex-direction: column;
  }
  .home-banner-section {
    padding: 50px 0;
  }
  .App .common_bg .container {
    max-width: 1100px;
  }
  .air-max h2 {
    font-size: 24px;
  }
  .soccor-banner h2,
  .soccor-banner h2 span {
    font-size: 90px;
  }

  .connect-wallet-bt {
    display: block;
  }

  .input-image {
    width: 250px;
    height: 250px;
  }

  .soccor-banner {
    padding: 140px 0;
  }

  .activities-heading h3,
  .activities-heading h3 span {
    font-size: 80px;
  }

  .activities-heading h5 span {
    font-size: 26px;
  }

  .activities-heading h5 {
    font-size: 26px;
    line-height: 47px;
  }

  .soccor-text {
    font-size: 48px;
  }

  header {
    background: rgb(0 0 0);
  }

  .skiptranslate.goog-te-gadget {
    margin: 0 auto;
    height: 42px !important;
  }

  .day-timer li {
    margin: 0 12px;
  }

  .live-auction {
    display: inline-block !important;
  }

  .home-banner-left h1 {
    font-size: 50px;
  }

  .navbar-nav .dropdown-item .startselling_btn {
    width: 110px !important;
    text-align: center;
    border-radius: 10px !important;
  }

  .navbar-nav.navbar-nav-scroll {
    margin-bottom: 16px !important;
  }

  #dropdown-basic {
    padding: 14px 7px;
    font-size: 13px;
  }

  .searchbar_header input {
    min-width: 192px;
  }

  header .startselling_btn {
    padding: 14px 7px;
    font-size: 13px;
  }

  .collecting-bt {
    font-size: 16px;
  }

  .market-left h1 {
    font-size: 50px;
  }

  .tab_box_home {
    padding: 10px;
    margin: 0 5px 10px;
  }

  .explore-box h1 {
    margin-bottom: 23px;
  }

  .home_banner_right {
    padding: 35px 0;
  }

  .hot-heading {
    font-size: 50px;
  }

  .home-banner-left p {
    font-size: 18px;
  }

  .collection_img img {
    height: 180px;
  }

  .App .container {
    max-width: 1100px;
    padding: 0 20px;
  }

  .tenis-box img {
    max-width: 50px;
  }

  .tenis-box {
    padding: 9px 8px;
  }

  .like-view h6 {
    font-size: 12px;
  }

  .instant-sale-prize {
    width: 78%;
  }

  .name-description-filed {
    padding: 30px 12px;
  }

  .seller-box h2 {
    font-size: 50px;
  }

  .navbar-nav button.btn.btn-unset {
    width: fit-content;
    margin: 0 auto;
  }

  #google_translate_element {
    text-align: center;
    margin-top: 11px;
  }

  .navbar-nav .dropdown,
  .navbar-nav .dropdown-menu.show {
    text-align: center;
  }

  header .startselling_btn {
    margin: 10px auto !important;
    width: fit-content;
    width: 180px;
  }

  #dropdown-basic {
    width: 180px;
    height: 50px;
  }

  #google_translate_element select {
    margin-left: 0;
  }

  header .nav-link {
    margin: 12px 15px 0;
  }
}

@media (max-width: 991px) {
  .discord-section-bg.branding-partnership-mid-area .discord-section-content h2 {
    font-size: 30px !important;
}
.discord-section-bg.branding-partnership-mid-area .discord-section-content h2 br {
  display: none;
}
  .ai-content-area-inner {
    padding: 50px 35px;
  }
  .number-input input {
    top: 10px !important;
    right: 4% !important;
  }
  .ai-generator-area h2 {
    font-size: 55px;
  }
  .number-input input {
    top: 9px !important;
    right: 4% !important;
  }
  .one-digit.form-control, .two-digit.form-control {
    right: 3% !important;
  }
  .collection-team a {
    display: unset;
    flex-direction: unset;
  }
  .ath-content p,
  .get-in-area p,
  .unique-content p {
    font-size: 16px;
  }
  .profile-area {
    padding: 40px 30px;
  }
  .preview-mobile-view {
    display: block;
    margin-top: 30px;
  }
  .signle-detail-data-area input.form-control {
    width: 100%;
  }
  .auction-reverse-box {
    display: flex;
    flex-direction: column-reverse;
  }

  .founding-div p {
    font-size: 15px;
  }
  .founding-div h4 {
    font-size: 22px;
  }
  .common_box_content h5 {
    font-size: 15px;
  }
  .providers-logo {
    margin-bottom: 20px;
  }
  .first_section_main {
    text-align: center;
  }
  .air-max h2 {
    font-size: 21px;
  }
  .input-image {
    width: 172px;
    height: 172px;
  }

  .beach-text {
    font-size: 35px;
  }

  .soccor-text {
    font-size: 38px;
  }

  .activities-heading h5 {
    font-size: 20px;
    line-height: 32px;
  }

  .activities-heading {
    position: absolute;
    top: 80px;
  }

  .activities-heading h3,
  .activities-heading h3 span {
    font-size: 60px;
  }

  .prizes-box img {
    max-width: 50%;
  }

  .contact-form-two {
    max-width: 100%;
    padding: 50px;
  }

  .activities-heading h5 span {
    font-size: 20px;
  }

  .soccor-banner h2,
  .soccor-banner h2 span {
    font-size: 70px;
  }

  .flex-row.flex-wrap.market_categories.nav.nav-pills {
    max-width: 68%;
  }

  .main_h2.underline_text {
    font-size: 25px;
  }

  .App .common_bg .container {
    max-width: 720px;
  }

  /* .main_h2 br {
    display: none;
  } */

  .p_text {
    color: #c8ceec;
    font-size: 16px;
    font-weight: normal;
    width: 90%;
    margin-right: auto;
  }

  .home_banner_section {
    padding: 90px 0 0px;
  }

  .about-page h1 {
    font-size: 60px;
  }

  .market_banner_section #left-tabs-example-tabpane-first {
    margin-top: 0;
  }

  .market_banner_section.h-100 {
    padding: 50px 0 20px;
  }

  .sign-area {
    height: 237px;
    margin-bottom: 20px;
  }

  #google_translate_element select {
    margin: 0;
  }

  .App .container {
    max-width: 720px;
  }

  .market_banner_section {
    margin-top: 74px;
  }

  .home_banner_right {
    /* height: 85vh; */
  }

  .attri-name {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 50px;
    line-height: 1.8em;
  }

  .more-div {
    margin-bottom: 12px;
  }

  .explore-box {
    padding: 30px 0 0;
  }

  .leftfooter {
    padding-right: 20px;
  }

  footer h3 {
    margin-bottom: 19px;
    width: 100%;
  }

  .category_row.row {
    margin-top: 0px;
  }

  .footer_head h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: -0.3px;
    color: #fff;
  }
  .auction-right-side {
    padding: 0;
    margin-bottom: 15px;
  }

  .bid_auction {
    margin-top: 74px;
    padding: 60px 0 20px;
  }

  .connect-wallet-bt {
    margin: 0 auto;
  }

  .preview-image {
    width: 100%;
    height: auto;
  }

  .featured-athlet-left h3,
  .featured-athlet-right h3 {
    font-size: 28px;
    font-weight: 700;
  }
  .athe-banner-area {
    margin-top: 70px;
  }
  .unique-content h2 br {
    display: none;
  }
  .get-in-img img {
    top: 0;
  }
}

@media (max-width: 767px) {
  .ai-generator-area h2 {
    font-size: 40px;
  }
  .main_h2 {
    font-size: 34px !important;
  }
  .choose-ai-tab-area ul li {
    padding: 18px 20px;
  }
  .choose-ai-tab-area ul li img {
    width: 35px;
  }
  .generate-ai h2 {
    font-size: 35px !important;
  }
  .generate-ai h2 {
    font-size: 35px !important;
  }
  .auction-right-side img {
    height: 400px;
  }
  .kennedy-brooks .row {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }
  .kennedy-brooks img {
    margin-bottom: 40px;
  }
  .auction-form input {
    height: 45px;
  }
  .get-in-section {
    padding: 52px 0 0;
  }
  .get-in-img img {
    width: 100%;
    max-width: 60%;
  }
  .get-in-img img {
    position: unset;
  }
  .inner-content {
    border-bottom: 1px solid #1e1e1e;
  }
  .unique-img img {
    max-width: 60%;
    margin-bottom: 30px;
  }
  .ath-img img {
    margin-bottom: 20px;
  }
  .ath-content h2,
  .get-in-area h2,
  .unique-content h2 {
    font-size: 30px !important;
  }
  .banner-ath-image {
    display: block;
  }
  .athe-banner-area {
    background-image: none;
    margin-top: 20px;
  }
  .flex-direction {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  /* .detail-left-side {
    height: 350px;
  } */
  .collections-name-box {
    margin: -35px 0 0 0 !important;
    border-radius: 15px;
  }
  .create-store-info {
    padding-left: 0 !important;
  }
  .circle_row .col-md-6:last-child {
    padding: 0 0px 0 11px !important;
  }
  .flex-row.flex-wrap.market_categories.nav.nav-pills {
    max-width: 100%;
  }
  .detail-left-side img {
    float: none;
    margin: 0 auto;
    display: block;
    height: 400px;
  }

  .prizes-box {
    padding: 140px 0;
  }
  .kennedy-brooks img {
    margin-bottom: 40px;
  }

  .contact-form-two h3,
  .contact-form-two h3 span {
    font-size: 36px;
  }

  .activities-heading {
    top: 53px;
  }

  .beach-soccor-one {
    margin-bottom: 20px;
  }

  .input-image {
    width: 300px;
    height: 300px;
  }

  .member-one {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .direction-change {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 50px;
  }

  .greate-left {
    text-align: center;
    margin-top: 20px;
  }

  .greate-left h2 {
    font-size: 48px;
  }

  .sports-tab--new {
    width: 50%;
  }

  .auction-right-side {
    margin-bottom: 30px;
  }

  .about-page h3 {
    font-size: 30px;
    margin: 0 0 20px 0;
  }

  .about-page h2 {
    font-size: 30px;
  }

  .about-page {
    padding: 120px 0 20px;
    min-height: 600px;
  }

  .about-page h1 {
    font-size: 40px;
  }

  .founding-div h4 {
    line-height: 28px;
    font-size: 20px;
  }
  .founding-div p {
    font-size: 16px;
    margin: 23px 0;
  }

  .new-banner-area {
    margin-top: 75px !important;
  }

  .navbar-brand img {
    max-width: 150px !important;
  }

  .air-max h2 {
    font-size: 20px;
  }

  .details-cover img {
    height: 280px;
  }

  .bid_auction p {
    font-size: 14px;
  }

  .home-banner-left h1 {
    font-size: 40px;
  }

  .bid_auction h1 {
    font-size: 20px;
  }

  .hot-heading {
    font-size: 40px;
  }

  .works-area-box h2 {
    font-size: 40px;
  }

  .shade-box {
    left: 0;
  }

  /* .crete-collect-new {
    flex-direction: column-reverse;
  } */

  /* .preview-area {
    margin: 20px;
  } */

  .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
  }

  .home-banner-left h1 {
    text-align: center;
  }

  .home-banner-left {
    text-align: center;
  }

  .rightsdefooter {
    margin-top: 30px;
  }

  .explore-box .text-center.col-md-5 {
    max-width: 500px;
    margin: 0 auto;
  }

  .down-scroll {
    margin-top: 80px;
  }

  .leftfooter {
    margin-bottom: 20px;
  }

  .rightsdefoote {
    text-align: left;
  }

  .market_banner_section.h-100 {
    padding: 30px 0;
  }

  .scroll-div {
    margin-top: 30px;
  }

  .founding-div {
    margin: 30px auto 0;
  }
}

@media (max-width: 576px) {
  .discord-section-bg.branding-partnership-mid-area .discord-section-content h2 {
    font-size: 25px !important;
}
  .ai-generator-area h2 {
    font-size: 28px !important;
  }
  
  .preview-area .name-input input {
    width: 51% !important;
  }
  .number-input input {
    right: 5% !important;
    top: 2% !important;
  }
  .generate-ai-content {
    margin-top: 30px;
  }
  .choose-ai-tab-area ul li {
    padding: 18px 10px;
  }
  .choose-ai-tab-area ul li {
    font-size: 14px;
  }
  .choose-ai-tab-area ul li img {
    width: 30px;
    margin-right: 4px;
  }
  .athelete-top.area {
    margin-top: 75px;
  }
  iframe#review-card-payment-iframe {
    width: 87%;
    left: 7%;
  }
  .buy-now-bt {
    max-width: 160px;
  }
  .detail-collection-area {
    margin-left: 0;
  }
  .ath-content h2,
  .get-in-area h2,
  .unique-content h2 {
    font-size: 28px !important;
  }
  .greate-left {
    padding: 0;
  }
  button.btn.btn-unset {
    padding: 8px;
  }
  button.btn.btn-unset {
    padding: 8px;
  }
  .main_h2 {
    font-size: 32px;
  }
  /* .main_h2 br {
    display: none;
  } */
  .touch_form {
    padding: 37px 27px;
  }

  .forrm-submit-btn {
    width: 100%;
    margin: 30px auto 0 !important;
  }
  .soccor-banner h2,
  .soccor-banner h2 span {
    font-size: 50px;
  }
  .beach-text {
    font-size: 24px;
  }
  .soccor-text {
    font-size: 28px;
  }
  .contact-form-two {
    padding: 30px;
  }
  .contact-form-two h3,
  .contact-form-two h3 span {
    font-size: 24px;
    line-height: 38px;
  }
  .activities-heading h3,
  .activities-heading h3 span {
    font-size: 40px;
  }

  form.login-form-box {
    text-align: left;
  }

  .about-page h1 {
    font-size: 30px;
    margin-top: 10px;
  }

  .rightside_border {
    max-width: fit-content;
    margin-top: 30px;
  }

  .about-page {
    text-align: center;
  }

  .air-max h2 {
    font-size: 22px;
  }

  select.sort-by-filter.form-select {
    height: 55px;
    border-radius: 15px;
    width: 200px;
  }

  .sign-area {
    height: 267px;
    margin-bottom: 20px;
    padding: 0 16px;
  }

  .social-icon li {
    display: inline-block;
    margin: 13px 22px;
  }

  .featured-area ul {
    display: block;
  }

  .home-banner-left h1 {
    font-size: 42px;
  }

  .hot-heading {
    font-size: 42px;
  }

  .eth-box h5 {
    font-size: 20px;
  }

  .seller-box h2 {
    font-size: 36px;
  }

  .air-max h4 {
    font-size: 20px;
  }

  .explore-box .col-sm-3 {
    width: 50%;
  }

  .home-banner-left {
    padding: 70px 10px 0;
  }

  .home_banner_right {
    /* height: 36vh; */
  }

  .home-banner-left h1 {
    font-size: 30px;
  }

  .hot-heading {
    font-size: 30px;
  }

  .market-left h1 {
    font-size: 36px;
  }

  .collection-head-new {
    padding: 7px 9px;
    font-size: 14px;
  }

  .works-area-box h2 {
    font-size: 30px;
  }

  .rightsdefooter {
    margin-top: 0;
  }

  footer {
    padding: 30px 10px;
  }

  .end-reserve-bid-box {
    margin: 15px 23px 0 8px;
  }

  .category_row.row .nav-item {
    width: 50%;
  }

  .market_categories .nav-item .nav-link {
    min-width: 160px;
    margin: 5px;
  }

  .search_marketplace {
    width: 100%;
  }

  .category_row.row {
    margin-top: 30px;
  }
  .creater-rayality-box {
    display: block;
  }
  .greate-left h2 {
    font-size: 35px;
  }
  button.select-payement-buy-btn {
    max-width: unset;
  }
  .choose-ai-tab-area ul li {
    padding: 18px 10px;
  }
  .svg-animation p {
    top: 64%;
    left: 50%;
  }
  .loader {
    top: calc(50%);
    left: calc(50%);
  }
}
@media (max-width: 480px) {
  .athelete-top.area {
    margin-top: 70px;
}
  section.discord-section.brand-mid-spacing {
    padding-top: 60px;
}
  .main_h2 {
    font-size: 25px;
  }
  .frame-value {
    font-size: 15px;
  }
  .preview-area .name-input {
    bottom: 13px;
    left: 69px;
    width: 50%;
  }
  .ai-generator-area h2 {
    font-size: 23px !important;
  }
  /* .home_header .container {
    flex-direction: column;
  } */
  .ai-content-area-inner h6 {
    font-size: 15px;
  }
  .ai-content-area-inner {
    padding: 20px 5px;
  }
  .choose-ai-tab-area ul li {
    font-size: 11px;
  }
  .choose-ai-tab-area ul li img {
    width: 22px;
  }
  .toggle-drop-right {
    margin-left: -160px;
    margin-top: 4px;
  }
  button.btn.btn-unset.generate-nft-btn {
    margin-left: 0;
  }
  
}
@media (max-width: 420px) {
  .discord-section-bg.branding-partnership-mid-area .discord-section-content h2 {
    font-size: 20px !important;
}
.discord-section-bg.branding-partnership-mid-area a {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 12px;
  font-size: 15px;
}
.discord-section-bg.branding-partnership-mid-area .discord-section-content p {
  font-size: 14px !important;
  line-height: 23px !important;
}
  .discord-section-bg {
    margin: 65px 0 0px 0;
  }
  .nil-providers {
    padding: 15px 0;
  }
  span.navbar-brand {
    margin-right: 0;
  }
  .get-in-bg {
    padding: 20px;
  }
  .correct-image img {
    width: 150px;
  }
  .bid-modal.text-center h5 {
    font-size: 18px;
  }
  .logout-text {
    font-size: 16px;
  }
  .sale-auction-tab li.nav-item button {
    width: 129px;
    font-size: 14px;
    white-space: nowrap;
  }
  .single-multiple-box .react-tabs__tab {
    width: 136px;
    font-size: 16px;
  }
  .collections-details-box {
    flex-wrap: wrap;
  }
  .founding-div h4 {
    position: relative;
  }
  .discord-section-bg {
    height: 260px;
    max-height: 260px;
  }

  #details-collapse-text p {
    color: #fff;
    font-size: 14px;
    margin: 0;
    min-width: 150px;
  }
  .count_box h5 {
    font-size: 18px;
  }
  .featured-area {
    padding: 40px 0 0;
  }
  .rightsdefoote a {
    padding-right: 11px;
  }

  .home-banner-left {
    padding: 30px 10px 0;
  }

  .soccor-banner {
    padding: 110px 0 20px !important;
    min-height: 500px;
  }

  .works-area-box {
    padding: 44px 0 50px;
  }

  #hot-collection {
    padding: 0;
  }

  .activities-heading {
    text-align: center;
    position: unset;
    margin-bottom: 20px;
  }
  .frame-image {
    width: 280px;
  }
  .soccor-banner h2 {
    margin-bottom: 80px;
  }
  .input-image {
    width: 230px;
    height: 230px;
  }
  .soccor-text {
    font-size: 24px;
  }
  .market_categories .nav-item .nav-link {
    min-width: 100px;
  }

  .market_categories .nav-item .nav-link {
    padding: 12px;
  }

  .sign-ar {
    padding: 20px 20px 20px 20px;
  }
  .react-tabs__tab {
    padding: 6px 9px !important;
    font-size: 13px;
  }
  .detail-area-buy-nft ul.react-tabs__tab-list {
    margin-bottom: 14px;
    display: flex;
  }
  button.detail-submit-btn.btn.btn-unset {
    width: 100px !important;
    height: 35px !important;
    font-size: 14px !important;
  }
  .detail-area-buy-nft label.m-0.form-label {
    font-size: 15px;
  }
  .meta-mask-modal h3 {
    font-size: 20px;
  }
  .bid-modal h5 {
    font-size: 17px;
  }
  button.select-payement-buy-btn {
    height: 37px !important;
  }
  .buy-now-modal .buy-now-bt {
    height: 43px;
    font-size: 13px !important;
  }
}
.buy-modal {
  display: flex;
}
.buy-modal button {
  padding: 4px 4px;
  margin: 4px 2px;
}
.pagination li.active {
  background: #fe7702;
}

ul.pagination li a {
  color: #fe7702;
}
.pagination li.active a {
  color: #fff;
}

/* button regenerate */
.regenerate-nft {
  position: absolute;
  width: 211px;
  height: 62px;
  left: 739px;
}
.regenerate-nft {
  position: absolute;
  width: 164px;
  height: 28px;
  left: 763px;
  /* top: 3530px; */
}

.regenerate-nft {
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
}
.regenerate-nft {
  color: #ffffff;
}

.regenerate-nft {
  background: linear-gradient(91.69deg, #d955e2 -3.76%, #40a0e3 100%);
  border-radius: 11px;
}

/*  */

ul.pagination li {
  border: 1px solid #fe7702;
  padding: 6px 14px;
  /* background: #000; */
  border-radius: 5px;
  margin: 0 4px;
}
ul.pagination {
  justify-content: center;
}

/* shimmer */

.card {
  position: relative !important;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.041);
  background-color: #f4f5f6 !important;
  border-radius: 6px;
  height: 420px !important;
  overflow: hidden;
  border-radius: 20px;
  padding: 10px;
  border: none !important;
}
.card .shimmerBG {
  animation-duration: 3.7s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(
    to right,
    #492018 8%,
    #451f17ed 18%,
    #8364e221 33%
  );
  background-size: 1200px 100%;
}
@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
.card .media {
  height: 306px !important;
}
.card .p-32 {
  padding: 20px 0 0;
  height: fit-content;
}
.card .title-line {
  height: 24px;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 20px;
}
.card .content-line {
  height: 8px;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 8px;
}
.card .end {
  width: 40%;
}

.m-t-24 {
  margin-top: 24px;
}
.card-small {
  height: 106px !important;
  padding: 0 !important;
}
.wallet-connected-popup {
  background: rgba(36, 36, 53, 0.9411764705882353) !important;
  box-shadow: 0 4px 20px 0 rgb(10 10 10 / 80%);
}

.popular-area-box .slider-next-btn,
.popular-area-box .slider-prev-btn {
  top: 67%;
}
.one-digit{
  right: 2% !important;
}
.two-digit{
  right: 2% !important;
}
/* ======= */
@import url(https://fonts.cdnfonts.com/css/circular-std);
html {
  scroll-behavior: smooth;
}
body,
html {
  font-family: "Circular Std", sans-serif !important;
}

.term-page {
  padding-top: 130px;
}

.comingsoon-img {
  width: 300px !important;
  margin: 50px auto;
}

.balance-check {
  background: none !important;
  border: none !important;
  color: #fe7702 !important;
}

.widget-container ._1CW8I {
  display: none !important;
}

.nil-providers {
  padding: 100px 0;
  position: relative;
}

.globe-image {
  position: absolute;
  max-width: 450px;
  left: -58%;
  bottom: 0;
}

hr {
  margin: 1rem 0;
  color: #ff8eff !important;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

button.submit-bt {
  background: #fe7702 !important;
  color: #fff !important;
  border-radius: 12px;
  margin: 0 7px 0 0;
  height: 52px;
}

.profile-bt-new img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.buy-now-bt {
  width: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
.user-timer-box .timer-box {
  background: #4f0c75;
  position: absolute;
  right: 3px;
  /* width: 185px; */
  top: 3px;
  height: 26px;
  border-radius: 49px;
  text-align: center;
  color: #fff;
  padding: 1px 10px;
}
.expired-nft {
  background: #4f0c75;
  padding: 2px 4px;
  border-radius: 50px;
  color: #ffffff;
  /* font-weight: 600; */
  /* margin-bottom: 10px; */
  display: block;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.coming-soon {
  background: #fe7702;
  max-width: fit-content;
  padding: 2px 7px;
  border-radius: 80px;
}

.signup-bt {
  text-align: center;
  margin: 20px 0 0;
}

.signup-bt a {
  color: #fe7702 !important;
}

.login-form-box button {
  width: 14vw;
  padding: 0 15px;
  margin: 40px auto 0 !important;
  display: block;
  background: #fe7702 !important;
}

.forgot {
  display: block;
  text-align: end;
  color: #fff !important;
  width: fit-content;
  margin-left: auto;
}

.login-form-box input {
  height: 55px;
  font-weight: 400;
}

.user-timer-box .timer-box ul {
  padding: 0;
  line-height: 26px;
}

.error-new-box {
  left: 24px;
  color: red;
  position: absolute;
  top: 52px;
  font-size: 14px;
}

.thenae-box {
  position: relative;
}

.air-desc {
  font-size: 19px;
  color: #ffd6ae;
  margin-bottom: 0;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 600;
}
.detail-owner-heading {
  margin-bottom: -13px;
}

.balance-nw {
  color: black !important;
  font-size: 24px !important;
  font-weight: 700 !important;
}

.withdraw-bt {
  background: #fe7702;
  padding: 10px 21px;
  border-radius: 10px;
  color: #fff;
}

section.collection_section.view-balance-box .single-multiple-box {
  max-width: 500px;
  text-align: center;
  background: white;
  border-radius: 10px;
  padding: 20px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 7px #dbdbdb;
  width: 100%;
}
.air-price-new {
  color: #fff;
  font-size: 18px;
  margin-top: 17px;
  margin-bottom: 20px;
  line-height: 30px;
}
.spinner-content h6 {
  font-weight: 600;
  color: #4f0c75;
}

.create-items .activedata {
  border: 1px solid #f67728;

  background: #f7f3f3;
}

.bt-div {
  margin-top: 30px !important;
}

.air-price-new span {
  background: #4f0c75;
  padding: 5px 13px;
  border-radius: 30px;
  margin-left: 6px;
  white-space: nowrap;
  font-size: 15px;
}

.creater-collection-area {
  display: flex;
  justify-content: space-between;
}

.collection-team {
  /* margin-left:  100px; */

  color: #fff;
}

#details-collapse-text p {
  color: #fff;
  font-size: 14px;
  margin: 0;
  min-width: 180px;
}

.view-details-collapse {
  margin: 4px 0;
  border-radius: 10px;
  font-size: 16px !important;
  background: none !important;
  border: none !important;
  padding: 0 !important;
  text-decoration: underline !important;
}

/* .creater-rayality-box {
    width: 360px;
} */
.collapse-details-view {
  display: flex;
  /* justify-content: space-between; */
}

.featured-athlet-left {
  /* background-image: url('../images/athlet-leftt.png'); */

  background-position: right;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  height: 500px;

  border-radius: 20px;

  padding: 20px;

  display: flex;

  flex-direction: column;

  justify-content: center;
}

.featured-athlet-right {
  /* background-image: url('../images/athlet-right.png'); */

  background-position: right;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  height: 500px;

  border-radius: 20px;

  padding: 20px;

  display: flex;

  flex-direction: column;

  justify-content: center;
  /* background-color: #1f1f23; */
}

.featured-athlet-left h6,
.featured-athlet-right h6 {
  color: #fe7702;
  font-size: 20px;
  margin-bottom: 20px;
}

.featured-athlet-left h3,
.featured-athlet-right h3 {
  font-size: 50px;

  font-weight: 700;
}

.featured-athlet-left p,
.featured-athlet-right p {
  font-size: 18px;
  max-width: 500px;
  margin-bottom: 15px;
}

.feature-athlet {
  background: #000;

  padding: 40px 0;
}

.sign-explore-bt {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 83px;
  width: 169px;
}

.sign-explore-bt {
  /* background-image: url('../images/btn-new.png'); */
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 83px;
  width: 212px;
}

.feature-athlet .col-md-6 {
  padding: 15px !important;
}

.collection-team p {
  color: #fff;

  font-size: 14px;
}

.owner-details p {
  margin-bottom: 0;

  font-size: 15px;

  color: #fff;

  max-width: 240px;
}

.owner-list a {
  font-size: 14px;
  padding: 3px 7px 3px 0;
  /* margin-left: 100px; */
  min-width: 70px;
}

.owner-sale-box {
  color: #ffffffad !important;
}

.owner-list {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ffffff3b;
  padding: 10px 0px;
}
.owner-list:first-child {
  padding-top: 0;
}
.all-owner-box .owner-list:last-child {
  border: none !important;
}

.owner-list-nosale {
  padding-top: 13px;
}

.new-loader {
  border: 5px solid #d3d3d3;

  border-radius: 50%;

  border-top: 5px solid #fe7702;

  width: 40px;

  height: 40px;

  animation: spin 2s linear infinite;

  position: absolute;

  top: 35px;

  left: 35px;
}

.air-max.auction-page-air {
  margin: 24px 0;
}

.loader-list-items {
  position: relative;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.nft-collection-box li.react-tabs__tab--selected {
  background: #000 !important;

  border: none !important;

  color: #fff !important;

  border-radius: 5px !important;
}

.nft-collection-box li.react-tabs__tab--selected:focus::after {
  background: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.collection-nft-tab-market {
  margin-top: 40px;
}

.market_banner_section #left-tabs-example-tabpane-first {
  margin-top: 100px;
  min-height: 400px;
}

.no-data-div {
  position: relative;
  min-height: 300px;
}

.no-data-div h2 {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.owner-details {
  margin-right: 40px;

  width: 352px;
}

.owner-list .sale-auction-tab li.nav-item button {
  width: 105px;

  padding: 6px 0px;

  font-size: 14px;

  /* background: url('../images/buy.png'); */

  background-position: left;

  background-repeat: no-repeat;

  background-size: contain;

  height: 79px;

  display: block;

  border-radius: 5px;

  text-align: center;

  line-height: 68px;

  color: #fff !important;

  max-width: 190px;

  border: none;

  box-shadow: none;

  height: 83px;
}

.margin-safari .goog-te-combo {
  margin-left: 12px !important;
}

.owner-list button.buy-now-bt {
  width: 100%;
}

.buy-now-bt {
  font-size: 15px !important;
}

.collection-team img {
  width: 50px !important;
  height: 50px !important;
  border-radius: 100%;
  border: 3px solid #fff;
  object-fit: cover;
  margin-right: 5px;
}

.collection-team a {
  color: white !important;
}

.owner-list img {
  width: 55px;

  height: 55px;

  border-radius: 50%;

  border: 3px solid #fff;

  object-fit: cover;
}

.linking-modal .modal-dialog {
  max-width: 410px;
}

.linking-modal .modal-content {
  padding: 16px 0 !important;
}

.linking-modal a {
  background: #fe7702;
  display: inline-block;
  color: #fff !important;
  width: 250px;
  border-radius: 10px;
  padding: 17px 0;
  margin: 5px 0;
  font-size: 16px;
}

.buy-now-modal a.buy-now-bt {
  width: 120px;

  margin-top: 10px;
}

.owner-list .sale-auction-tab li.nav-item button.nav-link.active {
  background-color: rgba(255, 0, 0, 0) !important;
}

.all-owner-box .tab-content {
  margin-left: 20px !important;
}

.all-owner-box .tab-content input {
  font-size: 14px;

  padding: 7px !important;

  height: 31px;

  max-width: 170px;

  margin-bottom: 5px;
}

.buy-now-modal .modal-content {
  padding-bottom: 0;
}

.buy-now-modal input {
  margin-bottom: 16px;
}

.buy-now-modal label {
  margin-bottom: 0;
}

.buy-now-modal {
  text-align: left;
}

.buy-now-modal .modal-dialog {
  max-width: 480px;
}

.no-data-new {
  text-align: center;
}

.creater-collection-area a {
  color: #fff !important;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.collection-up-both-img .modal-content {
  padding: 0 !important;
}

.finda-display {
  margin-top: 30px !important;
}

.new-banner-area {
  position: relative;

  margin-top: 100px;
}

.discord {
  color: #bf74cb !important;

  font-size: 20px;

  background: white;

  width: 100px;

  position: absolute;

  top: 50%;

  left: 50%;

  width: 200px;

  border-radius: 50px;

  text-align: center;

  padding: 11px 0;

  transform: translate(-130%, -50%);
}

.term-privacy-box-new {
  display: flex;

  /* align-items: flex-end; */
}
.rightsdefoote {
  margin: 30px 0;
}
.buy-now-modal .buy-now-bt {
  height: 43px;
  font-size: 17px !important;
  border-radius: 5px !important;
  padding: 0 10px !important;
  margin-top: 0;
}
.buy-now-bt.nft-buy-now {
  height: 40px !important;
  width: 130px !important;
  border-radius: 5px !important;
  margin-top: 0;
}

.term-page.about-page {
  padding: 150px 0 50px;
}

.forgot-password-page {
  background-image: url("../images/about_bg.jpg");

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  padding: 120px 0 60px;
  height: 75.2vh;
}
.forgot-password-form {
  margin-top: 13vh !important;
}

.contact-us-page {
  background-image: url("../images/about_bg.jpg");

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  padding: 120px 0 60px;
  min-height: 750px;
  max-height: 1600px;
}

.about-page {
  background-image: url("../images/about_bg.jpg");

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  padding: 120px 0 60px;
  min-height: 900px;
}

.about-page h1 {
  font-size: 80px;

  text-transform: uppercase;

  font-weight: 700;
}

.about-page h2 {
  font-size: 50px;

  text-transform: uppercase;

  font-weight: 700;

  margin-bottom: 0;
}

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

.about-page h1 span {
  color: #fe7702;
}

.about-page p {
  max-width: 630px;

  font-size: 18px;

  line-height: 32px;
}

.about-page h3 {
  font-size: 40px;

  margin: 30px 0;
}

.filda-bounding {
  background-image: url("../images/about_bg_two.jpg");

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  padding: 60px 0;
}

.listingsection .nft-collection-box ul {
  margin: auto !important;
  padding: 7px 7px;
}

.my_listing-area.listingsection {
  background-color: white;
  padding-top: 10px;
}

.listingsection .nft-collection-box li {
  padding: 11px 12px;
}

.rightside_border {
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  background-color: #ffffff00;
  border-color: #ffffff7d;
  padding: 8px 10px 2px 10px;
  /* width: 280px; */
}
.buy-sell-created {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.install-metamask {
  text-align: center;
}

.install-metamask-modal .modal-content {
  width: 70% !important;
  margin: 0 auto;
  padding: 10px 0 15px 0;
}

.install-metamask img {
  width: 80px;
}
.rightside_border .created {
  font-size: 14px;
  margin-bottom: 5px;
}
/* .created_use_img {
  filter: invert(1);
} */

.rightside_border p {
  font-size: 17px;
}

.wallet_num {
  display: inline-block;
  align-items: center;
  font-size: 14px;
  border-radius: 100px;
  line-height: 22px;
  text-align: center;
  color: grey;
  background: rgb(255 255 255);
  transition: all 0.12s ease-in-out 0s;
  padding: 6px 18px;
  margin-left: 34px;
}
.expired-area {
  display: unset;
}
.rightside_border div {
  position: relative;
  padding-left: 25px;
}

.rightside_border div span {
  color: white;
  font-size: 17px;
}

.rightside_border div img {
  position: absolute;
  left: 0;
}
.created_use_img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  object-fit: cover;
  overflow: hidden;
  margin-top: 4px;
}

.listingsection .nft-collection-box li.react-tabs__tab--selected {
  background: #fe7702 !important;
  white-space: nowrap;
}

.founding-div {
  /* max-width: 650px; */

  text-align: center;

  margin: 100px auto 0;
}

.rightsdefoote a {
  padding-right: 18px;
}

.member-one {
  background: #1b1225;
  border-radius: 15px;
  padding: 15px;
}

.our-team h2 {
  font-size: 50px;
  color: #fff;
  margin-top: 60px;
}

.our-team h2 span {
  color: #fe7702 !important;
}

.member-one {
  background: #2a202d;
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 25px;
  position: relative;
  z-index: 1;
  height: 100%;
}

.member-info p {
  color: #ffffffad;
  margin-bottom: 0 !important;
}

a.linkedin-bt {
  position: absolute;
  bottom: -27px;
  left: 50%;
  transform: translate(-50%, 0);
}

.our-team .col-md-6 {
  padding: 0 15px !important;
}

.user-img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 20px;
}

.user-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.member-one {
  text-align: center;
}

.air-max {
  margin-bottom: 20px;
}

.founding-div h4 {
  line-height: 40px;

  font-size: 27px;
}

.founding-div p {
  font-size: 18px;
}

.greate-our-goal {
  background-image: url("../images/goal.jpg");

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  padding: 60px 0;
}

.greate-left h2 {
  font-size: 60px;

  font-weight: 700;
}

.greate-left h2 span {
  color: #000;
}

.greate-left p {
  color: #000;
}

.greate-left {
  padding: 0 50px;
}

.footer-link {
  color: #fff !important;
}

.home_banner_section {
  /* background-image: url('../images/home_banner.jpg'); */

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;
  padding: 180px 0 90px;
}

.view-balance-box {
  min-height: 650px;
}

:focus {
  box-shadow: none !important;
  outline: 0 !important;
}

fieldset,
img {
  border: 0 none;
}

input[type="submit"],
select {
  -webkit-appearance: none;
}

html {
  scroll-behavior: smooth;
}

.alice-carousel__dots {
  display: none !important;
}

.alice-carousel__next-btn-item {
  background: #fe7702;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 30px;
  color: #fff !important;
  position: absolute;
  right: 0;
  top: 130px;
}
span.put-on-auction-note {
  /* font-style: italic;
  font-weight: bolder;
  font-size: 15px;
  color: #fe7702; */

  font-style: italic;
  font-weight: bolder;
  font-size: 15px;
  color: #7d02fe;
}

.nft-prize-usd {
  text-align: end;
  font-size: 14px;
  padding-top: 2px;
}
.alice-carousel__prev-btn-item {
  background: #fe7702;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 30px;
  color: #fff !important;
  position: absolute;
  left: 0;
  top: 130px;
  text-align: center;
}

img {
  border: none;
  height: auto;
  max-width: 100%;
  outline: 0;
  vertical-align: middle;
}

iframe {
  vertical-align: middle;
}

a:hover {
  text-decoration: none;
  color: #6bcbfc;
}

a,
abbr,
acronym,
address,
article,
aside,
b,
bdo,
big,
blockquote,
body,
br,
button,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
img,
ins,
kbd,
legend,
li,
map,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
ul,
var {
  border: medium none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none !important;
}

input[type="submit"],
select {
  -webkit-appearance: none;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}

header {
  padding: 9px 0;
  position: fixed;
  top: 0;
  z-index: 1024;
  width: 100%;
  background: #000;
}

.rightsdefooter p a {
  color: #fff;
}

.searchbar_header input {
  background: #f6f7fa !important;
  border-radius: 16px;
  border: 0;
  height: 56px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #081946;
  min-width: 330px;
}

.searchbar_header {
  position: relative;
  margin-left: 20px;
}

.bg-white {
  box-shadow: 2px 2px 2px #e1e1e138;
}

.profile-pagination {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-pagination li {
  list-style: none;
}

.profile-pagination li a {
  border: 1px solid #ffffff38;
  margin: 0 5px;
  display: block;
  padding: 5px 12px;
  border-radius: 5px;
  color: #fff;
}

.profile-pagination li.selected a {
  background: #fe7702;
}

.searchbar_header button {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translate(0, -50%);
}

.more-games:hover {
  color: #fe7702;
}

header .startselling_btn {
  background: #fe7702;
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  height: 52px;
}

.btm_details.market-btm-details {
  position: relative;
  top: inherit !important;
  left: inherit !important;
}

.btm_details.market-btm-details h6.bid-text-two {
  padding-left: 0 !important;
}

button.bg-transparent.border-0.subscribe-bt.btn.btn-unset {
  background: #fe7702 !important;
  height: 50px;
  width: auto;
  color: #fff;
  font-size: 18px;
  border-radius: 10px 10px 0 0;
}

.navbar-nav .dropdown-item .startselling_btn {
  text-align: left;
  border-radius: 0 0 10px 10px;
  width: 100%;
  border-top: 1px solid #ffffff3d;
  margin: 0;
}

.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.navbar-nav .dropdown-menu.show {
  top: 30px;
  min-width: 100%;
}

#google_translate_element {
  position: relative;
}

#details-collapse-text {
  margin-bottom: 20px;
  max-width: 300px;
}

#details-collapse-text a {
  color: #fff;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

.hot-collection-comming-soon {
  min-height: 300px;
}

.hot-collection-comming-soon h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#google_translate_element i {
  position: absolute;
  right: 10px;
  top: 16px;
  color: #fff;
  font-size: 12px;
}

.skiptranslate.goog-te-gadget {
  height: 55px;
  overflow: hidden;
}

header .nav-link {
  font-style: normal;
  font-weight: 500;
  padding: 0 0 6px 0 !important;
  font-size: 14px;
  border-radius: 0;
  margin: 0 20px;
  line-height: 20px;
  color: rgb(255 255 255) !important;
  border-bottom: 4px solid #fff;
}

.modal-email span {
  color: #fe7702;
}

header .nav-link:hover {
  color: #fe7702 !important;
}

header .active.nav-link {
  color: #fe7702 !important;
  border-bottom: 4px solid #fe7702;
}

header .nav-link {
  border-bottom: none;
}

header .login_header .logintext {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #081946;
}

.down-scroll {
  margin-top: 130px;
}

.searchbar_header input::-webkit-input-placeholder {
  color: #4f0c75;
}

.searchbar_header input::-moz-placeholder {
  color: #4f0c75;
}

.searchbar_header input:-ms-input-placeholder {
  color: #4f0c75;
}

.searchbar_header input:-moz-placeholder {
  color: #4f0c75;
}

.banner_section {
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: center;
  position: relative;
  padding: 135px 0;
  overflow: hidden;
  margin-top: 93px;
}

.banner_section .gobackbtn {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 99px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fff;
}
.main-term-box h5 {
  margin-top: 20px;
}

.banner_section h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  color: #fff;
  margin: 35px 0;
}

.banner_section p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #fff;
}

.banner_section h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-decoration-line: underline;
  color: #fe7702;
  cursor: pointer;
}

.banner_section .connectbtn {
  background: #fe7702;
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: #f8f6f6;
  padding: 17px 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 270px;
  margin: 40px 0;
}

.banner_section .connectbtn img {
  transform: rotate(180deg);
}

.images_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner_section p.dulltext {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #ffffffa6;
  margin-top: 25px;
}

footer {
  padding: 70px 0;
  background-color: #4f0c75;
}

.footer_head {
  display: flex;
  justify-content: end;
  align-items: center;
}

.footer_head h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  letter-spacing: -0.3px;
  color: #fff;
}

.footerline {
  background: #fff;
  opacity: 0.14;
  height: 1px;
  width: 100%;
}

footer h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  letter-spacing: -0.3px;
  color: #fff;
  margin-bottom: 35px;
  width: 50%;
}

.searchbar_header.footer input {
  border-radius: 0;
  background-color: transparent !important;
  border-bottom: 1px solid #ffffff1f;
  padding: 0;
  font-weight: 300;
  color: #fff;
  height: 52px;
  padding: 0 126px 0 10px;
}

.featured-area {
  text-align: center;
  padding: 40px 0;
}

.searchbar_header button.btn-unset {
  margin: 0 !important;
}

.searchbar_header.footer button {
  right: 0;
}

.searchbar_header.footer input::-webkit-input-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer input::-moz-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer input:-ms-input-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer input:-moz-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer {
  margin-left: 0;
}

.common_bg {
  background-color: #120715;
  position: relative;
}

.rightsdefooter h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: #ffd2dd;
  margin-bottom: 15px;
}

.img_position_1 {
  position: absolute;
  top: 0%;
  z-index: 1;
}

.common_padding_1 {
  padding: 100px 0;
}

.main_h2.underline_text {
  position: relative;
  font-size: 40px;
  display: inline-block;
  z-index: 2;
}

.count_box h5 {
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
}

.circle_row {
  position: relative;
}

.circle_row .col-md-6 {
  position: relative;
  padding: 0 85px 0 11px !important;
  z-index: 2;
}

.circle_row .col-md-6:last-child {
  padding: 0 0px 0 85px !important;
}

.circle_img_postion {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 61% !important;
}

.touch_section {
  /* padding-top: 80px;
  padding-bottom: 100px; */
  overflow: hidden;
  position: relative;
  padding: 50px 0;
}

.touch_section .container {
  position: relative;
  z-index: 2;
}

.circle_img_postion1 {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100% !important;
  z-index: 0;
}

.touch_section .circle_img_postion1 {
}

.touch_section .underline_text:before {
  position: absolute;
  content: "";
  bottom: -12px;
  height: 1px;
  background-color: white;
  width: 200px;
  left: 50%;
  transform: translate(-50%, 0);
}

.touch_section .underline_text:after {
  position: absolute;
  content: "";
  bottom: -13px;
  width: 18%;
  height: 4px;
  background-color: #fe7803;
  left: 50%;
  transform: translate(-50%, 0);
}

.service_section1 .col-lg-3 {
  padding: 0 12px !important;
}

.touch_form {
  background-color: #2a202d8a;
  border-radius: 10px;
  padding: 37px 80px;
  margin-bottom: 32px;
  box-shadow: rgb(163 163 163 / 12%) 0px 5px 15px inset;
}
.modal-footer {
  border: none !important;
}
.no-data img {
  opacity: 0.5;
}
.no-data {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.main_footer p,
.main_footer a {
  color: #ffffff !important;
  font-size: 16px;
}

.main_footer span {
  height: 100%;
  width: 1px;
  background-color: white;
  display: inline-block;
}
.login-form-box input,
.login-form-box textarea {
  background-color: transparent !important;
  border: 1px solid #ffffff8c;
  border-radius: 5px;
  padding: 12px;
  color: white !important;
  /* margin-bottom: 15px; */
  font-size: 16px;
}
.submit-bt {
  width: 100px !important;
}

.touch_form input,
.touch_form textarea {
  background-color: transparent !important;
  border: 1px solid #ffffff8c;
  border-radius: 5px;
  padding: 12px;
  color: white !important;
  /* margin-bottom: 15px; */
}

.touch_form .col-md-6 {
  padding: 0 12px !important;
}

.count_box {
  margin-bottom: 50px;
}

.main_footer {
  background-color: #0d010d;
}

.count_box p {
  color: #c8ceec;
  font-size: 17px;
  font-weight: normal;
  margin-left: 57px;
  position: relative;
  padding-left: 28px;
}

.count_box p span {
  color: white;
  padding-right: 5px;
  position: absolute;
  top: 0;
  left: 0;
}

.count_box h5 span {
  width: 35px;
  height: 35px;
  background-color: white;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 19px;
  margin-right: 20px;
}

.circle_row {
  position: absolute;
  top: 0%;
  z-index: 1;
}

.service_section1 {
  padding-top: 80px;
}

.underline_text:before {
  position: absolute;
  content: "";
  bottom: -12px;
  width: 100%;
  height: 1px;
  background-color: white;
}

.underline_text:after {
  position: absolute;
  content: "";
  bottom: -13px;
  width: 67%;
  height: 4px;
  background-color: #fe7803;
  left: 50%;
  transform: translate(-50%, 0);
}

.common_box_content {
  background-color: #10122d;
  padding: 30px 20px 10px;
  border-radius: 10px;
  height: 100%;
  position: relative;
}

/* .box1_shade{
    content: '';
    position: absolute;
    top:0;
    left: 0;
    background-image: image();
} */
.box1_shade .img_position_1 {
  left: 0;
  height: 100%;
  width: 100%;
}

.relative-div {
  position: relative;
  z-index: 2;
}

.common_box_content h5 {
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
}

.common_box_content p {
  color: #c8ceec;
  font-size: 17px;
  font-weight: normal;
}

.service_section1 {
  position: relative;
}

.service_section1 .img_position_2 {
  position: absolute;
  top: 0%;
  z-index: 1;
  max-width: 350px;
  left: -7%;
}

.main_h2 {
  font-size: 50px;
  color: #ffffff;
  font-weight: 700;
}

.section_positon {
  position: relative;
  z-index: 1;
}

.p_text {
  color: #c8ceec;
  font-size: 18px;
  font-weight: normal;
  width: 75%;
  margin-right: auto;
}

.shades-box {
  position: absolute;
  top: -290px;
  z-index: 0;
}

.App .common_bg .container {
  max-width: 1140px;
}

.main_h2 span {
  color: #fe7803;
}

.rightsdefooter p {
  font-style: normal;
  font-weight: 200;
  font-size: 15px;
  cursor: pointer;
  color: #fff;
}

.leftfooter {
  padding-right: 180px;
}

.collection_section {
  padding: 90px 0;
}

.market-btm-details .echo-two {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.preview-image video.video-container.video-container-overlay {
  width: 100%;
  height: -webkit-fill-available;
}

.sale-auction-tab li.nav-item button {
  border-radius: 10px !important;
}
button.profile-area-submit-btn {
  width: 100px !important;
}
.collection_section h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  color: #000;
}
fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.collection_section h5 {
  font-style: normal;
  font-weight: 200;
  font-size: 21px;
  color: #6b6b6b;
}

.time_row {
  background-color: #4f0c75;
  border-radius: 100px;
  padding: 7px 10px;
  display: inline-block;
  margin-top: 10px;
}

.time_row span {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  padding: 0 4px;
}

.collection_box h3 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin-top: 12px;
}

.userpic_name {
  color: #000;
  font-size: 16px;
  font-weight: 200;
}

.userpic_name {
  position: relative;
  padding-left: 46px;
  padding-top: 2px;
  margin-top: 12px;
}

.userpic_name img {
  width: 33px;
  position: absolute;
  left: 0;
  top: 0;
  height: 33px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 100%;
}

.collection_box {
  border: 1px solid #7070704d;
  border-radius: 17px;
  padding: 17px;
  box-shadow: 0 0 13px -1px #00000024;
}

.collection_img {
  margin-bottom: 5px;
}

.collection_img img {
  max-width: 100%;
  height: 300px;
  width: 100%;
  border-radius: 14px;
  object-fit: cover;
  overflow: hidden;
}

.collectible-upload-image h6 {
  color: #4f0c75;
  font-size: 18px;
  margin: 0;
}

.collectible-upload-image {
  background: #fff;
  box-shadow: 0 0 15px rgb(17 47 73 / 15%);
  border-radius: 15px;
  cursor: pointer;
  padding: 1px 0;
}

.collectible-box {
  position: relative;
  margin: 0 20px;
}

.uplage-image-collect {
  position: absolute;
  height: 290px;
  opacity: 0;
}

.name-description-filed {
  background: #fcfaff;
  padding: 30px 0;
  margin-top: 30px;
}

.collectible-bg {
  /* background: #fcfaff; */
  padding: 100px 0;
  border: 2px dashed #d7d7d7;
  margin: 20px;
  border-radius: 15px;
}

.name-description-filed input,
.name-description-filed textarea {
  border-radius: 10px;
  height: 48px;
  font-size: 14px;
}

.start_end_row .MuiFormControl-root {
  width: 100% !important;
}

.create-bt {
  background: #fe7702;
  color: #fff;
  width: 130px;
  height: 37px;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 20px;
}

.preview-text {
  color: #00000057;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.preview-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.description-toggle {
  margin-left: auto;
}

.spinner-section {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 5px;
}
.right-icon {
  width: 70px;
  text-align: center;
}

.sl-spinner3 .sl-loader .sl-loader-parent .sl-circle {
  width: 60px !important;
  height: 60px !important;
}

.grey-check {
  color: #d3d3d3 !important;
  font-size: 40px;
}

.form-switch .form-check-input {
  width: 62px !important;
  height: 25px !important;
}

.form-check-input:checked {
  background-color: #4f0c75 !important;
  border-color: #4f0c75 !important;
}

.auction-toggle.area label {
  margin-bottom: 0;
}

.home-banner-left {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tyler-hero {
  color: #4f0c75;
  background: #4f0c7521;
  padding: 8px 20px;
  border-radius: 50px;
  font-size: 16px;
}

.home-banner-left h1 {
  font-size: 64px;
  color: #fff;
  font-weight: 700;
  margin-top: 15px;
}

.home-banner-left p {
  font-size: 20px;

  color: #ffffff;
}

.nft-market-checkbox li {
  display: inline-block;
  margin-right: 25px;
}

.nft-market-checkbox {
  padding: 0;
  margin-bottom: 41px;
}

.collecting-bt i {
  margin-left: 15px;
}

.customer-box li {
  margin-left: -22px;
  display: inline-block;
}

.customer-box {
  padding: 0;
}

.customer-box li:last-child {
  margin-left: 20px;
}

.customer-box li:last-child {
  margin-left: 23px;
}

.customer-box {
  margin: 71px 0 50px 20px;
  display: flex;
  align-items: center;
}

.connect-wallet-bt {
  border-radius: 10px;
  border: 2px solid #fe7702;
  padding: 17px 15px 17px;
  text-align: center !important;
  margin: 0 10px;
  color: #fe7702;
}

.dropdown-menu.show {
  border: none;
  padding-left: 0;
  background: 0 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background: 0 0 !important;
}

a.dropdown-item {
  padding: 0;
}

.explore-heading {
  text-align: center;
  color: #fff;
  font-size: 48px;
  padding: 60px 0;
}

.explore-heading span {
  color: #fe7702;
}

/* .no-data {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

#hot-collection {
  /* background-image: url(../images/explore.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  color: #fff;
  text-align: center;
  position: relative;
  padding: 10px 0 40px;
}

.list-tab-box.tab-content {
  width: 100%;
  padding: 20px 0;
}

.more-div {
  margin-bottom: 60px;
}

.name-dec {
  cursor: pointer;
}

.hot-heading {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 50px;
}

.collection-head-new {
  background: #fff;
  color: #4f0c75;
  border-radius: 30px;
  padding: 7px 14px;
  line-height: 97px;
}

#hot-collection .tab-pane {
  margin: 0 10px;
}

.bt-div {
  margin-top: 10px;
}

.tab_box_home h4 {
  font-style: normal;
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 14px;
  color: #12041e;
}

.explore-box .col-md-3 {
  padding-right: 0;
}

.tab_box_home.active {
  background: #fe7702;
  min-height: 59px;
}

.tab_box_home.active h4 {
  color: #fff;
}

.touch_form p.danger {
  font-size: 15px;
  line-height: 5px;
  color: red !important;
}

.upcoming-events {
  background: #342b38;
  padding: 50px;
}

.upcoming-events .soccor-text {
  margin-bottom: 50px;
}

.event-dates h4 {
  margin: 0;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Space Grotesk", sans-serif;
}

.event-dates h3 {
  font-size: 38px;
  text-transform: uppercase;
  margin: 0;
  font-weight: 500;
  color: #fd7803;
  font-family: "Space Grotesk", sans-serif;
}

.event-dates {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  border: 1px solid #c66311;
  padding: 8px 50px;
  margin-top: 20px;
}

.upcoming-events {
  background: #342b38;
  padding: 50px;
  margin: 90px 0 20px;
  position: relative;
}

.upcoming-events:after {
  content: "";
  background: #342b38b5;
  height: 20px;
  position: absolute;
  bottom: -17px;
  left: 50%;
  z-index: 0;
  width: 95%;
  transform: translate(-50%, 0);
}

.upcoming-events:before {
  content: "";
  background: #342b386b;
  height: 20px;
  position: absolute;
  bottom: -35px;
  left: 50%;
  z-index: 0;
  width: 88%;
  transform: translate(-50%, 0);
}

.upcoming-events .soccor-text span {
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fd7803;
}

.event-dates.event-dates-two {
  border: 1px solid #c663113d;
}

input.btn.btn-unset.submit-input {
  background-color: #fe7702 !important;
  background: #fe7702 !important;
  color: #fff !important;
  border-radius: 12px;
  margin: 0 7px 0 0;
  height: 52px;
  border: none !important;
  width: -moz-fit-content;
  width: fit-content;
}

.explore-box h1 {
  margin-bottom: 70px;
}

.tab_box_home.active h5 {
  color: #fff;
  opacity: 0.5;
}

.tab_box_home h5 {
  font-style: normal;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
  color: #c2bfe8;
}

.tab_box_home span {
  position: absolute;
  top: 50%;
  right: 20px;
  cursor: pointer;
  transform: translate(0, -50%);
}

img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-30px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-30px);
  }
}

.market_banner_section {
  background-color: #000;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  color: #fff;
  text-align: center;
  position: relative;
  padding-bottom: 60px;
}

.market-left h1 {
  font-size: 90px;
  background: -webkit-linear-gradient(45deg, #f8a8ff, #6155ed);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  font-weight: 800;
  margin-bottom: 30px;
}

#nft-feature {
  /* background-image: url(../images/feature.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  color: #fff;
  text-align: center;
  position: relative;
  padding-bottom: 60px;
}

.searchbar_item video {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 10px;
}

.explore-box {
  /* background-image: url(../images/explore_bg.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  color: #fff;
  position: relative;
  padding: 60px 0;
}

.isolate-inheritance ._1CW8I {
  opacity: 0 !important;
  cursor: pointer;
}

.header-dropdown.dropdown-menu.show {
  background: #fe7702;
  padding: 5px 10px 0 10px;
}

.header-dropdown.dropdown-menu.show a {
  color: #fff !important;
  border-top: 1px solid #ffffff59;
  padding: 3px 0;
  font-size: 14px;
}

.header-dropdown.dropdown-menu.show a:first-child {
  border-top: none;
}

.header-dropdown.dropdown-menu.show a:hover {
  background: #ff861e !important;
}

.header-dropdown.dropdown-menu.show button {
  background: none;
  border: none;
  padding: 0 0 8px;
}

.navbar-nav .logout-dropdown.dropdown-menu.show {
  left: -62px !important;
  padding: 2px 8px;
}

.touch-bt-two {
  background: #fe7702 !important;
  color: #fff !important;
  border-radius: 12px !important;
  height: 52px;
  line-height: 39px !important;
}

.discord-section-bg {
  background: url("../images/discord-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  position: relative;
  height: 370px;
  max-height: 500px;
  margin: 100px 0 30px 0;
}

.discord-section-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.discord-section-content h2 {
  color: #fff;
  font-size: 50px;
  font-weight: 700;
}

.discord-section-content h2 span {
  color: #fe7702;
}

.discord-section-content p {
  font-size: 28px;
  color: #fff;
  font-weight: 600;
  line-height: 55px;
  margin-bottom: 20px;
}

.discord-section-content a {
  background: #fe7702;
  padding: 15px 40px;
  color: #fff !important;
  border-radius: 10px;
  font-size: 18px;
  letter-spacing: 1px;
}

.more-games {
  color: #fff;
  font-size: 17px;
  line-height: 60px;
}

#nft-feature h3 {
  color: #fff;
  margin-top: 20px;
}

#nft-feature h3 {
  color: #fff;
}

.step-two {
  padding-top: 40px;
}

.step-three {
  padding-top: 80px;
}

#nft-feature p {
  color: #ffffff70;
  padding: 0 120px;
}

.contact-box-area {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  padding: 60px 0;
}

.contact-left-side h3 {
  color: #fff;
  font-size: 40px;
  line-height: 63px;
  padding: 40px 0 0 70px;
}

.contact-left-side {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

.contact-right-side input {
  background: 0 0 !important;
  border-bottom: 1px solid #ffffff8c !important;
  border: none;
  border-radius: 0;
  color: #fff !important;
  padding-left: 0;
  padding-right: 0;
  margin-top: 50px;
}

.contact-right-side input::placeholder {
  color: #fff !important;
}

.contact-form .btn {
  display: flex;
  margin: 14px auto;
}

.contact-right-side {
  display: flex;
  margin: 25px auto;
}

.contact-form {
  background: #ffffff12;
  border-radius: 20px;
  border: 2px solid #ffffff14;
  padding: 10px 80px 10px 100px;
  position: absolute;
  bottom: 0;
}

.contact-filled-box .row {
  position: relative;
}

.choose-heading {
  font-size: 20px;
}
.air-max.Royality-max {
  justify-content: left;
}
.single-multiple-box .react-tabs__tab-list {
  display: inline-block;
}

.addresstext {
  position: relative;
}

.addresstext i {
  position: absolute;
  right: 0;
  top: 0;
}

.single-multiple-box .react-tabs__tab--selected {
  border-radius: 5px;
  border: none !important;
  background: #fe7702 !important;
  width: 135px !important;
  text-align: center;
  color: #fff !important;
}

.create-items li p {
  margin: 0;
}

.single-multiple-box .react-tabs__tab {
  border: none !important;
  padding: 6px 12px;
  cursor: pointer;
  background: #f0ebf6;
  color: #0000008c;
  width: 160px;
  border-radius: 5px;
  font-size: 20px;
  text-align: center;
}

.create-items h6 {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 16px;
  max-width: 100px;
  margin: 0 auto !important;
}

.items-area-box-two img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 2px;
}

.create-items h6 {
  margin: 0;
}

.searchbar_header input {
  padding: 0 68px 0 15px;
}

.create-items ul li {
  display: flex;
  justify-content: center !important;
  border: 1px solid #0000003d;
  border-radius: 5px;
  width: 110px;
  height: 110px;
  margin-right: 15px;
  cursor: pointer;
  padding: 12px 0;
  align-items: center;
}

.items-area-box,
.items-area-box-two {
  text-align: center;
}

.create-items ul {
  display: flex;
}

.modal-profile-img {
  position: relative;
  height: 30px;
}

.cover-image-box img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

form.text-left {
  text-align: left;
  margin-top: 70px;
}

.profile-input-mod {
  width: 135px !important;
  height: 120px;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.img-input-mod {
  position: absolute;
  top: 0;
  height: 270px;
  width: 94%;
  opacity: 0;
}

.collection-create .modal-content {
  padding: 0;
}

.create-items ul {
  padding: 0;
}

.copies-input {
  max-width: 200px;
}

.single-multiple-box .react-tabs__tab-list {
  border-bottom: none !important;
  margin-bottom: 20px;
}

.modalclose_icon i {
  margin-top: -2px;
}
.collection-create .modal-footer {
  padding-top: 0;
}
/* .modalclose_icon {
  width: 30px;
  cursor: pointer;
  height: 30px;
  background: #fe7702;
  color: #fff;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
} */

.uploadimg {
  display: inline-block;
  position: relative;
}

.uploadimg input {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  opacity: 0;
}

.addresstext {
  padding-right: 38px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.slide-1 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.tenis-img-two {
  margin-left: -20px;
}

.like-view {
  text-align: center;
  color: #fff;
  margin: 0 15px;
}

.like-view i {
  color: #fc3a3a;
}

.in-stock {
  background: #00000085;
  padding: 10px 13px;
  color: #fff;
  margin-left: auto;
  border-radius: 30px;
}

.slide-percent {
  font-size: 70px;
  color: #fff;
  padding: 80px 30px 0;
  margin-bottom: 0;
}

.slide-progress {
  color: #fff;
  border: 2px solid #fe7702;
  display: inline-block;
  padding: 3px 11px;
  margin-left: 30px;
}

.echo-two {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 1.6em;
  color: #fff;
  font-size: 18px;
}

.echo {
  color: #fff;
  padding: 140px 30px 0;
}

.items-name h5 {
  color: #fff;
  font-size: 18px;
}

.bid-text {
  padding-left: 30px;
  color: #fff;
  font-size: 14px;
}

.bid-text span {
  color: #fe7702;
}

.bid-text-two span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.bid-text-two {
  padding: 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.our-team {
  background: #000;
  padding: 1px 0 70px 0;
  position: relative;
}

.shades-box-two {
  position: absolute;
  right: 0;
  bottom: -370px;
  pointer-events: none;
}

.bid-text-two.bid-usd-box {
  padding-left: 8px;
}

.member-info span {
  font-size: 14px;
  color: #fe7702;
}

.member-info h5 {
  font-size: 22px;
  color: #c8ceec;
  font-weight: 400;
}

.mainimg {
  height: 246px;
}

.tenis-box {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  position: absolute;
  top: 4px;
  left: 0;
}

nav.home_header {
  padding: 8px 0;
}

.morefaq {
  background: #fe7702;
  color: #fff !important;
  padding: 9px 20px;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: fit-content;
}

li.alice-carousel__stage-item {
  padding: 0 10px;
}

.social-icon li {
  display: inline-block;
  margin: 0 22px;
}

.social-icon {
  padding: 0;
  text-align: center;
  border-radius: 10px;
}

.slide-margin {
  margin: 7px 5px;
}

.react-multi-carousel-track:first-child
  .react-multi-carousel-item.react-multi-carousel-item--active {
  display: none;
}

.errors {
  color: red;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  display: block;
}

.end-reserve-bid-box input {
  margin-bottom: 7px;
  width: 97%;
}

.end-reserve-bid-box {
  margin: 28px 23px 0;
  width: 90%;
}

.meta-mask-modal a b {
  text-decoration: underline;
}

.meta-mask-modal h3 {
  font-size: 24px;
  color: #000;
}

.meta-mask-modal .modal-content {
  border-radius: 10px;
  /* padding: 40px 0; */
  /* background-image: url(../images/modal-bg.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.modal-header h3 {
  font-size: 20px;
}

.modal-header {
  padding-bottom: 0 !important;
}

.meta-mask-modal a {
  color: #fe7702;
}

.ok-bt.btn::after {
  content: "";
  /* background-image: url(../images/line.png); */
  background-size: contain;
  position: absolute;
  height: 50px;
  width: 50px;
  right: -70px;
  top: -25px;
}

.sale-auction-tab {
  border: none !important;
}

.sale-auction-tab input {
  max-width: 240px !important;
  height: 46px;
  border-radius: 10px !important;
  border: none !important;
}

.sale-auction-tab li.nav-item button {
  width: 160px;
  border-radius: 10px;
  color: #5a0075;
  border: 1px solid #fff;
  box-shadow: 2px 4px 1px #b04f19;
  background: #ffffff;
  margin: 0 5px;
}

.alice-carousel__stage-item * {
  max-width: 400px !important;
}

.date-box .MuiFormControl-root {
  width: 100%;
}

.tab-one {
  max-width: 280px;
}

.tab-one input {
  height: 46px;
}

.tab-bid-box label {
  background: #fff;
  padding: 0 9px !important;
  border-radius: 5px;
}

.tab-bid-box input {
  width: 97%;
  height: 46px;
  border-radius: 10px;
}

.profile-box {
  background: #f3f3f3;
  margin: 86px 0 0 0;
  padding: 70px 0;
}

.profile-left-side img {
  width: 220px;
  height: 220px;
  border-radius: 15px;
  object-fit: cover;
}

.edint-pencil {
  background: #fe7702;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  font-size: 13px;
  position: absolute;
  right: -15px;
  top: -16px;
  cursor: pointer;
}

.profile-Right-side {
  text-align: left;
  padding: 0 0 0 18px;
}

.profile-content h5 {
  color: #47036f;
}

.logout-flag button {
  background: none !important;
}

.logout-flag {
  font-size: 15px;
  color: #fe7702 !important;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.profile-content h6 {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-weight: 500;
}

.logout-flag i {
  color: #fe7702 !important;
}

.profile-content h6 a,
.profile-content h6 i {
  color: #fe7702 !important;
}

.profile-Right-side i {
  font-size: 18px;
  margin-right: 8px;
}

.profile-Right-side .fa-address-book-o {
  margin-right: 0;
}

.modal-img-new {
  width: 160px;
  height: 160px;
  object-fit: cover;
}

.profile-Right-side h2 {
  font-size: 22px;
  color: #47036f;
  display: inline-flex;
  align-items: center;
}

.edit-profile-modal .modal-dialog {
  max-width: 410px;
}

.modal-form-new button.ok-bt {
  background: #fe7702;
  padding: 7px 20px;
  border-radius: 5px;
  color: #fff;
  margin: 0 auto;
  display: block;
}

.modal-form-new {
  text-align: left;
  margin: 0 60px;
}
.frame-options:hover {
  transform: scale(1.1);
  transition: 0.3s all;
  cursor: pointer;
}
.profile-Right-side h4 {
  font-size: 15px;
  color: #fe7702;
}

.bid_auction .tab-content {
  margin: 0 13px 0 0 !important;
}

.tab-bid-box .row {
  --bs-gutter-x: 0;
}

.sale-auction-tab .nav-item.show .nav-link,
.sale-auction-tab .nav-link.active {
  color: #ffffff !important;
  background-color: #5a0075 !important;
  border-color: #5a0075 !important;
}

.auction-right-side {
  text-align: center;
}

.navbar-light .navbar-toggler {
  color: rgb(255 255 255 / 100%) !important;
  border-color: rgb(255 255 255 / 50%) !important;
}

.navbar-light .navbar-toggler-icon {
  filter: invert(1);
}

.save-bid-price {
  background: #5a0075;
  padding: 6px;
  margin-top: 20px !important;
  display: block;
  width: 90px !important;
  text-align: center;
  font-size: 20px;
  color: #fff !important;
  border-radius: 10px;
}

.white_box {
  width: 70px;
  height: 70px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: -62px;
  z-index: 2;
  position: relative;
}

.blockchain_row h4 {
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
}

.blockchain_row span {
  min-height: 210px;
  overflow: hidden;
  display: inline-block;
}

.blockchain_row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
}

header .bloglink.active.nav-link {
  color: #fff !important;
  border-bottom: 0 !important;
}

.create_store_row h4 {
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
}

.create_store_row p {
  color: #c8ceec;
  font-size: 17px;
  font-weight: normal;
}

.launch_section .common_box_content {
  text-align: center;
  margin-bottom: 30px;
}

.launch_section h5 {
  margin-bottom: 20px;
}
.display-hidden-important {
  display: none;
  /* content-visibility: hidden !important; */
  /* visibility: collapse !important; */
}
.main-loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(0,0,0,.8196078431372549);
  z-index: 1056;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading {
  text-align: center;
  font-size: 18px;
  color: #fff;
  margin-top: 15px;
}

.name-dec {
  display: flex;
  align-items: center;
}

.name-dec p {
  margin-right: 5px;
  font-size: 18px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.shade-box {
  position: absolute;
  width: 500px;
  top: 0;
  left: 220px;
}

.market_categories .nav-item .nav-link {
  background: linear-gradient(180deg, #fafaff 0, #cfd4fe 100%);
  border-radius: 16px;
  min-width: 240px;
  padding: 22px;
  margin: 1px 10px 9px 0;
  position: relative;
  cursor: pointer;
  text-align: left;
  height: 83px;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

body {
  top: 0 !important;
}

.MuiOutlinedInput-root.MuiInputBase-root {
  background: #fff;
  height: 47px;
  margin-bottom: 7px;
  width: 97%;
  border-radius: 10px;
}

.market_categories .nav-item .nav-link span {
  position: absolute;
  top: 50%;
  right: 20px;
  cursor: pointer;
  transform: translate(0, -50%);
}

.market_categories .nav-item .nav-link.active {
  background: #fe7702;
  border-radius: 16px;
}

.market_categories .nav-item .nav-link.active h4 {
  color: #fff;
}

.market_categories .nav-item .nav-link.active h5 {
  color: #fff;
  opacity: 0.5;
}

.market_categories .nav-item .nav-link h4 {
  font-style: normal;
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 14px;
  color: #12041e;
}

.market_categories .nav-item .nav-link h5 {
  font-style: normal;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
  color: #c2bfe8;
}

.search_marketplace .searchbar_header {
  margin-left: 0;
}

.my_listing-area {
  min-height: 600px;
  background: #f3f3f3;
  padding: 50px 0;
  position: relative;
}

.searchbarlist {
  background-color: #fff;
  border-radius: 10px;
  border-bottom: 1px solid #d3d3d32e;
  padding: 10px;
  width: 100%;
  position: absolute;
  top: 64px;
  min-height: 100%;
  max-height: 370px !important;
  overflow: auto;
  box-shadow: 0 2px 4px #00000024;
}

.search-market-banner {
  background-color: #fff;
  border-radius: 10px;
  border-bottom: 1px solid #d3d3d32e;
  padding: 10px;
  width: 100%;
  min-height: 100%;
  max-height: 180px;
  overflow: auto;
  z-index: 1;
  position: relative;
  text-align: left;
}

#asset-search-result {
  max-width: 370px;
  max-height: 180px;
}

.onsale-modal.form-select {
  position: absolute;
  max-width: 150px;
  top: 0;
}

.searchbar_item {
  position: relative;
  padding: 5px 2px;
  border-bottom: 1px solid #d3d3d32e;
  margin-bottom: 0;
}

.__react_component_tooltip {
  background: #fcfaff !important;
  width: 291px;
  border-radius: 10px !important;
  color: #000 !important;
  font-size: 13px !important;
  font-weight: 400;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.33);
  padding: 10px !important;
}

.choose-heading i {
  cursor: pointer;
}

.__react_component_tooltip .multi-line {
  text-align: left !important;
}

.__react_component_tooltip::before {
  color: #fcfaff !important;
}

.__react_component_tooltip::after {
  border-bottom-color: #fcfaff !important;
}

.price-category {
  padding-left: 8px;
}

.searchbar_item h5 {
  font-size: 17px;
  color: #292929;
}

.bid-text-two.bid-usd-box {
  color: #fff !important;
}

.bid-text-two.bid-usd-box span {
  color: #fff !important;
}

.header-serach-box {
  padding-left: 43px;
}

.header-serach-box h5,
.header-serach-box p {
  margin: 0;
  word-break: break-word;
}

.searchbar_item img {
  width: 38px;
  position: absolute;
  height: 38px;
  border-radius: 100%;
  object-fit: cover;
  overflow: hidden;
}

.featured-area {
  text-align: center;
}

.btm_details .attri-description {
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 3px;
}

.featured-area ul li {
  list-style: none;
  padding: 13px 30px;
}

.featured-area {
  text-align: center;
  padding: 40px 0;
}

.featured-area ul {
  display: flex;
  justify-content: center;
}

.btm_details p {
  color: #fff;
  margin-bottom: 3px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 1.6em;
  margin-top: 9px;
}

.items-name {
  margin-top: 10px;
}

.sl-spinner3 {
  width: 70px;
}

.right-icon i {
  font-size: 40px;
}
/* .detail-collection-area {
  margin-left: 270px;
} */
.detail-left-side img {
  height: 540px;
  max-width: 540px;
  object-fit: contain;
  border-radius: 10px;
  width: -webkit-fill-available !important;
}
.detail-left-side {
  text-align: center;
}
/* .detail-left-side {
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 25px;
  height: calc(100vh - 200px);
  text-align: center;
} */
.items-datails {
  text-align: left;
  padding: 15px 15px 5px 15px;
}

.works-area-box {
  /* background-image: url('../images/faq_bg.png'); */
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  color: #fff;
  padding: 150px 0 60px;
}

.featured-area h5 {
  font-size: 36px;
  font-weight: 700;
}

.sign-area h5 {
  margin: 20px 0;
}

.sign-area p {
  color: #ffffffb8;
  font-weight: 400;
}

.auction-right-side video {
  max-height: 450px;
}

.sign-area {
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 20px 96px 20px 20px;
  height: 317px;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  justify-content: center;
}

.works-area-box h2 {
  text-align: center;
  font-size: 60px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 50px;
}

.featured-area {
  text-align: center;
  padding: 40px 0;
}

.faq-box h2.accordion-header {
  margin: 0;
  background: 0 0;
}

.accordion-item {
  border: 1px solid #fff !important;
}

.faq-box h2.accordion-header button {
  background: 0 0;
  color: #fff !important;
}

.slide-2 video {
  height: 246px;
  object-fit: cover;
  display: block;
}
.kennedy-brooks {
  background-image: url("../images/promote-bg.png");
  background-repeat: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.accordion-button::after {
  filter: invert(1);
}

.accordion {
  max-width: 750px;
  margin: 0 auto;
}

.accordion-item {
  background: 0 0;
  border: 2px solid #fff;
  color: #fff;
  margin-bottom: 20px;
  border-radius: 10px !important;
}

.list-market-new video {
  height: 246px;
  object-fit: contain;
}

.accordion-item {
  background: 0 0 !important;
}

.featured-area h5 {
  font-size: 31px;
  font-weight: 700;
  color: #565656;
}

.slide-2 img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.list-market-new:hover {
  background: #131414;
}

.mainimg {
  height: 246px;
  border-radius: 10px 10px 0 0 !important;
  object-fit: cover;
}

.connect-wallet-bt a {
  color: #fe7702;
}

.connect-wallet-bt:hover a {
  color: #fff;
}

.my_listing-area h2 {
  color: #47036f;
}

.btm_details .attri-price {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.slide-2 .btm_details {
  width: 100%;
  text-align: left;
  padding: 0 8px 0 8px !important;
  padding-bottom: 10px;
  height: 101px;
}

.slide-2 .mainimg {
  min-width: 100%;
  height: 246px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 15px 15px 0 0;
}

.slide-2 .bid-text-two {
  padding-top: 8px;
  padding-left: 0;
}

.slide-2 .echo-two {
  padding: 0;
}

.auction-right-side video {
  max-width: 560px;
  width: -webkit-fill-available;
}

.live-auction h2 {
  color: #fff;
  font-size: 34px;
  margin-left: 10px;
}

.fade {
  transition: opacity 3s linear !important;
}

.day-timer h4 {
  font-family: "Circular Std", sans-serif !important;
  margin-bottom: 9px;
}

.live-auction {
  display: flex;
  align-items: start;
}

.live-auction img {
  margin-top: 10px;
}

.day-timer h6 {
  font-size: 14px;
  margin-bottom: 0;
}

.share-bt p {
  margin-bottom: 0;
  background: #ffffff21;
  border-radius: 50px;
  padding: 3px 20px;
  margin-left: 10px;
  font-size: 14px !important;
  /* min-width: 100px; */
}

.fa-share-alt {
  background: #fff;
  width: 20px;
  text-align: center;
  color: #b34933;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
}

.auction-form .input-group-text {
  border-radius: 0;
  background-color: #fff;
  border: none;
  border-left: 3px solid #26b1a1;
  margin: 13px 0;
  padding: 4px;
}

.auction-form .input-group {
  background: #fff;
  border-radius: 50px;
  padding: 0 20px;
}

.auction-form .row {
  display: flex;
  align-items: center;
}

.auction-form input {
  border-radius: 0 30px 30px 0 !important;
  height: 58px;
  border: none;
}

.eth-box h5 {
  font-size: 16px;
  color: #fff;
  background: #ffffff21;
  border-radius: 50px;
  padding: 0 11px;
  margin: 6px 0 6px 10px;
}
input.minimum-quantity {
  margin-bottom: 2px !important;
}
.collection-team h6 {
  color: #ffd6ae;
}

.bid_auction h1 {
  font-size: 24px;
  color: #ffd6ae;
  font-weight: 700;
}

.air-max h2 {
  font-size: 30px;
  color: #ffd6ae;
  margin-bottom: 0;
  display: block;
  line-height: 30px;
  max-width: 270px;
  word-break: break-word;
}

.name-description h5 {
  color: #fff;
  margin-bottom: 23px;
}

.name-description p {
  font-size: 14px !important;
  color: #efefef;
  font-weight: 400;
  margin: 0;
}

.name-description {
  margin-left: 15px !important;
}

.air-max h4 {
  color: #fff;
  font-size: 20px;
}
/* 
.buy-sell-box {
  margin-top: 50px;
} */

.buy-sell-box h4 {
  color: #fff;
}

.collections-details-box img {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  object-fit: cover;
}

.buy-now-png {
  /* background-image: url('../images/buy.png'); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 44px;
  color: #fff;
  line-height: 60px;
}

.air-max {
  display: flex;
  align-items: center;
}

.day-timer li {
  color: #fff;
  display: inline-block;
  margin: 0 20px;
  text-align: center;
}

.day-timer {
  margin: 0 0 0 auto;
  text-align: center;
}

.collec-heading {
  color: #fff;
  margin-bottom: 30px;
}

.collec-heading a {
  color: #c5c5c5 !important;
}

.details-cover img {
  width: 100%;
  height: 400px;
  object-fit: contain;
}

.seller-box h2 {
  font-size: 36px;
  text-align: center;
  margin-bottom: 30px;
}

.seller-box .tab-pane {
  margin: 0 10px;
}

.seller-box h2 span {
  color: #fe7702;
}

.login_header button {
  font-size: 14px;
}

.my-acc-bt {
  color: rgba(61, 66, 79, 0.65) !important;
}

.navbar-expand-lg .navbar-collapse {
  align-items: initial;
}

.nft-collection-box li {
  color: #ffffff94;
  list-style: none;
  display: inline-table;
  width: 170px !important;
  text-align: center;
}

.listingsection .nft-collection-box {
  background: white;
  padding: 11px 0;
  margin-bottom: 40px;
}

.load-absolute {
  text-align: center;
}

.items-price {
  color: #fff;
  margin-bottom: 10px;
}

.nft-collection-box ul {
  padding: 0;
  border: none;
}

.nft-collection-box li a {
  color: #fff;
}

.faq-link {
  color: #fe7702 !important;
  font-size: 16px;
  display: block;
  margin-top: 30px;
  text-decoration: underline !important;
}

.onsale-modal.form-select {
  position: absolute;
  max-width: 150px;
  top: 0px;
  left: 280px;
  height: 48px;
  border-radius: 10px;
  border: 2px solid #000;
}

.nft-collection-box li a.active {
  background: #000;
  padding: 8px 10px;
  border-radius: 10px;
  height: 40px;
  display: block;
  color: #fe7702;
}

.nft-collection-box ul {
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  background: #2f333c;
  padding: 5px;
  margin-bottom: 15px;
}

.nft-collection-box h5 {
  margin-bottom: 10px;
}

.searhmarket-content {
  padding-left: 45px;
}

.auto_break_next_line {
  /* max-width: 100px; */
  line-break: anywhere;
}

.image_fix_cover {
  object-fit: cover !important;
}

/* soccor page css statr*/

@import url("https://fonts.cdnfonts.com/css/space-grotesk");
@import url("https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap");

.soccor-banner h2 {
  font-family: "Space Grotesk", sans-serif;
  color: #fff;
  font-size: 100px;
  font-weight: 700;
  margin-bottom: 120px;
}

.soccor-banner h2 span {
  color: rgba(255, 255, 255, 0);
  font-size: 50px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(255, 255, 255);
  font-size: 130px;
  font-weight: 700;
}

.soccor-banner {
  padding: 235px 0;
  background-image: url("../images/soccor_banner.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 600px;
}

.beach-text {
  font-family: "Rock Salt", cursive;
  font-size: 50px;
  color: #fd7803;
  padding: 40px 0;
}

.input-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.soccor-text {
  font-family: "Space Grotesk", sans-serif;
  color: #fff;
  font-size: 70px;
  font-weight: 700;
  text-transform: uppercase;
}

.beach-box {
  background-color: #120817;
  padding: 60px 0;
}

.beach-soccor-one {
  position: relative;
}

.activities-box {
  background: #22102b;
  padding: 70px 0;
  position: relative;
}

.activities-heading h3 {
  font-size: 130px;
  font-weight: 700;
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
}

.activities-heading {
  position: absolute;
  top: 120px;
}

.activities-heading h3 span {
  font-size: 130px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(255, 255, 255);
}

.activities-heading h5 {
  font-size: 40px;
  color: #fff;
  line-height: 70px;
  font-family: "Space Grotesk", sans-serif;
  text-transform: uppercase;
}

.activities-heading h5 span {
  font-size: 40px;
  font-family: "Rock Salt", cursive;
  color: #fd7803;
}

.prizes-box {
  background-image: url("../images/prize_banner.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 250px 0;
}

.soccor-contact-box {
  background-image: linear-gradient(180deg, black, #23112d);
  padding: 120px 0;
}

.contact-form-two h3 {
  font-size: 40px;
  text-align: center;
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  line-height: 63px;
}

.contact-form-two input {
  background: no-repeat !important;
  border: none;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  padding: 10px 0;
  color: #fff !important;
}
.swal2-styled.swal2-confirm {
  background-color: #fe7702 !important;
}
.contact-form-two .danger {
  color: red !important;
  margin-top: 5px;
}

.forrm-submit-btn {
  background: #fd7803 !important;
  border: none !important;
  width: 390px;
  color: #fff !important;
  height: 48px;
  margin: 50px auto 0 !important;
  display: block !important;
}

.contact-form-two {
  max-width: 80%;
  margin: 0 auto;
  border: 2px solid rgb(255 255 255 / 18%);
  padding: 50px 150px;
}

.contact-form-two input::placeholder {
  color: #fff !important;
}

.contact-form-two h3 span {
  font-size: 40px;
  font-family: "Rock Salt", cursive;
  color: #fd7803;
  font-weight: 500;
}
.market-pagination .pagination {
  justify-content: center;
}
.market-pagination li a {
  background: black;
  color: #747474;
  border-color: #4e4e4e;
  width: 40px;
  margin: 0 5px;
}
.market-pagination .active.page-item a {
  border-color: #fe7803 !important;
  background: #fe7803 !important;
}
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
.signle-detail-data-area .MuiOutlinedInput-root.MuiInputBase-root {
  background: linear-gradient(211deg, #d65e1c, #e76911);
  border: 1px solid #fff !important;
}
.signle-detail-data-area
  .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root
  label {
  background: linear-gradient(211deg, #d65e1c, #e76911);
  padding: 0 6px;
  color: #fff;
}
.signle-detail-data-area.tab-bid-box input {
  width: 97%;
  height: 46px;
  border-radius: 10px;
  color: #fff;
}
.signle-detail-data-area.tab-bid-box input::placeholder {
  color: #fff;
}
.signle-detail-data-area .css-i4bv87-MuiSvgIcon-root {
  fill: #fff;
}
.signle-detail-data-area input.form-control {
  background: linear-gradient(211deg, #d65e1c, #e76911);
  color: #fff !important;
  border: 1px solid #fff;
}
.signle-detail-data-area input.form-control::placeholder {
  color: #fff;
}
.signle-detail-data-area .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: #fff;
}
.preview-mobile-view {
  display: none;
}
.single-nft-detail-area {
  background: #fff !important;
  margin-top: 10px !important;
}
p.logout-title {
  margin-bottom: 0;
  color: #000;
}
.swal2-popup {
  width: 25em !important;
}
.swal2-image {
  margin-bottom: 0 !important;
}
div#swal2-html-container {
  font-size: 17px;
}
.swal2-title {
  color: #000 !important;
}
.toggle-drop-right .dropdown-menu.show {
  min-width: 100px;
  padding-top: 0;
  padding-bottom: 0;
  left: 80px;
  top: 40px;
}
.toggle-drop-right .wallet-bt-connect.dropdown {
  display: none;
}
/* get-in-touch */
.athe-banner-area {
  background-color: #000;
  color: #fff;
  background-image: url(../images/sports-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  padding: 133px 0;
  margin-top: 78px;
}
.banner-ath-image {
  display: none;
}
.ath-content h2,
.get-in-area h2,
.unique-content h2 {
  font-size: 50px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 30px;
}
.text-sport {
  color: #fe7803;
}
.ath-content p,
.get-in-area p,
.unique-content p {
  font-size: 18px;
  color: #c8ceec;
}
.inner-bg {
  background-image: url(../images/main-bg.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 55px;
}
.inner-content {
  padding: 32px 0;
}
.unique-img {
  text-align: center;
}
.get-in-section {
  padding: 52px 0;
}
.get-in-bg {
  background: hsla(0, 0%, 100%, 0.13);
  border: 2px solid hsla(0, 0%, 100%, 0.16);
  -webkit-backdrop-filter: blur(85px);
  backdrop-filter: blur(85px);
  border-radius: 24px;
  padding: 51px;
}
.get-in-bg input,
.get-in-bg textarea {
  border: none !important;
  background: hsla(0, 0%, 100%, 0.12156862745098039) !important;
  color: #fff !important;
  resize: none;
}
.get-in-bg .text-danger {
  text-align: left;
  display: inherit;
}
.btn-get-in {
  border-radius: 6px;
  background: #fe7803 !important;
  border: #fe7803 !important;
  padding: 11px 39px !important;
  margin: 15px 0 0;
}
.submit-btn .btn-get-in {
  float: left;
}
.get-in-bg input::placeholder,
.get-in-bg textarea::placeholder {
  color: #fff;
  font-size: 14px;
}
.header-login-hide {
  display: none !important;
}
iframe#review-card-payment-iframe {
  position: fixed;
  top: 5%;
  z-index: 9999999;
  width: 62%;
  left: 19%;
  height: 89%;
  overflow: auto;
}
div#headlessui-portal-root {
  position: fixed;
  top: 5%;
  z-index: 9999999;
  width: 0%;
  left: 19%;
  height: 89%;
  overflow: auto;
}
.detail-area-buy-nft ul.react-tabs__tab-list {
  margin-bottom: 15px;
}
.detail-area-buy-nft .nft-modal-items {
  margin-bottom: 10px;
}
.detail-area-buy-nft label.m-0.mb-2.form-label {
  font-weight: 600;
}
.detail-area-buy-nft .meta-mask-modal h3 {
  font-size: 24px;
  color: #000;
  font-weight: 700;
}
input#formBasicEmail::placeholder {
  font-size: 13px;
  color: #adadad;
}
.detail-area-buy-nft label.m-0.form-label {
  font-weight: 600;
}
.detail-area-buy-nft .buy-nft-tab .nft-modal-items {
  min-height: 70px;
}
svg#loader {
  display: none;
}
.absolute.top-1\/2.left-1\/2.-translate-x-1\/2.-translate-y-1\/2 {
  display: none !important;
}

/* soccor page css end */

/* soccor page css end */
/* athlenfts page css starts */
.athe-banner-area {
  background-color: #000;
  color: #fff;
  background-image: url("../images/sports-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  padding: 150px 0;
  margin-top: 100px;
}
.banner-ath-image {
  display: none;
}

.new-header .navbar {
  background: rgb(7 10 41);
  padding: 30px;
  position: fixed;
  top: 0;
  width: 100%;
}

.new-header .nav-link {
  color: #fff !important;
  margin: 0px 14px;
}

.ath-content h2,
.unique-content h2,
.get-in-area h2 {
  font-size: 50px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 30px;
}
section.works-area-box.faq-page-box {
  background: black;
}
.works-area-box.faq-page-box .accordion-body {
  color: #fff;
}

.ath-content p,
.unique-content p,
.get-in-area p {
  font-size: 18px;
  color: #c8ceec;
}

.text-sport {
  color: #fe7803;
}

.btn-get-in {
  border-radius: 6px;
  background: #fe7803 !important;
  border: #fe7803 !important;
  padding: 11px 39px !important;
  margin: 15px 0 0 0;
}

.inner-bg {
  background-image: url(../images/main-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 55px;
}

.inner-content {
  padding: 32px 0;
}
.get-in-bg {
  background: rgb(255 255 255 / 13%);
  border: 2px solid rgb(255 255 255 / 16%);
  -webkit-backdrop-filter: blur(85px);
  backdrop-filter: blur(85px);
  border-radius: 24px;
  padding: 51px;
}

.get-in-bg input,
.get-in-bg textarea {
  border: none !important;
  background: #ffffff1f !important;
  color: #fff !important;
  resize: none;
}

.submit-btn .btn-get-in {
  float: left;
}

.footer-area {
  background: #0d113a;
  color: #fff;
  text-align: center;
  position: relative;
}

.social-icons img {
  margin: 0 24px;
}

.copyright-text {
  padding: 22px 0 0 0;
  border-top: 1px solid #ffffff1a;
}

.social-icons {
  margin-bottom: 24px;
}

.unique-img {
  text-align: center;
}

.get-in-section {
  padding: 52px 0;
}

.new-header .nav-link:hover {
  color: #fff;
}

.get-in-bg input::-webkit-input-placeholder {
  color: #fff;
}

.get-in-bg textarea::-webkit-input-placeholder {
  color: #fff;
}

.get-in-bg .text-danger {
  text-align: left;
  display: inherit;
}
select.sort-by-filter.form-select {
  height: 55px;
  border-radius: 15px;
  width: 200px;
}
.search_marketplace {
  display: flex;
  align-items: center;
}
.choose-ai-tab-area nav.mb-3.nav.nav-tabs {
  justify-content: center;
}
.choose-ai-tab-area {
  margin-top: 30px;
}
.choose-ai-tab-area ul li {
  color: #fff;
}
.choose-ai-tab-area ul {
  display: flex;
  justify-content: center;
  list-style: none;
  padding-left: 0;
}
.choose-ai-tab-area ul li img {
  width: 30px;
  height: auto;
  filter: invert(1);
  margin-right: 5px;
}

.choose-ai-tab-area ul li {
  background: linear-gradient(180deg, #ffffff 0%, #cfd4fe 100%);
  color: #000;
  padding: 18px;
  margin: 0 8px;
  font-weight: 500;
  font-size: 15px;
  border-radius: 15px;
  cursor: pointer;
}
.choose-ai-tab-area ul li.active {
  background: #fe7702 !important;
  color: #fff !important;
  position: relative;
}
.choose-ai-tab-area ul li.active img {
  filter: unset !important;
}
.choose-ai-tab-area ul li.active:before {
  content: "";
  position: absolute;
  background: #fe7702;
  width: 25px;
  height: 25px;
  right: 7px;
  bottom: -7px;
  -webkit-transform: rotate(127deg);
  transform: rotate(127deg);
}
.image-image-upload-area {
  justify-content: center;
}
.ai-content-area-right {
  background: #000000a6;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: 2px solid #ffffff26;
  padding: 50px 10px;
  /* height: 100%; */
  border-radius: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
}
.ai-content-area-inner h6 {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 13px;
  
}
.ai-content-area-inner p {
  font-size: 13px;
  text-transform: capitalize;
}

/* 15-jun-23 */
.ai-content-area-right textarea.form-control {
  border: none;
  resize: none;
}
/* 
.form-control:focus{
   border:none;
} */

/* 15-jun-23 */

.ai-content-area-inner {
  width: 100%;
  /* padding: 0 35px; */
}
.ai-content-area-inner .input-group {
  /* height: 50px; */
  height: 60px;
  background: #fff;
  border-radius: 6px;
}
#basic-addon21 {
  background: #fe7702;
  padding: 10px;
  border: none;
  color: #fff;
  margin: 3px;
  border-radius: 5px;
  cursor: pointer;
}
.cursor-default-important {
  cursor: unset ;
}
.ai-content-area-inner .input-group input::placeholder {
  font-size: 13px;
}
.ai-content-area-inner .input-group input {
  border-right: none;
}
.ai-content-area-inner .input-group span#basic-addon2 {
  background: #fe7702;
  border: none;
  color: #fff;
  margin: 3px;
  border-radius: 5px;
  cursor: pointer;
}
.search-content-alignment {
  align-items: center;
}
h5.ai-to-heading span {
  color: #fe7702;
  font-weight: 600;
}

h5.ai-to-heading {
  color: #fff;
  text-align: center;
  margin-bottom: 45px;
  font-size: 20px;
}
.ai-tab-content-area {
  padding-top: 28px;
}
.ai-content-area-right:after {
  content: "";
  position: absolute;
  background-image: url("../images/blur-bg.png");
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  object-fit: contain;
  background-position: center;
  z-index: -1;
}
/* .generate-ai h2 {
  font-size: 50px !important;
} */

.generate-ai-content h6 {
  font-size: 16px;
  color: #fff;
  display: flex;
  margin-top: 15px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.generate-ai-content h6 span {
  background: linear-gradient(91.41deg, #f8a8ff 43.18%, #6155ed 154.56%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 25px;
  font-weight: 800;
  border: 1px solid #f8a8ff;
  padding: 8px 12px;
  border-radius: 6px;
  margin-right: 10px;
}
.process-image {
  min-height: 340px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-uploaad-area .fa {
  position: absolute;
  bottom: -8px;
  background: #fff;
  color: #000;
  padding: 6px;
  border-radius: 32px;
  right: -11px;
}
.frame-area {
  background: url("../images/frame-area-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 14px 0 30px 0;
}
.frame-center-image-area {
  position: absolute;
  top: 6%;
  z-index: 0;
  width: 86%;
  left: 5%;
  height: 81%;
  overflow: hidden;
}
.center-frame-area {
  position: relative;
  width: 348px;
  text-align: center;
  margin: 0 auto;
}
.center-frame-area img {
  z-index: 1;
  position: relative;
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}
.number-input {
  position: absolute;
  z-index: 999;
  right: 0;
  width: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 17px;
}
.preview-area .center-frame-area img {
  object-fit: cover !important;
}
/* .name-input input {
  width: 248px;
  left: 145px;
  left: 145px;
  position: absolute;
  bottom: 11px;
  z-index: 2;
  height: 58px;
  background: transparent;
  border: transparent;
  color: #fff !important;
  font-size: 22px;
  font-weight: 700;
} */
.name-input input {
  width: 100%;
  /* left: 20%; */
  /* position: absolute; */
  /* bottom: 0; */
  z-index: 2;
  /* height: 58px; */
  background: transparent;
  border: transparent;
  font-size: 17px;
  font-weight: 700;
  /* background: -webkit-linear-gradient(#fff, #ffff); */
  background: -webkit-linear-gradient(#fcee21, #fbb03b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.name-input .form-control:focus {
  background-color: #0000 !important;
}
.name-input input::placeholder {
  color: #ffffffa3;
  font-size: 18px;
  font-weight: 900px;
  background: -webkit-linear-gradient(#fcee21, #fbb03b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.number-input input {
  width: 100%;
  right: 12px;
  z-index: 2;
  top: 0;
  border-radius: 50%;
  background: transparent;
  border: transparent;
  font-size: 20px;
  font-weight: 700;
  color: #fff !important;
  /* background: -webkit-linear-gradient(#fff, #ffff); */
  background: -webkit-linear-gradient(#fcee21, #fbb03b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.number-input input::placeholder {
  color: #ffffffa3;
  font-size: 22px;
  font-weight: 900px;
  background: -webkit-linear-gradient(#fcee21, #fbb03b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.number-input .form-control:focus {
  background-color: #0000 !important;
}
.frame-options-area {
  padding: 60px 0 120px;
}
.image-image-area {
  text-align: center;
}
.image-image-area p span {
  color: red;
  font-size: 30px;
}

.image-image-area p {
  font-size: 15px;
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-image-area button {
  background: #fe7702;
  color: #fff;
  padding: 8px 33px;
  font-size: 16px;
  border-radius: 7px;
  margin-top: 10px;
}
.image-uploaad-area input {
  width: 130px;
  height: 130px;
  position: absolute;
  top: 0;
  opacity: 0;
}
.image-uploaad-area img {
  width: 130px;
  height: 125px;
  min-width: 130px;
  object-fit: cover;
  border-radius: 5px;
}
.image-uploaad-area {
  width: 130px;
  margin: 0 auto;
  position: relative;
}
.bottom-area-btns {
  display: flex;
  justify-content: center;
  margin-top: 7px;
}
.regenerate-btn button {
  background: linear-gradient(
    91.74deg,
    #e853e2 -1.58%,
    #e74fe2 -1.57%,
    #34a6e3 103.26%
  ) !important;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  width: 200px;
  height: 50px;
  margin: 0 5px;
}
.ai-mint-nft button {
  border: 2px solid #d158e2;
  background: transparent;
  width: 127px;
  height: 42px;
  margin: 0 5px;
  border-radius: 10px;
  background: -webkit-linear-gradient(#40a0e3, #d158e2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-weight: 700;
}
.outer-box-frame {
  width: 100%;
  position: relative;
}

svg {
  width: 100%;
  height: 100%;
  visibility: hidden;
}

.ell,
#ai {
  fill: none;
}

/* .loader {
  width: 100px;
  height: 100px;
  perspective: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
}
.loader__tile {
  display: block;
  float: left;
  width: 33.33%;
  height: 33.33%;
  animation-name: flip;
  transform-style: preserve-3d;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  transform: rotateY(0deg);
  z-index: 0;
}
.loader__tile__1 {
  background-color: #fe7702;
  animation-delay: 0.1s * 1;
}
.loader__tile__2 {
  background-color: #d7532d;
  animation-delay: 0.1s * 2;
}
.loader__tile__3 {
  background-color: #fe7702;
  animation-delay: 0.1s * 3;
}
.loader__tile__4 {
  background-color: #f8a8ff;
  animation-delay: 0.1s * 4;
}
.loader__tile__5 {
  background-color: #b39a3b;
  animation-delay: 0.1s * 5;
}
.loader__tile__6 {
  background-color: #f8a8ff;
  animation-delay: 0.1s * 6;
}
.loader__tile__7 {
  background-color: #ece5be;
  animation-delay: 0.1s * 7;
}
.loader__tile__8 {
  background-color: #fe7702;
  animation-delay: 0.1s * 8;
}
.loader__tile__9 {
  background-color: #eff2e4;
  animation-delay: 0.1s * 9;
}
@keyframes flip {
  0% {
    transform: rotateY(0deg);
 }
  11% {
    transform: rotateY(180deg);
 }
} */

.loader {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  perspective: 800px;
}

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.inner.one {
  left: 0%;
  top: 0%;
  animation: rotate-one 1s linear infinite;
  border-bottom: 3px solid #efeffa;
}

.inner.two {
  right: 0%;
  top: 0%;
  animation: rotate-two 1s linear infinite;
  border-right: 3px solid #efeffa;
}

.inner.three {
  right: 0%;
  bottom: 0%;
  animation: rotate-three 1s linear infinite;
  border-top: 3px solid #efeffa;
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
.frame-ai-image .frame-center-image-area img {
  background: #000;
}
/* athlenfts page css end */
@media (min-width: 1700px) {
  .App .container {
    max-width: 1600px;
  }
  .preview-area .name-input input {
    left: 26%;
    bottom: 0%;
    width: 200px;
  }
}

@media (max-width: 1699px) {
  .athe-banner-area {
    padding: 95px 0;
    margin-top: 73px;
  }
  .searchbar_header input {
    min-width: 276px;
  }

  .input-image {
    width: 250px;
    height: 250px;
  }

  .market_banner_section #left-tabs-example-tabpane-first {
    margin-top: 40px;
  }
}

@media (max-width: 1399px) {
  .home_banner_right {
    background-size: contain;
    background-repeat: no-repeat;
  }

  .searchbar_header input {
    min-width: 233px;
  }

  .slide-2 .mainimg {
    height: 310px;
  }

  header .nav-link {
    font-size: 15px;
    margin: 0 15px;
  }

  .navbar-brand img {
    max-width: 150px;
  }
}
@media (max-width: 1366px) {
  .preview-area .number-input.one-digit-preview {
    right: 31px;
  }
}
@media (max-width: 1440px) {
  .preview-area .number-input {
    right: 19px;
    top: 23px;
  }
  .preview-area .number-input.two-digit-preview {
    right: 24px;
  }
 .preview-area .center-frame-area {
    width: 421px;
  }
  .preview-area .name-input {
    bottom: 20px;
    left: 95px;
  }
}
@media (max-width: 1280px) {
  .preview-area .number-input {
    right: 14px;
    top: 18px;
    }
    .preview-area .number-input.two-digit-preview {
      right: 19px;
    }
    .preview-area .number-input.one-digit-preview {
      right: 26px !important;
  }
 .preview-area .center-frame-area {
    width: 366px;
  }
  .preview-area .name-input {
    bottom: 18px;
    left: 82px;
  }
}
@media (max-width: 1140px) {
  .frame-number {
    font-size: 17px;
  }
  .preview-area .number-input.one-digit-preview {
    right: 23px;
  }
 .preview-area .center-frame-area {
    width: 316px;
  }
  .preview-area .name-input {
    bottom: 11px;
    left: 70px;
  }
}
@media (max-width: 1400px) {
  .ath-content h2,
  .get-in-area h2,
  .unique-content h2 {
    font-size: 40px;
  }
  .preview-area .number-input.one-digit-preview {
    right: 32px;
  }
  span.navbar-brand img {
    cursor: pointer;
    max-width: 160px !important;
  }

  header .container {
    max-width: 100%;
  }

  .collection_img img {
    height: 245px;
  }

  .tab_box_home span {
    right: 7px;
  }

  .in-stock {
    padding: 6px 13px;
    font-size: 13px;
  }
}

@media (max-width: 1199px) {
  .preview-area .number-input.one-digit-preview {
    right: 23px !important;
}
  .generate-ai-content h6 span {
    font-size: 22px;
    padding: 8px 12px;
  }
  .athe-banner-area {
    padding: 50px 0;
  }
  .home_header .navbar-toggler {
    padding: 2px 5px;
  }
  .wallet-bt-connect.dropdown {
    display: none;
  }
  .toggle-drop-right .wallet-bt-connect.dropdown {
    display: block;
  }
  .toggle-drop-right button#dropdown-basic {
    /* width: auto; */
    position: relative;
    left: 60px;
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
    top: -2px;
  }
  .toggle-drop-right {
    display: flex;
    align-items: center;
  }

  .our-team h2 {
    font-size: 42px;
  }

  .event-dates h4 {
    font-size: 24px;
  }
  .event-dates h3 {
    font-size: 30px;
  }

  .market_categories .nav-item .nav-link {
    min-width: 160px !important;
  }

  .home-banner-left h1 {
    font-size: 50px;
  }

  .navbar-nav.navbar-nav-scroll {
    margin-bottom: 16px !important;
  }

  #dropdown-basic {
    padding: 14px 7px;
    font-size: 13px;
  }

  .searchbar_header input {
    min-width: 192px;
  }

  header .startselling_btn {
    padding: 14px 7px;
    font-size: 13px;
  }

  .collecting-bt {
    font-size: 16px;
  }

  .market-left h1 {
    font-size: 50px;
  }

  .tab_box_home {
    padding: 10px;
  }

  .explore-box h1 {
    margin-bottom: 23px;
  }

  .home_banner_right {
    padding: 35px 0;
  }

  .hot-heading {
    font-size: 50px;
  }

  .home-banner-left p {
    font-size: 18px;
  }

  .collection_img img {
    height: 180px;
  }

  .App .container {
    max-width: 1100px;
    padding: 0 20px;
  }

  .slide-2 .mainimg {
    height: 290px;
  }

  .tenis-box img {
    max-width: 50px;
  }

  .tenis-box {
    padding: 9px 8px;
  }

  .like-view h6 {
    font-size: 12px;
  }

  .instant-sale-prize {
    width: 78%;
  }

  .name-description-filed {
    padding: 30px 12px;
  }

  .seller-box h2 {
    font-size: 50px;
  }

  #google_translate_element {
    text-align: center;
    margin-top: 11px;
  }

  .navbar-nav .dropdown,
  .navbar-nav .dropdown-menu.show {
    text-align: center;
  }

  header .startselling_btn {
    margin: 10px auto !important;
    width: fit-content;
    width: 180px;
  }

  #dropdown-basic {
    width: 180px;
    height: 50px;
  }

  #google_translate_element select {
    margin-left: 0;
  }

  header .nav-link {
    margin: 12px 15px 0;
  }
}

@media (max-width: 991px) {
  .process-image {
    min-height: unset;
    display: unset;
  }
  .ai-mint-nft button {
    width: 140px;
    height: 42px;
    font-size: 16px;
  }
  .generate-ai-content {
    text-align: center;
    margin-top: 50px;
  }
  .frame-options-area {
    padding: 60px 0;
  }
  .ai-generator-area h2 {
    font-size: 55px;
  }
  .main_h2 {
    font-size: 27px !important;
  }
  /* .preview-desktop-view {
    display: none;
  } */
  .signle-detail-data-area {
    margin-bottom: 15px;
  }
  .touch_form {
    padding: 37px 30px;
  }
  .nil-providers {
    padding: 50px 0;
  }
  .circle_row .col-md-6:last-child {
    padding: 0 0px 0 50px !important;
  }
  .unique-content h2 br {
    display: none;
  }

  .member-one {
    padding: 10px;
  }

  .discord-section-content h2 {
    font-size: 40px !important;
  }

  .discord-section-content p {
    font-size: 18px !important;
  }

  .discord-section-bg {
    padding: 10px;
  }

  .circle_row .col-md-6 {
    position: relative;
    padding: 0 50px 0 11px !important;
    z-index: 2;
  }

  .preview-image img {
    width: 100%;
    height: 250px;
    object-fit: contain;
  }

  .home_banner_section {
    height: auto !important;
  }

  .about-page h1 {
    font-size: 60px;
  }

  .creater-collection-area {
    display: block;
  }

  .sign-area {
    height: 237px;
    margin-bottom: 20px;
  }

  #google_translate_element select {
    margin: 0;
  }

  .App .container {
    max-width: 720px;
  }

  .market_banner_section {
    margin-top: 74px;
  }

  .home_banner_right {
    /* height: 85vh; */
  }

  .attri-name {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 50px;
    line-height: 1.8em;
  }

  .more-div {
    margin-bottom: 12px;
  }

  .explore-box {
    padding: 30px 0 0;
  }

  .leftfooter {
    padding-right: 20px;
  }

  footer h3 {
    margin-bottom: 19px;
    width: 100%;
  }

  .category_row.row {
    margin-top: 0px;
  }

  .footer_head h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: -0.3px;
    color: #fff;
  }

  .auction-right-side {
    padding: 0 20px;
  }

  .connect-wallet-bt {
    margin: 0 auto;
  }

  .preview-image {
    width: 100%;
    height: auto;
  }

  .preview-area {
    height: auto;
  }

  .event-dates {
    padding: 8px 15px;
  }
  .event-dates h4 {
    font-size: 18px;
  }
  .event-dates h3 {
    font-size: 24px;
  }
  .preview-area {
    width: 52%;
  }
}

@media (max-width: 767px) {

  .preview-area .number-input.one-digit-preview {
    right: 23px;
    top: 17px;
  }

  .choose-ai-tab-area ul li {
    padding: 18px 20px;
  }
  .choose-ai-tab-area ul li img {
    width: 35px;
  }
  .ai-generator-area h2 {
    font-size: 40px;
  }
  .ai-generator-area h2 {
    font-size: 55px;
  }
  .frame-options {
    text-align: center;
    margin-bottom: 40px;
  }
  .home-banner-left .collecting-bt {
    margin: 0 auto !important;
  }
  .event-dates {
    display: block;
  }

  .boder-box {
    margin-top: 20px;
  }

  .event-dates h4 {
    margin-bottom: 5px;
  }
  .MuiOutlinedInput-root.MuiInputBase-root {
    width: 100% !important;
  }

  .first_section_main img {
    max-width: 450px;
    margin-bottom: 10px;
    width: 100%;
  }

  .member-one {
    /* height: auto; */
    padding: 10px;
    /* max-width: 70%; */
    margin: 0 auto 50px;
  }

  .member-info p {
    color: #ffffffad;
    min-height: 60px;
  }

  .blockchain_row div {
    width: 100% !important;
  }

  .discord-section-content p {
    line-height: 23px !important;
    margin-bottom: 40px !important;
  }

  .about-page h3 {
    font-size: 30px;
    margin: 0 0 20px 0;
  }

  .about-page h2 {
    font-size: 36px;
  }

  .about-page h1 {
    font-size: 48px;
  }

  .founding-div p {
    font-size: 16px;
    margin: 23px 30px;
  }

  .new-banner-area {
    margin-top: 75px !important;
  }

  .navbar-brand img {
    max-width: 150px !important;
  }

  .air-max h2 {
    font-size: 20px;
  }

  .details-cover img {
    height: 280px;
  }

  .bid_auction p {
    font-size: 14px;
  }

  .home-banner-left h1 {
    font-size: 40px;
  }

  .bid_auction h1 {
    font-size: 20px;
  }

  .hot-heading {
    font-size: 40px;
  }

  .works-area-box h2 {
    font-size: 40px;
  }

  .shade-box {
    left: 0;
  }
  /* 
  .crete-collect-new {
    flex-direction: column-reverse;
  } */

  /* .preview-area {
    margin: 20px;
  } */

  .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
  }

  .home-banner-left h1 {
    text-align: center;
  }

  .home-banner-left {
    text-align: center;
  }

  .rightsdefooter {
    margin-top: 30px;
  }

  .explore-box .text-center.col-md-5 {
    max-width: 500px;
    margin: 0 auto;
  }

  .down-scroll {
    margin-top: 80px;
  }

  .leftfooter {
    margin-bottom: 20px;
  }

  .rightsdefoote {
    text-align: left;
  }

  .market_banner_section.h-100 {
    padding: 30px 0;
  }

  .ath-content h2,
  .unique-content h2,
  .get-in-area h2 {
    font-size: 35px;
  }
  .new-header .navbar-collapse {
    margin-top: 20px;
  }
  .new-header .navbar-collapse {
    margin-top: 20px;
  }
  .new-header .nav-link:first-child {
    border-top: 1px solid #ffffff26;
  }
  .ath-content h2,
  .unique-content h2,
  .get-in-area h2 {
    font-size: 30px !important;
  }
  .get-in-area p br {
    display: none;
  }
  .new-header .navbar {
    padding: 15px 5px;
  }
  .get-in-section {
    padding: 52px 0 0;
  }
  .new-header .nav-link {
    color: #fff;
    margin: 0px 8px;
    font-size: 14px;
    border-bottom: 1px solid #ffffff26;
    padding: 10px 0;
  }

  .btn-get-in {
    font-size: 14px !important;
  }
  .get-in-img img {
    width: 100%;
    max-width: 60%;
  }
  .preview-area {
    width: 71%;
  }
}

@media (max-width: 576px) {
  .generate-ai-area {
    padding: 70px 0 0;
  }
  .ai-content-area-inner .input-group input {
    /* border-right: none; */
    text-overflow: ellipsis;
    width: 0px;
    white-space: nowrap;
    overflow: hidden !important;
  }
  .ai-generator-area h2 {
    font-size: 28px;
  }
  .ai-tab-content-area {
    padding-top: 30px;
  }
  h5.ai-to-heading {
    font-size: 15px;
    margin-bottom: 5px;
  }
  .choose-ai-tab-area ul li img {
    width: 22px;
  }
  .choose-ai-tab-area ul li {
    font-size: 14px;
  }
  .choose-ai-tab-area ul li img {
    width: 30px;
    margin-right: 4px;
  }
  .home_header span.navbar-brand {
    margin-right: 0;
  }
  .user-icon {
    font-size: 24px !important;
  }
  .navbar-light .navbar-toggler-icon {
    font-size: 16px;
  }
  .toggle-drop-right button#dropdown-basic {
    left: 70px;
  }
  h2#swal2-title {
    font-size: 22px;
  }
  .our-team h2 {
    font-size: 30px;
  }
  .discord-section-content h2 {
    font-size: 35px !important;
  }
  .discord-section-content a {
    padding: 10px 40px;
  }
  .owner-details p {
    max-width: inherit;
  }
  .ath-content h2,
  .unique-content h2,
  .get-in-area h2 {
    font-size: 28px !important;
  }

  .owner-details {
    margin-right: 0;
    width: auto;
  }

  .air-price-new {
    font-size: 16px;
  }

  .shades-box {
    z-index: -1;
  }

  .owner-list {
    display: block;
  }

  .owner-list a {
    float: left;
  }

  .margin-ios .goog-te-combo {
    margin-left: 10px !important;
  }

  .air-max h2 {
    font-size: 36px;
  }

  .social-icon li {
    display: inline-block;
    margin: 13px 22px;
  }

  .featured-area ul {
    display: block;
  }

  .home-banner-left h1 {
    font-size: 42px;
  }

  .hot-heading {
    font-size: 42px;
  }

  .eth-box h5 {
    font-size: 20px;
  }

  .seller-box h2 {
    font-size: 36px;
  }

  .air-max h4 {
    font-size: 20px;
  }

  .explore-box .col-sm-3 {
    width: 50%;
  }

  .home-banner-left {
    padding: 70px 10px 0;
  }

  .home_banner_right {
    /* height: 36vh; */
  }

  .home-banner-left h1 {
    font-size: 30px;
  }

  .hot-heading {
    font-size: 30px;
  }

  .market-left h1 {
    font-size: 36px;
  }

  .collection-head-new {
    padding: 7px 9px;
    font-size: 14px;
  }

  .works-area-box h2 {
    font-size: 30px;
  }

  .rightsdefooter {
    margin-top: 0;
  }

  footer {
    padding: 30px 0;
  }

  .end-reserve-bid-box {
    margin: 15px 23px 0 8px;
  }

  .category_row.row .nav-item {
    width: 50%;
  }

  .market_categories .nav-item .nav-link {
    min-width: 160px;
    margin: 5px;
  }

  .search_marketplace {
    width: 100%;
  }

  .category_row.row {
    margin-top: 30px;
  }
  .ok-bt-install-metamask-modal {
    margin-bottom: 12px;
  }
  .discord-section-content {
    width: 90% !important;
  }

  .member-one {
    max-width: 100%;
  }
  .air-price-new b {
    display: inline-block;
  }
  .preview-area {
    width: 100%;
  }
  .preview-area .number-input input {
    right: 7% !important;
    top: 1% !important;
  }
  .preview-area .name-input input {
    bottom: 1px !important;
    width: 52% !important;
  }
  .home-banner-section button.btn.btn-unset.generate-nft-btn, .home-banner-section button.btn.btn-unset {
    width: 70% !important;
  }
}

@media (max-width: 420px) {
  button.swal2-cancel.swal2-styled {
    padding: 5px 18px;
  }
  .home-banner-section button.btn.btn-unset.generate-nft-btn, .home-banner-section button.btn.btn-unset{
    width: 100% !important;
  }
  .preview-area .number-input input {
    right: 6% !important;
    top: 0% !important;
  }
  .swal2-popup {
    width: 17em !important;
    /* height: 280px; */
  }
  div#swal2-html-container {
    font-size: 14px;
    margin-top: 0;
  }
  .swal2-styled.swal2-confirm {
    background-color: #fe7702 !important;
    padding: 5px 18px;
  }
  h2#swal2-title {
    font-size: 18px;
  }
  /* button#uncontrolled-tab-example-tab-sale {
    margin-bottom: 15px;
  } */
  .touch_section {
    padding: 0;
  }
  .buy-sell-created .rightside_border {
    margin-top: 14px;
  }
  .buy-sell-created {
    flex-wrap: wrap;
  }
  .collec-heading {
    font-size: 18px;
  }

  .about-page p {
    font-size: 14px;
    line-height: 30px;
  }
  .service_section1 {
    padding: 80px 0 0 0 !important;
  }
  .nft-collection-box li {
    font-size: 14px;
    width: auto !important;
    padding: 8px 11px !important;
  }

  /* .share-bt p {
    padding: 3px 18px !important;
  } */

  .discord-section-content h2 {
    font-size: 25px !important;
  }

  .discord-section-content p {
    font-size: 16px !important;
    line-height: 28px !important;
  }

  .featured-area {
    padding: 40px 0 0;
  }

  .home-banner-left {
    padding: 30px 10px 0;
  }

  .works-area-box {
    padding: 44px 0 50px;
  }

  #hot-collection {
    padding: 0;
  }

  .market_categories .nav-item .nav-link {
    min-width: 100px;
  }

  .market_categories .nav-item .nav-link {
    padding: 12px;
  }

  .sign-ar {
    padding: 20px 20px 20px 20px;
  }
}

@import url(https://fonts.cdnfonts.com/css/circular-std);

body,
html {
  font-family: "Circular Std", sans-serif !important;
}

:focus {
  box-shadow: none !important;
  outline: 0 !important;
}

fieldset,
img {
  border: 0 none;
}

input[type="submit"],
select {
  -webkit-appearance: none;
}

html {
  scroll-behavior: smooth;
}

.alice-carousel__dots {
  display: none !important;
}

.alice-carousel__next-btn-item {
  background: #fe7702;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 30px;
  color: #fff !important;
  position: absolute;
  right: 0;
  top: 130px;
}

.alice-carousel__prev-btn-item {
  background: #fe7702;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 30px;
  color: #fff !important;
  position: absolute;
  left: 0;
  top: 130px;
  text-align: center;
}

img {
  border: none;
  height: auto;
  max-width: 100%;
  outline: 0;
  vertical-align: middle;
}

iframe {
  vertical-align: middle;
}

a:hover {
  text-decoration: none;
  color: #6bcbfc;
}

a,
abbr,
acronym,
address,
article,
aside,
b,
bdo,
big,
blockquote,
body,
br,
button,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
img,
ins,
kbd,
legend,
li,
map,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
ul,
var {
  border: medium none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none !important;
}

input[type="submit"],
select {
  -webkit-appearance: none;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}

header {
  padding: 9px 0;
  position: fixed;
  top: 0;
  z-index: 1024;
  width: 100%;
  background: rgb(0 0 0);
}

.img_position_blue {
  position: absolute;
  right: 0;
  z-index: 1;
}

.black-head {
  background: black;
}

.searchbar_header input {
  background: #f6f7fa !important;
  border-radius: 16px;
  border: 0;
  height: 56px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #081946;
  min-width: 550px;
}

.searchbar_header {
  position: relative;
  margin-left: 20px;
}

.bg-white {
  box-shadow: 2px 2px 2px #e1e1e138;
}

.searchbar_header button {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translate(0, -50%);
}
.bid-modal-bg .text-right {
  margin-bottom: 0;
}
.collection-correct-image {
  margin-top: -22px;
}
.more-games:hover {
  color: #fe7702;
}

.correct-image {
  text-align: center;
  /* margin-top: -39px; */
}
.modal-content {
  width: 90% !important;
  margin: 0 auto;
}
header .startselling_btn {
  background: #fe7702 !important;
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #fff !important;
  height: 52px;
}

.modal-cross-icon-new {
  font-size: 22px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.error {
  color: red;
}

button.btn.btn-unset {
  background: #fe7702 ;
  color: #fff !important;
  border-radius: 10px;
  width: fit-content;
  padding: 14px;
  border: unset !important;
}

.btm_details.market-btm-details {
  position: relative;
  top: inherit !important;
  left: inherit !important;
}



.btm_details.market-btm-details h6.bid-text-two {
  padding-left: 0 !important;
}

button.bg-transparent.border-0.subscribe-bt.btn.btn-unset {
  background: #fe7702 !important;
  height: 50px;
  width: auto;
  color: #fff;
  font-size: 18px;
  border-radius: 10px 10px 0 0;
}

.navbar-nav .dropdown-item .startselling_btn {
  text-align: left;
  border-radius: 0 0 10px 10px;
  width: 100%;
  border-top: 1px solid #ffffff3d;
  margin: 0;
}

.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.navbar-nav .dropdown-menu.show {
  top: 54px;
  min-width: 100px;
  left: -36px;
  padding: 0;
}

.user-icon {
  font-size: 30px;
}

.user-profile-pic {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
}

.profile-bt-new {
  display: flex !important;
  align-items: center;
}

#google_translate_element {
  position: relative;
}

#google_translate_element select option {
  color: #000 !important;
}

.skiptranslate.goog-te-gadget {
  background: #fe7702;
  border-radius: 10px;
  width: 139px;
  height: 50px !important;
  margin-left: 10px;
}

#google_translate_element select {
  background: none;
  border: none !important;
  color: #fff;
  border-radius: 10px;
  height: 42px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

#google_translate_element i {
  position: absolute;
  right: 10px;
  top: 16px;
  color: #fff;
  font-size: 12px;
}

.skiptranslate.goog-te-gadget {
  height: 55px;
  overflow: hidden;
}

header .nav-link {
  font-style: normal;
  font-weight: 500;
  padding: 0 0 6px 0 !important;
  font-size: 14px;
  border-radius: 0;
  margin: 0 20px;
  line-height: 20px;
  color: rgb(255 255 255) !important;
  border-bottom: 4px solid #fff;
}

.modal-email span {
  color: #fe7702;
}
.modal-email {
  margin: 32px 20px;
}

header .nav-link:hover {
  color: #fe7702 !important;
}

header .active.nav-link {
  color: #fe7702 !important;
  border-bottom: 4px solid #fe7702;
}

header .nav-link {
  border-bottom: none;
}

header .login_header .logintext {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #081946;
}

.down-scroll {
  margin-top: 130px;
}

.searchbar_header input::-webkit-input-placeholder {
  color: #4f0c75;
}

.searchbar_header input::-moz-placeholder {
  color: #4f0c75;
}

.searchbar_header input:-ms-input-placeholder {
  color: #4f0c75;
}

.searchbar_header input:-moz-placeholder {
  color: #4f0c75;
}

.banner_section {
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: center;
  position: relative;
  padding: 135px 0;
  overflow: hidden;
  margin-top: 93px;
}

.banner_section .gobackbtn {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 99px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fff;
}

.banner_section h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  color: #fff;
  margin: 35px 0;
}

.banner_section p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #fff;
}

.banner_section h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-decoration-line: underline;
  color: #fe7702;
  cursor: pointer;
}

.banner_section .connectbtn {
  background: #fe7702;
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: #f8f6f6;
  padding: 17px 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 270px;
  margin: 40px 0;
}

.banner_section .connectbtn img {
  transform: rotate(180deg);
}

.images_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner_section p.dulltext {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #ffffffa6;
  margin-top: 25px;
}

footer {
  padding: 30px 0;
  background-color: #000;
}

.footer_head {
  display: flex;
  justify-content: end;
  align-items: center;
}

.footer_head h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  letter-spacing: -0.3px;
  color: #fff;
}

.footerline {
  background: #fff;
  opacity: 0.14;
  height: 1px;
  width: 100%;
}

footer h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  letter-spacing: -0.3px;
  color: #fff;
  margin-bottom: 35px;
  width: 50%;
}

button.buy-now-bt.buy-now-bt-copy {
  margin: 0;
}

.bid-amount h6 {
  color: #ffffffb8;
}

.auction-form input.bid-now-input {
  border-radius: 80px !important;
}

.searchbar_header.footer input {
  border-radius: 0;
  background-color: transparent !important;
  border-bottom: 1px solid #ffffff1f;
  padding: 0;
  font-weight: 300;
  color: #fff;
  height: 52px;
  padding: 0 126px 0 10px;
}

.featured-area {
  text-align: center;
  padding: 40px 0;
}

.searchbar_header button.btn-unset {
  margin: 0px 3px !important;
}

.searchbar_header.footer button {
  right: 0;
}

.searchbar_header.footer input::-webkit-input-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer input::-moz-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer input:-ms-input-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer input:-moz-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer {
  margin-left: 0;
}

.rightsdefooter h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: #ffd2dd;
  margin-bottom: 15px;
}

.rightsdefooter p {
  font-style: normal;
  font-weight: 200;
  font-size: 15px;
  cursor: pointer;
  color: #fff;
}

.leftfooter {
  padding-right: 180px;
}

.collection_section {
  padding: 90px 0;
}

.preview-image video.video-container.video-container-overlay {
  width: 100%;
  height: -webkit-fill-available;
}

.collection_section h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  color: #000;
}

.collection_section h5 {
  font-style: normal;
  font-weight: 200;
  font-size: 21px;
  color: #6b6b6b;
}

.time_row {
  background-color: #4f0c75;
  border-radius: 100px;
  padding: 7px 10px;
  display: inline-block;
  margin-top: 10px;
}

.time_row span {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  padding: 0 4px;
}

.collection_box h3 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin-top: 12px;
}

.userpic_name {
  color: #000;
  font-size: 16px;
  font-weight: 200;
}

.userpic_name {
  position: relative;
  padding-left: 46px;
  padding-top: 2px;
  margin-top: 12px;
}

.userpic_name img {
  width: 33px;
  position: absolute;
  left: 0;
  top: 0;
  height: 33px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 100%;
}

.collection_box {
  border: 1px solid #7070704d;
  border-radius: 17px;
  padding: 17px;
  box-shadow: 0 0 13px -1px #00000024;
}

.collection_img {
  margin-bottom: 5px;
}

.collection_img img {
  max-width: 100%;
  height: 300px;
  width: 100%;
  border-radius: 14px;
  object-fit: cover;
  overflow: hidden;
}

.collectible-upload-image h6 {
  color: #4f0c75;
  font-size: 18px;
  margin: 0;
}

.collectible-upload-image {
  background: #fff;
  box-shadow: 0 0 15px rgb(17 47 73 / 15%);
  border-radius: 15px;
  cursor: pointer;
  padding: 1px 0;
}

.collectible-box {
  position: relative;
  margin: 0 20px;
}

.uplage-image-collect {
  position: absolute;
  height: 290px;
  opacity: 0;
}

.name-description-filed {
  background: #fcfaff;
  padding: 30px 0;
  margin-top: 30px;
}

.collectible-bg {
  /* background: #fcfaff; */
  padding: 100px 0;
  border: 2px dashed #d7d7d7;
  margin: 20px;
  border-radius: 15px;
}

.name-description-filed input {
  border-radius: 10px;
  height: 48px;
}

.create-bt {
  background: #fe7702;
  color: #fff;
  width: 130px;
  height: 37px;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 20px;
}

.preview-area {
  background: #fff;
  box-shadow: 0 0 15px rgb(17 47 73 / 15%);
  border-radius: 15px;
  padding: 20px;
  position: relative;
  height: auto;
  min-height: 230px;
}

.preview-text {
  color: #00000057;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.date-box i {
  position: absolute;
  /* z-index: 1; */
  right: 15px;
  top: 9px;
  background: white;
  padding: 5px;
  color: #5e5e5e;
}

.date-box {
  position: relative;
}

.tab-bid-box {
  margin-top: 22px;
}

.width-box {
  width: 170px !important;
  margin: 0 !important;
}

.put-sale-box {
  margin: 15px 0 !important;
}

.preview-image {
  width: 100%;
  height: 350px;
  object-fit: contain;
  background: #f3f3f3;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.preview-area p {
  word-break: break-word;
  width: 100%;
  text-align: left;
}
.preview-area .description-text {
  word-break: break-word;
  width: 100%;
  text-align: left;
}

.description-toggle {
  margin-left: auto;
}

.form-switch .form-check-input {
  width: 62px !important;
  height: 25px !important;
}

.form-check-input:checked {
  background-color: #4f0c75 !important;
  border-color: #4f0c75 !important;
}

.auction-toggle.area label {
  margin-bottom: 0;
}

.auction-toggle.area {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
}
button.react-share__ShareButton.Demo__some-network__share-button {
  margin: 0 2px;
}
.instant-sale-prize {
  margin-bottom: 10px;
}
.home_banner_right {
  background-size: contain;
  width: 100%;
  /* height: 100vh; */
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  padding: 135px 0;
}

.tyler-hero {
  color: #4f0c75;
  background: #4f0c7521;
  padding: 8px 20px;
  border-radius: 50px;
  font-size: 16px;
}

.profile-image {
  text-align: center;
}

.home-banner-left h1 {
  font-size: 64px;
  color: #fff;
  font-weight: 700;
  margin-top: 15px;
}

.home-banner-left p {
  font-size: 20px;
  color: #bfbfbf;
  margin-bottom: 20px !important;
}

.nft-view-dtails-bt {
  background: #fe7702;
  display: block;
  width: 190px;
  border-radius: 10px;
  text-align: center;
  color: #fff !important;
  padding: 8px 0;
}

.nft-market-checkbox li {
  display: inline-block;
  margin-right: 25px;
}

.nft-market-checkbox {
  padding: 0;
  margin-bottom: 41px;
}

.collecting-bt {
  position: relative;
  background-color: #fe7702;
  padding: 20px 40px;
  border-radius: 10px;
  font-size: 24px;
  color: #fff !important;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  cursor: pointer;
  display: block;
  margin-bottom: 20px;
}

.collecting-bt i {
  margin-left: 15px;
}

.customer-box li {
  margin-left: -22px;
  display: inline-block;
}

.customer-box {
  padding: 0;
}

/* toggle css */
.switch {
  position: relative;
  display: inline-block;
  width: 62px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

.slider.round {
  border-radius: 34px;
}

input:checked + .slider {
  background-color: #4f0c75 !important;
}

.slider.round:before {
  border-radius: 50%;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(37px);
}

.customer-box li:last-child {
  margin-left: 20px;
}

.customer-box li:last-child {
  margin-left: 23px;
}

.customer-box {
  margin: 71px 0 50px 20px;
  display: flex;
  align-items: center;
}

#dropdown-basic {
  background: none;
  border: none;
}

.dropdown-menu.show {
  border: none;
  padding-left: 0;
  background: 0 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background: 0 0 !important;
}

a.dropdown-item {
  padding: 0;
}

.flex-row.flex-wrap.market_categories.nav.nav-pills {
  max-width: 50%;
}

.explore-heading {
  text-align: center;
  color: #fff;
  font-size: 48px;
  padding: 60px 0;
}

.explore-heading span {
  color: #fe7702;
}

/* .no-data {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

#hot-collection {
  /* background-image: url(../images/explore.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  color: #fff;
  text-align: center;
  position: relative;
  padding: 10px 0 40px;
}

.list-tab-box.tab-content {
  width: 100%;
  padding: 20px 0;
}

.more-div {
  margin-bottom: 60px;
}

.name-dec {
  cursor: pointer;
}

.hot-heading {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 50px;
}

.collection-head-new {
  background: #fff;
  color: #4f0c75;
  border-radius: 30px;
  padding: 7px 14px;
  line-height: 97px;
}

#hot-collection .tab-pane {
  margin: 0 10px;
}

.bt-div {
  margin-top: 10px;
}

.tab_box_home {
  background: linear-gradient(180deg, #fafaff 0, #cfd4fe 100%);
  border-radius: 16px;
  /* max-width: 222px; */
  padding: 22px;
  margin: 0 5px 10px;
  position: relative;
  cursor: pointer;
  text-align: left;
}

.tab_box_home h4 {
  font-style: normal;
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 14px;
  color: #12041e;
  white-space: nowrap;
}

.explore-box .col-md-3 {
  padding-right: 0;
}

.tab_box_home.active {
  background: #fe7702;
}

.tab_box_home.active h4 {
  color: #fff;
}

.explore-box h1 {
  margin-bottom: 70px;
}

.tab_box_home.active h5 {
  color: #fff;
  opacity: 0.5;
}

.tab_box_home h5 {
  font-style: normal;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
  color: #c2bfe8;
  height: 17px;
}

.tab_box_home span {
  position: absolute;
  top: 50%;
  right: 20px;
  cursor: pointer;
  transform: translate(0, -50%);
}

img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-30px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-30px);
  }
}

.market_banner_section {
  background-color: #000;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  color: #fff;
  text-align: center;
  position: relative;
  padding-bottom: 60px;
}

.market-left h1 {
  font-size: 90px;
  background: -webkit-linear-gradient(45deg, #f8a8ff, #6155ed);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  font-weight: 800;
}

#nft-feature {
  /* background-image: url(../images/feature.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  color: #fff;
  text-align: center;
  position: relative;
  padding-bottom: 60px;
}

.searchbar_item video {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 10px;
}

.explore-box {
  /* background-image: url(../images/explore_bg.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  color: #fff;
  position: relative;
  padding: 60px 0;
}

.more-games {
  color: #fff;
  font-size: 17px;
  line-height: 60px;
}

#nft-feature h3 {
  color: #fff;
  margin-top: 20px;
}

#nft-feature h3 {
  color: #fff;
}

.step-two {
  padding-top: 40px;
}

.step-three {
  padding-top: 80px;
}

#nft-feature p {
  color: #ffffff70;
  padding: 0 120px;
}

.contact-box-area {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  padding: 60px 0;
}

.contact-left-side h3 {
  color: #fff;
  font-size: 40px;
  line-height: 63px;
  padding: 40px 0 0 70px;
}

.contact-left-side {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

.contact-right-side input {
  background: 0 0 !important;
  border-bottom: 1px solid #ffffff8c !important;
  border: none;
  border-radius: 0;
  color: #fff !important;
  padding-left: 0;
  padding-right: 0;
  margin-top: 50px;
}

.contact-right-side input::placeholder {
  color: #fff !important;
}

.contact-form .btn {
  display: flex;
  margin: 14px auto;
}

.contact-right-side {
  display: flex;
  margin: 25px auto;
}

.contact-form {
  background: #ffffff12;
  border-radius: 20px;
  border: 2px solid #ffffff14;
  padding: 10px 80px 10px 100px;
  position: absolute;
  bottom: 0;
}

.contact-filled-box .row {
  position: relative;
}

.choose-heading {
  font-size: 20px;
}

.single-multiple-box .react-tabs__tab-list {
  display: inline-block;
}

.addresstext {
  position: relative;
}

.addresstext i {
  position: absolute;
  right: 0;
  top: 0;
}

.single-multiple-box .react-tabs__tab--selected {
  border-radius: 5px;
  border: none !important;
  background: #fe7702 !important;
  width: 120px;
  text-align: center;
  color: #fff !important;
}

.create-items li p {
  margin: 0;
}

.single-multiple-box .react-tabs__tab {
  border: none !important;
  padding: 6px 12px;
  cursor: pointer;
  background: #f0ebf6;
  color: #0000008c;
  width: 160px;
  border-radius: 5px;
  font-size: 20px;
  text-align: center;
}

.create-items h6 {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 16px;
  max-width: 100px;
  margin: 0 auto !important;
}

.items-area-box-two img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 2px;
}

.create-items h6 {
  margin: 0;
}

.searchbar_header input {
  padding: 0 68px 0 15px;
}

.create-items ul li {
  display: flex;
  justify-content: center !important;
  border: 1px solid #0000003d;
  border-radius: 5px;
  width: 110px;
  height: 110px;
  margin-right: 15px;
  cursor: pointer;
  padding: 12px 0;
  align-items: center;
}

.items-area-box,
.items-area-box-two {
  text-align: center;
}

.modal-profile-img img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid #fff;
  box-shadow: 1px 3px 7px #c7c7c7;
}

.create-items ul {
  display: flex;
}

.modal-profile-img {
  position: relative;
  height: 30px;
}

.cover-image-box img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

form.text-left {
  text-align: left;
  margin-top: 70px;
}

.profile-input-mod {
  width: 120px;
  height: 120px;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.img-input-mod {
  position: absolute;
  top: 0;
  height: 270px;
  width: 94%;
  opacity: 0;
}

.collection-create .modal-content {
  padding: 0;
}

.craete-bt {
  background: #fe7702;
  color: #fff;
  width: 164px;
  border-radius: 5px;
  height: 50px;
  margin-left: 16px;
}

.create-items ul {
  padding: 0;
  margin-bottom: 0px;
}
.craete-bt.submit-bt {
  width: fit-content !important;
  margin: 0 auto;
  padding: 0 20px;
}

.copies-input {
  max-width: 200px;
}

.single-multiple-box .react-tabs__tab-list {
  border-bottom: none !important;
  margin-bottom: 20px;
}

.buy-nft-tab .nft-modal-items {
  min-height: 85px;
}

.buy-nft-tab .react-tabs__tab--selected {
  border: none;
  color: #fff !important;
  background: #fe7702 !important;
}

.buy-nft-tab button.buy-now-bt.btn.btn-unset {
  width: 80px;
}

.share-modal-new input {
  height: 41px;
  width: 70%;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  padding: 0 8px;
  margin-right: 15px;
}

.buy-nft-tab .react-tabs__tab {
  border: none !important;
  background: #e7e7e7;
  border-radius: 5px;
  margin-right: 5px;
}

.buy-nft-tab ul {
  border: none;
}

.modalclose_icon i {
  margin-top: -2px;
  color: black;
  font-size: 22px;
}
.modalclose_icon {
  width: 30px;
  cursor: pointer;
  height: 30px;
  /* background: #fe7702; */
  color: #fff;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
}

.uploadimg {
  display: inline-block;
  position: relative;
}

.uploadimg input {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  opacity: 0;
}

.addresstext {
  padding-right: 38px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.slide-1 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.tenis-img-two {
  margin-left: -20px;
}

.like-view {
  text-align: center;
  color: #fff;
  margin: 0 15px;
}

.like-view i {
  color: #fc3a3a;
}

.in-stock {
  background: #00000085;
  padding: 10px 13px;
  color: #fff;
  margin-left: auto;
  border-radius: 30px;
}

.slide-percent {
  font-size: 70px;
  color: #fff;
  padding: 80px 30px 0;
  margin-bottom: 0;
}

.slide-progress {
  color: #fff;
  border: 2px solid #fe7702;
  display: inline-block;
  padding: 3px 11px;
  margin-left: 30px;
}

.echo {
  color: #fff;
  padding: 140px 30px 0;
}

.text-right {
  text-align: right;
}

p.text-right i {
  font-size: 22px;
  cursor: pointer;
}

.bid-text {
  padding-left: 30px;
  color: #fff;
  font-size: 14px;
}

.bid-text span {
  color: #fe7702;
}

.bid-text-two span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.btm_details .prices-nft {
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  color: #fff;
  margin-bottom: 10px !important;
}

.total-text {
  margin-bottom: 12px !important;
}

.bid-text-two {
  padding: 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.bid-text-two.bid-usd-box {
  padding-left: 8px;
}

.mainimg {
  height: 246px;
}

.tenis-box {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  position: absolute;
  top: 4px;
  left: 0;
}

.morefaq {
  background: #fe7702;
  color: #fff !important;
  padding: 9px 20px;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: fit-content;
}

li.alice-carousel__stage-item {
  padding: 0 10px;
}

.social-icon li {
  display: inline-block;
  margin: 0 22px;
}

.social-icon {
  padding: 0;
  text-align: center;
  border-radius: 10px;
}

.slide-margin {
  margin: 7px 5px;
}

.react-multi-carousel-track:first-child
  .react-multi-carousel-item.react-multi-carousel-item--active {
  display: none;
}

.errors {
  color: red;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  display: block;
}

.end-reserve-bid-box input {
  margin-bottom: 7px;
  width: 97%;
}

.end-reserve-bid-box {
  margin: 28px 23px 0;
  width: 90%;
}

.meta-mask-modal a b {
  text-decoration: underline;
}

.meta-mask-modal h3 {
  font-size: 24px;
  color: #000;
  font-weight: 600;
}

.meta-mask-modal .modal-content {
  border-radius: 10px;
  /* padding: 40px 0; */
  /* background-image: url(../images/modal-bg.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.ok-bt.btn {
  background: #fe7702 !important;
  border: none;
  width: 70px;
  height: 43px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 8px;
}

.meta-mask-modal a {
  color: #fe7702;
}

.ok-bt.btn::after {
  content: "";
  /* background-image: url(../images/line.png); */
  background-size: contain;
  position: absolute;
  height: 50px;
  width: 50px;
  right: -70px;
  top: -25px;
}

.sale-auction-tab {
  border: none !important;
}

.sale-auction-tab input {
  max-width: 240px !important;
  height: 46px;
  border-radius: 10px !important;
  border: none !important;
}

.sale-auction-tab li.nav-item button {
  width: 160px;
  border-radius: 0;
  color: #5a0075;
  border: 1px solid #fff;
  box-shadow: 2px 4px 1px #b04f19;
  background: #fff;
}
.tab-bid-box .date-box {
  margin-right: 10px;
}

.date-box .MuiFormControl-root {
  width: 100%;
}

.tab-one {
  max-width: 280px;
}

.tab-one input {
  height: 46px;
}

.tab-bid-box label {
  background: #fff;
  padding: 0 9px !important;
  border-radius: 5px;
}

.tab-bid-box input {
  width: 97%;
  height: 46px;
  border-radius: 10px;
}

.profile-box {
  background: #f3f3f3;
  margin: 86px 0 0 0;
  padding: 70px 0;
}

.profile-left-side img {
  width: 220px;
  height: 220px;
  border-radius: 15px;
  object-fit: cover;
}

.edint-pencil {
  background: #fe7702;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  font-size: 13px;
  position: absolute;
  right: -15px;
  top: -16px;
  cursor: pointer;
}

.profile-left-side {
  max-width: 400px;
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  margin: 0 auto;
  position: relative;
  width: 90%;
}

.profile-Right-side {
  text-align: left;
  padding: 0 0 0 18px;
}

.profile-Right-side i {
  font-size: 18px;
  margin-right: 8px;
}

.profile-Right-side .fa-address-book-o {
  margin-right: 0;
}

.modal-img-new {
  width: 160px;
  height: 160px;
  object-fit: cover;
}

.profile-Right-side h2 {
  font-size: 22px;
  color: #47036f;
  display: inline-flex;
  align-items: center;
}

.edit-profile-modal .modal-dialog {
  max-width: 410px;
  margin: 0 auto;
}

.modal-form-new button.ok-bt {
  background: #fe7702;
  padding: 7px 20px;
  border-radius: 5px;
  color: #fff;
  margin: 0 auto;
  display: block;
}

.modal-form-new {
  text-align: left;
  margin: 30px;
}

.profile-Right-side h4 {
  font-size: 15px;
  color: #fe7702;
}

.bid_auction .tab-content {
  margin: 0 13px 0 0 !important;
}

.tab-bid-box .row {
  --bs-gutter-x: 0;
}

.sale-auction-tab .nav-item.show .nav-link,
.sale-auction-tab .nav-link.active {
  color: #fff !important;
  background-color: #5a0075 !important;
  border-color: #5a0075 !important;
}

.auction-right-side {
  text-align: center;
}

.navbar-light .navbar-toggler {
  color: rgb(255 255 255 / 100%) !important;
  border-color: rgb(255 255 255 / 50%) !important;
}

.navbar-light .navbar-toggler-icon {
  filter: invert(1);
}

.save-bid-price {
  background: #5a0075;
  padding: 6px;
  margin-top: 20px !important;
  display: block;
  width: 90px !important;
  text-align: center;
  font-size: 20px;
  color: #fff !important;
}

.main-loader-emp {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #000000d1;
  z-index: 1056;
  top: 0;
  left: 0;
}

.loading {
  text-align: center;
  font-size: 18px;
  color: #fff;
  margin-top: 135px !important;
}
.name-dec {
  display: flex;
  align-items: center;
}

.name-dec p {
  margin-right: 5px;
  font-size: 18px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

/* .search_marketplace {
  width: 70%;
} */
form.searchbar_header {
  margin-right: 12px;
}
.shade-box {
  position: absolute;
  width: 500px;
  top: 0;
  left: 220px;
}

.market_categories .nav-item .nav-link {
  background: linear-gradient(180deg, #fafaff 0, #cfd4fe 100%);
  border-radius: 16px;
  min-width: 180px;
  padding: 22px;
  margin: 1px 10px 9px 0;
  position: relative;
  cursor: pointer;
  text-align: left;
  height: 83px;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

/* body {
  top: 0 !important;
} */

.MuiOutlinedInput-root.MuiInputBase-root {
  background: #fff;
  height: 47px;
  margin-bottom: 7px;
  width: 100%;
  border-radius: 10px;
  /* border: 1px solid #ced4da !important; */
}
.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root
  label {
  background: white;
  padding: 0 6px;
  color: #000;
}
.market_categories .nav-item .nav-link span {
  position: absolute;
  top: 50%;
  right: 20px;
  cursor: pointer;
  transform: translate(0, -50%);
}

.market_categories .nav-item .nav-link.active {
  background: #fe7702;
  border-radius: 16px;
}

.market_categories .nav-item .nav-link.active h4 {
  color: #fff;
}

.market_categories .nav-item .nav-link.active h5 {
  color: #fff;
  opacity: 0.5;
}

.market_categories .nav-item .nav-link h4 {
  font-style: normal;
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 14px;
  color: #12041e;
}

.market_categories .nav-item .nav-link h5 {
  font-style: normal;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
  color: #c2bfe8;
}

.market_banner_section.h-100 {
  padding: 100px 0 20px;
  min-height: 875px;
}

.search_marketplace .searchbar_header {
  margin-left: 0;
}

.slide-2 {
  background-image: unset;
  position: relative;
  margin-top: 0;
  padding: 0;
  border-radius: 15px;
}

.my_listing-area {
  min-height: 600px;
  background: #f3f3f3;
  padding: 50px 0;
  position: relative;
}

span.navbar-brand img {
  cursor: pointer;
  max-width: 229px;
}

.searchbarlist {
  background-color: #fff;
  border-radius: 10px;
  border-bottom: 1px solid #d3d3d32e;
  padding: 10px;
  width: 100%;
  position: absolute;
  top: 64px;
  min-height: 100%;
  max-height: 370px !important;
  overflow: auto;
  box-shadow: 0 2px 4px #00000024;
}

.search-market-banner {
  background-color: #fff;
  border-radius: 10px;
  border-bottom: 1px solid #d3d3d32e;
  padding: 10px;
  width: 100%;
  min-height: 100%;
  max-height: 180px;
  overflow: auto;
  z-index: 1;
  position: relative;
  text-align: left;
}

#asset-search-result {
  max-width: 370px;
  max-height: 180px;
}

.onsale-modal.form-select {
  position: absolute;
  max-width: 150px;
  top: 0;
}

.searchbar_item {
  position: relative;
  padding: 5px 2px;
  border-bottom: 1px solid #d3d3d32e;
  margin-bottom: 0;
}

.__react_component_tooltip {
  background: #fcfaff !important;
  width: 291px;
  border-radius: 10px !important;
  color: #000 !important;
  font-size: 13px !important;
  font-weight: 400;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.33);
  padding: 10px !important;
}

.choose-heading i {
  cursor: pointer;
}

.__react_component_tooltip .multi-line {
  text-align: left !important;
}

.__react_component_tooltip::before {
  color: #fcfaff !important;
}

.__react_component_tooltip::after {
  border-bottom-color: #fcfaff !important;
}

.price-category {
  padding-left: 8px;
}

.searchbar_item h5 {
  font-size: 17px;
  color: #292929;
}

.bid-text-two.bid-usd-box {
  color: #fff !important;
}

.bid-text-two.bid-usd-box span {
  color: #fff !important;
}

.header-serach-box {
  padding-left: 43px;
}

.header-serach-box h5,
.header-serach-box p {
  margin: 0;
  word-break: break-word;
}

.searchbar_item img {
  width: 38px;
  position: absolute;
  height: 38px;
  border-radius: 100%;
  object-fit: cover;
  overflow: hidden;
}

.featured-area {
  text-align: center;
}

.btm_details .attri-description {
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 3px;
}

.featured-area ul li {
  list-style: none;
  padding: 13px 30px;
}

.featured-area {
  text-align: center;
  padding: 40px 0;
}

.featured-area ul {
  display: flex;
  justify-content: center;
}

.works-area-box {
  /* background-image: url('../images/faq_bg.png'); */
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  color: #fff;
  padding: 150px 0 60px;
}

.featured-area h5 {
  font-size: 36px;
  font-weight: 700;
}

.sign-area h5 {
  margin: 20px 0;
}

.sign-area p {
  color: #ffffffb8;
  font-weight: 400;
}

.auction-right-side img {
  height: 500px;
  max-width: 540px;
  object-fit: cover;
  border-radius: 10px;
  width: -webkit-fill-available !important;
}

.sign-area {
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 20px 96px 20px 20px;
  height: 317px;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  justify-content: center;
}

.works-area-box h2 {
  text-align: center;
  font-size: 60px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 50px;
}

.featured-area {
  text-align: center;
  padding: 40px 0;
}

.faq-box h2.accordion-header {
  margin: 0;
  background: 0 0;
}

.accordion-item {
  border: 1px solid #fff !important;
}

.faq-box h2.accordion-header button {
  background: 0 0;
  color: #fff !important;
}

.slide-2 video {
  height: 246px;
  object-fit: cover;
  display: block;
}

.accordion-button::after {
  filter: invert(1);
}

.accordion {
  max-width: 750px;
  margin: 0 auto;
}

.accordion-item {
  background: 0 0;
  border: 2px solid #fff;
  color: #fff;
  margin-bottom: 20px;
  border-radius: 10px !important;
}

.list-market-new video {
  height: 246px;
  object-fit: contain;
}

.accordion-item {
  background: 0 0 !important;
}

.featured-area h5 {
  font-size: 31px;
  font-weight: 700;
  color: #565656;
}

.slide-2 img {
  width: 100%;
  border-radius: 10px;
}

.slide-2 {
  background: #111722;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #6a6a6a66;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

.btm_details {
  border-radius: 0 0 15px 15px;
  padding: 8px 0 1px 8px !important;
  text-align: left;
  min-height: 45px;
}

.meta-mask-modal.edit-profile-modal.buy-now-modal.modal .modal-content {
  padding-bottom: 5px;
}

.list-market-new:hover {
  background: #131414;
}

.mainimg {
  height: 246px;
  border-radius: 10px 10px 0 0 !important;
  object-fit: cover;
}

.connect-wallet-bt a {
  color: #fe7702;
}

.connect-wallet-bt:hover a {
  color: #fff;
}

span.connect-wallet-bt:hover {
  background: #fe7702;
  border-color: #fe7702;
  color: #fff;
}

.wallet-bt-connect .dropdown-menu.show {
  background: #fe7702;
  /* padding: 5px 10px 5px 10px; */
}

.navbar-expand-xl .navbar-nav {
  align-items: center;
}

.wallet-bt-connect .dropdown-menu.show a:first-child {
  border-top: none;
}

.wallet-bt-connect .dropdown-menu.show a {
  color: #fff !important;
  border-top: 1px solid #ffffff59;
  padding: 10px;
  font-size: 14px;
}

.my_listing-area h2 {
  color: #47036f;
}

.btm_details .attri-price {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.list-market-new {
  border-radius: 15px;
  overflow: hidden;
  background-color: #111722;
}

.creater-rayality-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.slide-2 .bid-text-two {
  padding-top: 8px;
  padding-left: 0;
}

.slide-2 .echo-two {
  padding: 0;
}

.auction-right-side video {
  max-width: 560px;
  width: -webkit-fill-available;
}

.bid_auction {
  background-image: linear-gradient(211deg, #4f0c75, #fe7702);
  padding: 140px 0 50px;
  min-height: 80vh;
}

.live-auction h2 {
  color: #fff;
  font-size: 34px;
  margin-left: 10px;
}

.fade {
  transition: opacity 3s linear !important;
}

.day-timer h4 {
  font-family: "Circular Std", sans-serif !important;
  margin-bottom: 9px;
}

.live-auction {
  display: flex;
  align-items: start;
}

.day-timer {
  background: #00000099;
  border: 2px solid #053430;
  padding: 7px;
  border-radius: 20px;
  color: #fff;
}

.live-auction img {
  margin-top: 10px;
}

.day-timer h6 {
  font-size: 14px;
  margin-bottom: 0;
}

.share-bt p {
  margin-bottom: 0;
  background: #ffffff21;
  border-radius: 50px;
  padding: 5px 10px 5px 5px;
  margin-left: 10px;
  font-size: 14px !important;
  /* min-width: 100px; */
}

.fa-share-alt {
  background: #fff;
  width: 20px;
  text-align: center;
  color: #b34933;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
}
.footer-area-email.modal-body {
  font-size: 14px;
  /* padding-bottom: 11px; */
}
.auction-form .input-group-text {
  border-radius: 0;
  background-color: #fff;
  border: none;
  border-left: 3px solid #26b1a1;
  margin: 13px 0;
  padding: 4px;
}

.auction-form .input-group {
  background: #fff;
  border-radius: 50px;
  padding: 0 20px;
}

.auction-form .row {
  display: flex;
  align-items: center;
}

.auction-form input {
  border-radius: 0 30px 30px 0 !important;
  height: 58px;
  border: none;
}

.bid_auction hr {
  color: #fff;
}

.eth-box h5 {
  font-size: 16px;
  color: #fff;
  background: #ffffff21;
  border-radius: 50px;
  padding: 0 11px;
  margin: 6px 0 6px 10px;
}

.bid_auction h1 {
  font-size: 24px;
  color: #ffd6ae;
  font-weight: 700;
}

.buy-now-bt {
  background: url("../images/buy.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  height: 79px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  color: #fff !important;
  max-width: 190px;
  margin-top: 20px;
  justify-content: center;
}

.d-inline-block {
  display: inline-block !important;
}

.air-max h2 {
  font-size: 35px;
  color: #ffd6ae;
  margin-bottom: 0;
  display: block;
  line-height: 30px;
  max-width: 600px;
  word-break: break-word;
}

.name-description h5 {
  color: #fff;
  margin-bottom: 5px;
}

.name-description p {
  font-size: 14px !important;
  color: #efefef;
  font-weight: 400;
  margin: 0;
}


p.nft-auction-time {
  background: #4f0c75;
  color: #fff;
  padding: 2px 12px;
  border-radius: 15px;
  max-width: fit-content;
}
.air-max h4 {
  color: #fff;
  font-size: 20px;
  margin-bottom: 0;
}

.buy-sell-box {
  margin-top: 25px;
}

.buy-sell-box h4 {
  color: #fff;
}

.collections-details-box {
  max-width: 740px;
  display: flex;
  align-items: center;
}

.details-cover {
  margin-bottom: 50px;
}

.collections-details-box img {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  object-fit: cover;
}

.buy-now-png {
  /* background-image: url('../images/buy.png'); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 44px;
  color: #fff;
  line-height: 60px;
}

.bid_auction p {
  color: #ffffffb8;
  font-size: 16px;
  word-break: break-word;
  font-weight: 400;
  margin: 0;
}

.air-max {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.day-timer li {
  color: #fff;
  display: inline-block;
  margin: 0 20px;
  text-align: center;
}

.day-timer {
  margin: 0 0 0 auto;
  text-align: center;
}

.seller-box {
  background-color: #000;
  width: 100%;
  color: #fff;
  position: relative;
  padding: 40px 0;
}

.collec-heading {
  color: #fff;
  margin-bottom: 30px;
}
span.text-danger {
  color: red !important;
  font-size: 14px;
  font-weight: 400;
}
.collec-heading a {
  color: #c5c5c5 !important;
}

.details-cover img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 15px;
}
.collections-name-box {
  margin: -195px 0 0 0 !important;
  background: #00000087;
  position: relative;
  width: 100%;
  border-radius: 0 0 15px 15px;
  padding: 10px;
}

.seller-box h2 {
  font-size: 36px;
  text-align: center;
  margin-bottom: 30px;
}

.seller-box .tab-pane {
  margin: 0 10px;
}

.seller-box h2 span {
  color: #fe7702;
}

.login_header button {
  font-size: 14px;
}

.my-acc-bt {
  color: rgba(61, 66, 79, 0.65) !important;
}

.navbar-expand-lg .navbar-collapse {
  align-items: initial;
}

.nft-collection-box li {
  color: #ffffff94;
  list-style: none;
  display: inline-table;
  width: 120px;
  text-align: center;
}

.nft-collection-box {
  text-align: left;
  position: relative;
}

.nft-collection-box ul {
  padding: 0;
  border: none;
}

.nft-collection-box li a {
  color: #fff;
}

.faq-link {
  color: #fe7702 !important;
  font-size: 16px;
  display: block;
  margin-top: 30px;
  text-decoration: underline !important;
}

.onsale-modal.form-select {
  position: absolute;
  max-width: 150px;
  top: 0px;
  left: 280px;
  height: 48px;
  border-radius: 10px;
  border: 2px solid #000;
}

.nft-collection-box li a.active {
  background: #000;
  padding: 8px 10px;
  border-radius: 10px;
  height: 40px;
  display: block;
  color: #fe7702;
}

.nft-collection-box ul {
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  background: #2f333c;
  padding: 5px;
  margin-bottom: 15px;
}

.nft-collection-box h5 {
  margin-bottom: 10px;
}

.searhmarket-content {
  padding-left: 45px;
}
.service_section1 {
  padding: 80px 0;
}
.single-nft-preview {
  text-align: center !important;
}
.signle-area-collectibla-box {
  margin: 0;
}
.swal-button {
  background-color: #fe7702 !important;
}
.swal-footer {
  text-align: center;
}
.kennedy-brooks {
  background-image: url(../images/promote-bg.png);
  background-repeat: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}
.athelete-top.area {
  margin-top: 83px;
}
button.btn.btn-unset.generate-nft-btn {
  background: linear-gradient(
    91.74deg,
    #e853e2 -1.58%,
    #e74fe2 -1.57%,
    #34a6e3 103.26%
  ) !important;
  /* width: 221px; */
  margin-left: 12px;
  border: none;
}
.choose-ai-area {
  padding: 50px 0 30px;
}
.ai-generator-area h2 {
  margin-bottom: 0;
}
.ai-generator-area h2 {
  font-size: 50px;
  background: -webkit-linear-gradient(45deg, #f8a8ff, #6155ed);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-weight: 800;
}
.buy-nfts-section {
  padding: 132px;
  text-align: center;
  height: calc(100vh - 243px);
  display: flex;
  align-items: center;
  justify-content: center
}

@media (min-width: 1700px) {
  .App .container {
    max-width: 1600px;
  }
}

@media (max-width: 1699px) {
  .searchbar_header input {
    min-width: 276px;
  }
  .preview-area .number-input input {
    right: 15%;
  }
  .preview-area .name-input input {
    left: 29%;
    bottom: -1%;
  }
  .athe-banner-area {
    padding: 84px 0;
  }
}

@media (max-width: 1399px) {
  .home_banner_right {
    background-size: contain;
    background-repeat: no-repeat;
  }

  .market_categories .nav-item .nav-link {
    min-width: 170px;
  }

  .searchbar_header input {
    min-width: 233px;
  }

  header .nav-link {
    font-size: 15px;
    margin: 0 15px;
  }

  .navbar-brand img {
    max-width: 150px;
  }
}

@media (max-width: 1400px) {
  header .container {
    max-width: 100%;
  }
  .air-max h2 {
    font-size: 28px;
  }
  .input-image {
    width: 290px;
    height: 290px;
  }

  .activities-heading h3,
  .activities-heading h3 span {
    font-size: 100px;
  }

  .activities-heading h5 {
    font-size: 30px;
    line-height: 54px;
  }

  .activities-heading h5 span {
    font-size: 30px;
  }

  .soccor-text {
    font-size: 60px;
  }

  .collection_img img {
    height: 245px;
  }

  .tab_box_home span {
    right: 7px;
  }

  .in-stock {
    padding: 6px 13px;
    font-size: 13px;
  }
}

@media (max-width: 1199px) {
  .generate-ai h2 {
    font-size: 40px !important;
  }
  .athelete-top.area {
    margin-top: 82px;
  }
  .collection-team a {
    display: flex;
    flex-direction: column;
  }
  .home-banner-section {
    padding: 50px 0;
  }
  .App .common_bg .container {
    max-width: 1100px;
  }
  .air-max h2 {
    font-size: 24px;
  }
  .soccor-banner h2,
  .soccor-banner h2 span {
    font-size: 90px;
  }

  .connect-wallet-bt {
    display: block;
  }

  .input-image {
    width: 250px;
    height: 250px;
  }

  .soccor-banner {
    padding: 140px 0;
  }

  .activities-heading h3,
  .activities-heading h3 span {
    font-size: 80px;
  }

  .activities-heading h5 span {
    font-size: 26px;
  }

  .activities-heading h5 {
    font-size: 26px;
    line-height: 47px;
  }

  .soccor-text {
    font-size: 48px;
  }

  header {
    background: rgb(0 0 0);
  }

  .skiptranslate.goog-te-gadget {
    margin: 0 auto;
    height: 42px !important;
  }

  .day-timer li {
    margin: 0 12px;
  }

  .live-auction {
    display: inline-block !important;
  }

  .home-banner-left h1 {
    font-size: 50px;
  }

  .navbar-nav .dropdown-item .startselling_btn {
    width: 110px !important;
    text-align: center;
    border-radius: 10px !important;
  }

  .navbar-nav.navbar-nav-scroll {
    margin-bottom: 16px !important;
  }

  #dropdown-basic {
    padding: 14px 7px;
    font-size: 13px;
  }

  .searchbar_header input {
    min-width: 192px;
  }

  header .startselling_btn {
    padding: 14px 7px;
    font-size: 13px;
  }

  .collecting-bt {
    font-size: 16px;
  }

  .market-left h1 {
    font-size: 50px;
  }

  .tab_box_home {
    padding: 10px;
    margin: 0 5px 10px;
  }

  .explore-box h1 {
    margin-bottom: 23px;
  }

  .home_banner_right {
    padding: 35px 0;
  }

  .hot-heading {
    font-size: 50px;
  }

  .home-banner-left p {
    font-size: 18px;
  }

  .collection_img img {
    height: 180px;
  }

  .App .container {
    max-width: 1100px;
    padding: 0 20px;
  }

  .tenis-box img {
    max-width: 50px;
  }

  .tenis-box {
    padding: 9px 8px;
  }

  .like-view h6 {
    font-size: 12px;
  }

  .instant-sale-prize {
    width: 78%;
  }

  .name-description-filed {
    padding: 30px 12px;
  }

  .seller-box h2 {
    font-size: 50px;
  }

  .navbar-nav button.btn.btn-unset {
    width: fit-content;
    margin: 0 auto;
  }

  #google_translate_element {
    text-align: center;
    margin-top: 11px;
  }

  .navbar-nav .dropdown,
  .navbar-nav .dropdown-menu.show {
    text-align: center;
  }

  header .startselling_btn {
    margin: 10px auto !important;
    width: fit-content;
    width: 180px;
  }

  #dropdown-basic {
    width: 180px;
    height: 50px;
  }

  #google_translate_element select {
    margin-left: 0;
  }

  header .nav-link {
    margin: 12px 15px 0;
  }
}

@media (max-width: 991px) {
  .ai-content-area-inner {
    padding: 50px 35px;
  }
  .ai-generator-area h2 {
    font-size: 55px;
  }
  .collection-team a {
    display: unset;
    flex-direction: unset;
  }
  .ath-content p,
  .get-in-area p,
  .unique-content p {
    font-size: 16px;
  }
  .profile-area {
    padding: 40px 30px;
  }
  .preview-mobile-view {
    /* display: block; */
    margin-top: 30px;
  }
  .signle-detail-data-area input.form-control {
    width: 100%;
  }
  .auction-reverse-box {
    display: flex;
    flex-direction: column-reverse;
  }

  .founding-div p {
    font-size: 15px;
  }
  .founding-div h4 {
    font-size: 22px;
  }
  .common_box_content h5 {
    font-size: 15px;
  }
  .providers-logo {
    margin-bottom: 20px;
  }
  .first_section_main {
    text-align: center;
  }
  .air-max h2 {
    font-size: 21px;
  }
  .input-image {
    width: 172px;
    height: 172px;
  }

  .beach-text {
    font-size: 35px;
  }

  .soccor-text {
    font-size: 38px;
  }

  .activities-heading h5 {
    font-size: 20px;
    line-height: 32px;
  }

  .activities-heading {
    position: absolute;
    top: 80px;
  }

  .activities-heading h3,
  .activities-heading h3 span {
    font-size: 60px;
  }

  .prizes-box img {
    max-width: 50%;
  }

  .contact-form-two {
    max-width: 100%;
    padding: 50px;
  }

  .activities-heading h5 span {
    font-size: 20px;
  }

  .soccor-banner h2,
  .soccor-banner h2 span {
    font-size: 70px;
  }

  .flex-row.flex-wrap.market_categories.nav.nav-pills {
    max-width: 68%;
  }

  .main_h2.underline_text {
    font-size: 25px;
  }

  .App .common_bg .container {
    max-width: 720px;
  }

  /* .main_h2 br {
    display: none;
  } */

  .p_text {
    color: #c8ceec;
    font-size: 16px;
    font-weight: normal;
    width: 100%;
    margin-right: auto;
  }

  .home_banner_section {
    padding: 90px 0 0px;
  }

  .about-page h1 {
    font-size: 60px;
  }

  .market_banner_section #left-tabs-example-tabpane-first {
    margin-top: 0;
  }

  .market_banner_section.h-100 {
    padding: 50px 0 20px;
  }

  .sign-area {
    height: 237px;
    margin-bottom: 20px;
  }

  #google_translate_element select {
    margin: 0;
  }

  .App .container {
    max-width: 720px;
  }

  .market_banner_section {
    margin-top: 74px;
  }

  .home_banner_right {
    /* height: 85vh; */
  }

  .attri-name {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 50px;
    line-height: 1.8em;
  }

  .more-div {
    margin-bottom: 12px;
  }

  .explore-box {
    padding: 30px 0 0;
  }

  .leftfooter {
    padding-right: 20px;
  }

  footer h3 {
    margin-bottom: 19px;
    width: 100%;
  }

  .category_row.row {
    margin-top: 0px;
  }

  .footer_head h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: -0.3px;
    color: #fff;
  }
  .auction-right-side {
    padding: 0;
    margin-bottom: 15px;
  }

  .bid_auction {
    margin-top: 74px;
    padding: 60px 0 20px;
  }

  .connect-wallet-bt {
    margin: 0 auto;
  }

  .preview-image {
    width: 100%;
    height: auto;
  }

  .featured-athlet-left h3,
  .featured-athlet-right h3 {
    font-size: 28px;
    font-weight: 700;
  }
  .athe-banner-area {
    margin-top: 70px;
  }
  .unique-content h2 br {
    display: none;
  }
  .get-in-img img {
    top: 0;
  }
}

@media (max-width: 767px) {
  .own-journey-area .create-store-info h4 {
    font-size: 36px !important;
}
  .ai-generator-area h2 {
    font-size: 40px;
  }
  .home-banner-section button.btn.btn-unset.generate-nft-btn {
    margin-left: 23px !important;
  }
  .preview-area .number-input input {
    right: 7% !important;
    top: 1% !important;
  }
  .preview-area .name-input input {
    bottom: 3px !important;
  }
  .choose-ai-tab-area ul li {
    padding: 18px 20px;
  }
  .choose-ai-tab-area ul li img {
    width: 35px;
  }
  .generate-ai h2 {
    font-size: 35px !important;
  }
  .generate-ai h2 {
    font-size: 35px !important;
  }
  .auction-right-side img {
    height: 400px;
  }
  .kennedy-brooks .row {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }
  .kennedy-brooks img {
    margin-bottom: 40px;
  }
  .auction-form input {
    height: 45px;
  }
  .get-in-section {
    padding: 52px 0 0;
  }
  .get-in-img img {
    width: 100%;
    max-width: 60%;
  }
  .get-in-img img {
    position: unset;
  }
  .inner-content {
    border-bottom: 1px solid #1e1e1e;
  }
  .unique-img img {
    max-width: 60%;
    margin-bottom: 30px;
  }
  .ath-img img {
    margin-bottom: 20px;
  }
  .ath-content h2,
  .get-in-area h2,
  .unique-content h2 {
    font-size: 30px !important;
  }
  .banner-ath-image {
    display: block;
  }
  .athe-banner-area {
    background-image: none;
    margin-top: 20px;
  }
  .flex-direction {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  /* .detail-left-side {
    height: 350px;
  } */
  .collections-name-box {
    margin: -35px 0 0 0 !important;
    border-radius: 15px;
  }
  .create-store-info {
    padding-left: 0 !important;
  }
  .circle_row .col-md-6:last-child {
    padding: 0 0px 0 11px !important;
  }
  .flex-row.flex-wrap.market_categories.nav.nav-pills {
    max-width: 100%;
  }
  .detail-left-side img {
    float: none;
    margin: 0 auto;
    display: block;
    height: 400px;
  }

  .prizes-box {
    padding: 140px 0;
  }
  .kennedy-brooks img {
    margin-bottom: 40px;
  }

  .contact-form-two h3,
  .contact-form-two h3 span {
    font-size: 36px;
  }

  .activities-heading {
    top: 53px;
  }

  .beach-soccor-one {
    margin-bottom: 20px;
  }

  .input-image {
    width: 300px;
    height: 300px;
  }

  .member-one {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .direction-change {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 50px;
  }

  .greate-left {
    text-align: center;
    margin-top: 20px;
  }

  .greate-left h2 {
    font-size: 48px;
  }

  .sports-tab--new {
    width: 50%;
  }

  .auction-right-side {
    margin-bottom: 30px;
  }

  .about-page h3 {
    font-size: 30px;
    margin: 0 0 20px 0;
  }

  .about-page h2 {
    font-size: 30px;
  }

  .about-page {
    padding: 120px 0 20px;
    min-height: 600px;
  }

  .about-page h1 {
    font-size: 40px;
  }

  .founding-div h4 {
    line-height: 28px;
    font-size: 20px;
  }
  .founding-div p {
    font-size: 16px;
    margin: 23px 0;
  }

  .new-banner-area {
    margin-top: 75px !important;
  }

  .navbar-brand img {
    max-width: 150px !important;
  }

  .air-max h2 {
    font-size: 20px;
  }

  .details-cover img {
    height: 280px;
  }

  .bid_auction p {
    font-size: 14px;
  }

  .home-banner-left h1 {
    font-size: 40px;
  }

  .bid_auction h1 {
    font-size: 20px;
  }

  .hot-heading {
    font-size: 40px;
  }

  .works-area-box h2 {
    font-size: 40px;
  }

  .shade-box {
    left: 0;
  }

  /* .crete-collect-new {
    flex-direction: column-reverse;
  } */

  /* .preview-area {
    margin: 20px;
  } */

  .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
  }

  .home-banner-left h1 {
    text-align: center;
  }

  .home-banner-left {
    text-align: center;
  }

  .rightsdefooter {
    margin-top: 30px;
  }

  .explore-box .text-center.col-md-5 {
    max-width: 500px;
    margin: 0 auto;
  }

  .down-scroll {
    margin-top: 80px;
  }

  .leftfooter {
    margin-bottom: 20px;
  }

  .rightsdefoote {
    text-align: left;
  }

  .market_banner_section.h-100 {
    padding: 30px 0;
  }

  .scroll-div {
    margin-top: 30px;
  }

  .founding-div {
    margin: 30px auto 0;
  }
}

@media (max-width: 576px) {
  .ai-generator-area h2 {
    font-size: 28px !important;
  }
  .frame-center-image-area {
    left: 4%;
  }
  .number-input input {
    right: 5% !important;
    top: 2% !important;
  }
  .one-digit.form-control, .two-digit.form-control {
    right: 4% !important;
  }
  .generate-ai-content {
    margin-top: 30px;
  }
  .choose-ai-tab-area ul li {
    padding: 18px 10px;
  }
  .choose-ai-tab-area ul li {
    font-size: 14px;
  }
  .choose-ai-tab-area ul li img {
    width: 30px;
    margin-right: 4px;
  }
  .athelete-top.area {
    margin-top: 75px;
  }
  iframe#review-card-payment-iframe {
    width: 87%;
    left: 7%;
  }
  .buy-now-bt {
    max-width: 160px;
  }
  .detail-collection-area {
    margin-left: 0;
  }
  .ath-content h2,
  .get-in-area h2,
  .unique-content h2 {
    font-size: 28px !important;
  }
  .greate-left {
    padding: 0;
  }
  button.btn.btn-unset {
    padding: 8px;
  }
  button.btn.btn-unset {
    padding: 8px;
  }
  .main_h2 {
    font-size: 32px;
  }
  /* .main_h2 br {
    display: none;
  } */
  .touch_form {
    padding: 37px 27px;
  }

  .forrm-submit-btn {
    width: 100%;
    margin: 30px auto 0 !important;
  }
  .soccor-banner h2,
  .soccor-banner h2 span {
    font-size: 50px;
  }
  .beach-text {
    font-size: 24px;
  }
  .soccor-text {
    font-size: 28px;
  }
  .contact-form-two {
    padding: 30px;
  }
  .contact-form-two h3,
  .contact-form-two h3 span {
    font-size: 24px;
    line-height: 38px;
  }
  .activities-heading h3,
  .activities-heading h3 span {
    font-size: 40px;
  }

  form.login-form-box {
    text-align: left;
  }

  .about-page h1 {
    font-size: 30px;
    margin-top: 10px;
  }

  .rightside_border {
    max-width: fit-content;
    margin-top: 30px;
  }

  .about-page {
    text-align: center;
  }

  .air-max h2 {
    font-size: 22px;
  }

  select.sort-by-filter.form-select {
    height: 55px;
    border-radius: 15px;
    width: 200px;
  }

  .sign-area {
    height: 267px;
    margin-bottom: 20px;
    padding: 0 16px;
  }

  .social-icon li {
    display: inline-block;
    margin: 13px 22px;
  }

  .featured-area ul {
    display: block;
  }

  .home-banner-left h1 {
    font-size: 42px;
  }

  .hot-heading {
    font-size: 42px;
  }

  .eth-box h5 {
    font-size: 20px;
  }

  .seller-box h2 {
    font-size: 36px;
  }

  .air-max h4 {
    font-size: 20px;
  }

  .explore-box .col-sm-3 {
    width: 50%;
  }

  .home-banner-left {
    padding: 70px 10px 0;
  }

  .home_banner_right {
    /* height: 36vh; */
  }

  .home-banner-left h1 {
    font-size: 30px;
  }

  .hot-heading {
    font-size: 30px;
  }

  .market-left h1 {
    font-size: 36px;
  }

  .collection-head-new {
    padding: 7px 9px;
    font-size: 14px;
  }

  .works-area-box h2 {
    font-size: 30px;
  }

  .rightsdefooter {
    margin-top: 0;
  }

  footer {
    padding: 30px 10px;
  }

  .end-reserve-bid-box {
    margin: 15px 23px 0 8px;
  }

  .category_row.row .nav-item {
    width: 50%;
  }

  .market_categories .nav-item .nav-link {
    min-width: 160px;
    margin: 5px;
  }

  .search_marketplace {
    width: 100%;
  }

  .category_row.row {
    margin-top: 30px;
  }
  .creater-rayality-box {
    display: block;
  }
  .greate-left h2 {
    font-size: 35px;
  }
  button.select-payement-buy-btn {
    max-width: unset;
  }
  .choose-ai-tab-area ul li {
    padding: 18px 10px;
  }
}
@media (max-width: 480px) {
  .athelete-top.area {
    margin-top: 70px;
}
  .service_section1 .img_position_2 {
    left: -10% !important;
}
  .main_h2 {
    font-size: 25px;
  }
  .number-input.one-digit-preview {
    right: 22px;
  }
  .one-digit.form-control, .two-digit.form-control {
    right: 3% !important;
    top: 9px !important;
  }

  .number-input input {
    right: 4% !important;
    top: 8px !important;
  }
  .ai-generator-area h2 {
    font-size: 23px !important;
  }
  /* .home_header .container {
    flex-direction: column;
  } */
  .ai-content-area-inner h6 {
    font-size: 15px;
  }
  .ai-content-area-inner {
    padding: 20px 5px;
  }
  .choose-ai-tab-area ul li {
    font-size: 11px;
  }
  .choose-ai-tab-area ul li img {
    width: 22px;
  }
  .toggle-drop-right {
    margin-left: -160px;
    margin-top: 4px;
  }
  button.btn.btn-unset.generate-nft-btn {
    margin-left: 0;
  }
  .preview-area .center-frame-area {
    width: 300px;
  }
  .frame-number {
    color: #fbb03b !important;
    font-size: 14px;
  }
  .preview-area .number-input {
    right: 15px;
    top: 19px;
  }
}
@media (max-width: 420px) {
  .discord-section-bg {
    margin: 65px 0 0px 0;
  }
  .number-input input {
    right: 3% !important;
    top: 2px !important;
  } 
  .name-input input {
    width: 176px !important;
  }
  .name-input input {
    bottom: 0 !important;
  }
  .one-digit.form-control, .two-digit.form-control {
    right: 2% !important;
    top: 4px !important;
  }
  .svg-animation p {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .nil-providers {
    padding: 15px 0;
  }
  span.navbar-brand {
    margin-right: 0;
  }
  .get-in-bg {
    padding: 20px;
  }
  .correct-image img {
    width: 150px;
  }
  .bid-modal.text-center h5 {
    font-size: 18px;
  }
  .logout-text {
    font-size: 16px;
  }
  .sale-auction-tab li.nav-item button {
    width: 129px;
    font-size: 14px;
    white-space: nowrap;
  }
  .single-multiple-box .react-tabs__tab {
    width: 136px;
    font-size: 16px;
  }
  .collections-details-box {
    flex-wrap: wrap;
  }
  .founding-div h4 {
    position: relative;
  }
  .discord-section-bg {
    height: 260px;
    max-height: 260px;
  }

  #details-collapse-text p {
    color: #fff;
    font-size: 14px;
    margin: 0;
    min-width: 150px;
  }
  .count_box h5 {
    font-size: 18px;
  }
  .featured-area {
    padding: 40px 0 0;
  }
  .rightsdefoote a {
    padding-right: 11px;
  }

  .home-banner-left {
    padding: 30px 10px 0;
  }

  .soccor-banner {
    padding: 110px 0 20px !important;
    min-height: 500px;
  }

  .works-area-box {
    padding: 44px 0 50px;
  }

  #hot-collection {
    padding: 0;
  }

  .activities-heading {
    text-align: center;
    position: unset;
    margin-bottom: 20px;
  }
  .frame-image {
    width: 280px;
  }
  .soccor-banner h2 {
    margin-bottom: 80px;
  }
  .input-image {
    width: 230px;
    height: 230px;
  }
  .soccor-text {  
    font-size: 24px;
  }
  .market_categories .nav-item .nav-link {
    min-width: 100px;
  }

  .market_categories .nav-item .nav-link {
    padding: 12px;
  }

  .sign-ar {
    padding: 20px 20px 20px 20px;
  }
  .react-tabs__tab {
    padding: 6px 9px !important;
    font-size: 13px;
  }
  .detail-area-buy-nft ul.react-tabs__tab-list {
    margin-bottom: 14px;
    display: flex;
  }
  button.detail-submit-btn.btn.btn-unset {
    width: 100px !important;
    height: 35px !important;
    font-size: 14px !important;
  }
  .detail-area-buy-nft label.m-0.form-label {
    font-size: 15px;
  }
  .meta-mask-modal h3 {
    font-size: 20px;
  }
  .bid-modal h5 {
    font-size: 17px;
  }
  button.select-payement-buy-btn {
    height: 37px !important;
  }
  .buy-now-modal .buy-now-bt {
    height: 43px;
    font-size: 13px !important;
  }
}
@media (max-width: 375px) {
  .center-frame-area {
    width: 318px;
  }
  .name-input {
    left: 67px;
  }
  .ai-mint-nft {
    margin: 0;
  }
  .number-input {
    top: 14px;
  }
}
@media (max-width: 380px) {
  .one-digit.form-control, .two-digit.form-control {
    right: 1% !important;
    top: -2px !important;
  }
  .number-input input {
    right: 3% !important;
    top: -1px !important;
  }
  .name-input input {
    width: 158px !important;
  }
  .name-input input {
    bottom: -5px !important;
  }
  .collections-details-box {
    padding: 0 !important;
}
}
@media (max-width: 320px) {
  .one-digit.form-control, .two-digit.form-control {
    right: -1% !important;
    top: -7px !important;
    font-size: 14px;
  }
  .center-frame-area {
    position: relative;
    width: 268px;
  }
  .number-input {
    right: -7px;
  } 
  .name-input {
    bottom: 6px;
    left: 56px;
  }
  .number-input input {
    right: -1% !important;
    top: -6px !important;
    font-size: 14px;
  }
  .name-input input {
    width: 119px !important;
    font-size: 14px;
  }
  .name-input input {
      bottom: -8px !important;
  }
  
button.btn.btn-unset.generate-nft-btn.px-4.mt-4 {
  font-size: 13px !important;
}

}
.buy-modal {
  display: flex;
}
.buy-modal button {
  padding: 4px 4px;
  margin: 4px 2px;
}
.pagination li.active {
  background: #fe7702;
}

ul.pagination li a {
  color: #fe7702;
}
.pagination li.active a {
  color: #fff;
}

/* button regenerate */
.regenerate-nft {
  position: absolute;
  width: 211px;
  height: 62px;
  left: 739px;
}
.regenerate-nft {
  position: absolute;
  width: 164px;
  height: 28px;
  left: 763px;
  /* top: 3530px; */
}

.regenerate-nft {
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
}
.regenerate-nft {
  color: #ffffff;
}

.regenerate-nft {
  background: linear-gradient(91.69deg, #d955e2 -3.76%, #40a0e3 100%);
  border-radius: 11px;
}

/*  */

ul.pagination li {
  border: 1px solid #fe7702;
  padding: 6px 14px;
  /* background: #000; */
  border-radius: 5px;
  margin: 0 4px;
}
ul.pagination {
  justify-content: center;
}

.preview-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  background: #f3f3f3;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.buy-now-bt {
  width: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
.user-timer-box .timer-box {
  background: #4f0c75;
  position: absolute;
  right: 3px;
  /* width: 185px; */
  top: 3px;
  height: 26px;
  border-radius: 49px;
  text-align: center;
  color: #fff;
  padding: 1px 10px;
  z-index: 1;
}
.expired-nft {
  background: #4f0c75;
  padding: 2px 4px;
  border-radius: 50px;
  color: #ffffff;
  /* font-weight: 600; */
  /* margin-bottom: 10px; */
  display: block;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.coming-soon {
  background: #fe7702;
  max-width: fit-content;
  padding: 2px 7px;
  border-radius: 80px;
}

.signup-bt {
  text-align: center;
  margin: 20px 0 0;
}

.signup-bt a {
  color: #fe7702 !important;
}

.login-form-box button {
  width: 14vw;
  padding: 0 15px;
  margin: 40px auto 0 !important;
  display: block;
  background: #fe7702 !important;
}

.forgot {
  display: block;
  text-align: end;
  color: #fff !important;
  width: fit-content;
  margin-left: auto;
}

.login-form-box input {
  height: 55px;
  font-weight: 400;
}

.user-timer-box .timer-box ul {
  padding: 0;
  line-height: 26px;
}

.error-new-box {
  left: 24px;
  color: red;
  position: absolute;
  top: 52px;
  font-size: 14px;
}

.thenae-box {
  position: relative;
}

.air-desc {
  font-size: 19px;
  color: #ffd6ae;
  margin-bottom: 0;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 600;
}
.detail-owner-heading {
  margin-bottom: -13px;
}

.balance-nw {
  color: black !important;
  font-size: 24px !important;
  font-weight: 700 !important;
}

.withdraw-bt {
  background: #fe7702;
  padding: 10px 21px;
  border-radius: 10px;
  color: #fff;
}

section.collection_section.view-balance-box .single-multiple-box {
  max-width: 500px;
  text-align: center;
  background: white;
  border-radius: 10px;
  padding: 20px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 7px #dbdbdb;
  width: 100%;
}
.air-price-new {
  color: #fff;
  font-size: 18px;
  margin-top: 17px;
  margin-bottom: 20px;
  line-height: 30px;
}
.spinner-content h6 {
  font-weight: 600;
  color: #4f0c75;
}

.create-items .activedata {
  border: 1px solid #f67728;

  background: #f7f3f3;
}

.bt-div {
  margin-top: 30px !important;
}

.air-price-new span {
  background: #4f0c75;
  padding: 5px 13px;
  border-radius: 30px;
  margin-left: 6px;
  white-space: nowrap;
  font-size: 15px;
}

.creater-collection-area {
  display: flex;
  justify-content: space-between;
}
.collection-team {
  /* margin-left: 100px; */
  color: #fff;
  margin-top: 30px;
}

#details-collapse-text p {
  color: #fff;
  font-size: 14px;
  margin: 0;
  min-width: 180px;
}

.view-details-collapse {
  margin: 4px 0;
  border-radius: 10px;
  font-size: 16px !important;
  background: none !important;
  border: none !important;
  padding: 0 !important;
  text-decoration: underline !important;
}

/* .creater-rayality-box {
    width: 360px;
} */
.collapse-details-view {
  display: flex;
  /* justify-content: space-between; */
}

.featured-athlet-left {
  /* background-image: url('../images/athlet-leftt.png'); */

  background-position: right;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  height: 500px;

  border-radius: 20px;

  padding: 20px;

  display: flex;

  flex-direction: column;

  justify-content: center;
}

.featured-athlet-right {
  /* background-image: url('../images/athlet-right.png'); */

  background-position: right;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  height: 500px;

  border-radius: 20px;

  padding: 20px;

  display: flex;

  flex-direction: column;

  justify-content: center;
  /* background-color: #1f1f23; */
}

.featured-athlet-left h6,
.featured-athlet-right h6 {
  color: #fe7702;
  font-size: 20px;
  margin-bottom: 20px;
}

.featured-athlet-left h3,
.featured-athlet-right h3 {
  font-size: 50px;

  font-weight: 700;
}

.featured-athlet-left p,
.featured-athlet-right p {
  font-size: 18px;
  max-width: 500px;
  margin-bottom: 15px;
}

.feature-athlet {
  background: #000;

  padding: 40px 0;
}

.sign-explore-bt {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 83px;
  width: 169px;
}

.sign-explore-bt {
  /* background-image: url('../images/btn-new.png'); */
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 83px;
  width: 212px;
}

.feature-athlet .col-md-6 {
  padding: 15px !important;
}

.collection-team p {
  color: #fff;

  font-size: 14px;
}

.owner-details p {
  margin-bottom: 0;

  font-size: 15px;

  color: #fff;

  max-width: 240px;
}

.owner-list a {
  font-size: 14px;
  padding: 3px 7px 3px 0;
  /* margin-left: 100px; */
  min-width: 70px;
}

.owner-sale-box {
  color: #ffffffad !important;
}

.owner-list {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ffffff3b;
  padding: 10px 0px;
}
.owner-list:first-child {
  padding-top: 0;
}
.all-owner-box .owner-list:last-child {
  border: none !important;
}

.owner-list-nosale {
  padding-top: 13px;
}

.new-loader {
  border: 5px solid #d3d3d3;

  border-radius: 50%;

  border-top: 5px solid #fe7702;

  width: 40px;

  height: 40px;

  animation: spin 2s linear infinite;

  position: absolute;

  top: 35px;

  left: 35px;
}

.air-max.auction-page-air {
  margin: 24px 0;
}

.loader-list-items {
  position: relative;
}
.put-on-sale-div {
  display: none !important;
}
.put-sale-boxes {
  background: #00000000;
  padding: 13px;
  border-radius: 12px;
  margin-top: 15px;
}
.put-sale-boxes input {
  padding: 10px;
  border-radius: 9px;
  background: transparent !important;
}
.put-sale-boxes.row button {
  margin: 0;
}
.input-group {
  display: flex;
  align-items: center;
}

.input-group-text {
  background-color: transparent;
  border: none; /* Or adjust as needed */
  padding: 0 10px;
  font-size: 1rem; /* Adjust size as needed */
}

input[type="number"] {
  padding-left: 1.5rem; /* Add padding to the left */
}


.put-sale-boxes.row button.disabled-btn {
  filter: grayscale(110%);
  opacity: 0.7;
  cursor: not-allowed;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.nft-collection-box li.react-tabs__tab--selected {
  background: #000 !important;

  border: none !important;

  color: #fff !important;

  border-radius: 5px !important;
}

.nft-collection-box li.react-tabs__tab--selected:focus::after {
  background: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.collection-nft-tab-market {
  margin-top: 40px;
}

.market_banner_section #left-tabs-example-tabpane-first {
  margin-top: 100px;
  min-height: 400px;
}

.no-data-div {
  position: relative;
  min-height: 300px;
}

.no-data-div h2 {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.owner-details {
  margin-right: 40px;

  width: 352px;
}

.owner-list .sale-auction-tab li.nav-item button {
  width: 105px;

  padding: 6px 0px;

  font-size: 14px;

  /* background: url('../images/buy.png'); */

  background-position: left;

  background-repeat: no-repeat;

  background-size: contain;

  height: 79px;

  display: block;

  border-radius: 5px;

  text-align: center;

  line-height: 68px;

  color: #fff !important;

  max-width: 190px;

  border: none;

  box-shadow: none;

  height: 83px;
}

.margin-safari .goog-te-combo {
  margin-left: 12px !important;
}

.owner-list button.buy-now-bt {
  width: 100%;
}

.buy-now-bt {
  font-size: 15px !important;
}

.collection-team img {
  width: 50px !important;
  height: 50px !important;
  border-radius: 100%;
  border: 3px solid #fff;
  object-fit: cover;
  margin-right: 5px;
}

.collection-team a {
  color: white !important;
}

.owner-list img {
  width: 55px;

  height: 55px;

  border-radius: 50%;

  border: 3px solid #fff;

  object-fit: cover;
}

.linking-modal .modal-dialog {
  max-width: 410px;
}

.linking-modal .modal-content {
  padding: 16px 0 !important;
}

.linking-modal a {
  background: #fe7702;
  display: inline-block;
  color: #fff !important;
  width: 250px;
  border-radius: 10px;
  padding: 17px 0;
  margin: 5px 0;
  font-size: 16px;
}

.buy-now-modal a.buy-now-bt {
  width: 120px;

  margin-top: 10px;
}

.owner-list .sale-auction-tab li.nav-item button.nav-link.active {
  background-color: rgba(255, 0, 0, 0) !important;
}

.all-owner-box .tab-content {
  margin-left: 20px !important;
}

.all-owner-box .tab-content input {
  font-size: 14px;

  padding: 7px !important;

  height: 31px;

  max-width: 170px;

  margin-bottom: 5px;
}

.buy-now-modal .modal-content {
  padding-bottom: 0;
}

.buy-now-modal input {
  margin-bottom: 16px;
}

.buy-now-modal label {
  margin-bottom: 0;
}

.buy-now-modal {
  text-align: left;
}

.buy-now-modal .modal-dialog {
  max-width: 480px;
}

.no-data-new {
  text-align: center;
}

.creater-collection-area a {
  color: #fff !important;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.collection-up-both-img .modal-content {
  padding: 0 !important;
}

.finda-display {
  margin-top: 30px !important;
}

.new-banner-area {
  position: relative;

  margin-top: 100px;
}

.discord {
  color: #bf74cb !important;

  font-size: 20px;

  background: white;

  width: 100px;

  position: absolute;

  top: 50%;

  left: 50%;

  width: 200px;

  border-radius: 50px;

  text-align: center;

  padding: 11px 0;

  transform: translate(-130%, -50%);
}

.term-privacy-box-new {
  display: flex;

  /* align-items: flex-end; */
}
.collapse {
  visibility: unset;
}
.buy-now-modal.buy-modal.action-main-btn {
  display: flex;
  width: 100%;
  align-items: center;
}
.buy-now-modal.buy-modal.action-main-btn button {
  width: 100%;
  padding: 11px !important;
  text-align: center;
  margin: 0 2px;
  white-space: nowrap;
}
.edit-profile-modal.action-main-btn .modal-dialog {
  max-width: 530px;
  margin: 0 auto;
}
.rightsdefoote {
  margin: 30px 0;
  display: flex;
  align-items: center;
}
.buy-now-modal .buy-now-bt {
  height: 43px;
  font-size: 17px !important;
  border-radius: 5px !important;
  padding: 0 10px !important;
  margin-top: 0;
}
.buy-now-bt.nft-buy-now {
  height: 40px !important;
  width: 130px !important;
  border-radius: 5px !important;
  margin-top: 0;
}

.term-page.about-page {
  padding: 150px 0 50px;
}

.forgot-password-page {
  background-image: url("../images/about_bg.jpg");

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  padding: 120px 0 60px;
  height: 75.2vh;
}
.forgot-password-form {
  margin-top: 13vh !important;
}

.contact-us-page {
  background-image: url("../images/about_bg.jpg");

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  padding: 120px 0 60px;
  min-height: 750px;
  max-height: 1600px;
}

.about-page {
  background-image: url("../images/about_bg.jpg");

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  padding: 120px 0 60px;
  min-height: 900px;
}

.about-page h1 {
  font-size: 80px;

  text-transform: uppercase;

  font-weight: 700;
}

.about-page h2 {
  font-size: 50px;

  text-transform: uppercase;

  font-weight: 700;

  margin-bottom: 0;
}

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

.about-page h1 span {
  color: #fe7702;
}

.about-page p {
  max-width: 630px;

  font-size: 18px;

  line-height: 32px;
}

.about-page h3 {
  font-size: 40px;

  margin: 30px 0;
}

.filda-bounding {
  background-image: url("../images/about_bg_two.jpg");

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  padding: 60px 0;
}
.create-nft-bottom-btns {
  display: flex;
  align-items: center;
}
.save-to-draft-btn-right {
  margin-right: 10px;
}
.listingsection .nft-collection-box ul {
  margin: auto !important;
  padding: 7px 7px;
}

.my_listing-area.listingsection {
  background-color: white;
  padding-top: 10px;
}

.listingsection .nft-collection-box li {
  padding: 11px 12px;
}

.rightside_border {
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  background-color: #ffffff00;
  border-color: #ffffff7d;
  padding: 8px 10px 2px 10px;
  /* width: 280px; */
}
.buy-sell-created {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.install-metamask {
  text-align: center;
}

.install-metamask-modal .modal-content {
  width: 70% !important;
  margin: 0 auto;
  padding: 10px 0 15px 0;
}

.install-metamask img {
  width: 80px;
}
.rightside_border .created {
  font-size: 14px;
  margin-bottom: 5px;
}
/* .created_use_img {
  filter: invert(1);
} */

.rightside_border p {
  font-size: 17px;
}

.wallet_num {
  display: inline-block;
  align-items: center;
  font-size: 14px;
  border-radius: 100px;
  line-height: 22px;
  text-align: center;
  color: grey;
  background: rgb(255 255 255);
  transition: all 0.12s ease-in-out 0s;
  padding: 6px 18px;
  margin-left: 34px;
}
.expired-area {
  display: unset;
}
.rightside_border div {
  position: relative;
  padding-left: 25px;
}

.rightside_border div span {
  color: white;
  font-size: 17px;
}

.rightside_border div img {
  position: absolute;
  left: 0;
}
.created_use_img {
  width: 35px;
  height: 35px;
  border-radius: 100px;
  object-fit: cover;
  overflow: hidden;
  margin-top: -4px;
}
.listingsection .nft-collection-box li.react-tabs__tab--selected {
  background: #fe7702 !important;
}

.founding-div {
  /* max-width: 650px; */

  text-align: center;

  margin: 100px auto 0;
}

.rightsdefoote a {
  padding-right: 18px;
}

.member-one {
  background: #1b1225;
  border-radius: 15px;
  padding: 15px;
}

.our-team h2 {
  font-size: 50px;
  color: #fff;
  margin-top: 60px;
}

.our-team h2 span {
  color: #fe7702 !important;
}

.member-one {
  background: #2a202d;
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 25px;
  position: relative;
  z-index: 1;
  height: 100%;
}

.member-info p {
  color: #ffffffad;
  margin-bottom: 0 !important;
}

a.linkedin-bt {
  position: absolute;
  bottom: -27px;
  left: 50%;
  transform: translate(-50%, 0);
}

.our-team .col-md-6 {
  padding: 0 15px !important;
}

.user-img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 20px;
  /* position: relative; */
}
.trash-icon-area {
  position: absolute;
  right: 26px;
  top: -15px;
  z-index: 0124;
}
.trash-area-box {
  position: relative;
}
.draft-area-edit i {
  color: #ffffff;
  background: #fe7702;
  font-size: 15px;
  border-radius: 23px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
}
.trash-icon-area i {
  color: red;
  background: #111722;
  font-size: 16px;
  border-radius: 23px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
}
.button-disables button {
  padding: 9px 30px;
  background: darkgray;
  color: #000;
  border-radius: 5px;
  font-weight: 500;
}
.draft-bottom-area {
  height: unset;
}
.draft-bottom-area h5.echo-two {
  padding-bottom: 10px;
}
.draft-bottom-area h6.attri-price {
  padding-bottom: 10px;
}
.draft-area-edit {
  position: absolute;
  z-index: 1023;
  right: 66px;
  top: -15px;
}
.user-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.member-one {
  text-align: center;
}

.air-max {
  margin-bottom: 20px;
}

.founding-div h4 {
  line-height: 40px;

  font-size: 27px;
}

.founding-div p {
  font-size: 18px;
}

.greate-our-goal {
  background-image: url("../images/goal.jpg");

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  padding: 60px 0;
}

.greate-left h2 {
  font-size: 60px;

  font-weight: 700;
}

.greate-left h2 span {
  color: #000;
}

.greate-left p {
  color: #000;
}

.greate-left {
  padding: 0 50px;
}

.footer-link {
  color: #fff !important;
}

.home_banner_section {
  /* background-image: url('../images/home_banner.jpg'); */

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;
  padding: 180px 0 90px;
}

.view-balance-box {
  min-height: 650px;
}

:focus {
  box-shadow: none !important;
  outline: 0 !important;
}

fieldset,
img {
  border: 0 none;
}

input[type="submit"],
select {
  -webkit-appearance: none;
}

html {
  scroll-behavior: smooth;
}

.alice-carousel__dots {
  display: none !important;
}

.alice-carousel__next-btn-item {
  background: #fe7702;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 30px;
  color: #fff !important;
  position: absolute;
  right: 0;
  top: 130px;
}
span.put-on-auction-note {
  /* font-style: italic;
  font-weight: bolder;
  font-size: 15px;
  color: #fe7702; */

  font-style: italic;
  font-weight: bolder;
  font-size: 15px;
  color: #7d02fe;
}

.nft-prize-usd {
  text-align: end;
  font-size: 14px;
  padding-top: 2px;
}
.alice-carousel__prev-btn-item {
  background: #fe7702;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 30px;
  color: #fff !important;
  position: absolute;
  left: 0;
  top: 130px;
  text-align: center;
}

img {
  border: none;
  height: auto;
  max-width: 100%;
  outline: 0;
  vertical-align: middle;
}

iframe {
  vertical-align: middle;
}

a:hover {
  text-decoration: none;
  color: #6bcbfc;
}

a,
abbr,
acronym,
address,
article,
aside,
b,
bdo,
big,
blockquote,
body,
br,
button,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
img,
ins,
kbd,
legend,
li,
map,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
ul,
var {
  border: medium none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none !important;
}

input[type="submit"],
select {
  -webkit-appearance: none;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}

header {
  padding: 9px 0;
  position: fixed;
  top: 0;
  z-index: 1024;
  width: 100%;
  background: #000;
}

.rightsdefooter p a {
  color: #fff;
}

.searchbar_header input {
  background: #f6f7fa !important;
  border-radius: 16px;
  border: 0;
  height: 56px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #081946;
  min-width: 330px;
}

.searchbar_header {
  position: relative;
  margin-left: 20px;
}

.bg-white {
  box-shadow: 2px 2px 2px #e1e1e138;
}

.profile-pagination {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-pagination li {
  list-style: none;
}

.profile-pagination li a {
  border: 1px solid #ffffff38;
  margin: 0 5px;
  display: block;
  padding: 5px 12px;
  border-radius: 5px;
  color: #fff;
}

.profile-pagination li.selected a {
  background: #fe7702;
}

.searchbar_header button {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translate(0, -50%);
}

.more-games:hover {
  color: #fe7702;
}

header .startselling_btn {
  background: #fe7702;
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  height: 52px;
}

.btm_details.market-btm-details {
  position: relative;
  top: inherit !important;
  left: inherit !important;
}

.btm_details.market-btm-details h6.bid-text-two {
  padding-left: 0 !important;
}

button.bg-transparent.border-0.subscribe-bt.btn.btn-unset {
  background: #fe7702 !important;
  height: 50px;
  width: auto;
  color: #fff;
  font-size: 18px;
  border-radius: 10px 10px 0 0;
}

.navbar-nav .dropdown-item .startselling_btn {
  text-align: left;
  border-radius: 0 0 10px 10px;
  width: 100%;
  border-top: 1px solid #ffffff3d;
  margin: 0;
}

.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.navbar-nav .dropdown-menu.show {
  top: 30px;
  min-width: 100%;
}

#google_translate_element {
  position: relative;
}

#details-collapse-text {
  margin-bottom: 20px;
  max-width: 300px;
}

#details-collapse-text a {
  color: #fff;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

.hot-collection-comming-soon {
  min-height: 300px;
}

.hot-collection-comming-soon h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#google_translate_element i {
  position: absolute;
  right: 10px;
  top: 16px;
  color: #fff;
  font-size: 12px;
}

.skiptranslate.goog-te-gadget {
  height: 55px;
  overflow: hidden;
}

header .nav-link {
  font-style: normal;
  font-weight: 500;
  padding: 0 0 6px 0 !important;
  font-size: 14px;
  border-radius: 0;
  margin: 0 20px;
  line-height: 20px;
  color: rgb(255 255 255) !important;
  border-bottom: 4px solid #fff;
}

.modal-email span {
  color: #fe7702;
}

header .nav-link:hover {
  color: #fe7702 !important;
}

header .active.nav-link {
  color: #fe7702 !important;
  border-bottom: 4px solid #fe7702;
}

header .nav-link {
  border-bottom: none;
}

header .login_header .logintext {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #081946;
}

.down-scroll {
  margin-top: 130px;
}

.searchbar_header input::-webkit-input-placeholder {
  color: #4f0c75;
}

.searchbar_header input::-moz-placeholder {
  color: #4f0c75;
}

.searchbar_header input:-ms-input-placeholder {
  color: #4f0c75;
}

.searchbar_header input:-moz-placeholder {
  color: #4f0c75;
}

.banner_section {
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: center;
  position: relative;
  padding: 135px 0;
  overflow: hidden;
  margin-top: 93px;
}

.banner_section .gobackbtn {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 99px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fff;
}
.main-term-box h5 {
  margin-top: 20px;
}

.banner_section h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  color: #fff;
  margin: 35px 0;
}

.banner_section p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #fff;
}

.banner_section h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-decoration-line: underline;
  color: #fe7702;
  cursor: pointer;
}

.banner_section .connectbtn {
  background: #fe7702;
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: #f8f6f6;
  padding: 17px 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 270px;
  margin: 40px 0;
}

.banner_section .connectbtn img {
  transform: rotate(180deg);
}

.images_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner_section p.dulltext {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #ffffffa6;
  margin-top: 25px;
}

footer {
  padding: 70px 0;
  background-color: #4f0c75;
}

.footer_head {
  display: flex;
  justify-content: end;
  align-items: center;
}

.footer_head h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  letter-spacing: -0.3px;
  color: #fff;
}

.footerline {
  background: #fff;
  opacity: 0.14;
  height: 1px;
  width: 100%;
}

footer h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  letter-spacing: -0.3px;
  color: #fff;
  margin-bottom: 35px;
  width: 50%;
}

.searchbar_header.footer input {
  border-radius: 0;
  background-color: transparent !important;
  border-bottom: 1px solid #ffffff1f;
  padding: 0;
  font-weight: 300;
  color: #fff;
  height: 52px;
  padding: 0 126px 0 10px;
}

.featured-area {
  text-align: center;
  padding: 40px 0;
}

.searchbar_header button.btn-unset {
  margin: 0 !important;
}

.searchbar_header.footer button {
  right: 0;
}

.searchbar_header.footer input::-webkit-input-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer input::-moz-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer input:-ms-input-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer input:-moz-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer {
  margin-left: 0;
}

.common_bg {
  background-color: #120715;
  position: relative;
}

.rightsdefooter h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: #ffd2dd;
  margin-bottom: 15px;
}

.img_position_1 {
  position: absolute;
  top: 0%;
  z-index: 1;
}

.common_padding_1 {
  padding: 100px 0;
}

.main_h2.underline_text {
  position: relative;
  font-size: 40px;
  display: inline-block;
  z-index: 2;
}

.count_box h5 {
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
}

.circle_row {
  position: relative;
}

.circle_row .col-md-6 {
  position: relative;
  padding: 0 85px 0 11px !important;
  z-index: 2;
}

.circle_row .col-md-6:last-child {
  padding: 0 0px 0 85px !important;
}

.circle_img_postion {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 61% !important;
}

.touch_section {
  /* padding-top: 80px;
  padding-bottom: 100px; */
  overflow: hidden;
  position: relative;
  padding: 50px 0;
}

.touch_section .container {
  position: relative;
  z-index: 2;
}

.circle_img_postion1 {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100% !important;
  z-index: 0;
}

.touch_section .circle_img_postion1 {
}

.touch_section .underline_text:before {
  position: absolute;
  content: "";
  bottom: -12px;
  height: 1px;
  background-color: white;
  width: 200px;
  left: 50%;
  transform: translate(-50%, 0);
}

.touch_section .underline_text:after {
  position: absolute;
  content: "";
  bottom: -13px;
  width: 18%;
  height: 4px;
  background-color: #fe7803;
  left: 50%;
  transform: translate(-50%, 0);
}

.service_section1 .col-lg-3 {
  padding: 0 12px !important;
}

.touch_form {
  background-color: #2a202d8a;
  border-radius: 10px;
  padding: 37px 80px;
  margin-bottom: 32px;
  box-shadow: rgb(163 163 163 / 12%) 0px 5px 15px inset;
}
.modal-footer {
  border: none !important;
}
.no-data img {
  opacity: 0.5;
}
.no-data {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.main_footer p,
.main_footer a {
  color: #ffffff !important;
  font-size: 16px;
}

.main_footer span {
  height: 100%;
  width: 1px;
  background-color: white;
  display: inline-block;
}
.login-form-box input,
.login-form-box textarea {
  background-color: transparent !important;
  border: 1px solid #ffffff8c;
  border-radius: 5px;
  padding: 12px;
  color: white !important;
  /* margin-bottom: 15px; */
  font-size: 16px;
}
.submit-bt {
  width: 100px !important;
}

.touch_form input,
.touch_form textarea {
  background-color: transparent !important;
  border: 1px solid #ffffff8c;
  border-radius: 5px;
  padding: 12px;
  color: white !important;
  /* margin-bottom: 15px; */
}

.touch_form .col-md-6 {
  padding: 0 12px !important;
}

.count_box {
  margin-bottom: 50px;
}

.main_footer {
  background-color: #0d010d;
}

.count_box p {
  color: #c8ceec;
  font-size: 17px;
  font-weight: normal;
  margin-left: 57px;
  position: relative;
  padding-left: 28px;
}

.count_box p span {
  color: white;
  padding-right: 5px;
  position: absolute;
  top: 0;
  left: 0;
}

.count_box h5 span {
  width: 35px;
  height: 35px;
  background-color: white;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 19px;
  margin-right: 20px;
}

.circle_row {
  position: absolute;
  top: 0%;
  z-index: 1;
}

.service_section1 {
  padding-top: 80px;
}

.underline_text:before {
  position: absolute;
  content: "";
  bottom: -12px;
  width: 100%;
  height: 1px;
  background-color: white;
}

.underline_text:after {
  position: absolute;
  content: "";
  bottom: -13px;
  width: 67%;
  height: 4px;
  background-color: #fe7803;
  left: 50%;
  transform: translate(-50%, 0);
}

.common_box_content {
  background-color: #10122d;
  padding: 30px 20px 10px;
  border-radius: 10px;
  height: 100%;
  position: relative;
}

/* .box1_shade{
    content: '';
    position: absolute;
    top:0;
    left: 0;
    background-image: image();
} */
.box1_shade .img_position_1 {
  left: 0;
  height: 100%;
  width: 100%;
}

.relative-div {
  position: relative;
  z-index: 2;
}

.common_box_content h5 {
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
}

.common_box_content p {
  color: #c8ceec;
  font-size: 17px;
  font-weight: normal;
}

.service_section1 {
  position: relative;
}

.service_section1 .img_position_2 {
  position: absolute;
  top: 0%;
  z-index: 1;
  max-width: 350px;
  left: -7%;
}

.main_h2 {
  font-size: 45px;
  color: #ffffff;
  font-weight: 700;
}

.section_positon {
  position: relative;
  z-index: 1;
}

.p_text {
  color: #c8ceec;
  font-size: 18px;
  font-weight: normal;
  width: 75%;
  margin-right: auto;
}

.shades-box {
  position: absolute;
  top: -290px;
  z-index: 0;
}

.App .common_bg .container {
  max-width: 1140px;
}

.main_h2 span {
  color: #fe7803;
}

.rightsdefooter p {
  font-style: normal;
  font-weight: 200;
  font-size: 15px;
  cursor: pointer;
  color: #fff;
}

.leftfooter {
  padding-right: 180px;
}

.collection_section {
  padding: 90px 0;
}

.market-btm-details .echo-two {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.preview-image video.video-container.video-container-overlay {
  width: 100%;
  height: -webkit-fill-available;
}

.sale-auction-tab li.nav-item button {
  border-radius: 10px !important;
}
button.profile-area-submit-btn {
  width: 100px !important;
}
.collection_section h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  color: #000;
}
fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.collection_section h5 {
  font-style: normal;
  font-weight: 200;
  font-size: 21px;
  color: #6b6b6b;
}

.time_row {
  background-color: #4f0c75;
  border-radius: 100px;
  padding: 7px 10px;
  display: inline-block;
  margin-top: 10px;
}

.time_row span {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  padding: 0 4px;
}

.collection_box h3 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin-top: 12px;
}

.userpic_name {
  color: #000;
  font-size: 16px;
  font-weight: 200;
}

.userpic_name {
  position: relative;
  padding-left: 46px;
  padding-top: 2px;
  margin-top: 12px;
}

.userpic_name img {
  width: 33px;
  position: absolute;
  left: 0;
  top: 0;
  height: 33px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 100%;
}

.collection_box {
  border: 1px solid #7070704d;
  border-radius: 17px;
  padding: 17px;
  box-shadow: 0 0 13px -1px #00000024;
}

.collection_img {
  margin-bottom: 5px;
}

.collection_img img {
  max-width: 100%;
  height: 300px;
  width: 100%;
  border-radius: 14px;
  object-fit: cover;
  overflow: hidden;
}

.collectible-upload-image h6 {
  color: #4f0c75;
  font-size: 18px;
  margin: 0;
}

.collectible-upload-image {
  background: #fff;
  box-shadow: 0 0 15px rgb(17 47 73 / 15%);
  border-radius: 15px;
  cursor: pointer;
  padding: 1px 0;
}

.collectible-box {
  position: relative;
  margin: 0 20px;
}

.uplage-image-collect {
  position: absolute;
  height: 290px;
  opacity: 0;
}

.name-description-filed {
  background: #fcfaff;
  padding: 30px 0;
  margin-top: 30px;
}

.collectible-bg {
  /* background: #fcfaff; */
  padding: 100px 0;
  border: 2px dashed #d7d7d7;
  margin: 20px;
  border-radius: 15px;
}

.name-description-filed input,
.name-description-filed textarea {
  border-radius: 10px;
  height: 48px;
  font-size: 14px;
}

.start_end_row .MuiFormControl-root {
  width: 100% !important;
}

.create-bt {
  background: #fe7702;
  color: #fff;
  width: 130px;
  height: 37px;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 20px;
}

.preview-text {
  color: #00000057;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.preview-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.description-toggle {
  margin-left: auto;
}

.spinner-section {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 5px;
}
.right-icon {
  width: 70px;
  text-align: center;
}

.sl-spinner3 .sl-loader .sl-loader-parent .sl-circle {
  width: 60px !important;
  height: 60px !important;
}

.grey-check {
  color: #d3d3d3 !important;
  font-size: 40px;
}

.form-switch .form-check-input {
  width: 62px !important;
  height: 25px !important;
}

.form-check-input:checked {
  background-color: #4f0c75 !important;
  border-color: #4f0c75 !important;
}

.auction-toggle.area label {
  margin-bottom: 0;
}

.home-banner-left {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tyler-hero {
  color: #4f0c75;
  background: #4f0c7521;
  padding: 8px 20px;
  border-radius: 50px;
  font-size: 16px;
}

.home-banner-left h1 {
  font-size: 64px;
  color: #fff;
  font-weight: 700;
  margin-top: 15px;
}

.home-banner-left p {
  font-size: 20px;

  color: #ffffff;
}

.nft-market-checkbox li {
  display: inline-block;
  margin-right: 25px;
}

.nft-market-checkbox {
  padding: 0;
  margin-bottom: 41px;
}

.collecting-bt i {
  margin-left: 15px;
}

.customer-box li {
  margin-left: -22px;
  display: inline-block;
}

.customer-box {
  padding: 0;
}

.customer-box li:last-child {
  margin-left: 20px;
}

.customer-box li:last-child {
  margin-left: 23px;
}

.customer-box {
  margin: 71px 0 50px 20px;
  display: flex;
  align-items: center;
}

.connect-wallet-bt {
  border-radius: 10px;
  border: 2px solid #fe7702;
  padding: 17px 15px 17px;
  text-align: center !important;
  margin: 0 10px;
  color: #fe7702;
}

.dropdown-menu.show {
  border: none;
  padding-left: 0;
  background: 0 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background: 0 0 !important;
}

a.dropdown-item {
  padding: 0;
}

.explore-heading {
  text-align: center;
  color: #fff;
  font-size: 48px;
  padding: 60px 0;
}

.explore-heading span {
  color: #fe7702;
}

/* .no-data {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

#hot-collection {
  /* background-image: url(../images/explore.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  color: #fff;
  text-align: center;
  position: relative;
  padding: 10px 0 40px;
}

.list-tab-box.tab-content {
  width: 100%;
  padding: 20px 0;
}

.more-div {
  margin-bottom: 60px;
}

.name-dec {
  cursor: pointer;
}

.hot-heading {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 50px;
}

.collection-head-new {
  background: #fff;
  color: #4f0c75;
  border-radius: 30px;
  padding: 7px 14px;
  line-height: 97px;
}

#hot-collection .tab-pane {
  margin: 0 10px;
}

.bt-div {
  margin-top: 10px;
}

.tab_box_home h4 {
  font-style: normal;
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 14px;
  color: #12041e;
}

.explore-box .col-md-3 {
  padding-right: 0;
}

.tab_box_home.active {
  background: #fe7702;
  min-height: 59px;
}

.tab_box_home.active h4 {
  color: #fff;
}

.touch_form p.danger {
  font-size: 15px;
  line-height: 5px;
  color: red !important;
}

.upcoming-events {
  background: #342b38;
  padding: 50px;
}

.upcoming-events .soccor-text {
  margin-bottom: 50px;
}

.event-dates h4 {
  margin: 0;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Space Grotesk", sans-serif;
}

.event-dates h3 {
  font-size: 38px;
  text-transform: uppercase;
  margin: 0;
  font-weight: 500;
  color: #fd7803;
  font-family: "Space Grotesk", sans-serif;
}

.event-dates {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  border: 1px solid #c66311;
  padding: 8px 50px;
  margin-top: 20px;
}

.upcoming-events {
  background: #342b38;
  padding: 50px;
  margin: 90px 0 20px;
  position: relative;
}

.upcoming-events:after {
  content: "";
  background: #342b38b5;
  height: 20px;
  position: absolute;
  bottom: -17px;
  left: 50%;
  z-index: 0;
  width: 95%;
  transform: translate(-50%, 0);
}

.upcoming-events:before {
  content: "";
  background: #342b386b;
  height: 20px;
  position: absolute;
  bottom: -35px;
  left: 50%;
  z-index: 0;
  width: 88%;
  transform: translate(-50%, 0);
}

.upcoming-events .soccor-text span {
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fd7803;
}

.event-dates.event-dates-two {
  border: 1px solid #c663113d;
}

input.btn.btn-unset.submit-input {
  background-color: #fe7702 !important;
  background: #fe7702 !important;
  color: #fff !important;
  border-radius: 12px;
  margin: 0 7px 0 0;
  height: 52px;
  border: none !important;
  width: -moz-fit-content;
  width: fit-content;
}

.explore-box h1 {
  margin-bottom: 70px;
}

.tab_box_home.active h5 {
  color: #fff;
  opacity: 0.5;
}

.tab_box_home h5 {
  font-style: normal;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
  color: #c2bfe8;
}

.tab_box_home span {
  position: absolute;
  top: 50%;
  right: 20px;
  cursor: pointer;
  transform: translate(0, -50%);
}

img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-30px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-30px);
  }
}

.market_banner_section {
  background-color: #000;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  color: #fff;
  text-align: center;
  position: relative;
  padding-bottom: 60px;
}

.market-left h1 {
  font-size: 90px;
  background: -webkit-linear-gradient(45deg, #f8a8ff, #6155ed);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  font-weight: 800;
  margin-bottom: 30px;
}

#nft-feature {
  /* background-image: url(../images/feature.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  color: #fff;
  text-align: center;
  position: relative;
  padding-bottom: 60px;
}

.searchbar_item video {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 10px;
}

.explore-box {
  /* background-image: url(../images/explore_bg.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  color: #fff;
  position: relative;
  padding: 60px 0;
}

.isolate-inheritance ._1CW8I {
  opacity: 0 !important;
  cursor: pointer;
}

.header-dropdown.dropdown-menu.show {
  background: #fe7702;
  padding: 5px 10px 0 10px;
}

.header-dropdown.dropdown-menu.show a {
  color: #fff !important;
  border-top: 1px solid #ffffff59;
  padding: 3px 0;
  font-size: 14px;
}

.header-dropdown.dropdown-menu.show a:first-child {
  border-top: none;
}

.header-dropdown.dropdown-menu.show a:hover {
  background: #ff861e !important;
}

.header-dropdown.dropdown-menu.show button {
  background: none;
  border: none;
  padding: 0 0 8px;
}

.navbar-nav .logout-dropdown.dropdown-menu.show {
  left: -62px !important;
  padding: 2px 8px;
}

.touch-bt-two {
  background: #fe7702 !important;
  color: #fff !important;
  border-radius: 12px !important;
  height: 52px;
  line-height: 39px !important;
}

.discord-section-bg {
  background: url("../images/discord-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  position: relative;
  height: 370px;
  max-height: 500px;
  margin: 100px 0 30px 0;
}

.discord-section-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.discord-section-content h2 {
  color: #fff;
  font-size: 50px;
  font-weight: 700;
}

.discord-section-content h2 span {
  color: #fe7702;
}

.discord-section-content p {
  font-size: 28px;
  color: #fff;
  font-weight: 600;
  line-height: 55px;
  margin-bottom: 20px;
}

.discord-section-content a {
  background: #fe7702;
  padding: 15px 40px;
  color: #fff !important;
  border-radius: 10px;
  font-size: 18px;
  letter-spacing: 1px;
}

.more-games {
  color: #fff;
  font-size: 17px;
  line-height: 60px;
}

#nft-feature h3 {
  color: #fff;
  margin-top: 20px;
}

#nft-feature h3 {
  color: #fff;
}

.step-two {
  padding-top: 40px;
}

.step-three {
  padding-top: 80px;
}

#nft-feature p {
  color: #ffffff70;
  padding: 0 120px;
}

.contact-box-area {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  padding: 60px 0;
}

.contact-left-side h3 {
  color: #fff;
  font-size: 40px;
  line-height: 63px;
  padding: 40px 0 0 70px;
}

.contact-left-side {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

.contact-right-side input {
  background: 0 0 !important;
  border-bottom: 1px solid #ffffff8c !important;
  border: none;
  border-radius: 0;
  color: #fff !important;
  padding-left: 0;
  padding-right: 0;
  margin-top: 50px;
}

.contact-right-side input::placeholder {
  color: #fff !important;
}

.contact-form .btn {
  display: flex;
  margin: 14px auto;
}

.contact-right-side {
  display: flex;
  margin: 25px auto;
}

.contact-form {
  background: #ffffff12;
  border-radius: 20px;
  border: 2px solid #ffffff14;
  padding: 10px 80px 10px 100px;
  position: absolute;
  bottom: 0;
}

.contact-filled-box .row {
  position: relative;
}

.choose-heading {
  font-size: 20px;
}
.air-max.Royality-max {
  justify-content: left;
}
.single-multiple-box .react-tabs__tab-list {
  display: inline-block;
}

.addresstext {
  position: relative;
}

.addresstext i {
  position: absolute;
  right: 0;
  top: 0;
}

.single-multiple-box .react-tabs__tab--selected {
  border-radius: 5px;
  border: none !important;
  background: #fe7702 !important;
  width: 135px !important;
  text-align: center;
  color: #fff !important;
}

.create-items li p {
  margin: 0;
}

.single-multiple-box .react-tabs__tab {
  border: none !important;
  padding: 6px 12px;
  cursor: pointer;
  background: #f0ebf6;
  color: #0000008c;
  width: 160px;
  border-radius: 5px;
  font-size: 20px;
  text-align: center;
}

.create-items h6 {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 16px;
  max-width: 100px;
  margin: 0 auto !important;
}

.items-area-box-two img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 2px;
}

.create-items h6 {
  margin: 0;
}

.searchbar_header input {
  padding: 0 68px 0 15px;
}

.create-items ul li {
  display: flex;
  justify-content: center !important;
  border: 1px solid #0000003d;
  border-radius: 5px;
  width: 110px;
  height: 110px;
  margin-right: 15px;
  cursor: pointer;
  padding: 12px 0;
  align-items: center;
}

.items-area-box,
.items-area-box-two {
  text-align: center;
}

.create-items ul {
  display: flex;
}

.modal-profile-img {
  position: relative;
  height: 30px;
}

.cover-image-box img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

form.text-left {
  text-align: left;
  margin-top: 70px;
}

.profile-input-mod {
  width: 135px !important;
  height: 120px;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.img-input-mod {
  position: absolute;
  top: 0;
  height: 270px;
  width: 94%;
  opacity: 0;
}

.collection-create .modal-content {
  padding: 0;
}

.create-items ul {
  padding: 0;
}

.copies-input {
  max-width: 200px;
}

.single-multiple-box .react-tabs__tab-list {
  border-bottom: none !important;
  margin-bottom: 20px;
}

.modalclose_icon i {
  margin-top: -2px;
}
.collection-create .modal-footer {
  padding-top: 0;
}
/* .modalclose_icon {
  width: 30px;
  cursor: pointer;
  height: 30px;
  background: #fe7702;
  color: #fff;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
} */

.uploadimg {
  display: inline-block;
  position: relative;
}

.uploadimg input {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  opacity: 0;
}

.addresstext {
  padding-right: 38px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.slide-1 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.tenis-img-two {
  margin-left: -20px;
}

.like-view {
  text-align: center;
  color: #fff;
  margin: 0 15px;
}

.like-view i {
  color: #fc3a3a;
}

.in-stock {
  background: #00000085;
  padding: 10px 13px;
  color: #fff;
  margin-left: auto;
  border-radius: 30px;
}

.slide-percent {
  font-size: 70px;
  color: #fff;
  padding: 80px 30px 0;
  margin-bottom: 0;
}

.slide-progress {
  color: #fff;
  border: 2px solid #fe7702;
  display: inline-block;
  padding: 3px 11px;
  margin-left: 30px;
}

.echo-two {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 1.6em;
  color: #fff;
  font-size: 18px;
}

.echo {
  color: #fff;
  padding: 140px 30px 0;
}

.items-name h5 {
  color: #fff;
  font-size: 18px;
}

.bid-text {
  padding-left: 30px;
  color: #fff;
  font-size: 14px;
}

.bid-text span {
  color: #fe7702;
}

.bid-text-two span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.bid-text-two {
  padding: 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.our-team {
  background: #000;
  padding: 1px 0 70px 0;
  position: relative;
}

.shades-box-two {
  position: absolute;
  right: 0;
  bottom: -370px;
  pointer-events: none;
}

.bid-text-two.bid-usd-box {
  padding-left: 8px;
}

.member-info span {
  font-size: 14px;
  color: #fe7702;
}

.member-info h5 {
  font-size: 22px;
  color: #c8ceec;
  font-weight: 400;
}

.mainimg {
  height: 246px;
}

.tenis-box {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  position: absolute;
  top: 4px;
  left: 0;
}

nav.home_header {
  padding: 8px 0;
}

.morefaq {
  background: #fe7702;
  color: #fff !important;
  padding: 9px 20px;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: fit-content;
}

li.alice-carousel__stage-item {
  padding: 0 10px;
}

.social-icon li {
  display: inline-block;
  margin: 0 22px;
}

.social-icon {
  padding: 0;
  text-align: center;
  border-radius: 10px;
}

.slide-margin {
  margin: 7px 5px;
}

.react-multi-carousel-track:first-child
  .react-multi-carousel-item.react-multi-carousel-item--active {
  display: none;
}

.errors {
  color: red;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  display: block;
}

.end-reserve-bid-box input {
  margin-bottom: 7px;
  width: 97%;
}

.end-reserve-bid-box {
  margin: 28px 23px 0;
  width: 90%;
}

.meta-mask-modal a b {
  text-decoration: underline;
}

.meta-mask-modal h3 {
  font-size: 24px;
  color: #000;
}

.meta-mask-modal .modal-content {
  border-radius: 10px;
  /* padding: 40px 0; */
  /* background-image: url(../images/modal-bg.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.modal-header h3 {
  font-size: 20px;
}

.modal-header {
  padding-bottom: 0 !important;
}

.meta-mask-modal a {
  color: #fe7702;
}

.ok-bt.btn::after {
  content: "";
  /* background-image: url(../images/line.png); */
  background-size: contain;
  position: absolute;
  height: 50px;
  width: 50px;
  right: -70px;
  top: -25px;
}

.sale-auction-tab {
  border: none !important;
}

.sale-auction-tab input {
  max-width: 240px !important;
  height: 46px;
  border-radius: 10px !important;
  border: none !important;
}

.sale-auction-tab li.nav-item button {
  width: 160px;
  border-radius: 10px;
  color: #5a0075;
  border: 1px solid #fff;
  box-shadow: 2px 4px 1px #b04f19;
  background: #ffffff;
  margin: 0 5px;
}

.alice-carousel__stage-item * {
  max-width: 400px !important;
}

.date-box .MuiFormControl-root {
  width: 100%;
}

.tab-one {
  max-width: 280px;
}

.tab-one input {
  height: 46px;
}

.tab-bid-box label {
  background: #fff;
  padding: 0 9px !important;
  border-radius: 5px;
}

.tab-bid-box input {
  width: 97%;
  height: 46px;
  border-radius: 10px;
}

.profile-box {
  background: #f3f3f3;
  margin: 86px 0 0 0;
  padding: 70px 0;
}

.profile-left-side img {
  width: 220px;
  height: 220px;
  border-radius: 15px;
  object-fit: cover;
}

.edint-pencil {
  background: #fe7702;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  font-size: 13px;
  position: absolute;
  right: -15px;
  top: -16px;
  cursor: pointer;
}

.profile-Right-side {
  text-align: left;
  padding: 0 0 0 18px;
}

.profile-content h5 {
  color: #47036f;
}

.logout-flag button {
  background: none !important;
}

.logout-flag {
  font-size: 15px;
  color: #fe7702 !important;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.profile-content h6 {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-weight: 500;
}

.logout-flag i {
  color: #fe7702 !important;
}

.profile-content h6 a,
.profile-content h6 i {
  color: #fe7702 !important;
}

.profile-Right-side i {
  font-size: 18px;
  margin-right: 8px;
}

.profile-Right-side .fa-address-book-o {
  margin-right: 0;
}

.modal-img-new {
  width: 160px;
  height: 160px;
  object-fit: cover;
}

.profile-Right-side h2 {
  font-size: 22px;
  color: #47036f;
  display: inline-flex;
  align-items: center;
}

.edit-profile-modal .modal-dialog {
  max-width: 410px;
}

.modal-form-new button.ok-bt {
  background: #fe7702;
  padding: 7px 20px;
  border-radius: 5px;
  color: #fff;
  margin: 0 auto;
  display: block;
}

.modal-form-new {
  text-align: left;
  margin: 0 60px;
}

.profile-Right-side h4 {
  font-size: 15px;
  color: #fe7702;
}

.bid_auction .tab-content {
  margin: 0 13px 0 0 !important;
}

.tab-bid-box .row {
  --bs-gutter-x: 0;
}

.sale-auction-tab .nav-item.show .nav-link,
.sale-auction-tab .nav-link.active {
  color: #ffffff !important;
  background-color: #5a0075 !important;
  border-color: #5a0075 !important;
}

.auction-right-side {
  text-align: center;
}

.navbar-light .navbar-toggler {
  color: rgb(255 255 255 / 100%) !important;
  border-color: rgb(255 255 255 / 50%) !important;
}

.navbar-light .navbar-toggler-icon {
  filter: invert(1);
}

.save-bid-price {
  background: #5a0075;
  padding: 6px;
  margin-top: 20px !important;
  display: block;
  width: 90px !important;
  text-align: center;
  font-size: 20px;
  color: #fff !important;
  border-radius: 10px;
}

.white_box {
  width: 70px;
  height: 70px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: -62px;
  z-index: 2;
  position: relative;
}

.blockchain_row h4 {
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
}

.blockchain_row span {
  min-height: 210px;
  overflow: hidden;
  display: inline-block;
}

.blockchain_row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
}

header .bloglink.active.nav-link {
  color: #fff !important;
  border-bottom: 0 !important;
}

.create_store_row h4 {
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
}

.create_store_row p {
  color: #c8ceec;
  font-size: 17px;
  font-weight: normal;
}

.launch_section .common_box_content {
  text-align: center;
  margin-bottom: 30px;
}

.launch_section h5 {
  margin-bottom: 20px;
}

.main-loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #000000d1;
  z-index: 1056;
  top: 0;
}

.loading {
  text-align: center;
  font-size: 18px;
  color: #fff;
  margin-top: 15px;
}

.name-dec {
  display: flex;
  align-items: center;
}

.name-dec p {
  margin-right: 5px;
  font-size: 18px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.generate-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.generate-loader .loader {
  position: relative !important;
  left: 45%;
  top: 50%;
  /* perspective-origin: unset; */
}
.shade-box {
  position: absolute;
  width: 500px;
  top: 0;
  left: 220px;
}

.market_categories .nav-item .nav-link {
  background: linear-gradient(180deg, #fafaff 0, #cfd4fe 100%);
  border-radius: 16px;
  min-width: 240px;
  padding: 22px;
  margin: 1px 10px 9px 0;
  position: relative;
  cursor: pointer;
  text-align: left;
  height: 83px;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}



.MuiOutlinedInput-root.MuiInputBase-root {
  background: #fff;
  height: 47px;
  margin-bottom: 7px;
  width: 97%;
  border-radius: 10px;
}

.market_categories .nav-item .nav-link span {
  position: absolute;
  top: 50%;
  right: 20px;
  cursor: pointer;
  transform: translate(0, -50%);
}

.market_categories .nav-item .nav-link.active {
  background: #fe7702;
  border-radius: 16px;
}

.market_categories .nav-item .nav-link.active h4 {
  color: #fff;
}

.market_categories .nav-item .nav-link.active h5 {
  color: #fff;
  opacity: 0.5;
}

.market_categories .nav-item .nav-link h4 {
  font-style: normal;
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 14px;
  color: #12041e;
}

.market_categories .nav-item .nav-link h5 {
  font-style: normal;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
  color: #c2bfe8;
}

.search_marketplace .searchbar_header {
  margin-left: 0;
}

.my_listing-area {
  min-height: 600px;
  background: #f3f3f3;
  padding: 50px 0;
  position: relative;
}

.searchbarlist {
  background-color: #fff;
  border-radius: 10px;
  border-bottom: 1px solid #d3d3d32e;
  padding: 10px;
  width: 100%;
  position: absolute;
  top: 64px;
  min-height: 100%;
  max-height: 370px !important;
  overflow: auto;
  box-shadow: 0 2px 4px #00000024;
}

.search-market-banner {
  background-color: #fff;
  border-radius: 10px;
  border-bottom: 1px solid #d3d3d32e;
  padding: 10px;
  width: 100%;
  min-height: 100%;
  max-height: 180px;
  overflow: auto;
  z-index: 1;
  position: relative;
  text-align: left;
}

#asset-search-result {
  max-width: 370px;
  max-height: 180px;
}

.onsale-modal.form-select {
  position: absolute;
  max-width: 150px;
  top: 0;
}

.searchbar_item {
  position: relative;
  padding: 5px 2px;
  border-bottom: 1px solid #d3d3d32e;
  margin-bottom: 0;
}

.__react_component_tooltip {
  background: #fcfaff !important;
  width: 291px;
  border-radius: 10px !important;
  color: #000 !important;
  font-size: 13px !important;
  font-weight: 400;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.33);
  padding: 10px !important;
}

.choose-heading i {
  cursor: pointer;
}

.__react_component_tooltip .multi-line {
  text-align: left !important;
}

.__react_component_tooltip::before {
  color: #fcfaff !important;
}

.__react_component_tooltip::after {
  border-bottom-color: #fcfaff !important;
}

.price-category {
  padding-left: 8px;
}

.searchbar_item h5 {
  font-size: 17px;
  color: #292929;
}

.bid-text-two.bid-usd-box {
  color: #fff !important;
}

.bid-text-two.bid-usd-box span {
  color: #fff !important;
}

.header-serach-box {
  padding-left: 43px;
}

.header-serach-box h5,
.header-serach-box p {
  margin: 0;
  word-break: break-word;
}

.searchbar_item img {
  width: 38px;
  position: absolute;
  height: 38px;
  border-radius: 100%;
  object-fit: cover;
  overflow: hidden;
}

.featured-area {
  text-align: center;
}

.btm_details .attri-description {
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 3px;
}

.featured-area ul li {
  list-style: none;
  padding: 13px 30px;
}

.featured-area {
  text-align: center;
  padding: 40px 0;
}

.featured-area ul {
  display: flex;
  justify-content: center;
}

.btm_details p {
  color: #fff;
  margin-bottom: 3px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 1.6em;
  margin-top: 9px;
}

.items-name {
  margin-top: 10px;
}

.sl-spinner3 {
  width: 70px;
}

.right-icon i {
  font-size: 40px;
}
/* .detail-collection-area {
  margin-left: 270px;
} */
.detail-left-side img {
  height: 540px;
  max-width: 540px;
  object-fit: contain;
  border-radius: 10px;
  width: -webkit-fill-available !important;
}
.detail-left-side {
  text-align: center;
}
/* .detail-left-side {
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 25px;
  height: calc(100vh - 200px);
  text-align: center;
} */
.items-datails {
  text-align: left;
  padding: 15px 15px 5px 15px;
}

.works-area-box {
  /* background-image: url('../images/faq_bg.png'); */
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  color: #fff;
  padding: 150px 0 60px;
}

.featured-area h5 {
  font-size: 36px;
  font-weight: 700;
}

.sign-area h5 {
  margin: 20px 0;
}

.sign-area p {
  color: #ffffffb8;
  font-weight: 400;
}

.auction-right-side video {
  max-height: 450px;
}

.sign-area {
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 20px 96px 20px 20px;
  height: 317px;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  justify-content: center;
}

.works-area-box h2 {
  text-align: center;
  font-size: 60px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 50px;
}

.featured-area {
  text-align: center;
  padding: 40px 0;
}

.faq-box h2.accordion-header {
  margin: 0;
  background: 0 0;
}

.accordion-item {
  border: 1px solid #fff !important;
}

.faq-box h2.accordion-header button {
  background: 0 0;
  color: #fff !important;
}

.slide-2 video {
  height: 246px;
  object-fit: cover;
  display: block;
}
.kennedy-brooks {
  background-image: url("../images/promote-bg.png");
  background-repeat: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.accordion-button::after {
  filter: invert(1);
}

.accordion {
  max-width: 750px;
  margin: 0 auto;
}

.accordion-item {
  background: 0 0;
  border: 2px solid #fff;
  color: #fff;
  margin-bottom: 20px;
  border-radius: 10px !important;
}

.list-market-new video {
  height: 246px;
  object-fit: contain;
}

.accordion-item {
  background: 0 0 !important;
}

.featured-area h5 {
  font-size: 31px;
  font-weight: 700;
  color: #565656;
}

.slide-2 img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.list-market-new:hover {
  background: #131414;
}

.mainimg {
  height: 246px;
  border-radius: 10px 10px 0 0 !important;
  object-fit: cover;
}

.connect-wallet-bt a {
  color: #fe7702;
}

.connect-wallet-bt:hover a {
  color: #fff;
}

.my_listing-area h2 {
  color: #47036f;
}

.btm_details .attri-price {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.slide-2 .btm_details {
  width: 100%;
  text-align: left;
  padding: 0 8px 0 8px !important;
  padding-bottom: 10px;
  height: 101px;
}

.slide-2 .mainimg {
  min-width: 100%;
  height: 246px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 15px 15px 0 0;
}

.slide-2 .bid-text-two {
  padding-top: 8px;
  padding-left: 0;
}

.slide-2 .echo-two {
  padding: 0;
}

.auction-right-side video {
  max-width: 560px;
  width: -webkit-fill-available;
}

.live-auction h2 {
  color: #fff;
  font-size: 34px;
  margin-left: 10px;
}

.fade {
  transition: opacity 3s linear !important;
}

.day-timer h4 {
  font-family: "Circular Std", sans-serif !important;
  margin-bottom: 9px;
}

.live-auction {
  display: flex;
  align-items: start;
}

.live-auction img {
  margin-top: 10px;
}

.day-timer h6 {
  font-size: 14px;
  margin-bottom: 0;
}

.share-bt p {
  margin-bottom: 0;
  background: #ffffff21;
  border-radius: 50px;
  padding: 3px 20px;
  margin-left: 10px;
  font-size: 14px !important;
  /* min-width: 100px; */
}

.fa-share-alt {
  background: #fff;
  width: 20px;
  text-align: center;
  color: #b34933;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
}

.auction-form .input-group-text {
  border-radius: 0;
  background-color: #fff;
  border: none;
  border-left: 3px solid #26b1a1;
  margin: 13px 0;
  padding: 4px;
}

.auction-form .input-group {
  background: #fff;
  border-radius: 50px;
  padding: 0 20px;
}

.auction-form .row {
  display: flex;
  align-items: center;
}

.auction-form input {
  border-radius: 0 30px 30px 0 !important;
  height: 58px;
  border: none;
}

.eth-box h5 {
  font-size: 16px;
  color: #fff;
  background: #ffffff21;
  border-radius: 50px;
  padding: 0 11px;
  margin: 6px 0 6px 10px;
}
input.minimum-quantity {
  margin-bottom: 2px !important;
}
.collection-team h6 {
  color: #ffd6ae;
}

.bid_auction h1 {
  font-size: 24px;
  color: #ffd6ae;
  font-weight: 700;
}

.air-max h2 {
  font-size: 30px;
  color: #ffd6ae;
  margin-bottom: 0;
  display: block;
  line-height: 30px;
  max-width: 270px;
  word-break: break-word;
}

.name-description h5 {
  color: #fff;
  margin-bottom: 23px;
}

.name-description p {
  font-size: 14px !important;
  color: #efefef;
  font-weight: 400;
  margin: 0;
}

.air-max h4 {
  color: #fff;
  font-size: 20px;
}
/* 
.buy-sell-box {
  margin-top: 50px;
} */

.buy-sell-box h4 {
  color: #fff;
}

.collections-details-box img {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  object-fit: cover;
}

.buy-now-png {
  /* background-image: url('../images/buy.png'); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 44px;
  color: #fff;
  line-height: 60px;
}

.air-max {
  display: flex;
  align-items: center;
}

.day-timer li {
  color: #fff;
  display: inline-block;
  margin: 0 20px;
  text-align: center;
}

.day-timer {
  margin: 0 0 0 auto;
  text-align: center;
}

.collec-heading {
  color: #fff;
  margin-bottom: 30px;
}

.collec-heading a {
  color: #c5c5c5 !important;
}

.details-cover img {
  width: 100%;
  height: 400px;
  object-fit: contain;
}

.seller-box h2 {
  font-size: 36px;
  text-align: center;
  margin-bottom: 30px;
}

.seller-box .tab-pane {
  margin: 0 10px;
}

.seller-box h2 span {
  color: #fe7702;
}

.login_header button {
  font-size: 14px;
}

.my-acc-bt {
  color: rgba(61, 66, 79, 0.65) !important;
}

.navbar-expand-lg .navbar-collapse {
  align-items: initial;
}

.nft-collection-box li {
  color: #ffffff94;
  list-style: none;
  display: inline-table;
  width: 160px !important;
  text-align: center;
}

.listingsection .nft-collection-box {
  background: white;
  padding: 11px 0;
  margin-bottom: 40px;
}

.load-absolute {
  text-align: center;
}

.items-price {
  color: #fff;
  margin-bottom: 10px;
}

.nft-collection-box ul {
  padding: 0;
  border: none;
}

.nft-collection-box li a {
  color: #fff;
}

.faq-link {
  color: #fe7702 !important;
  font-size: 16px;
  display: block;
  margin-top: 30px;
  text-decoration: underline !important;
}

.onsale-modal.form-select {
  position: absolute;
  max-width: 150px;
  top: 0px;
  left: 280px;
  height: 48px;
  border-radius: 10px;
  border: 2px solid #000;
}

.nft-collection-box li a.active {
  background: #000;
  padding: 8px 10px;
  border-radius: 10px;
  height: 40px;
  display: block;
  color: #fe7702;
}

.nft-collection-box ul {
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  background: #2f333c;
  padding: 5px;
  margin-bottom: 15px;
}

.nft-collection-box h5 {
  margin-bottom: 10px;
}

.searhmarket-content {
  padding-left: 45px;
}

.auto_break_next_line {
  /* max-width: 100px; */
  line-break: anywhere;
}

.image_fix_cover {
  object-fit: cover !important;
}

/* soccor page css statr*/

@import url("https://fonts.cdnfonts.com/css/space-grotesk");
@import url("https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap");

.soccor-banner h2 {
  font-family: "Space Grotesk", sans-serif;
  color: #fff;
  font-size: 100px;
  font-weight: 700;
  margin-bottom: 120px;
}

.soccor-banner h2 span {
  color: rgba(255, 255, 255, 0);
  font-size: 50px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(255, 255, 255);
  font-size: 130px;
  font-weight: 700;
}

.soccor-banner {
  padding: 235px 0;
  background-image: url("../images/soccor_banner.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 600px;
}

.beach-text {
  font-family: "Rock Salt", cursive;
  font-size: 50px;
  color: #fd7803;
  padding: 40px 0;
}

.input-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.soccor-text {
  font-family: "Space Grotesk", sans-serif;
  color: #fff;
  font-size: 70px;
  font-weight: 700;
  text-transform: uppercase;
}

.beach-box {
  background-color: #120817;
  padding: 60px 0;
}

.beach-soccor-one {
  position: relative;
}

.activities-box {
  background: #22102b;
  padding: 70px 0;
  position: relative;
}

.activities-heading h3 {
  font-size: 130px;
  font-weight: 700;
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
}

.activities-heading {
  position: absolute;
  top: 120px;
}

.activities-heading h3 span {
  font-size: 130px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(255, 255, 255);
}

.activities-heading h5 {
  font-size: 40px;
  color: #fff;
  line-height: 70px;
  font-family: "Space Grotesk", sans-serif;
  text-transform: uppercase;
}

.activities-heading h5 span {
  font-size: 40px;
  font-family: "Rock Salt", cursive;
  color: #fd7803;
}

.prizes-box {
  background-image: url("../images/prize_banner.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 250px 0;
}

.soccor-contact-box {
  background-image: linear-gradient(180deg, black, #23112d);
  padding: 120px 0;
}

.contact-form-two h3 {
  font-size: 40px;
  text-align: center;
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
  font-weight: 500;
  line-height: 63px;
}

.contact-form-two input {
  background: no-repeat !important;
  border: none;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  padding: 10px 0;
  color: #fff !important;
}
.swal2-styled.swal2-confirm {
  background-color: #fe7702 !important;
}
.contact-form-two .danger {
  color: red !important;
  margin-top: 5px;
}

.forrm-submit-btn {
  background: #fd7803 !important;
  border: none !important;
  width: 390px;
  color: #fff !important;
  height: 48px;
  margin: 50px auto 0 !important;
  display: block !important;
}

.contact-form-two {
  max-width: 80%;
  margin: 0 auto;
  border: 2px solid rgb(255 255 255 / 18%);
  padding: 50px 150px;
}

.contact-form-two input::placeholder {
  color: #fff !important;
}

.contact-form-two h3 span {
  font-size: 40px;
  font-family: "Rock Salt", cursive;
  color: #fd7803;
  font-weight: 500;
}
.market-pagination .pagination {
  justify-content: center;
}
.market-pagination li a {
  background: black;
  color: #747474;
  border-color: #4e4e4e;
  width: 40px;
  margin: 0 5px;
}
.market-pagination .active.page-item a {
  border-color: #fe7803 !important;
  background: #fe7803 !important;
}
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
.signle-detail-data-area .MuiOutlinedInput-root.MuiInputBase-root {
  background: linear-gradient(211deg, #d65e1c, #e76911);
  border: 1px solid #fff !important;
}
.signle-detail-data-area
  .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root
  label {
  background: linear-gradient(211deg, #d65e1c, #e76911);
  padding: 0 6px;
  color: #fff;
}
.signle-detail-data-area.tab-bid-box input {
  width: 97%;
  height: 46px;
  border-radius: 10px;
  color: #fff;
}
.signle-detail-data-area.tab-bid-box input::placeholder {
  color: #fff;
}
.signle-detail-data-area .css-i4bv87-MuiSvgIcon-root {
  fill: #fff;
}
.signle-detail-data-area input.form-control {
  background: linear-gradient(211deg, #d65e1c, #e76911);
  color: #fff !important;
  border: 1px solid #fff;
}
.signle-detail-data-area input.form-control::placeholder {
  color: #fff;
}
.signle-detail-data-area .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: #fff;
}
.preview-mobile-view {
  display: none;
}
.single-nft-detail-area {
  background: #fff !important;
  margin-top: 10px !important;
}
p.logout-title {
  margin-bottom: 0;
  color: #000;
}
.swal2-popup {
  width: 25em !important;
}
.swal2-image {
  margin-bottom: 0 !important;
}
div#swal2-html-container {
  font-size: 17px;
}
.swal2-title {
  color: #000 !important;
}
.toggle-drop-right .dropdown-menu.show {
  min-width: 100px;
  padding-top: 0;
  padding-bottom: 0;
  left: 80px;
  top: 40px;
}
.toggle-drop-right .wallet-bt-connect.dropdown {
  display: none;
}
/* get-in-touch */
.athe-banner-area {
  background-color: #000;
  color: #fff;
  background-image: url(../images/sports-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  padding: 133px 0;
  margin-top: 78px;
}
.banner-ath-image {
  display: none;
}
.ath-content h2,
.get-in-area h2,
.unique-content h2 {
  font-size: 50px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 30px;
}
.text-sport {
  color: #fe7803;
}
.ath-content p,
.get-in-area p,
.unique-content p {
  font-size: 18px;
  color: #c8ceec;
}
.inner-bg {
  background-image: url(../images/main-bg.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 55px;
}
.inner-content {
  padding: 32px 0;
}
.unique-img {
  text-align: center;
}
.get-in-section {
  padding: 52px 0;
}
.get-in-bg {
  background: hsla(0, 0%, 100%, 0.13);
  border: 2px solid hsla(0, 0%, 100%, 0.16);
  -webkit-backdrop-filter: blur(85px);
  backdrop-filter: blur(85px);
  border-radius: 24px;
  padding: 51px;
}
.get-in-bg input,
.get-in-bg textarea {
  border: none !important;
  background: hsla(0, 0%, 100%, 0.12156862745098039) !important;
  color: #fff !important;
  resize: none;
}
.get-in-bg .text-danger {
  text-align: left;
  display: inherit;
}
.btn-get-in {
  border-radius: 6px;
  background: #fe7803 !important;
  border: #fe7803 !important;
  padding: 11px 39px !important;
  margin: 15px 0 0;
}
.submit-btn .btn-get-in {
  float: left;
}
.get-in-bg input::placeholder,
.get-in-bg textarea::placeholder {
  color: #fff;
  font-size: 14px;
}
.header-login-hide {
  display: none !important;
}
iframe#review-card-payment-iframe {
  position: fixed;
  top: 5%;
  z-index: 9999999;
  width: 62%;
  left: 19%;
  height: 89%;
  overflow: auto;
}
div#headlessui-portal-root {
  position: fixed;
  top: 5%;
  z-index: 9999999;
  width: 0%;
  left: 19%;
  height: 89%;
  overflow: auto;
}
.detail-area-buy-nft ul.react-tabs__tab-list {
  margin-bottom: 15px;
}
.detail-area-buy-nft .nft-modal-items {
  margin-bottom: 10px;
}
.detail-area-buy-nft label.m-0.mb-2.form-label {
  font-weight: 600;
}
.detail-area-buy-nft .meta-mask-modal h3 {
  font-size: 24px;
  color: #000;
  font-weight: 700;
}
input#formBasicEmail::placeholder {
  font-size: 13px;
  color: #adadad;
}
.detail-area-buy-nft label.m-0.form-label {
  font-weight: 600;
}
.detail-area-buy-nft .buy-nft-tab .nft-modal-items {
  min-height: 70px;
}
svg#loader {
  display: none;
}
.absolute.top-1\/2.left-1\/2.-translate-x-1\/2.-translate-y-1\/2 {
  display: none !important;
}

/* soccor page css end */

/* soccor page css end */
/* athlenfts page css starts */
.athe-banner-area {
  background-color: #000;
  color: #fff;
  background-image: url("../images/sports-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  padding: 150px 0;
  margin-top: 100px;
}
.banner-ath-image {
  display: none;
}

.new-header .navbar {
  background: rgb(7 10 41);
  padding: 30px;
  position: fixed;
  top: 0;
  width: 100%;
}

.new-header .nav-link {
  color: #fff !important;
  margin: 0px 14px;
}

.ath-content h2,
.unique-content h2,
.get-in-area h2 {
  font-size: 50px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 30px;
}
section.works-area-box.faq-page-box {
  background: black;
}
.works-area-box.faq-page-box .accordion-body {
  color: #fff;
}

.ath-content p,
.unique-content p,
.get-in-area p {
  font-size: 18px;
  color: #c8ceec;
}

.text-sport {
  color: #fe7803;
}

.btn-get-in {
  border-radius: 6px;
  background: #fe7803 !important;
  border: #fe7803 !important;
  padding: 11px 39px !important;
  margin: 15px 0 0 0;
}

.inner-bg {
  background-image: url(../images/main-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 55px;
}

.inner-content {
  padding: 32px 0;
}
.get-in-bg {
  background: rgb(255 255 255 / 13%);
  border: 2px solid rgb(255 255 255 / 16%);
  -webkit-backdrop-filter: blur(85px);
  backdrop-filter: blur(85px);
  border-radius: 24px;
  padding: 51px;
}

.get-in-bg input,
.get-in-bg textarea {
  border: none !important;
  background: #ffffff1f !important;
  color: #fff !important;
  resize: none;
}

.submit-btn .btn-get-in {
  float: left;
}

.footer-area {
  background: #0d113a;
  color: #fff;
  text-align: center;
  position: relative;
}

.social-icons img {
  margin: 0 24px;
}

.copyright-text {
  padding: 22px 0 0 0;
  border-top: 1px solid #ffffff1a;
}

.social-icons {
  margin-bottom: 24px;
}

.unique-img {
  text-align: center;
}

.get-in-section {
  padding: 52px 0;
}

.new-header .nav-link:hover {
  color: #fff;
}

.get-in-bg input::-webkit-input-placeholder {
  color: #fff;
}

.get-in-bg textarea::-webkit-input-placeholder {
  color: #fff;
}

.get-in-bg .text-danger {
  text-align: left;
  display: inherit;
}
select.sort-by-filter.form-select {
  height: 55px;
  border-radius: 15px;
  width: 200px;
}
.search_marketplace {
  display: flex;
  align-items: center;
}
.overlay-upper {
  background-repeat: no-repeat;
  height: 250px;
  -moz-filter: blur(6px);
  -o-filter: blur(6px);
  -ms-filter: blur(6px);
  filter: blur(6px);
  opacity: 0.8;
  z-index: 0;
  position: relative;
}
.containe_img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain !important;
}
.own-journey-area .create-store-info h4 {
  font-size: 45px;
  font-weight: 700;
}
.own-journey-area .create-store-info h4 span {
  color: #fe7702;
}
.exclusive-athlete-area {
  text-align: center;
  margin-top: 100px;
}
.exclusive-athlete-area h2 {
  font-size: 45px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 40px;
}
.exclusive-athlete-area h2 span {
  font-weight: 100;
  color: #ff7903;
  font-size: 40px;
}
.exclusive-athlete-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.own-journey-area {
  padding-bottom: 0 !important;
}
.login-form-box input::placeholder, .login-form-box textarea::placeholder {
  color: #ffffff47;
}
.get-in-bg input::placeholder, .get-in-bg textarea::placeholder {
  color: #a6a6a8;
}
button.btn.btn-unset a {
  color: #fff !important;
}
/* athlenfts page css end */
@media (min-width: 1700px) {
  .App .container {
    max-width: 1600px;
  }
}

@media (max-width: 1699px) {
  .athe-banner-area {
    padding: 95px 0;
    margin-top: 73px;
  }
  .searchbar_header input {
    min-width: 276px;
  }

  .input-image {
    width: 250px;
    height: 250px;
  }

  .market_banner_section #left-tabs-example-tabpane-first {
    margin-top: 40px;
  }
}

@media (max-width: 1399px) {
  .home_banner_right {
    background-size: contain;
    background-repeat: no-repeat;
  }
  .searchbar_header input {
    min-width: 233px;
  }

  .slide-2 .mainimg {
    height: 310px;
  }

  header .nav-link {
    font-size: 15px;
    margin: 0 15px;
  }

  .navbar-brand img {
    max-width: 150px;
  }
}
@media (max-width: 1300px) {
}
@media (max-width: 1400px) {
  .ath-content h2,
  .get-in-area h2,
  .unique-content h2 {
    font-size: 40px;
  }
  span.navbar-brand img {
    cursor: pointer;
    max-width: 160px !important;
  }

  header .container {
    max-width: 100%;
  }

  .collection_img img {
    height: 245px;
  }

  .tab_box_home span {
    right: 7px;
  }

  .in-stock {
    padding: 6px 13px;
    font-size: 13px;
  }
}

@media (max-width: 1199px) {
 
  .athe-banner-area {
    padding: 50px 0;
  }

  .home_header .navbar-toggler {
    padding: 2px 5px;
  }
  .wallet-bt-connect.dropdown {
    display: none;
  }
  .toggle-drop-right .wallet-bt-connect.dropdown {
    display: block;
  }
  .toggle-drop-right button#dropdown-basic {
    /* width: auto; */
    position: relative;
    left: 60px;
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
    top: -2px;
  }
  .toggle-drop-right {
    display: flex;
    align-items: center;
  }

  .our-team h2 {
    font-size: 42px;
  }

  .event-dates h4 {
    font-size: 24px;
  }
  .event-dates h3 {
    font-size: 30px;
  }

  .market_categories .nav-item .nav-link {
    min-width: 160px !important;
  }

  .home-banner-left h1 {
    font-size: 50px;
  }

  .navbar-nav.navbar-nav-scroll {
    margin-bottom: 16px !important;
  }

  #dropdown-basic {
    padding: 14px 7px;
    font-size: 13px;
  }

  .searchbar_header input {
    min-width: 192px;
  }

  header .startselling_btn {
    padding: 14px 7px;
    font-size: 13px;
  }

  .collecting-bt {
    font-size: 16px;
  }

  .market-left h1 {
    font-size: 50px;
  }

  .tab_box_home {
    padding: 10px;
  }

  .explore-box h1 {
    margin-bottom: 23px;
  }

  .home_banner_right {
    padding: 35px 0;
  }

  .hot-heading {
    font-size: 50px;
  }

  .home-banner-left p {
    font-size: 18px;
  }

  .collection_img img {
    height: 180px;
  }

  .App .container {
    max-width: 1100px;
    padding: 0 20px;
  }

  .slide-2 .mainimg {
    height: 290px;
  }

  .tenis-box img {
    max-width: 50px;
  }

  .tenis-box {
    padding: 9px 8px;
  }

  .like-view h6 {
    font-size: 12px;
  }

  .instant-sale-prize {
    width: 78%;
  }

  .name-description-filed {
    padding: 30px 12px;
  }

  .seller-box h2 {
    font-size: 50px;
  }

  #google_translate_element {
    text-align: center;
    margin-top: 11px;
  }

  .navbar-nav .dropdown,
  .navbar-nav .dropdown-menu.show {
    text-align: center;
  }

  header .startselling_btn {
    margin: 10px auto !important;
    width: fit-content;
    width: 180px;
  }

  #dropdown-basic {
    width: 180px;
    height: 50px;
  }

  #google_translate_element select {
    margin-left: 0;
  }

  header .nav-link {
    margin: 12px 15px 0;
  }
}

@media (max-width: 991px) {
  .exclusive-athlete-bottom img {
    width: 200px !important;
  }
  .own-journey-area img {
    display: flex;
    margin: 0 auto;
}
  .main_h2 {
    font-size: 30px;
  }
  /* .preview-desktop-view {
    display: none;
  } */
  .signle-detail-data-area {
    margin-bottom: 15px;
  }
  .touch_form {
    padding: 37px 30px;
  }
  .nil-providers {
    padding: 50px 0;
  }
  .circle_row .col-md-6:last-child {
    padding: 0 0px 0 50px !important;
  }
  .unique-content h2 br {
    display: none;
  }

  .member-one {
    padding: 10px;
  }

  .discord-section-content h2 {
    font-size: 40px !important;
  }

  .discord-section-content p {
    font-size: 18px !important;
  }

  .discord-section-bg {
    padding: 10px;
  }

  .circle_row .col-md-6 {
    position: relative;
    padding: 0 50px 0 11px !important;
    z-index: 2;
  }

  .preview-image img {
    width: 100%;
    height: 250px;
    object-fit: contain;
  }

  .home_banner_section {
    height: auto !important;
  }

  .about-page h1 {
    font-size: 60px;
  }

  .creater-collection-area {
    display: block;
  }

  .sign-area {
    height: 237px;
    margin-bottom: 20px;
  }

  #google_translate_element select {
    margin: 0;
  }

  .App .container {
    max-width: 720px;
  }

  .market_banner_section {
    margin-top: 74px;
  }

  .home_banner_right {
    /* height: 85vh; */
  }

  .attri-name {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 50px;
    line-height: 1.8em;
  }

  .more-div {
    margin-bottom: 12px;
  }

  .explore-box {
    padding: 30px 0 0;
  }

  .leftfooter {
    padding-right: 20px;
  }

  footer h3 {
    margin-bottom: 19px;
    width: 100%;
  }

  .category_row.row {
    margin-top: 0px;
  }

  .footer_head h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: -0.3px;
    color: #fff;
  }

  .auction-right-side {
    padding: 0 20px;
  }

  .connect-wallet-bt {
    margin: 0 auto;
  }

  .preview-image {
    width: 100%;
    height: auto;
  }

  .preview-area {
    height: auto;
  }

  .event-dates {
    padding: 8px 15px;
  }
  .event-dates h4 {
    font-size: 18px;
  }
  .event-dates h3 {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .own-journey-area img {
    width: 400px;
}
  .exclusive-athlete-area h2 {
    font-size: 36px;
}
.exclusive-athlete-bottom img {
  width: 160px !important;
}
.exclusive-athlete-area h2 span {
  font-size: 28px;
}
  .home-banner-left .collecting-bt {
    margin: 0 auto !important;
  }
  .event-dates {
    display: block;
  }

  .boder-box {
    margin-top: 20px;
  }

  .event-dates h4 {
    margin-bottom: 5px;
  }
  .MuiOutlinedInput-root.MuiInputBase-root {
    width: 100% !important;
  }

  .first_section_main img {
    max-width: 450px;
    margin-bottom: 10px;
    width: 100%;
  }

  .member-one {
    /* height: auto; */
    padding: 10px;
    /* max-width: 70%; */
    margin: 0 auto 50px;
  }

  .member-info p {
    color: #ffffffad;
    min-height: 60px;
  }

  .blockchain_row div {
    width: 100% !important;
  }

  .discord-section-content p {
    line-height: 23px !important;
    margin-bottom: 40px !important;
  }

  .about-page h3 {
    font-size: 30px;
    margin: 0 0 20px 0;
  }

  .about-page h2 {
    font-size: 36px;
  }

  .about-page h1 {
    font-size: 48px;
  }

  .founding-div p {
    font-size: 16px;
    margin: 23px 30px;
  }

  .new-banner-area {
    margin-top: 75px !important;
  }

  .navbar-brand img {
    max-width: 150px !important;
  }

  .air-max h2 {
    font-size: 20px;
  }

  .details-cover img {
    height: 280px;
  }

  .bid_auction p {
    font-size: 14px;
  }

  .home-banner-left h1 {
    font-size: 40px;
  }

  .bid_auction h1 {
    font-size: 20px;
  }

  .hot-heading {
    font-size: 40px;
  }

  .works-area-box h2 {
    font-size: 40px;
  }

  .shade-box {
    left: 0;
  }
  /* 
  .crete-collect-new {
    flex-direction: column-reverse;
  } */

  /* .preview-area {
    margin: 20px;
  } */

  .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
  }

  .home-banner-left h1 {
    text-align: center;
  }

  .home-banner-left {
    text-align: center;
  }

  .rightsdefooter {
    margin-top: 30px;
  }

  .explore-box .text-center.col-md-5 {
    max-width: 500px;
    margin: 0 auto;
  }

  .down-scroll {
    margin-top: 80px;
  }

  .leftfooter {
    margin-bottom: 20px;
  }

  .rightsdefoote {
    text-align: left;
  }

  .market_banner_section.h-100 {
    padding: 30px 0;
  }

  .ath-content h2,
  .unique-content h2,
  .get-in-area h2 {
    font-size: 35px;
  }
  .new-header .navbar-collapse {
    margin-top: 20px;
  }
  .new-header .navbar-collapse {
    margin-top: 20px;
  }
  .new-header .nav-link:first-child {
    border-top: 1px solid #ffffff26;
  }
  .ath-content h2,
  .unique-content h2,
  .get-in-area h2 {
    font-size: 30px !important;
  }
  .get-in-area p br {
    display: none;
  }
  .new-header .navbar {
    padding: 15px 5px;
  }
  .get-in-section {
    padding: 52px 0 0;
  }
  .new-header .nav-link {
    color: #fff;
    margin: 0px 8px;
    font-size: 14px;
    border-bottom: 1px solid #ffffff26;
    padding: 10px 0;
  }

  .btn-get-in {
    font-size: 14px !important;
  }
  .get-in-img img {
    width: 100%;
    max-width: 60%;
  }
}

@media (max-width: 576px) {
  section.service_section1.launch_section.own-journey-area {
    padding-top: 40px !important;
}
  .own-journey-area .create-store-info h4 {
    font-size: 30px !important;
}
  .exclusive-athlete-area h2 span {
    font-size: 23px;
}
.exclusive-athlete-bottom img {
  width: 130px !important;
}
.exclusive-athlete-area h2 {
  font-size: 29px;
  line-height: 32px;
}
  .home_header span.navbar-brand {
    margin-right: 0;
  }
  .home-banner-section button.btn.btn-unset.generate-nft-btn {
    margin-left: 0 !important;
  }
  .user-icon {
    font-size: 24px !important;
  }
  .navbar-light .navbar-toggler-icon {
    font-size: 16px;
  }
  .toggle-drop-right button#dropdown-basic {
    left: 70px;
  }
  h2#swal2-title {
    font-size: 22px;
  }
  .our-team h2 {
    font-size: 30px;
  }
  .discord-section-content h2 {
    font-size: 35px !important;
  }
  .discord-section-content a {
    padding: 10px 40px;
  }
  .owner-details p {
    max-width: inherit;
  }
  .ath-content h2,
  .unique-content h2,
  .get-in-area h2 {
    font-size: 28px !important;
  }

  .owner-details {
    margin-right: 0;
    width: auto;
  }

  .air-price-new {
    font-size: 16px;
  }

  .shades-box {
    z-index: -1;
  }

  .owner-list {
    display: block;
  }

  .owner-list a {
    float: left;
  }

  .margin-ios .goog-te-combo {
    margin-left: 10px !important;
  }

  .air-max h2 {
    font-size: 36px;
  }

  .social-icon li {
    display: inline-block;
    margin: 13px 22px;
  }

  .featured-area ul {
    display: block;
  }

  .home-banner-left h1 {
    font-size: 42px;
  }

  .hot-heading {
    font-size: 42px;
  }

  .eth-box h5 {
    font-size: 20px;
  }

  .seller-box h2 {
    font-size: 36px;
  }

  .air-max h4 {
    font-size: 20px;
  }

  .explore-box .col-sm-3 {
    width: 50%;
  }

  .home-banner-left {
    padding: 70px 10px 0;
  }

  .home_banner_right {
    /* height: 36vh; */
  }

  .home-banner-left h1 {
    font-size: 30px;
  }

  .hot-heading {
    font-size: 30px;
  }

  .market-left h1 {
    font-size: 36px;
  }

  .collection-head-new {
    padding: 7px 9px;
    font-size: 14px;
  }

  .works-area-box h2 {
    font-size: 30px;
  }

  .rightsdefooter {
    margin-top: 0;
  }

  footer {
    padding: 30px 0;
  }

  .end-reserve-bid-box {
    margin: 15px 23px 0 8px;
  }

  .category_row.row .nav-item {
    width: 50%;
  }

  .market_categories .nav-item .nav-link {
    min-width: 160px;
    margin: 5px;
  }

  .search_marketplace {
    width: 100%;
  }

  .category_row.row {
    margin-top: 30px;
  }
  .ok-bt-install-metamask-modal {
    margin-bottom: 12px;
  }
  .discord-section-content {
    width: 90% !important;
  }

  .member-one {
    max-width: 100%;
  }
  .air-price-new b {
    display: inline-block;
  }
  .create-items ul {
    display: flex;
    flex-wrap: wrap;
  }
  .create-items ul li {
    margin-top: 13px;
  }
}
@media (max-width: 480px) {
  .athelete-top.area {
    margin-top: 70px;
}
  .own-journey-area .create-store-info p {
    font-size: 14px;
}
  .exclusive-athlete-bottom img {
    width: 85px !important;
}
.exclusive-athlete-area {
  margin-top: 70px;
}
button.btn.btn-unset {
  font-size: 14px;
}
}

@media (max-width: 420px) {
  .exclusive-athlete-area h2 {
    font-size: 22px;
    line-height: 25px;
}
.exclusive-athlete-area h2 span {
  font-size: 16px;
}
  button.swal2-cancel.swal2-styled {
    padding: 5px 18px;
  }
  .swal2-popup {
    width: 17em !important;
    /* height: 280px; */
  }
  div#swal2-html-container {
    font-size: 14px;
    margin-top: 0;
  }
  .swal2-styled.swal2-confirm {
    background-color: #fe7702 !important;
    padding: 5px 18px;
  }
  h2#swal2-title {
    font-size: 18px;
  }
  /* button#uncontrolled-tab-example-tab-sale {
    margin-bottom: 15px;
  } */
  .touch_section {
    padding: 0;
  }
  .buy-sell-created .rightside_border {
    margin-top: 14px;
  }
  .buy-sell-created {
    flex-wrap: wrap;
  }
  .collec-heading {
    font-size: 18px;
  }
  .name-description {
    margin-left: 15 !important;
    margin-top: 10px;
  }
  .about-page p {
    font-size: 14px;
    line-height: 30px;
  }
  .service_section1 {
    padding: 80px 0 0 0 !important;
  }
  .nft-collection-box li {
    font-size: 14px;
    width: auto !important;
    padding: 8px 11px !important;
  }

  /* .share-bt p {
    padding: 3px 18px !important;
  } */

  .discord-section-content h2 {
    font-size: 25px !important;
  }

  .discord-section-content p {
    font-size: 16px !important;
    line-height: 28px !important;
  }

  .featured-area {
    padding: 40px 0 0;
  }

  .home-banner-left {
    padding: 30px 10px 0;
  }

  .works-area-box {
    padding: 44px 0 50px;
  }

  #hot-collection {
    padding: 0;
  }

  .market_categories .nav-item .nav-link {
    min-width: 100px;
  }

  .market_categories .nav-item .nav-link {
    padding: 12px;
  }

  .sign-ar {
    padding: 20px 20px 20px 20px;
  }
}

@import url(https://fonts.cdnfonts.com/css/circular-std);

body,
html {
  font-family: "Circular Std", sans-serif !important;
}

:focus {
  box-shadow: none !important;
  outline: 0 !important;
}

fieldset,
img {
  border: 0 none;
}

input[type="submit"],
select {
  -webkit-appearance: none;
}

html {
  scroll-behavior: smooth;
}

.alice-carousel__dots {
  display: none !important;
}

.alice-carousel__next-btn-item {
  background: #fe7702;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 30px;
  color: #fff !important;
  position: absolute;
  right: 0;
  top: 130px;
}

.alice-carousel__prev-btn-item {
  background: #fe7702;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 30px;
  color: #fff !important;
  position: absolute;
  left: 0;
  top: 130px;
  text-align: center;
}

img {
  border: none;
  height: auto;
  max-width: 100%;
  outline: 0;
  vertical-align: middle;
}

iframe {
  vertical-align: middle;
}

a:hover {
  text-decoration: none;
  color: #6bcbfc;
}

a,
abbr,
acronym,
address,
article,
aside,
b,
bdo,
big,
blockquote,
body,
br,
button,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
img,
ins,
kbd,
legend,
li,
map,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
ul,
var {
  border: medium none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none !important;
}

input[type="submit"],
select {
  -webkit-appearance: none;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}

header {
  padding: 9px 0;
  position: fixed;
  top: 0;
  z-index: 1024;
  width: 100%;
  background: rgb(0 0 0);
}

.img_position_blue {
  position: absolute;
  right: 0;
  z-index: 1;
}

.black-head {
  background: black;
}
form.searchbar_header {
  margin-right: 12px;
}
select.sort-by-filter.form-select {
  height: 55px;
  border-radius: 15px;
  width: 200px;
}

.searchbar_header input {
  background: #f6f7fa !important;
  border-radius: 16px;
  border: 0;
  height: 56px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #081946;
  min-width: 550px;
}

.searchbar_header {
  position: relative;
  margin-left: 20px;
}

.bg-white {
  box-shadow: 2px 2px 2px #e1e1e138;
}

.searchbar_header button {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translate(0, -50%);
}
.bid-modal-bg .text-right {
  margin-bottom: 0;
}
.collection-correct-image {
  margin-top: -22px;
}
.more-games:hover {
  color: #fe7702;
}

.correct-image {
  text-align: center;
  /* margin-top: -39px; */
}
.modal-content {
  width: 90% !important;
  margin: 0 auto;
}
header .startselling_btn {
  background: #fe7702 !important;
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #fff !important;
  height: 52px;
}

.modal-cross-icon-new {
  font-size: 22px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.error {
  color: red;
}

button.btn.btn-unset {
  background: #fe7702;
  color: #fff !important;
  border-radius: 10px;
  width: fit-content;
  padding: 14px;
}

.btm_details.market-btm-details {
  position: relative;
  top: inherit !important;
  left: inherit !important;
}

.btm_details.market-btm-details h6.bid-text-two {
  padding-left: 0 !important;
}

button.bg-transparent.border-0.subscribe-bt.btn.btn-unset {
  background: #fe7702 !important;
  height: 50px;
  width: auto;
  color: #fff;
  font-size: 18px;
  border-radius: 10px 10px 0 0;
}

.navbar-nav .dropdown-item .startselling_btn {
  text-align: left;
  border-radius: 0 0 10px 10px;
  width: 100%;
  border-top: 1px solid #ffffff3d;
  margin: 0;
}

.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.navbar-nav .dropdown-menu.show {
  top: 54px;
  min-width: 100px;
  left: -36px;
  padding: 0;
}

.user-icon {
  font-size: 30px;
}

.user-profile-pic {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
}

.profile-bt-new {
  display: flex !important;
  align-items: center;
}

#google_translate_element {
  position: relative;
}

#google_translate_element select option {
  color: #000 !important;
}

.skiptranslate.goog-te-gadget {
  background: #fe7702;
  border-radius: 10px;
  width: 139px;
  height: 50px !important;
  margin-left: 10px;
}

#google_translate_element select {
  background: none;
  border: none !important;
  color: #fff;
  border-radius: 10px;
  height: 42px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

#google_translate_element i {
  position: absolute;
  right: 10px;
  top: 16px;
  color: #fff;
  font-size: 12px;
}

.skiptranslate.goog-te-gadget {
  height: 55px;
  overflow: hidden;
}

header .nav-link {
  font-style: normal;
  font-weight: 500;
  padding: 0 0 6px 0 !important;
  font-size: 14px;
  border-radius: 0;
  margin: 0 20px;
  line-height: 20px;
  color: rgb(255 255 255) !important;
  border-bottom: 4px solid #fff;
}

.modal-email span {
  color: #fe7702;
}
.modal-email {
  margin: 32px 20px;
}

header .nav-link:hover {
  color: #fe7702 !important;
}

header .active.nav-link {
  color: #fe7702 !important;
  border-bottom: 4px solid #fe7702;
}

header .nav-link {
  border-bottom: none;
}

header .login_header .logintext {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #081946;
}

.down-scroll {
  margin-top: 130px;
}

.searchbar_header input::-webkit-input-placeholder {
  color: #4f0c75;
}

.searchbar_header input::-moz-placeholder {
  color: #4f0c75;
}

.searchbar_header input:-ms-input-placeholder {
  color: #4f0c75;
}

.searchbar_header input:-moz-placeholder {
  color: #4f0c75;
}

.banner_section {
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: center;
  position: relative;
  padding: 135px 0;
  overflow: hidden;
  margin-top: 93px;
}

.banner_section .gobackbtn {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 99px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fff;
}

.banner_section h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  color: #fff;
  margin: 35px 0;
}

.banner_section p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #fff;
}

.banner_section h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-decoration-line: underline;
  color: #fe7702;
  cursor: pointer;
}

.banner_section .connectbtn {
  background: #fe7702;
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: #f8f6f6;
  padding: 17px 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 270px;
  margin: 40px 0;
}

.banner_section .connectbtn img {
  transform: rotate(180deg);
}

.images_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner_section p.dulltext {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #ffffffa6;
  margin-top: 25px;
}

footer {
  padding: 30px 0;
  background-color: #000;
}

.footer_head {
  display: flex;
  justify-content: end;
  align-items: center;
}

.footer_head h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  letter-spacing: -0.3px;
  color: #fff;
}

.footerline {
  background: #fff;
  opacity: 0.14;
  height: 1px;
  width: 100%;
}

footer h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  letter-spacing: -0.3px;
  color: #fff;
  margin-bottom: 35px;
  width: 50%;
}

button.buy-now-bt.buy-now-bt-copy {
  margin: 0;
}

.bid-amount h6 {
  color: #ffffffb8;
}

.auction-form input.bid-now-input {
  border-radius: 80px !important;
}

.searchbar_header.footer input {
  border-radius: 0;
  background-color: transparent !important;
  border-bottom: 1px solid #ffffff1f;
  padding: 0;
  font-weight: 300;
  color: #fff;
  height: 52px;
  padding: 0 126px 0 10px;
}

.featured-area {
  text-align: center;
  padding: 40px 0;
}

.searchbar_header button.btn-unset {
  margin: 0px 3px !important;
}

.searchbar_header.footer button {
  right: 0;
}

.searchbar_header.footer input::-webkit-input-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer input::-moz-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer input:-ms-input-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer input:-moz-placeholder {
  color: #ffffff7d;
}

.searchbar_header.footer {
  margin-left: 0;
}

.rightsdefooter h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: #ffd2dd;
  margin-bottom: 15px;
}

.rightsdefooter p {
  font-style: normal;
  font-weight: 200;
  font-size: 15px;
  cursor: pointer;
  color: #fff;
}

.leftfooter {
  padding-right: 180px;
}

.collection_section {
  padding: 90px 0;
}

.preview-image video.video-container.video-container-overlay {
  width: 100%;
  height: -webkit-fill-available;
}

.collection_section h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  color: #000;
}

.collection_section h5 {
  font-style: normal;
  font-weight: 200;
  font-size: 21px;
  color: #6b6b6b;
}

.time_row {
  background-color: #4f0c75;
  border-radius: 100px;
  padding: 7px 10px;
  display: inline-block;
  margin-top: 10px;
}

.time_row span {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  padding: 0 4px;
}

.collection_box h3 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin-top: 12px;
}

.userpic_name {
  color: #000;
  font-size: 16px;
  font-weight: 200;
}

.userpic_name {
  position: relative;
  padding-left: 46px;
  padding-top: 2px;
  margin-top: 12px;
}

.userpic_name img {
  width: 33px;
  position: absolute;
  left: 0;
  top: 0;
  height: 33px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 100%;
}

.collection_box {
  border: 1px solid #7070704d;
  border-radius: 17px;
  padding: 17px;
  box-shadow: 0 0 13px -1px #00000024;
}

.collection_img {
  margin-bottom: 5px;
}

.collection_img img {
  max-width: 100%;
  height: 300px;
  width: 100%;
  border-radius: 14px;
  object-fit: cover;
  overflow: hidden;
}

.collectible-upload-image h6 {
  color: #4f0c75;
  font-size: 18px;
  margin: 0;
}

.collectible-upload-image {
  background: #fff;
  box-shadow: 0 0 15px rgb(17 47 73 / 15%);
  border-radius: 15px;
  cursor: pointer;
  padding: 1px 0;
}

.collectible-box {
  position: relative;
  margin: 0 20px;
}

.uplage-image-collect {
  position: absolute;
  height: 290px;
  opacity: 0;
}

.name-description-filed {
  background: #fcfaff;
  padding: 30px 0;
  margin-top: 30px;
}

.collectible-bg {
  /* background: #fcfaff; */
  padding: 100px 0;
  border: 2px dashed #d7d7d7;
  margin: 20px;
  border-radius: 15px;
}

.name-description-filed input {
  border-radius: 10px;
  height: 48px;
}

.create-bt {
  background: #fe7702;
  color: #fff;
  width: 130px;
  height: 37px;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 20px;
}

.preview-area {
  background: #fff;
  box-shadow: 0 0 15px rgb(17 47 73 / 15%);
  border-radius: 15px;
  padding: 20px;
  position: relative;
  height: auto;
  min-height: 230px;
}

.preview-text {
  color: #00000057;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.date-box i {
  position: absolute;
  /* z-index: 1; */
  right: 15px;
  top: 9px;
  background: white;
  padding: 5px;
  color: #5e5e5e;
}

.date-box {
  position: relative;
}

.tab-bid-box {
  margin-top: 22px;
}

.width-box {
  width: 170px !important;
  margin: 0 !important;
}

.put-sale-box {
  margin: 15px 0 !important;
}

.preview-image {
  width: 100%;
  height: 350px;
  object-fit: contain;
  background: #f3f3f3;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.preview-area p {
  word-break: break-word;
  width: 100%;
  text-align: left;
}
.preview-area .description-text {
  word-break: break-word;
  width: 100%;
  text-align: left;
}

.description-toggle {
  margin-left: auto;
}

.form-switch .form-check-input {
  width: 62px !important;
  height: 25px !important;
}

.form-check-input:checked {
  background-color: #4f0c75 !important;
  border-color: #4f0c75 !important;
}

.auction-toggle.area label {
  margin-bottom: 0;
}

.auction-toggle.area {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
}
button.react-share__ShareButton.Demo__some-network__share-button {
  margin: 0 2px;
}
.instant-sale-prize {
  margin-bottom: 10px;
}
.home_banner_right {
  background-size: contain;
  width: 100%;
  /* height: 100vh; */
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  padding: 135px 0;
}

.tyler-hero {
  color: #4f0c75;
  background: #4f0c7521;
  padding: 8px 20px;
  border-radius: 50px;
  font-size: 16px;
}

.profile-image {
  text-align: center;
}

.home-banner-left h1 {
  font-size: 64px;
  color: #fff;
  font-weight: 700;
  margin-top: 15px;
}

.home-banner-left p {
  font-size: 20px;
  color: #bfbfbf;
  margin-bottom: 20px !important;
}

.nft-view-dtails-bt {
  background: #fe7702;
  display: block;
  width: 190px;
  border-radius: 10px;
  text-align: center;
  color: #fff !important;
  padding: 8px 0;
}

.nft-market-checkbox li {
  display: inline-block;
  margin-right: 25px;
}

.nft-market-checkbox {
  padding: 0;
  margin-bottom: 41px;
}

.collecting-bt {
  position: relative;
  background-color: #fe7702;
  padding: 20px 40px;
  border-radius: 10px;
  font-size: 24px;
  color: #fff !important;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  cursor: pointer;
  display: block;
  margin-bottom: 20px;
}

.collecting-bt i {
  margin-left: 15px;
}

.customer-box li {
  margin-left: -22px;
  display: inline-block;
}

.customer-box {
  padding: 0;
}

/* toggle css */
.switch {
  position: relative;
  display: inline-block;
  width: 62px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

.slider.round {
  border-radius: 34px;
}

input:checked + .slider {
  background-color: #4f0c75 !important;
}

.slider.round:before {
  border-radius: 50%;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(37px);
}

.customer-box li:last-child {
  margin-left: 20px;
}

.customer-box li:last-child {
  margin-left: 23px;
}

.customer-box {
  margin: 71px 0 50px 20px;
  display: flex;
  align-items: center;
}

#dropdown-basic {
  background: none;
  border: none;
}

.dropdown-menu.show {
  border: none;
  padding-left: 0;
  background: 0 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background: 0 0 !important;
}

a.dropdown-item {
  padding: 0;
}

.flex-row.flex-wrap.market_categories.nav.nav-pills {
  max-width: 50%;
}

.explore-heading {
  text-align: center;
  color: #fff;
  font-size: 48px;
  padding: 60px 0;
}

.explore-heading span {
  color: #fe7702;
}

/* .no-data {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

#hot-collection {
  /* background-image: url(../images/explore.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  color: #fff;
  text-align: center;
  position: relative;
  padding: 10px 0 40px;
}

.list-tab-box.tab-content {
  width: 100%;
  padding: 20px 0;
}

.more-div {
  margin-bottom: 60px;
}

.name-dec {
  cursor: pointer;
}

.hot-heading {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 50px;
}

.collection-head-new {
  background: #fff;
  color: #4f0c75;
  border-radius: 30px;
  padding: 7px 14px;
  line-height: 97px;
}

#hot-collection .tab-pane {
  margin: 0 10px;
}

.bt-div {
  margin-top: 10px;
}

.tab_box_home {
  background: linear-gradient(180deg, #fafaff 0, #cfd4fe 100%);
  border-radius: 16px;
  /* max-width: 222px; */
  padding: 22px;
  margin: 0 5px 10px;
  position: relative;
  cursor: pointer;
  text-align: left;
}

.tab_box_home h4 {
  font-style: normal;
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 14px;
  color: #12041e;
  white-space: nowrap;
}

.explore-box .col-md-3 {
  padding-right: 0;
}

.tab_box_home.active {
  background: #fe7702;
}

.tab_box_home.active h4 {
  color: #fff;
}

.explore-box h1 {
  margin-bottom: 70px;
}

.tab_box_home.active h5 {
  color: #fff;
  opacity: 0.5;
}

.tab_box_home h5 {
  font-style: normal;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
  color: #c2bfe8;
  height: 17px;
}

.tab_box_home span {
  position: absolute;
  top: 50%;
  right: 20px;
  cursor: pointer;
  transform: translate(0, -50%);
}

img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

img.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-30px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-30px);
  }
}

.market_banner_section {
  background-color: #000;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  color: #fff;
  text-align: center;
  position: relative;
  padding-bottom: 60px;
}

.market-left h1 {
  font-size: 90px;
  background: -webkit-linear-gradient(45deg, #f8a8ff, #6155ed);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  font-weight: 800;
}

#nft-feature {
  /* background-image: url(../images/feature.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  color: #fff;
  text-align: center;
  position: relative;
  padding-bottom: 60px;
}

.searchbar_item video {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 10px;
}

.explore-box {
  /* background-image: url(../images/explore_bg.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  color: #fff;
  position: relative;
  padding: 60px 0;
}

.more-games {
  color: #fff;
  font-size: 17px;
  line-height: 60px;
}

#nft-feature h3 {
  color: #fff;
  margin-top: 20px;
}

#nft-feature h3 {
  color: #fff;
}

.step-two {
  padding-top: 40px;
}

.step-three {
  padding-top: 80px;
}

#nft-feature p {
  color: #ffffff70;
  padding: 0 120px;
}

.contact-box-area {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  padding: 60px 0;
}

.contact-left-side h3 {
  color: #fff;
  font-size: 40px;
  line-height: 63px;
  padding: 40px 0 0 70px;
}

.contact-left-side {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

.contact-right-side input {
  background: 0 0 !important;
  border-bottom: 1px solid #ffffff8c !important;
  border: none;
  border-radius: 0;
  color: #fff !important;
  padding-left: 0;
  padding-right: 0;
  margin-top: 50px;
}

.contact-right-side input::placeholder {
  color: #fff !important;
}

.contact-form .btn {
  display: flex;
  margin: 14px auto;
}

.contact-right-side {
  display: flex;
  margin: 25px auto;
}

.contact-form {
  background: #ffffff12;
  border-radius: 20px;
  border: 2px solid #ffffff14;
  padding: 10px 80px 10px 100px;
  position: absolute;
  bottom: 0;
}

.contact-filled-box .row {
  position: relative;
}

.choose-heading {
  font-size: 20px;
}

.single-multiple-box .react-tabs__tab-list {
  display: inline-block;
}

.addresstext {
  position: relative;
}

.addresstext i {
  position: absolute;
  right: 0;
  top: 0;
}

.single-multiple-box .react-tabs__tab--selected {
  border-radius: 5px;
  border: none !important;
  background: #fe7702 !important;
  width: 120px;
  text-align: center;
  color: #fff !important;
}

.create-items li p {
  margin: 0;
}

.single-multiple-box .react-tabs__tab {
  border: none !important;
  padding: 6px 12px;
  cursor: pointer;
  background: #f0ebf6;
  color: #0000008c;
  width: 160px;
  border-radius: 5px;
  font-size: 20px;
  text-align: center;
}

.create-items h6 {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 16px;
  max-width: 100px;
  margin: 0 auto !important;
}

.items-area-box-two img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 2px;
}

.create-items h6 {
  margin: 0;
}

.searchbar_header input {
  padding: 0 68px 0 15px;
}

.create-items ul li {
  display: flex;
  justify-content: center !important;
  border: 1px solid #0000003d;
  border-radius: 5px;
  width: 110px;
  height: 110px;
  margin-right: 15px;
  cursor: pointer;
  padding: 12px 0;
  align-items: center;
}

.items-area-box,
.items-area-box-two {
  text-align: center;
}

.modal-profile-img img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid #fff;
  box-shadow: 1px 3px 7px #c7c7c7;
}

.create-items ul {
  display: flex;
}

.modal-profile-img {
  position: relative;
  height: 30px;
}

.cover-image-box img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

form.text-left {
  text-align: left;
  margin-top: 70px;
}

.profile-input-mod {
  width: 120px;
  height: 120px;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.sports-dropdown form-select {
  margin-inline: 15px;
  margin-left: 10px;
  /* box-sizing: inherit; */
  padding: 11px;
  margin: -35px;
  margin-left: 7px;
  margin-right: -60px;
}
.img-input-mod {
  position: absolute;
  top: 0;
  height: 270px;
  width: 94%;
  opacity: 0;
  z-index: 0;
}
form.text-left.finda-display {
  z-index: 9;
  position: relative;
}

.collection-create .modal-content {
  padding: 0;
}

.craete-bt {
  background: #fe7702;
  color: #fff;
  width: 164px;
  border-radius: 5px;
  height: 50px;
  margin-left: 16px;
}

.create-items ul {
  padding: 0;
  margin-bottom: 0px;
}
.craete-bt.submit-bt {
  width: fit-content !important;
  margin: 0 auto;
  padding: 0 20px;
}

.copies-input {
  max-width: 200px;
}

.single-multiple-box .react-tabs__tab-list {
  border-bottom: none !important;
  margin-bottom: 20px;
}

.buy-nft-tab .nft-modal-items {
  min-height: 85px;
}

.buy-nft-tab .react-tabs__tab--selected {
  border: none;
  color: #fff !important;
  background: #fe7702 !important;
}

.buy-nft-tab button.buy-now-bt.btn.btn-unset {
  width: 80px;
}

.share-modal-new input {
  height: 41px;
  width: 70%;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  padding: 0 8px;
  margin-right: 15px;
}

.buy-nft-tab .react-tabs__tab {
  border: none !important;
  background: #e7e7e7;
  border-radius: 5px;
  margin-right: 5px;
}

.buy-nft-tab ul {
  border: none;
}

.modalclose_icon i {
  margin-top: -2px;
  color: black;
  font-size: 22px;
}
.modalclose_icon {
  width: 30px;
  cursor: pointer;
  height: 30px;
  /* background: #fe7702; */
  color: #fff;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
}

.uploadimg {
  display: inline-block;
  position: relative;
}

.uploadimg input {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  opacity: 0;
}

.addresstext {
  padding-right: 38px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.slide-1 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.tenis-img-two {
  margin-left: -20px;
}

.like-view {
  text-align: center;
  color: #fff;
  margin: 0 15px;
}

.like-view i {
  color: #fc3a3a;
}

.in-stock {
  background: #00000085;
  padding: 10px 13px;
  color: #fff;
  margin-left: auto;
  border-radius: 30px;
}

.slide-percent {
  font-size: 70px;
  color: #fff;
  padding: 80px 30px 0;
  margin-bottom: 0;
}

.slide-progress {
  color: #fff;
  border: 2px solid #fe7702;
  display: inline-block;
  padding: 3px 11px;
  margin-left: 30px;
}

.echo {
  color: #fff;
  padding: 140px 30px 0;
}

.text-right {
  text-align: right;
}

p.text-right i {
  font-size: 22px;
  cursor: pointer;
}

.bid-text {
  padding-left: 30px;
  color: #fff;
  font-size: 14px;
}

.bid-text span {
  color: #fe7702;
}

.bid-text-two span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.btm_details .prices-nft {
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  color: #fff;
  margin-bottom: 10px !important;
}

.total-text {
  margin-bottom: 12px !important;
}

.bid-text-two {
  padding: 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.bid-text-two.bid-usd-box {
  padding-left: 8px;
}

.mainimg {
  height: 246px;
}

.tenis-box {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  position: absolute;
  top: 4px;
  left: 0;
}

.morefaq {
  background: #fe7702;
  color: #fff !important;
  padding: 9px 20px;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: fit-content;
}

li.alice-carousel__stage-item {
  padding: 0 10px;
}

.social-icon li {
  display: inline-block;
  margin: 0 22px;
}

.social-icon {
  padding: 0;
  text-align: center;
  border-radius: 10px;
}

.slide-margin {
  margin: 7px 5px;
}

.react-multi-carousel-track:first-child
  .react-multi-carousel-item.react-multi-carousel-item--active {
  display: none;
}

.errors {
  color: red;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  display: block;
  margin-top: 10px;
}

.end-reserve-bid-box input {
  margin-bottom: 7px;
  width: 97%;
}

.end-reserve-bid-box {
  margin: 28px 23px 0;
  width: 90%;
}

.meta-mask-modal a b {
  text-decoration: underline;
}

.meta-mask-modal h3 {
  font-size: 24px;
  color: #000;
  font-weight: 600;
}

.meta-mask-modal .modal-content {
  border-radius: 10px;
  /* padding: 40px 0; */
  /* background-image: url(../images/modal-bg.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.ok-bt.btn {
  background: #fe7702 !important;
  border: none;
  width: 70px;
  height: 43px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 8px;
}

.meta-mask-modal a {
  color: #fe7702;
}

.ok-bt.btn::after {
  content: "";
  /* background-image: url(../images/line.png); */
  background-size: contain;
  position: absolute;
  height: 50px;
  width: 50px;
  right: -70px;
  top: -25px;
}

.sale-auction-tab {
  border: none !important;
}

.sale-auction-tab input {
  max-width: 240px !important;
  height: 46px;
  border-radius: 10px !important;
  border: none !important;
}

.sale-auction-tab li.nav-item button {
  width: 160px;
  border-radius: 0;
  color: #5a0075;
  border: 1px solid #fff;
  box-shadow: 2px 4px 1px #b04f19;
  background: #fff;
}
.tab-bid-box .date-box {
  margin-right: 10px;
}

.date-box .MuiFormControl-root {
  width: 100%;
}

.tab-one {
  max-width: 280px;
}

.tab-one input {
  height: 46px;
}

.tab-bid-box label {
  background: #fff;
  padding: 0 9px !important;
  border-radius: 5px;
}

.tab-bid-box input {
  width: 97%;
  height: 46px;
  border-radius: 10px;
}

.profile-box {
  background: #f3f3f3;
  margin: 86px 0 0 0;
  padding: 70px 0;
}

.profile-left-side img {
  width: 220px;
  height: 220px;
  border-radius: 15px;
  object-fit: cover;
}

.edint-pencil {
  background: #fe7702;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  font-size: 13px;
  position: absolute;
  right: -15px;
  top: -16px;
  cursor: pointer;
}

.profile-left-side {
  max-width: 400px;
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  margin: 0 auto;
  position: relative;
  width: 90%;
}

.profile-Right-side {
  text-align: left;
  padding: 0 0 0 18px;
}

.profile-Right-side i {
  font-size: 18px;
  margin-right: 8px;
}

.profile-Right-side .fa-address-book-o {
  margin-right: 0;
}

.modal-img-new {
  width: 160px;
  height: 160px;
  object-fit: cover;
}

.profile-Right-side h2 {
  font-size: 22px;
  color: #47036f;
  display: inline-flex;
  align-items: center;
}

.edit-profile-modal .modal-dialog {
  max-width: 410px;
  margin: 0 auto;
}

.modal-form-new button.ok-bt {
  background: #fe7702;
  padding: 7px 20px;
  border-radius: 5px;
  color: #fff;
  margin: 0 auto;
  display: block;
}

.modal-form-new {
  text-align: left;
  margin: 30px;
}

.profile-Right-side h4 {
  font-size: 15px;
  color: #fe7702;
}

.bid_auction .tab-content {
  margin: 0 13px 0 0 !important;
}

.tab-bid-box .row {
  --bs-gutter-x: 0;
}

.sale-auction-tab .nav-item.show .nav-link,
.sale-auction-tab .nav-link.active {
  color: #fff !important;
  background-color: #5a0075 !important;
  border-color: #5a0075 !important;
}

.auction-right-side {
  text-align: center;
}

.navbar-light .navbar-toggler {
  color: rgb(255 255 255 / 100%) !important;
  border-color: rgb(255 255 255 / 50%) !important;
}

.navbar-light .navbar-toggler-icon {
  filter: invert(1);
}

.save-bid-price {
  background: #5a0075;
  padding: 6px;
  margin-top: 20px !important;
  display: block;
  width: 90px !important;
  text-align: center;
  font-size: 20px;
  color: #fff !important;
}

.main-loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #000000d1;
  z-index: 1056;
  top: 0;
}

.loading {
  text-align: center;
  font-size: 18px;
  color: #fff;
  margin-top: 15px;
}

.name-dec {
  display: flex;
  align-items: center;
}

.name-dec p {
  margin-right: 5px;
  font-size: 18px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.search_marketplace {
  /* width: 70%; */
  display: flex;
  align-items: center;
}
.shade-box {
  position: absolute;
  width: 500px;
  top: 0;
  left: 220px;
}

.market_categories .nav-item .nav-link {
  background: linear-gradient(180deg, #fafaff 0, #cfd4fe 100%);
  border-radius: 16px;
  min-width: 180px;
  padding: 22px;
  margin: 1px 10px 9px 0;
  position: relative;
  cursor: pointer;
  text-align: left;
  height: 83px;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}


.MuiOutlinedInput-root.MuiInputBase-root {
  background: #fff;
  height: 47px;
  margin-bottom: 7px;
  width: 100%;
  border-radius: 10px;
  /* border: 1px solid #ced4da !important; */
}
.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root
  label {
  background: white;
  padding: 0 6px;
  color: #000;
}
.market_categories .nav-item .nav-link span {
  position: absolute;
  top: 50%;
  right: 20px;
  cursor: pointer;
  transform: translate(0, -50%);
}

.market_categories .nav-item .nav-link.active {
  background: #fe7702;
  border-radius: 16px;
}

.market_categories .nav-item .nav-link.active h4 {
  color: #fff;
}

.market_categories .nav-item .nav-link.active h5 {
  color: #fff;
  opacity: 0.5;
}

.market_categories .nav-item .nav-link h4 {
  font-style: normal;
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 14px;
  color: #12041e;
}

.market_categories .nav-item .nav-link h5 {
  font-style: normal;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 14px;
  color: #c2bfe8;
}

.market_banner_section.h-100 {
  padding: 100px 0 20px;
  min-height: 875px;
}

.search_marketplace .searchbar_header {
  margin-left: 0;
}

.slide-2 {
  background-image: unset;
  position: relative;
  margin-top: 0;
  padding: 0;
  border-radius: 15px;
}

.my_listing-area {
  min-height: 600px;
  background: #f3f3f3;
  padding: 50px 0;
  position: relative;
}

span.navbar-brand img {
  cursor: pointer;
  max-width: 229px;
}

.searchbarlist {
  background-color: #fff;
  border-radius: 10px;
  border-bottom: 1px solid #d3d3d32e;
  padding: 10px;
  width: 100%;
  position: absolute;
  top: 64px;
  min-height: 100%;
  max-height: 370px !important;
  overflow: auto;
  box-shadow: 0 2px 4px #00000024;
}

.search-market-banner {
  background-color: #fff;
  border-radius: 10px;
  border-bottom: 1px solid #d3d3d32e;
  padding: 10px;
  width: 100%;
  min-height: 100%;
  max-height: 180px;
  overflow: auto;
  z-index: 1;
  position: relative;
  text-align: left;
}

#asset-search-result {
  max-width: 370px;
  max-height: 180px;
}

.onsale-modal.form-select {
  position: absolute;
  max-width: 150px;
  top: 0;
}

.searchbar_item {
  position: relative;
  padding: 5px 2px;
  border-bottom: 1px solid #d3d3d32e;
  margin-bottom: 0;
}

.__react_component_tooltip {
  background: #fcfaff !important;
  width: 291px;
  border-radius: 10px !important;
  color: #000 !important;
  font-size: 13px !important;
  font-weight: 400;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.33);
  padding: 10px !important;
}

.choose-heading i {
  cursor: pointer;
}

.__react_component_tooltip .multi-line {
  text-align: left !important;
}

.__react_component_tooltip::before {
  color: #fcfaff !important;
}

.__react_component_tooltip::after {
  border-bottom-color: #fcfaff !important;
}

.price-category {
  padding-left: 8px;
}
.boder-box svg {
  visibility: visible !important;
}
.searchbar_item h5 {
  font-size: 17px;
  color: #292929;
}

.bid-text-two.bid-usd-box {
  color: #fff !important;
}

.bid-text-two.bid-usd-box span {
  color: #fff !important;
}

.header-serach-box {
  padding-left: 43px;
}

.header-serach-box h5,
.header-serach-box p {
  margin: 0;
  word-break: break-word;
}

.searchbar_item img {
  width: 38px;
  position: absolute;
  height: 38px;
  border-radius: 100%;
  object-fit: cover;
  overflow: hidden;
}

.featured-area {
  text-align: center;
}

.btm_details .attri-description {
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 3px;
}

.featured-area ul li {
  list-style: none;
  padding: 13px 30px;
}

.featured-area {
  text-align: center;
  padding: 40px 0;
}

.featured-area ul {
  display: flex;
  justify-content: center;
}

.works-area-box {
  /* background-image: url('../images/faq_bg.png'); */
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  color: #fff;
  padding: 150px 0 60px;
}

.featured-area h5 {
  font-size: 36px;
  font-weight: 700;
}

.sign-area h5 {
  margin: 20px 0;
}

.sign-area p {
  color: #ffffffb8;
  font-weight: 400;
}

.auction-right-side img {
  height: 500px;
  max-width: 540px;
  object-fit: contain;
  border-radius: 10px;
  width: -webkit-fill-available !important;
}

.sign-area {
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 20px 96px 20px 20px;
  height: 317px;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  justify-content: center;
}

.works-area-box h2 {
  text-align: center;
  font-size: 60px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 50px;
}

.featured-area {
  text-align: center;
  padding: 40px 0;
}

.faq-box h2.accordion-header {
  margin: 0;
  background: 0 0;
}

.accordion-item {
  border: 1px solid #fff !important;
}

.faq-box h2.accordion-header button {
  background: 0 0;
  color: #fff !important;
}

.slide-2 video {
  height: 246px;
  object-fit: cover;
  display: block;
}

.accordion-button::after {
  filter: invert(1);
}

.accordion {
  max-width: 750px;
  margin: 0 auto;
}

.accordion-item {
  background: 0 0;
  border: 2px solid #fff;
  color: #fff;
  margin-bottom: 20px;
  border-radius: 10px !important;
}

.list-market-new video {
  height: 246px;
  object-fit: contain;
}

.accordion-item {
  background: 0 0 !important;
}

.featured-area h5 {
  font-size: 31px;
  font-weight: 700;
  color: #565656;
}

.slide-2 img {
  width: 100%;
  border-radius: 10px;
}

.slide-2 {
  background: #111722;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #6a6a6a66;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  /* height: 100%; */
}
.market_banner_section .tab-panne-one .slide-2 {
    height: 100%;
}

.btm_details {
  border-radius: 0 0 15px 15px;
  padding: 8px 0 1px 8px !important;
  text-align: left;
  min-height: 45px;
}

.meta-mask-modal.edit-profile-modal.buy-now-modal.modal .modal-content {
  padding-bottom: 5px;
}

.list-market-new:hover {
  background: #131414;
}

.mainimg {
  height: 246px;
  border-radius: 10px 10px 0 0 !important;
  object-fit: cover;
}
.popup-social-icons {
  display: flex;
  justify-content: center;
}

.connect-wallet-bt a {
  color: #fe7702;
}

.connect-wallet-bt:hover a {
  color: #fff;
}



span.connect-wallet-bt:hover {
  background: #fe7702;
  border-color: #fe7702;
  color: #fff;
}
.plus-add svg {
  visibility: unset;
  width: 40px;
}
.wallet-bt-connect .dropdown-menu.show {
  background: #fe7702;
  /* padding: 5px 10px 5px 10px; */
}

.navbar-expand-xl .navbar-nav {
  align-items: center;
}

.wallet-bt-connect .dropdown-menu.show a:first-child {
  border-top: none;
}

.wallet-bt-connect .dropdown-menu.show a {
  color: #fff !important;
  border-top: 1px solid #ffffff59;
  padding: 10px;
  font-size: 14px;
}

.my_listing-area h2 {
  color: #47036f;
}

.btm_details .attri-price {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}

.list-market-new {
  border-radius: 15px;
  overflow: hidden;
  background-color: #111722;
}

.creater-rayality-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.slide-2 .bid-text-two {
  padding-top: 8px;
  padding-left: 0;
}

.slide-2 .echo-two {
  padding: 0;
  text-transform: capitalize;
}

.auction-right-side video {
  max-width: 560px;
  width: -webkit-fill-available;
}

.bid_auction {
  background-image: linear-gradient(211deg, #4f0c75, #fe7702);
  padding: 140px 0 50px;
  min-height: 80vh;
}

.live-auction h2 {
  color: #fff;
  font-size: 34px;
  margin-left: 10px;
}

.fade {
  transition: opacity 3s linear !important;
}

.day-timer h4 {
  font-family: "Circular Std", sans-serif !important;
  margin-bottom: 9px;
}

.live-auction {
  display: flex;
  align-items: start;
}

.day-timer {
  background: #00000099;
  border: 2px solid #053430;
  padding: 7px;
  border-radius: 20px;
  color: #fff;
}

.live-auction img {
  margin-top: 10px;
}

.day-timer h6 {
  font-size: 14px;
  margin-bottom: 0;
}

.share-bt p {
  margin-bottom: 0;
  background: #ffffff21;
  border-radius: 50px;
  padding: 5px 10px 5px 5px;
  margin-left: 10px;
  font-size: 14px !important;
  /* min-width: 100px; */
}

.fa-share-alt {
  background: #fff;
  width: 20px;
  text-align: center;
  color: #b34933;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
}
.footer-area-email.modal-body {
  font-size: 14px;
  /* padding-bottom: 11px; */
}
.auction-form .input-group-text {
  border-radius: 0;
  background-color: #fff;
  border: none;
  border-left: 3px solid #26b1a1;
  margin: 13px 0;
  padding: 4px;
}

.auction-form .input-group {
  background: #fff;
  border-radius: 50px;
  padding: 0 20px;
}

.auction-form .row {
  display: flex;
  align-items: center;
}

.auction-form input {
  border-radius: 0 30px 30px 0 !important;
  height: 58px;
  border: none;
}

.bid_auction hr {
  color: #fff;
}

.eth-box h5 {
  font-size: 16px;
  color: #fff;
  background: #ffffff21;
  border-radius: 50px;
  padding: 0 11px;
  margin: 6px 0 6px 10px;
}

.bid_auction h1 {
  font-size: 24px;
  color: #ffd6ae;
  font-weight: 700;
}

.buy-now-bt {
  background: url("../images/buy.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  height: 79px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  color: #fff !important;
  max-width: 190px;
  margin-top: 20px;
  justify-content: center;
}

.d-inline-block {
  display: inline-block !important;
}

.air-max h2 {
  font-size: 35px;
  color: #ffd6ae;
  margin-bottom: 0;
  display: block;
  line-height: 30px;
  max-width: 600px;
  word-break: break-word;
}

.name-description h5 {
  color: #fff;
  margin-bottom: 5px;
}

.name-description p {
  font-size: 14px !important;
  color: #efefef;
  font-weight: 400;
  margin: 0;
}


p.nft-auction-time {
  background: #4f0c75;
  color: #fff;
  padding: 2px 12px;
  border-radius: 15px;
  max-width: fit-content;
}
.air-max h4 {
  color: #fff;
  font-size: 20px;
  margin-bottom: 0;
}

.buy-sell-box {
  margin-top: 25px;
}

.buy-sell-box h4 {
  color: #fff;
}

.collections-details-box {
  max-width: 740px;
  display: flex;
  align-items: center;
}

.details-cover {
  margin-bottom: 50px;
}

.collections-details-box img {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  object-fit: cover;
}

.buy-now-png {
  /* background-image: url('../images/buy.png'); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 44px;
  color: #fff;
  line-height: 60px;
}

.bid_auction p {
  color: #ffffffb8;
  font-size: 16px;
  word-break: break-word;
  font-weight: 400;
  margin: 0;
}

.air-max {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.day-timer li {
  color: #fff;
  display: inline-block;
  margin: 0 20px;
  text-align: center;
}

.day-timer {
  margin: 0 0 0 auto;
  text-align: center;
}

.seller-box {
  background-color: #000;
  width: 100%;
  color: #fff;
  position: relative;
  padding: 40px 0;
}

.collec-heading {
  color: #fff;
  margin-bottom: 30px;
}
span.text-danger {
  color: red !important;
  font-size: 14px;
  font-weight: 400;
}
.collec-heading a {
  color: #c5c5c5 !important;
}

.details-cover img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 15px;
}
.collections-name-box {
  margin: -195px 0 0 0 !important;
  background: #00000087;
  position: relative;
  width: 100%;
  border-radius: 0 0 15px 15px;
  padding: 10px;
}

.seller-box h2 {
  font-size: 36px;
  text-align: center;
  margin-bottom: 30px;
}

.seller-box .tab-pane {
  margin: 0 10px;
}

.seller-box h2 span {
  color: #fe7702;
}

.login_header button {
  font-size: 14px;
}

.my-acc-bt {
  color: rgba(61, 66, 79, 0.65) !important;
}

.navbar-expand-lg .navbar-collapse {
  align-items: initial;
}

.nft-collection-box li {
  color: #ffffff94;
  list-style: none;
  display: inline-table;
  width: 120px;
  text-align: center;
}

.nft-collection-box {
  text-align: left;
  position: relative;
}

.nft-collection-box ul {
  padding: 0;
  border: none;
}

.nft-collection-box li a {
  color: #fff;
}

.faq-link {
  color: #fe7702 !important;
  font-size: 16px;
  display: block;
  margin-top: 30px;
  text-decoration: underline !important;
}

.onsale-modal.form-select {
  position: absolute;
  max-width: 150px;
  top: 0px;
  left: 280px;
  height: 48px;
  border-radius: 10px;
  border: 2px solid #000;
}

.nft-collection-box li a.active {
  background: #000;
  padding: 8px 10px;
  border-radius: 10px;
  height: 40px;
  display: block;
  color: #fe7702;
}

.nft-collection-box ul {
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  background: #2f333c;
  padding: 5px;
  margin-bottom: 15px;
}

.nft-collection-box h5 {
  margin-bottom: 10px;
}

.searhmarket-content {
  padding-left: 45px;
}
.service_section1 {
  padding: 80px 0;
}
.single-nft-preview {
  text-align: center !important;
}
.signle-area-collectibla-box {
  margin: 0;
}
.swal-button {
  background-color: #fe7702 !important;
}
.swal-footer {
  text-align: center;
}
.kennedy-brooks {
  background-image: url(../images/promote-bg.png);
  background-repeat: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}
#center-frame1{
  background: transparent;
}
#center-frame2{
  background: transparent;
}
.athelete-top.area {
  margin-top: 75px;
}
.ReactCrop__child-wrapper>img, .ReactCrop__child-wrapper>video {
  width: 1024px !important;
  max-height: 1024px !important;
  object-fit: contain;
  min-width: 100%;
  min-height: 450px;
}
.cancel-btn {
  background: #959595 !important;
  padding: 9px 20px !important;
}
.add-bank-section {
  background-image: url("../images/about_bg.jpg");

  background-position: center;

  background-repeat: no-repeat;

  background-size: cover;

  color: #fff;

  padding: 120px 0 60px;
  min-height: 750px;
  max-height: 1600px;
}
form.login-form-box div {
  margin: 15px 0;
}
.action-btns-box button.submit-bt.btn.btn-unset.btn.btn-primary {
  width: 100% !important;
}
.action-btns-box button.skip-btn.btn.btn-unset {
  background: gray !important;
  cursor: pointer;
  width: 100%;
  margin-left: 12px !important;
}
.login-form-box .error-text {
  color: #c64040;
  display: inline-block;
  margin-top: 9px;
}
.cover-image-box .errors {
  z-index: 99;
  position: relative;
  top: -49px;
}
.seller-info .nft-collection-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.listingsection .nft-collection-box ul {
  margin: unset !important;
  padding: 7px 7px;
  width: fit-content !important;
}
.profile-box.under-profile-box {
    padding-bottom: 20px;
}
.action-right-btn {
    text-align: right;
    padding: 0 23px;
}
.primary-button {
     /* font-family: 'Valorant', sans-serif; */
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.05rem;
  border: 1px solid #0E1822;
  padding:11px 20px;
  background-image: url("../images/download.svg");
  background-color: #0E1822;
  background-size: 200%;
  background-position: 200%;
  background-repeat: no-repeat;
  transition: 0.3s ease-in-out;
  transition-property: background-position, border, color;
  position: relative;
  z-index: 1;
 }
 
 .primary-button:hover {
  border: 1px solid #FF4655;
  color: white;
  background-position: 40%;
 }
 
 .primary-button:before {
  content: "";
  position: absolute;
  background-color: #0E1822;
  width: 0.2rem;
  height: 0.2rem;
  top: -1px;
  left: -1px;
  transition: background-color 0.15s ease-in-out;
 }
 
 .primary-button:hover:before {
  background-color: white;
 }
 
 .primary-button:hover:after {
  background-color: white;
 }
 
 .primary-button:after {
  content: "";
  position: absolute;
  background-color: #fe7702;
  width: 0.3rem;
  height: 0.3rem;
  bottom: -1px;
  right: -1px;
  transition: background-color 0.15s ease-in-out;
 }
 
 .button-borders {
  position: relative;
  width: fit-content;
  height: fit-content;
 }
 
 .button-borders:before {
  content: "";
  position: absolute;
  width: calc(100% + 0.5em);
  height: 50%;
  left: -0.3em;
  top: -0.3em;
  border: 1px solid #0E1822;
  border-bottom: 0px;
     /* opacity: 0.3; */
 }
 
 .button-borders:after {
  content: "";
  position: absolute;
  width: calc(100% + 0.5em);
  height: 50%;
  left: -0.3em;
  bottom: -0.3em;
  border: 1px solid #0E1822;
  border-top: 0px;
     /* opacity: 0.3; */
  z-index: 0;
 }
 
 .shape {
  fill: #0E1822;
 }
 button.follow-btn.btn.btn-primary img {
  width: 25px;
  height: 25px;
  margin-right: 4px;
}
button.follow-btn.btn.btn-primary {
  background: #fe7702;
  border: none;
  filter: drop-shadow(0px 13px 16px rgba(0, 0, 0, 0.25));
   display: flex;
}
button.follow-btn.unfollow-btn.btn.btn-primary {
  background: #2F333C;
  display: flex;
}
@media (min-width: 1700px) {
  .App .container {
    max-width: 1600px;
  }
}

@media (max-width: 1699px) {
  .searchbar_header input {
    min-width: 276px;
  }
  .athe-banner-area {
    padding: 84px 0;
  }
}

@media (max-width: 1399px) {
  .home_banner_right {
    background-size: contain;
    background-repeat: no-repeat;
  }

  .market_categories .nav-item .nav-link {
    min-width: 170px;
  }

  button.generate-nft-btn {
    width: fit-content !important;
  }

  .searchbar_header input {
    min-width: 233px;
  }

  header .nav-link {
    font-size: 15px;
    margin: 0 15px;
  }

  .navbar-brand img {
    max-width: 150px;
  }
}

@media (max-width: 1400px) {
  .athelete-top.area {
    margin-top: 70px;
}
  header .container {
    max-width: 100%;
  }
  .air-max h2 {
    font-size: 28px;
  }
  .input-image {
    width: 290px;
    height: 290px;
  }

  .activities-heading h3,
  .activities-heading h3 span {
    font-size: 100px;
  }

  .activities-heading h5 {
    font-size: 30px;
    line-height: 54px;
  }

  .activities-heading h5 span {
    font-size: 30px;
  }

  .soccor-text {
    font-size: 60px;
  }

  .collection_img img {
    height: 245px;
  }

  .tab_box_home span {
    right: 7px;
  }

  .in-stock {
    padding: 6px 13px;
    font-size: 13px;
  }
}

@media (max-width: 1199px) {
  .athelete-top.area {
    margin-top: 82px;
  }
  .collection-team a {
    display: flex;
    flex-direction: column;
  }
  .home-banner-section {
    padding: 50px 0;
  }
  .App .common_bg .container {
    max-width: 1100px;
  }
  .air-max h2 {
    font-size: 24px;
  }
  .soccor-banner h2,
  .soccor-banner h2 span {
    font-size: 90px;
  }

  .connect-wallet-bt {
    display: block;
  }

  .input-image {
    width: 250px;
    height: 250px;
  }

  .soccor-banner {
    padding: 140px 0;
  }

  .activities-heading h3,
  .activities-heading h3 span {
    font-size: 80px;
  }

  .activities-heading h5 span {
    font-size: 26px;
  }

  .activities-heading h5 {
    font-size: 26px;
    line-height: 47px;
  }

  .soccor-text {
    font-size: 48px;
  }

  header {
    background: rgb(0 0 0);
  }

  .skiptranslate.goog-te-gadget {
    margin: 0 auto;
    height: 42px !important;
  }

  .day-timer li {
    margin: 0 12px;
  }

  .live-auction {
    display: inline-block !important;
  }

  .home-banner-left h1 {
    font-size: 50px;
  }

  .navbar-nav .dropdown-item .startselling_btn {
    width: 110px !important;
    text-align: center;
    border-radius: 10px !important;
  }

  .navbar-nav.navbar-nav-scroll {
    margin-bottom: 16px !important;
  }

  #dropdown-basic {
    padding: 14px 7px;
    font-size: 13px;
  }

  .searchbar_header input {
    min-width: 192px;
  }

  header .startselling_btn {
    padding: 14px 7px;
    font-size: 13px;
  }

  .collecting-bt {
    font-size: 16px;
  }

  .market-left h1 {
    font-size: 50px;
  }

  .tab_box_home {
    padding: 10px;
    margin: 0 5px 10px;
  }

  .explore-box h1 {
    margin-bottom: 23px;
  }

  .home_banner_right {
    padding: 35px 0;
  }

  .hot-heading {
    font-size: 50px;
  }

  .home-banner-left p {
    font-size: 18px;
  }

  .collection_img img {
    height: 180px;
  }

  .App .container {
    max-width: 1100px;
    padding: 0 20px;
  }

  .tenis-box img {
    max-width: 50px;
  }

  .tenis-box {
    padding: 9px 8px;
  }

  .like-view h6 {
    font-size: 12px;
  }

  .instant-sale-prize {
    width: 78%;
  }

  .name-description-filed {
    padding: 30px 12px;
  }

  .seller-box h2 {
    font-size: 50px;
  }

  .navbar-nav button.btn.btn-unset {
    width: fit-content;
    margin: 0 auto;
  }

  #google_translate_element {
    text-align: center;
    margin-top: 11px;
  }

  .navbar-nav .dropdown,
  .navbar-nav .dropdown-menu.show {
    text-align: center;
  }

  header .startselling_btn {
    margin: 10px auto !important;
    width: fit-content;
    width: 180px;
  }

  #dropdown-basic {
    width: 180px;
    height: 50px;
  }

  #google_translate_element select {
    margin-left: 0;
  }

  header .nav-link {
    margin: 12px 15px 0;
  }
}

@media (max-width: 991px) {
  .collection-team a {
    display: unset;
    flex-direction: unset;
  }
  .ath-content p,
  .get-in-area p,
  .unique-content p {
    font-size: 16px;
  }
  .profile-area {
    padding: 40px 30px;
  }
  .preview-mobile-view {
    /* display: block; */
    margin-top: 30px;
  }
  .signle-detail-data-area input.form-control {
    width: 100%;
  }
  .auction-reverse-box {
    display: flex;
    flex-direction: column-reverse;
  }

  .founding-div p {
    font-size: 15px;
  }
  .founding-div h4 {
    font-size: 22px;
  }
  .common_box_content h5 {
    font-size: 15px;
  }
  .providers-logo {
    margin-bottom: 20px;
  }
  .first_section_main {
    text-align: center;
  }
  .air-max h2 {
    font-size: 21px;
  }
  .input-image {
    width: 172px;
    height: 172px;
  }

  .beach-text {
    font-size: 35px;
  }

  .soccor-text {
    font-size: 38px;
  }

  .activities-heading h5 {
    font-size: 20px;
    line-height: 32px;
  }

  .activities-heading {
    position: absolute;
    top: 80px;
  }

  .activities-heading h3,
  .activities-heading h3 span {
    font-size: 60px;
  }

  .prizes-box img {
    max-width: 50%;
  }

  .contact-form-two {
    max-width: 100%;
    padding: 50px;
  }

  .activities-heading h5 span {
    font-size: 20px;
  }

  .soccor-banner h2,
  .soccor-banner h2 span {
    font-size: 70px;
  }

  .flex-row.flex-wrap.market_categories.nav.nav-pills {
    max-width: 68%;
  }

  .main_h2.underline_text {
    font-size: 25px;
  }

  .App .common_bg .container {
    max-width: 720px;
  }

  .main_h2 br {
    display: none;
  }

  .p_text {
    color: #c8ceec;
    font-size: 16px;
    font-weight: normal;
    width: 90%;
    margin-right: auto;
  }

  .home_banner_section {
    padding: 90px 0 0px;
  }

  .about-page h1 {
    font-size: 60px;
  }

  .market_banner_section #left-tabs-example-tabpane-first {
    margin-top: 0;
  }

  .market_banner_section.h-100 {
    padding: 50px 0 20px;
  }

  .sign-area {
    height: 237px;
    margin-bottom: 20px;
  }

  #google_translate_element select {
    margin: 0;
  }

  .App .container {
    max-width: 720px;
  }

  .market_banner_section {
    margin-top: 74px;
  }

  .home_banner_right {
    /* height: 85vh; */
  }

  .attri-name {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 50px;
    line-height: 1.8em;
  }

  .more-div {
    margin-bottom: 12px;
  }

  .explore-box {
    padding: 30px 0 0;
  }

  .leftfooter {
    padding-right: 20px;
  }

  footer h3 {
    margin-bottom: 19px;
    width: 100%;
  }

  .category_row.row {
    margin-top: 0px;
  }

  .footer_head h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: -0.3px;
    color: #fff;
  }
  .auction-right-side {
    padding: 0;
    margin-bottom: 15px;
  }

  .bid_auction {
    margin-top: 74px;
    padding: 60px 0 20px;
  }

  .connect-wallet-bt {
    margin: 0 auto;
  }

  .preview-image {
    width: 100%;
    height: auto;
  }

  .featured-athlet-left h3,
  .featured-athlet-right h3 {
    font-size: 28px;
    font-weight: 700;
  }
  .athe-banner-area {
    margin-top: 70px;
  }
  .unique-content h2 br {
    display: none;
  }
  .get-in-img img {
    top: 0;
  }
}

@media (max-width: 767px) {
  .auction-right-side img {
    height: 400px;
  }
  .kennedy-brooks .row {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }
  .kennedy-brooks img {
    margin-bottom: 40px;
  }
  .auction-form input {
    height: 45px;
  }
  .get-in-section {
    padding: 52px 0 0;
  }
  .get-in-img img {
    width: 100%;
    max-width: 60%;
  }
  .get-in-img img {
    position: unset;
  }
  .inner-content {
    border-bottom: 1px solid #1e1e1e;
  }
  .unique-img img {
    max-width: 60%;
    margin-bottom: 30px;
  }
  .ath-img img {
    margin-bottom: 20px;
  }
  .ath-content h2,
  .get-in-area h2,
  .unique-content h2 {
    font-size: 30px !important;
  }
  .banner-ath-image {
    display: block;
  }
  .athe-banner-area {
    background-image: none;
    margin-top: 20px;
  }
  .flex-direction {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  /* .detail-left-side {
    height: 350px;
  } */
  .collections-name-box {
    margin: -35px 0 0 0 !important;
    border-radius: 15px;
  }
  .create-store-info {
    padding-left: 0 !important;
  }
  .circle_row .col-md-6:last-child {
    padding: 0 0px 0 11px !important;
  }
  .flex-row.flex-wrap.market_categories.nav.nav-pills {
    max-width: 100%;
  }
  .detail-left-side img {
    float: none;
    margin: 0 auto;
    display: block;
    height: 400px;
  }

  .prizes-box {
    padding: 140px 0;
  }
  .kennedy-brooks img {
    margin-bottom: 40px;
  }

  .contact-form-two h3,
  .contact-form-two h3 span {
    font-size: 36px;
  }

  .activities-heading {
    top: 53px;
  }

  .beach-soccor-one {
    margin-bottom: 20px;
  }

  .input-image {
    width: 300px;
    height: 300px;
  }

  .member-one {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .direction-change {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 50px;
  }

  .greate-left {
    text-align: center;
    margin-top: 20px;
  }

  .greate-left h2 {
    font-size: 48px;
  }

  .sports-tab--new {
    width: 50%;
  }

  .auction-right-side {
    margin-bottom: 30px;
  }

  .about-page h3 {
    font-size: 30px;
    margin: 0 0 20px 0;
  }

  .about-page h2 {
    font-size: 30px;
  }

  .about-page {
    padding: 120px 0 20px;
    min-height: 600px;
  }

  .about-page h1 {
    font-size: 40px;
  }

  .founding-div h4 {
    line-height: 28px;
    font-size: 20px;
  }
  .founding-div p {
    font-size: 16px;
    margin: 23px 0;
  }

  .new-banner-area {
    margin-top: 75px !important;
  }

  .navbar-brand img {
    max-width: 150px !important;
  }

  .air-max h2 {
    font-size: 20px;
  }

  .details-cover img {
    height: 280px;
  }

  .bid_auction p {
    font-size: 14px;
  }

  .home-banner-left h1 {
    font-size: 40px;
  }

  .bid_auction h1 {
    font-size: 20px;
  }

  .hot-heading {
    font-size: 40px;
  }

  .works-area-box h2 {
    font-size: 40px;
  }

  .shade-box {
    left: 0;
  }

  /* .crete-collect-new {
    flex-direction: column-reverse;
  } */

  /* .preview-area {
    margin: 20px;
  } */

  .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
  }

  .home-banner-left h1 {
    text-align: center;
  }

  .home-banner-left {
    text-align: center;
  }

  .rightsdefooter {
    margin-top: 30px;
  }

  .explore-box .text-center.col-md-5 {
    max-width: 500px;
    margin: 0 auto;
  }

  .down-scroll {
    margin-top: 80px;
  }

  .leftfooter {
    margin-bottom: 20px;
  }

  .rightsdefoote {
    text-align: left;
  }

  .market_banner_section.h-100 {
    padding: 30px 0;
  }

  .scroll-div {
    margin-top: 30px;
  }

  .founding-div {
    margin: 30px auto 0;
  }
}

@media (max-width: 576px) {
  .own-journey-area .create-store-info {
    margin-bottom: 30px;
}
.profile-box.under-profile-box {
  padding-bottom: 21px;
}
.action-right-btn {
  justify-content: center;
  margin-top: 26px;
}
.nft-collection-box {
  flex-wrap: wrap;
}
.nft-collection-box button.btn.btn-unset.cursor-pointer.btn.btn-primary {
  margin: 12px 0;
}
  .athelete-top.area {
    margin-top: 75px;
  }
  iframe#review-card-payment-iframe {
    width: 87%;
    left: 7%;
  }
  .buy-now-bt {
    max-width: 160px;
  }
  .detail-collection-area {
    margin-left: 0;
  }
  .ath-content h2,
  .get-in-area h2,
  .unique-content h2 {
    font-size: 28px !important;
  }
  .greate-left {
    padding: 0;
  }
  button.btn.btn-unset {
    padding: 8px;
  }
  button.btn.btn-unset {
    padding: 8px;
  }
  .main_h2 {
    font-size: 32px;
  }
  .main_h2 br {
    display: none;
  }
  .touch_form {
    padding: 37px 27px;
  }

  .forrm-submit-btn {
    width: 100%;
    margin: 30px auto 0 !important;
  }
  .soccor-banner h2,
  .soccor-banner h2 span {
    font-size: 50px;
  }
  .beach-text {
    font-size: 24px;
  }
  .soccor-text {
    font-size: 28px;
  }
  .contact-form-two {
    padding: 30px;
  }
  .contact-form-two h3,
  .contact-form-two h3 span {
    font-size: 24px;
    line-height: 38px;
  }
  .activities-heading h3,
  .activities-heading h3 span {
    font-size: 40px;
  }

  form.login-form-box {
    text-align: left;
  }

  .about-page h1 {
    font-size: 30px;
    margin-top: 10px;
  }

  .rightside_border {
    max-width: fit-content;
    margin-top: 30px;
  }

  .about-page {
    text-align: center;
  }

  .air-max h2 {
    font-size: 22px;
  }

  select.sort-by-filter.form-select {
    height: 55px;
    border-radius: 15px;
    width: 200px;
  }

  .sign-area {
    height: 267px;
    margin-bottom: 20px;
    padding: 0 16px;
  }

  .social-icon li {
    display: inline-block;
    margin: 13px 22px;
  }

  .featured-area ul {
    display: block;
  }

  .home-banner-left h1 {
    font-size: 42px;
  }

  .hot-heading {
    font-size: 42px;
  }

  .eth-box h5 {
    font-size: 20px;
  }

  .seller-box h2 {
    font-size: 36px;
  }

  .air-max h4 {
    font-size: 20px;
  }

  .explore-box .col-sm-3 {
    width: 50%;
  }

  .home-banner-left {
    padding: 70px 10px 0;
  }

  .home_banner_right {
    /* height: 36vh; */
  }

  .home-banner-left h1 {
    font-size: 30px;
  }

  .hot-heading {
    font-size: 30px;
  }

  .market-left h1 {
    font-size: 36px;
  }

  .collection-head-new {
    padding: 7px 9px;
    font-size: 14px;
  }

  .works-area-box h2 {
    font-size: 30px;
  }

  .rightsdefooter {
    margin-top: 0;
  }

  footer {
    padding: 30px 10px;
  }

  .end-reserve-bid-box {
    margin: 15px 23px 0 8px;
  }

  .category_row.row .nav-item {
    width: 50%;
  }

  .market_categories .nav-item .nav-link {
    min-width: 160px;
    margin: 5px;
  }

  .search_marketplace {
    width: 100%;
  }

  .category_row.row {
    margin-top: 30px;
  }
  .creater-rayality-box {
    display: block;
  }
  .greate-left h2 {
    font-size: 35px;
  }
  button.select-payement-buy-btn {
    max-width: unset;
  }
}
@media (max-width: 480px) {
  /* .home_header .container {
    flex-direction: column;
  } */
  .athelete-top.area {
    margin-top: 70px;
}
  .toggle-drop-right {
    margin-left: -160px;
    margin-top: 4px;
  }
  select.sort-by-filter.ms-2.mobile-filter.form-select {
    margin-left: 0 !important;
}
.search_marketplace select {
  margin: 12px 0 0 0;
}
.search_marketplace {
  flex-direction: column;
}
select.sort-by-filter.form-select {
  width: 291px;
}
}
@media (max-width: 420px) {
  .own-journey-area .create-store-info h4 {
    font-size: 22px !important;
}
.d-flex.align-items-center.justify-content-between.mobile-view {
  flex-direction: column;
}
  .discord-section-bg {
    margin: 65px 0 0px 0;
  }
  .nil-providers {
    padding: 15px 0;
  }
  span.navbar-brand {
    margin-right: 0;
  }
  .get-in-bg {
    padding: 20px;
  }
  .correct-image img {
    width: 150px;
  }
  .bid-modal.text-center h5 {
    font-size: 18px;
  }
  .logout-text {
    font-size: 16px;
  }
  .sale-auction-tab li.nav-item button {
    width: 129px;
    font-size: 14px;
    white-space: nowrap;
  }
  .single-multiple-box .react-tabs__tab {
    width: 136px;
    font-size: 16px;
  }
  .collections-details-box {
    flex-wrap: wrap;
  }
  .founding-div h4 {
    position: relative;
  }
  .discord-section-bg {
    height: 260px;
    max-height: 260px;
  }

  #details-collapse-text p {
    color: #fff;
    font-size: 14px;
    margin: 0;
    min-width: 150px;
  }
  .count_box h5 {
    font-size: 18px;
  }
  .featured-area {
    padding: 40px 0 0;
  }
  .rightsdefoote a {
    padding-right: 11px;
  }

  .home-banner-left {
    padding: 30px 10px 0;
  }

  .soccor-banner {
    padding: 110px 0 20px !important;
    min-height: 500px;
  }

  .works-area-box {
    padding: 44px 0 50px;
  }

  #hot-collection {
    padding: 0;
  }

  .activities-heading {
    text-align: center;
    position: unset;
    margin-bottom: 20px;
  }
  .frame-image {
    width: 280px;
  }
  .soccor-banner h2 {
    margin-bottom: 80px;
  }
  .input-image {
    width: 230px;
    height: 230px;
  }
  .soccor-text {
    font-size: 24px;
  }
  .market_categories .nav-item .nav-link {
    min-width: 100px;
  }

  .market_categories .nav-item .nav-link {
    padding: 12px;
  }

  .sign-ar {
    padding: 20px 20px 20px 20px;
  }
  .react-tabs__tab {
    padding: 6px 9px !important;
    font-size: 13px;
  }
  .detail-area-buy-nft ul.react-tabs__tab-list {
    margin-bottom: 14px;
    display: flex;
  }
  button.detail-submit-btn.btn.btn-unset {
    width: 100px !important;
    height: 35px !important;
    font-size: 14px !important;
  }
  .detail-area-buy-nft label.m-0.form-label {
    font-size: 15px;
  }
  .meta-mask-modal h3 {
    font-size: 20px;
  }
  .bid-modal h5 {
    font-size: 17px;
  }
  button.select-payement-buy-btn {
    height: 37px !important;
  }
  .buy-now-modal .buy-now-bt {
    height: 43px;
    font-size: 13px !important;
  }
  .search_marketplace {
    align-items: unset;
    flex-direction: column;
  }
  select.sort-by-filter.form-select {
    margin-top: 12px;
    width: 268px;
  }
}
.buy-modal {
  display: flex;
}
.buy-modal button {
  padding: 4px 4px;
  margin: 4px 2px;
}
.pagination li.active {
  background: #fe7702;
}

ul.pagination li a {
  color: #fe7702;
}
.pagination li.active a {
  color: #fff;
}
ul.pagination li {
  border: 1px solid #fe7702;
  padding: 6px 14px;
  /* background: #000; */
  border-radius: 5px;
  margin: 0 4px;
}
ul.pagination {
  justify-content: center;
}

/* shimmer css */

.card {
  position: relative !important;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.041);
  background-color: #f4f5f6 !important;
  border-radius: 6px;
  height: 420px !important;
  overflow: hidden;
  border-radius: 20px;
  padding: 10px;
  border: none !important;
}
.card .shimmerBG {
  animation-duration: 3.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #ffffff 8%, #ffffff 18%, #8364e221 33%);
  background-size: 1200px 100%;
}
@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
.card .media {
  height: 306px !important;
}
.card .p-32 {
  padding: 20px 0 0;
  height: fit-content;
}
.card .title-line {
  height: 24px;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 20px;
}
.card .content-line {
  height: 8px;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 8px;
}
.card .end {
  width: 40%;
}

.m-t-24 {
  margin-top: 24px;
}
.card-small {
  height: 106px !important;
  padding: 0 !important;
}
.wallet-connected-popup {
  background: rgba(36, 36, 53, 0.9411764705882353) !important;
  box-shadow: 0 4px 20px 0 rgb(10 10 10 / 80%);
}

.popular-area-box .slider-next-btn,
.popular-area-box .slider-prev-btn {
  top: 67%;
}

.inner.three {
  right: 0%;
  bottom: 0%;
  animation: rotate-three 1s linear infinite;
  border-top: 3px solid #efeffa;
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
.frame-ai-image .frame-center-image-area img {
  background: #000;
}

.loader__tile__9 {
  background-color: #eff2e4;
  animation-delay: 0.1s * 9;
}
@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }
  11% {
    transform: rotateY(180deg);
  }
}

.loader {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  perspective: 800px;
}

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.inner.one {
  left: 0%;
  top: 0%;
  animation: rotate-one 1s linear infinite;
  border-bottom: 3px solid #efeffa;
}

.inner.two {
  right: 0%;
  top: 0%;
  animation: rotate-two 1s linear infinite;
  border-right: 3px solid #efeffa;
}

.loader__tile {
  display: block;
  float: left;
  width: 33.33%;
  height: 33.33%;
  animation-name: flip;
  transform-style: preserve-3d;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  transform: rotateY(0deg);
  z-index: 0;
}
.loader__tile__1 {
  background-color: #fe7702;
  animation-delay: 0.1s * 1;
}
.loader__tile__2 {
  background-color: #d7532d;
  animation-delay: 0.1s * 2;
}
.loader__tile__3 {
  background-color: #fe7702;
  animation-delay: 0.1s * 3;
}
.loader__tile__4 {
  background-color: #f8a8ff;
  animation-delay: 0.1s * 4;
}
.loader__tile__5 {
  background-color: #b39a3b;
  animation-delay: 0.1s * 5;
}
.loader__tile__6 {
  background-color: #f8a8ff;
  animation-delay: 0.1s * 6;
}
.loader__tile__7 {
  background-color: #ece5be;
  animation-delay: 0.1s * 7;
}
.loader__tile__8 {
  background-color: #fe7702;
  animation-delay: 0.1s * 8;
}
